import { BaseComponent } from "../../../../common/component/base.component";
import Model from "react-modal";
import { IAppState } from "../../../../store";
import { connect } from "react-redux";
import { setCommentData } from "../../../../common/stores/comment.slice";
import { IUserModel } from "../../../user/model/user.model";
import { ReportCommentModel, ReportCommentPayload } from "../../model/report.model";
import { ReportCommentDefault } from "../../model/defaults/report.default";
import { enableTestEdit } from "../../store/cleanroom-equipment/test.slice"
import RouteHelper from "../../../../common/helper/route-helper";

export interface IProps {
    testId: number,
    reportId: number,
    testName: string,
    reportCategory: number,
    createComment: (data: ReportCommentPayload) => void,
    commentData: ReportCommentModel,
    currentUser: IUserModel,
    setCommentData: (data: ReportCommentModel) => void,
}

export interface IState {
    show: boolean,
    comment: string,
}

const defaultState: IState = {
    show: false,
    comment: "",
}

class EditEnableComponent extends BaseComponent<IProps, IState> {

    constructor(props) {
        super(props);
        this.state = defaultState;
    }

    showHandler = () => {
        this.setState({ ...this.state, show: !this.state.show, })
    }

    async componentDidMount() {
        await this.props.setCommentData(ReportCommentDefault);
    }

    async componentWillUnmount() {
        this.setState(defaultState);
    }

    render() {
        return (RouteHelper.IsPortalAdmin() ? <div>
            <button className="btn m-0 btn-primary px-3 py-2 ml-3"
                onClick={this.showHandler}>Enable Edit
            </button>
            <Model
                isOpen={this.state.show}
                onRequestClose={() => {
                    this.showHandler();
                }}>
                <label htmlFor="commentBox">Add Comment</label>
                <textarea
                    id={"commentBox"}
                    className="form-control"
                    value={this.state.comment ?? ""}
                    onChange={e => {
                        e.preventDefault();
                        this.setState({ ...this.state, comment: e.target.value?.trimStart() })
                    }}>
                </textarea>
                <div className="text-right mt-2">

                    <button className="btn mr-0 mb-0 btn-primary py-2"
                        onClick={async (e) => {
                            e.preventDefault();
                            let object: ReportCommentModel = {
                                ...this.props.commentData,
                                reportTestName: this.props.testName,
                                reportId: this.props.reportId,
                                reportCategoryId: this.props.reportCategory,
                                reportTestId: this.props.testId,
                                commentDescription: this.state.comment?.trimStart()
                            }

                            this.props.createComment({ testName: this.props.testName, data: object });
                            this.setState(defaultState);
                        }}
                    >
                        Submit
                    </button>
                </div>
            </Model>
        </div>
            : ""
        )
    }
}

const mapStateToProps = (state: IAppState) => {
    return {
        commentData: state.comment.commentData,
        currentUser: state.userSession.currentUser,
    }
}

export default connect(mapStateToProps, { createComment: enableTestEdit, setCommentData, })(EditEnableComponent);