import {IFieldValidationModel, IValidatorModel} from "../../../common/model/validator.model";
import {ValidationScreens, ValidationType} from "../../../common/enums";


const fields: IFieldValidationModel[] = [
    {
        fieldName: "name",
        rules: [
            {
                type: ValidationType.Required,
                message: "Data logger name is required"
            }
        ]
    },
    {
        fieldName: "categoryId",
        rules: [
            {
                type: ValidationType.Required,
                message: "Category is required"
            }
        ]
    },
    {
        fieldName: "subCategoryId",
        rules: [
            {
                type: ValidationType.Required,
                message: "Sub-Category is required"
            }
        ]
    },
    {
        fieldName: "makeId",
        rules: [
            {
                type: ValidationType.Required,
                message: "Maker is required"
            }
        ]
    },
    {
        fieldName: "serialNumber",
        rules: [
            {
                type: ValidationType.Required,
                message: "Serial number is required"
            }
        ]
    },
    {
        fieldName: "lastCalibratedOn",
        rules: [
            {
                type: ValidationType.Required,
                message: "Last calibrated on is required"
            }
        ]
    },
    {
        fieldName: "calibrationDueOn",
        rules: [
            {
                type: ValidationType.Required,
                message: "Calibration due on is required"
            }
        ]
    }
]

export const dataLoggerValidator: IValidatorModel = {
    screen: ValidationScreens.DataLogger,
    fields: fields
}
