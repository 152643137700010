import { IDashboardSummaryModel } from "../model/dashboard.model";
import { dashboardSummaryDefault } from "../model/dashboard.defailt";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import DashboardService from "../service/dashboard.service";

export interface IDashboardState {
    summaryData: IDashboardSummaryModel
    summaryLoading: boolean
}

const initialState: IDashboardState = {
    summaryData: dashboardSummaryDefault,
    summaryLoading: false
}
export const loadSummary = createAsyncThunk("dashboard/loadSummary", DashboardService.GetSummary);

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        clearSummaryState: (state) => { state.summaryData = dashboardSummaryDefault }
    },
    extraReducers: (builder) => {
        // Load Summary
        builder.addCase(loadSummary.pending, (state, action) => {
            clearSummaryState();
            state.summaryLoading = true;
        });
        builder.addCase(loadSummary.rejected, (state, action) => {
            clearSummaryState();
            state.summaryLoading = false;
        });
        builder.addCase(loadSummary.fulfilled, (state, action) => {
            state.summaryData = action.payload;
            state.summaryLoading = false;
        });
    }
});


const { actions, reducer } = dashboardSlice;

export const { clearSummaryState } = actions;

export default reducer;
