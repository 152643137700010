import {IFieldValidationModel, IValidatorModel} from "../../../common/model/validator.model";
import {ValidationScreens, ValidationType} from "../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "name",
        rules: [
            {
                type: ValidationType.Required,
                message: "Access role name is required"
            }
        ]
    }
]

export const accessValidator: IValidatorModel = {
    screen: ValidationScreens.Client,
    fields: fields
}
