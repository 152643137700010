import {IFeatureModel} from "../feature.model";

export const featureDefault: IFeatureModel = {
    id: 0,
    name: "",
    displayName: "",
    featureAccessLevels: [],
    accessLevelEditDisabled: false,
    onlyForSysAdmin: false
}
