import {IFieldValidationModel, IValidatorModel} from "../../../../common/model/validator.model";
import {ValidationScreens, ValidationType} from "../../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "assigneeId",
        rules: [
            {
                type: ValidationType.Required,
                message: "Assignee is required"
            }
        ]
    },
    {
        fieldName: "isRawDataReviewed",
        rules: [
            {
                type: ValidationType.RequiredIfTrue,
                message: "Please complete rawData reviewed"
            }
        ]
    },
    {
        fieldName: "isActivitySheetReviewed",
        rules: [
            {
                type: ValidationType.RequiredIfTrue,
                message: "Please complete activity sheet reviewed"
            }
        ]
    },
    {
        fieldName: "isActivityAnnexuresReviewed",
        rules: [
            {
                type: ValidationType.RequiredIfTrue,
                message: "Please complete activity annexures reviewed"
            }
        ]
    },
    {
        fieldName: "isDocumentsReviewed",
        rules: [
            {
                type: ValidationType.RequiredIfTrue,
                message: "Please complete documents reviewed"
            }
        ]
    }
]

export const workflowTaskDocumentReviewStageValidator: IValidatorModel = {
    screen: ValidationScreens.WorkflowTaskDocumentReviewStage,
    fields: fields
}
