import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected,PayloadAction } from "@reduxjs/toolkit";
import { IStoreSaveModel } from "../../../../common/model/store-save.model";
import { IAirflowVelocityTestObservationModel } from "../../model/test/test.model";
import {IFilterIntegrityObservationModel} from "../../model/test/filter-integrity-test.model";
import { TestObservationDefaults } from "../../model/defaults/test.default";
import ObservationService from "../../service/observation.service";
import ReadingService from "../../service/reading.service";
import { ILITestObservationModel } from "../../model/test/light-intensity-test.model";
import { IPCObservation} from "../../model/test/particle-count-test.model";
import { IRecoveryReadings } from "../../model/test/recovery-test.model";
import { ISoundLevelObservationModel } from "../../model/test/sound-level-test.model";
import { IUVLightTestObservationModel } from "../../model/test/uv-light-test.model";

// -- AV
export const loadAVObservationById = createAsyncThunk("report/observation/av/loadById", ObservationService.GetAVObservationById);
export const createAVObservation = createAsyncThunk("report/observation/av/create", ObservationService.CreateAVObservation);
export const editAVObservation = createAsyncThunk("report/observation/av/edit", ObservationService.EditAVObservation);

// --- common ----
export const deleteObservation = createAsyncThunk("report/observation/delete", ObservationService.DeleteObservation);
// ---- ----

// -- AV REading
export const createAVReading = createAsyncThunk("report/observation/av/reading/create", ReadingService.CreateAVReading);
export const editAVReading = createAsyncThunk("report/observation/av/reading/edit", ReadingService.EditAVReading);

export const deleteReading = createAsyncThunk("report/observation/reading/delete", ReadingService.DeleteReading);

export const loadFIObservationById = createAsyncThunk("report/observation/fi/loadById", ObservationService.GetFIObservationById);
export const createFIObservation = createAsyncThunk("report/observation/fi/create", ObservationService.CreateFIObservation);
export const editFIObservation = createAsyncThunk("report/observation/fi/edit", ObservationService.EditFIObservation);


// --- LI ---
export const loadLIObservationById = createAsyncThunk("report/observation/li/loadById", ObservationService.GetLIObservationById);
export const createLIObservation = createAsyncThunk("report/observation/li/create", ObservationService.CreateLIObservation);
export const editLIObservation = createAsyncThunk("report/observation/li/edit", ObservationService.EditLIObservation);

export const createLIReading = createAsyncThunk("report/observation/li/reading/create", ReadingService.CreateLIReading);
export const editLIReading = createAsyncThunk("report/observation/li/reading/edit", ReadingService.EditLIReading);

// --- PC ---
export const loadPCLocationById = createAsyncThunk("report/location/pc/loadById", ObservationService.GetPCLocationById);
export const createPCLocation = createAsyncThunk("report/location/pc/create", ObservationService.CreatePCLocation);
export const editPCLocation = createAsyncThunk("report/location/pc/edit", ObservationService.EditPCLocation);
export const deletePCLocation = createAsyncThunk("report/location/pc/delete", ObservationService.DeletePCLocation);

// --- PC Observation
export const createPCObservation = createAsyncThunk("report/location/pc/observation/create", ReadingService.CreatePCObservation);
export const editPCObservation = createAsyncThunk("report/location/pc/observation/edit", ReadingService.EditPCObservation);
export const deletePCObservation = createAsyncThunk("report/location/pc/observation/delete", ReadingService.DeletePCObservation);

// --- Recovery Reading ---
export const createRecoveryReading = createAsyncThunk("report/recovery/reading/create", ReadingService.CreateRecoveryReading);
export const editRecoveryReading = createAsyncThunk("report/recovery/reading/edit", ReadingService.EditRecoveryReading);
export const deleteRecoveryreading = createAsyncThunk("report/recovery/reading/delete", ReadingService.DeleteRecoveryReading);

// --- Sound level observation ---
export const createSLObservation = createAsyncThunk("report/sl/observation/create", ObservationService.CreateSLObservation);
export const editSLObservation = createAsyncThunk("report/sl/observation/edit", ObservationService.EditSLObservation);

export const createSLReading = createAsyncThunk("report/sl/observation/reading/create", ReadingService.CreateSLReading);
export const editSLReading = createAsyncThunk("report/sl/observation/reading/edit", ReadingService.EditSLReading);
export const deleteSLReading = createAsyncThunk("report/sl/observation/reading/delete", ReadingService.DeleteReading);

// --- uvli observation ---
export const createUVLIObservation = createAsyncThunk("report/uvli/observation/create", ObservationService.CreateUVLIObservation);
export const editUVLIObservation = createAsyncThunk("report/uvli/observation/edit", ObservationService.EditUVLIObservation);

export const createUVLIReading = createAsyncThunk("report/uvli/observation/reading/create", ReadingService.CreateUVLIReading);
export const editUVLIReading = createAsyncThunk("report/uvli/observation/reading/edit", ReadingService.EditUVLIReading);
export const deleteUVLIReading = createAsyncThunk("report/uvli/observation/reading/delete", ReadingService.DeleteReading);

export interface ICleanroomEquipmentObservationState {
    observationList: IAirflowVelocityTestObservationModel[],
    observationData: IAirflowVelocityTestObservationModel | IFilterIntegrityObservationModel | ILITestObservationModel | IPCObservation | IRecoveryReadings | ISoundLevelObservationModel | IUVLightTestObservationModel,
    dataLoading: boolean,
    listLoading: boolean,
    createEditLoading: boolean,
    stageMoveLoading: boolean,
    deletedRoomId: number,
}

const initialState: ICleanroomEquipmentObservationState = {
    observationList: [],
    observationData: TestObservationDefaults,
    dataLoading: false,
    listLoading: false,
    createEditLoading: false,
    stageMoveLoading: false,
    deletedRoomId: -1,
}

const cleanroomEquipmentObservationSlice = createSlice({
    name: 'observation',
    initialState,
    reducers: {
        clearObservationDataState: (state, action) => { state.observationData = action.payload },
        updateObservationDataState: (state, action: PayloadAction<IStoreSaveModel>) => {
            state.observationData[action.payload.name] = action.payload.value;
        },
        setDeletdRoomId: (state, action: PayloadAction<number>) => {
            state.deletedRoomId = action.payload;
        }
    },
    extraReducers: (builder) => {

        // load, create
        builder.addMatcher(isFulfilled(createAVObservation,loadAVObservationById,createLIObservation,loadLIObservationById,createPCObservation,createRecoveryReading,createSLObservation),(state, action) => {
            state.observationData = action.payload;
            state.createEditLoading = false;
        });

        // edit
        builder.addMatcher(isFulfilled(editAVObservation,editLIObservation,editPCObservation,editRecoveryReading,editSLObservation),(state, action) => {
            state.observationData = action.payload;
        });
     
    }
});

const { actions, reducer } = cleanroomEquipmentObservationSlice;

export const { clearObservationDataState, updateObservationDataState, setDeletdRoomId } = actions;

export default reducer;