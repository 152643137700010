import React from "react";
import { IClientAddressModel, IClientModel } from "../../model/client.model";
import { IAppState } from "../../../../store";
import { connect } from "react-redux";
import { addMergeClientAddressToClientDataState, loadClientById, updateClientDataState } from "../../store/client.slice";
import { IStoreSaveModel } from "../../../../common/model/store-save.model";
import { clearClientAddressDataState, createClientAddress, editClientAddress, loadClientAddressById, updateClientAddressDataState } from "../../store/client-address.slice";
import { Link, Navigate } from "react-router-dom";
import ScreenUrls from "../../../../common/screen-urls";
import { FormBaseComponent, IFormBaseState } from "../../../../common/component/form-base.component";
import { ValidationScreens } from "../../../../common/enums";
import ValidationMessageControl from "../../../../common/control/validation-message.control";

export interface IProps {
    accessLevel: number,
    id?: string,
    clientId?: string,
    clientData: IClientModel,
    clientAddressData: IClientAddressModel,
    dataLoading: boolean,
    clientDataLoading: boolean,
    createEditLoading: boolean,
    loadClientById: (id: number) => void;
    loadClientAddressById: (id: number) => void;
    createClientAddress: (data: IClientAddressModel) => void;
    editClientAddress: (data: IClientAddressModel) => void;
    addMergeClientAddressToClientDataState: (payload: IClientAddressModel) => void;
    updateClientAddressDataState: (payload: IStoreSaveModel) => void;
    clearClientAddressDataState: () => void;
    updateClientDataState: (payload: IStoreSaveModel) => void;
}

export interface IState extends IFormBaseState {
    redirectUrl: string
}

class ClientCreateEditPage extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            redirectUrl: "",
            hideForm: false
        }
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.ClientAddress);

        if (this.props.id != null && Number(this.props.id) > 0) {
            await this.props.loadClientAddressById(Number(this.props.id));
        }
        else {
            await this.props.clearClientAddressDataState();
        }

        if (this.props.clientId != null && Number(this.props.clientId) > 0) {
            await this.props.loadClientById(Number(this.props.clientId));
            this.props.updateClientAddressDataState({ name: "clientId", value: Number(this.props.clientId) });
        }
    }

    render() {
        if (this.state.redirectUrl != "") return <Navigate to={this.state.redirectUrl} replace />
        return <React.Fragment>
            <div className="pcoded-content">
                <div className="pcoded-inner-content">
                    <div className="page-header">
                        <div className="page-block">
                            <div className="row">
                                <div className="col-md-12">
                                    <Link to={this.props.clientId != null && Number(this.props.clientId) > 0 ? ScreenUrls.Clients.Edit(this.props.clientId) : ScreenUrls.Clients.Create()} className="btn drp-icon btn-rounded btn-primary dropdown-toggle">
                                        <i className="feather icon-arrow-left"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    {(() => {
                        if (this.state.hideForm) return;
                        return <div className="main-body">
                            <div className="page-wrapper">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <article className="card mb-4">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className={`form-group col-6 ${this.vResult("displayName").className}`}>
                                                        <label htmlFor="displayName">Display Name<span className="requried-span">*</span></label>
                                                        <input id="displayName" className="form-control" type="text"
                                                            value={this.props.clientAddressData?.displayName ?? ""}
                                                            onChange={e => {
                                                                e.preventDefault();
                                                                this.ValidateField("displayName", e.target.value);
                                                                this.props.updateClientAddressDataState({ name: "displayName", value: e.target.value });
                                                            }} />
                                                        <ValidationMessageControl message={this.vResult("displayName").message} />
                                                    </div>
                                                    <div className={`form-group col-6 ${this.vResult("buildingName").className}`}>
                                                        <label htmlFor="buildingName">Building Name<span className="requried-span">*</span></label>
                                                        <input id="buildingName" className="form-control" type="text"
                                                            value={this.props.clientAddressData?.buildingName ?? ""}
                                                            onChange={e => {
                                                                e.preventDefault();
                                                                this.ValidateField("buildingName", e.target.value);
                                                                this.props.updateClientAddressDataState({ name: "buildingName", value: e.target.value });
                                                            }} />
                                                        <ValidationMessageControl message={this.vResult("buildingName").message} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className={`form-group col-6 ${this.vResult("addressLine1").className}`}>
                                                        <label htmlFor="addressLine1">Address Line 1<span className="requried-span">*</span></label>
                                                        <input id="addressLine1" className="form-control" type="text"
                                                            value={this.props.clientAddressData?.addressLine1 ?? ""}
                                                            onChange={e => {
                                                                e.preventDefault();
                                                                this.ValidateField("addressLine1", e.target.value);
                                                                this.props.updateClientAddressDataState({ name: "addressLine1", value: e.target.value });
                                                            }} />
                                                        <ValidationMessageControl message={this.vResult("addressLine1").message} />
                                                    </div>
                                                    <div className={`form-group col-6 ${this.vResult("addressLine2").className}`}>
                                                        <label htmlFor="addressLine2">Address Line 2<span className="requried-span">*</span></label>
                                                        <input id="addressLine2" className="form-control" type="text"
                                                            value={this.props.clientAddressData?.addressLine2 ?? ""}
                                                            onChange={e => {
                                                                e.preventDefault();
                                                                this.ValidateField("addressLine2", e.target.value);
                                                                this.props.updateClientAddressDataState({ name: "addressLine2", value: e.target.value });
                                                            }} />
                                                        <ValidationMessageControl message={this.vResult("addressLine2").message} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className={`form-group col-6 ${this.vResult("addressLine3").className}`}>
                                                        <label htmlFor="addressLine3">Address Line 3</label>
                                                        <input id="addressLine3" className="form-control" type="text"
                                                            value={this.props.clientAddressData?.addressLine3 ?? ""}
                                                            onChange={e => {
                                                                e.preventDefault();
                                                                this.ValidateField("addressLine3", e.target.value);
                                                                this.props.updateClientAddressDataState({ name: "addressLine3", value: e.target.value });
                                                            }} />
                                                        <ValidationMessageControl message={this.vResult("addressLine3").message} />
                                                    </div>
                                                    <div className={`form-group col-6 ${this.vResult("landmark").className}`}>
                                                        <label htmlFor="landmark">Landmark</label>
                                                        <input id="landmark" className="form-control" type="text"
                                                            value={this.props.clientAddressData?.landmark ?? ""}
                                                            onChange={e => {
                                                                e.preventDefault();
                                                                this.ValidateField("landmark", e.target.value);
                                                                this.props.updateClientAddressDataState({ name: "landmark", value: e.target.value });
                                                            }} />
                                                        <ValidationMessageControl message={this.vResult("landmark").message} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className={`form-group col-3 ${this.vResult("city").className}`}>
                                                        <label htmlFor="city">City<span className="requried-span">*</span></label>
                                                        <input id="city" className="form-control" type="text"
                                                            value={this.props.clientAddressData?.city ?? ""}
                                                            onChange={e => {
                                                                e.preventDefault();
                                                                this.ValidateField("city", e.target.value);
                                                                this.props.updateClientAddressDataState({ name: "city", value: e.target.value });
                                                            }} />
                                                        <ValidationMessageControl message={this.vResult("city").message} />
                                                    </div>
                                                    <div className={`form-group col-3 ${this.vResult("postalCode").className}`}>
                                                        <label htmlFor="postalCode">Pin Code<span className="requried-span">*</span></label>
                                                        <input id="postalCode" className="form-control" type="text"
                                                            value={this.props.clientAddressData?.postalCode ?? ""}
                                                            onChange={e => {
                                                                e.preventDefault();
                                                                this.ValidateField("postalCode", e.target.value);
                                                                this.props.updateClientAddressDataState({ name: "postalCode", value: e.target.value });
                                                            }} />
                                                        <ValidationMessageControl message={this.vResult("postalCode").message} />
                                                    </div>
                                                    <div className={`form-group col-3 ${this.vResult("state").className}`}>
                                                        <label htmlFor="state">State<span className="requried-span">*</span></label>
                                                        <input id="state" className="form-control" type="text"
                                                            value={this.props.clientAddressData?.state ?? ""}
                                                            onChange={e => {
                                                                e.preventDefault();
                                                                this.ValidateField("state", e.target.value);
                                                                this.props.updateClientAddressDataState({ name: "state", value: e.target.value });
                                                            }} />
                                                        <ValidationMessageControl message={this.vResult("state").message} />
                                                    </div>
                                                    <div className={`form-group col-3 ${this.vResult("country").className}`}>
                                                        <label htmlFor="country">Country<span className="requried-span">*</span></label>
                                                        <input id="country" className="form-control" type="text"
                                                            value={this.props.clientAddressData?.country ?? ""}
                                                            onChange={e => {
                                                                e.preventDefault();
                                                                this.ValidateField("country", e.target.value);
                                                                this.props.updateClientAddressDataState({ name: "country", value: e.target.value });
                                                            }} />
                                                        <ValidationMessageControl message={this.vResult("country").message} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className={`form-group col-6 ${this.vResult("email").className}`}>
                                                        <label htmlFor="email">Email<span className="requried-span">*</span></label>
                                                        <input id="email" className="form-control" type="text"
                                                            value={this.props.clientAddressData?.email ?? ""}
                                                            onChange={e => {
                                                                e.preventDefault();
                                                                this.ValidateField("email", e.target.value);
                                                                this.props.updateClientAddressDataState({ name: "email", value: e.target.value });
                                                            }} />
                                                        <ValidationMessageControl message={this.vResult("email").message} />
                                                    </div>
                                                    <div className={`form-group col-6 ${this.vResult("contactNo").className}`}>
                                                        <label htmlFor="contactNo">Contact No<span className="requried-span">*</span></label>
                                                        <input id="contactNo" className="form-control" type="text"
                                                            value={this.props.clientAddressData?.contactNo ?? ""}
                                                            onChange={e => {
                                                                e.preventDefault();
                                                                this.ValidateField("contactNo", e.target.value);
                                                                this.props.updateClientAddressDataState({ name: "contactNo", value: e.target.value });
                                                            }} />
                                                        <ValidationMessageControl message={this.vResult("contactNo").message} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className={`form-group col-6 ${this.vResult("faxNo").className}`}>
                                                        <label htmlFor="faxNo">Fax No</label>
                                                        <input id="addressLine2" className="form-control" type="text"
                                                            value={this.props.clientAddressData?.faxNo ?? ""}
                                                            onChange={e => {
                                                                e.preventDefault();
                                                                this.ValidateField("faxNo", e.target.value);
                                                                this.props.updateClientAddressDataState({ name: "faxNo", value: e.target.value });
                                                            }} />
                                                        <ValidationMessageControl message={this.vResult("faxNo").message} />
                                                    </div>
                                                </div>
                                            </div>
                                        </article>

                                        <article>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <button type="button" className="btn btn-primary btn--right"
                                                            disabled={this.props.clientAddressData == null || this.hasError}
                                                            onClick={async (e) => {
                                                                e.preventDefault();

                                                                const error = !this.ValidateForm(this.props.clientAddressData);
                                                                this.reloadForm();
                                                                if (error) return;

                                                                if (this.props.clientAddressData.id != null && this.props.clientAddressData.id > 0) {
                                                                    this.props.editClientAddress(this.props.clientAddressData);
                                                                } else {
                                                                    this.props.createClientAddress({ ...this.props.clientAddressData, clientId: this.props.clientData.id });
                                                                }

                                                                this.props.clearClientAddressDataState();
                                                                const clientId = this.props.clientData.id ? Number(this.props.clientData.id) : 0;
                                                                if (clientId > 0) {
                                                                    await this.props.loadClientById(clientId);
                                                                }

                                                                this.setState({ redirectUrl: ScreenUrls.Clients.Edit(this.props.clientData.id) });

                                                            }}>Submit</button>
                                                    </div>
                                                </div>
                                            </div>

                                            {(() => { if (this.props.createEditLoading) return <div className="loading--bar"><span></span></div> })()}
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })()}
                </div>
            </div>
        </React.Fragment>
    }
}

const mapStateToProps = (state: IAppState) => ({
    clientData: state.client.clientData,
    clientAddressData: state.clientAddress.clientAddressData,
    dataLoading: state.clientAddress.dataLoading,
    clientDataLoading: state.client.dataLoading,
    createEditLoading: state.clientAddress.createEditLoading
})

export default connect(mapStateToProps, {
    loadClientById, loadClientAddressById,
    createClientAddress, editClientAddress,
    addMergeClientAddressToClientDataState, updateClientDataState,
    updateClientAddressDataState, clearClientAddressDataState
})(ClientCreateEditPage);
