import {IWorkflowModel} from "../workflow.model";

export const workflowDefault: IWorkflowModel = {
    id: 0,
    currentStageId: 0,
    purchaseOrderId: 0,
    isComplete: false,
    nextStageAssigneeList: null,
    rejectStageAssigneeList: null,
}

