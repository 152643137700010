import {connect} from "react-redux";
import {BaseComponent} from "../../../common/component/base.component";
import {IClientModel} from "../model/client.model";
import {loadClients} from "../store/client.slice";
import {IAppState} from "../../../store";
import {Link} from "react-router-dom";
import ScreenUrls from "../../../common/screen-urls";
import {AgGridReact} from "ag-grid-react";
import {AccessLevel} from "../../../common/enums";
import {AgGridDefaultColDef} from "../../../common/app-defaults";
import ClientService from "../service/client.service";
import GridDeleteButtonControl from "../../../common/control/component/grid/grid-delete-buton.control";

export interface IProps {
    accessLevel: number,
    clientList: IClientModel[],
    loadClients: () => void,
    listLoading: boolean
}

class ClientPage extends BaseComponent<IProps, any> {
    componentDidMount() {
        this.props.loadClients();
    }

    render() {
        return  <div className="pcoded-content">
            <div className="pcoded-inner-content">
                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    {(() => { if(this.props.listLoading) return <div className="loading--bar"><span></span></div> })()}
                                    <div className="card-header">
                                        <h5>List of clients</h5>
                                        <div className="card-header-right">
                                            <div className="btn-group card-option">
                                                {(() => {
                                                    if(this.props.accessLevel >= AccessLevel.Create) {
                                                        return <Link className="btn drp-icon btn-rounded btn-primary" to={ScreenUrls.Clients.Create()}>
                                                            Add New
                                                        </Link>
                                                    }
                                                })()}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-block ag-theme-alpine xx-large-grid">
                                        <AgGridReact
                                            pagination={true}
                                            paginationAutoPageSize={true}
                                            unSortIcon={true}
                                            rowHeight={60}
                                            defaultColDef={AgGridDefaultColDef}
                                            columnDefs={[
                                                {
                                                    suppressMovable: true,
                                                    hide: this.props.accessLevel < AccessLevel.Edit,
                                                    headerName: "Edit", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                    cellRenderer: (params) => {
                                                        return (
                                                            <Link to={ScreenUrls.Clients.Edit(params.value)}>
                                                                <i className={`feather icon-edit`}></i>
                                                            </Link>
                                                        )
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    hide: this.props.accessLevel < AccessLevel.Delete,
                                                    headerName: "Delete", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link delete",
                                                    cellRenderer: (params) => {
                                                        return (                                                             
                                                              <GridDeleteButtonControl onDelete={async () => {                                                                 
                                                                await ClientService.Delete(params.value);
                                                                   this.props.loadClients();                                                                                                                                     
                                                             }}></GridDeleteButtonControl>
                                                        )
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "#", field: 'id', width: 100, cellClass: "grid-cell",
                                                    resizable:true,
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value}</span>
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Company Name", field: "companyName", flex: 2.5, sortable: true, cellClass: "grid-cell",
                                                    resizable:true,
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value}</span>
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Description", field: "description", flex: 2, sortable: true, cellClass: "grid-cell",
                                                    resizable:true,
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value}</span>
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "GST", field: "gstInNumber", flex: 1, sortable: true, cellClass: "grid-cell",
                                                    resizable:true,
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value}</span>
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "PAN", field: "panNumber", flex: 1, sortable: true, cellClass: "grid-cell",
                                                    resizable:true,
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value}</span>
                                                    }
                                                }
                                            ]}
                                            rowData={this.props.clientList}>
                                        </AgGridReact>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state: IAppState) => ({
    clientList: state.client.clientList,
    listLoading: state.client.dataLoading
})

export default connect(mapStateToProps, { loadClients })(ClientPage);
