import { Store } from "react-notifications-component";
import { FailureNotificationOptions, NotificationOptions } from "../app-defaults";

export default class NotificationHelper {
    static Error(err?: string) {
        if (err == null) err = "API failed to resolve";
        Store.addNotification({
            ...FailureNotificationOptions,
            title: "Error",
            message: err
        });
    }

    static Success(msg: string) {
        Store.addNotification({
            ...NotificationOptions,
            type: "success",
            title: "Success",
            message: msg
        });
    }

    static Created() { NotificationHelper.Success("Created successfully"); }

    static Updated() { NotificationHelper.Success("Updated successfully"); }

    static Custom(msg) { NotificationHelper.Success(msg); }
}
