import React from "react";
import ScreenUrls from "../../../../common/screen-urls";
import {Link} from "react-router-dom";
import {IAppState} from "../../../../store";
import {connect} from "react-redux";
import {IAccessRoleModel} from "../../model/access-role.model";
import {
    clearAccessRoleDataState,
    createAccessRole,
    editAccessRole,
    loadAccessRoleById, updateAccessRoleDataState
} from "../../store/access-role.slice";
import {IStoreSaveModel} from "../../../../common/model/store-save.model";
import {IGetAllRequestModel} from "../../../../common/model/get-all-request-model";
import {IFeatureModel} from "../../model/feature.model";
import {clearFeatureListState, loadFeatures} from "../../store/feature.slice";
import {AccessLevel, ValidationScreens} from "../../../../common/enums";
import {AgGridReact} from "ag-grid-react";
import {FormBaseComponent, IFormBaseState} from "../../../../common/component/form-base.component";
import ValidationMessageControl from "../../../../common/control/validation-message.control";
import {IUserModel} from "../../../user/model/user.model";
import {AppConstants} from "../../../../common/app-constants";

export interface IProps {
    accessLevel: number,
    id?: string,
    accessRoleData: IAccessRoleModel,
    dataLoading: boolean,
    createEditLoading: boolean,
    featureList: IFeatureModel[],
    featureListLoading: boolean,
    createAccessRole: (data: IAccessRoleModel) => void,
    editAccessRole: (data: IAccessRoleModel) => void,
    loadAccessRoleById: (id: number) => void,
    updateAccessRoleDataState: (payload: IStoreSaveModel) => void,
    clearAccessRoleDataState: () => void,
    clearFeatureListState: () => void,
    loadFeatures: (request?: IGetAllRequestModel) => void,
    currentUser: IUserModel
}

export interface IState extends IFormBaseState { }

class AccessRoleCreateEditPage extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            hideForm: false
        }
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.AccessRole);

        if(this.props.id != null) {
            await this.props.loadAccessRoleById(Number(this.props.id));
            await this.props.loadFeatures();
        }
        else {
            await this.props.clearAccessRoleDataState();
            await this.props.clearFeatureListState();
        }
    }

    updateFeatureAccessLevel(featureId: number, accessRoleId: number, level: AccessLevel) {
        let featureAccessLevels = this.props.accessRoleData.featureAccessLevels ?? [];
        let record = featureAccessLevels.filter(fa => fa.featureId === featureId && fa.accessRoleId === accessRoleId);

        if(record == null || record.length == 0) return;

        const updated = [
            ...featureAccessLevels.filter(fa => !(fa.featureId === featureId && fa.accessRoleId === accessRoleId)),
            {
                ...record[0],
                accessLevel: Number(level)
            }
        ];
        this.props.updateAccessRoleDataState({ name: "featureAccessLevels", value: updated });
    }

    render() {
        return <React.Fragment>
            <div className="pcoded-content">
                <div className="pcoded-inner-content">
                    <div className="page-header">
                        <div className="page-block">
                            <div className="row">
                                <div className="col-md-12">
                                    <Link to={ScreenUrls.Users.AccessRole.Index()}
                                          className="btn drp-icon btn-rounded btn-primary dropdown-toggle">
                                        <i className="feather icon-arrow-left"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-body">
                        <div className="page-wrapper">
                            <div className="row">
                                <div className="col-sm-12">
                                    <article className="card mb-4">
                                        {(() => { if(this.props.dataLoading) return <div className="loading--bar"><span></span></div> })()}
                                        {(() => {
                                            if(this.state.hideForm) return;
                                            return <div className="card-body">
                                                <div className="row">
                                                    <div className="col-4">
                                                        <div className={`form-group ${this.vResult("name").className}`}>
                                                            <label htmlFor="roleName" >Role Name<span className="requried-span">*</span></label>
                                                            <input id="roleName" className="form-control" type="text"
                                                                   value={this.props.accessRoleData?.name?.toString()}
                                                                   onChange={e => { e.preventDefault();
                                                                       this.ValidateField("name", e.target.value);
                                                                       this.props.updateAccessRoleDataState({ name: "name", value: e.target.value });
                                                                   }} />
                                                            <ValidationMessageControl message={this.vResult("name").message} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {(() => {
                                                    const accessId = this.props.accessRoleData?.id? Number(this.props.accessRoleData.id): 0;
                                                    if(accessId == null || accessId == 0) return;
                                                    return <div className="row">
                                                        <div className="col-12">
                                                            <div className="card">
                                                                <div className="card-header">
                                                                    <h5>Feature Access Level</h5>
                                                                </div>
                                                                <div className="card-block ag-theme-alpine xx-large-grid">
                                                                    <AgGridReact
                                                                        pagination={true}
                                                                        paginationAutoPageSize={true}
                                                                        unSortIcon={true}
                                                                        rowHeight={60}
                                                                        columnDefs={[
                                                                            {
                                                                                suppressMovable: true,
                                                                                hide: this.props.accessLevel < AccessLevel.Edit, sort: "asc",
                                                                                headerName: "", field: 'feature.displayName', flex: 1, cellClass: "grid-cell grid-cell-link",
                                                                                cellRenderer: (params) => {

                                                                                    const displayName = params.value ?? false;
                                                                                    let accessLevelEditDisabled = params.data.feature?.accessLevelEditDisabled ?? false;
                                                                                    if(params.data.feature && params.data.feature.onlyForSysAdmin && accessId !== AppConstants.SystemAdminAccessRoleId) {
                                                                                        accessLevelEditDisabled = !this.props.currentUser.isSysAdmin;
                                                                                    }
                                                                                    const accessLevel = Number(params.data.accessLevel);
                                                                                    const featureId = Number(params.data.featureId);
                                                                                    const accessRoleId = Number(params.data.accessRoleId);

                                                                                    return (
                                                                                        <div className="access-feature-level--row">
                                                                                            <p className="access-feature-level--title">{displayName}</p>

                                                                                            <input id="viewLevel" type="checkbox"
                                                                                                   checked={accessLevel >= AccessLevel.View}
                                                                                                   disabled={accessLevelEditDisabled}
                                                                                                   onChange={(e) => {
                                                                                                       e.preventDefault();
                                                                                                       const value = e.target.checked ? AccessLevel.View : AccessLevel.None;
                                                                                                       this.updateFeatureAccessLevel(featureId, accessRoleId, value);
                                                                                                   }} />
                                                                                            <label className="access-feature-level--label" htmlFor="viewLevel">
                                                                                                {AccessLevel[AccessLevel.View].toString()}
                                                                                            </label>

                                                                                            <input id="editLevel" type="checkbox"
                                                                                                   checked={accessLevel >= AccessLevel.Edit}
                                                                                                   disabled={accessLevelEditDisabled}
                                                                                                   onChange={(e) => {
                                                                                                       e.preventDefault();
                                                                                                       const value = e.target.checked ? AccessLevel.Edit : AccessLevel.View;
                                                                                                       this.updateFeatureAccessLevel(featureId, accessRoleId, value);
                                                                                                   }} />
                                                                                            <label className="access-feature-level--label" htmlFor="editLevel">
                                                                                                {AccessLevel[AccessLevel.Edit].toString()}
                                                                                            </label>

                                                                                            <input id="createLevel" type="checkbox"
                                                                                                   checked={accessLevel >= AccessLevel.Create}
                                                                                                   disabled={accessLevelEditDisabled}
                                                                                                   onChange={(e) => {
                                                                                                       e.preventDefault();
                                                                                                       const value = e.target.checked ? AccessLevel.Create : AccessLevel.Edit;
                                                                                                       this.updateFeatureAccessLevel(featureId, accessRoleId, value);
                                                                                                   }} />
                                                                                            <label className="access-feature-level--label" htmlFor="createLevel">
                                                                                                {AccessLevel[AccessLevel.Create].toString()}
                                                                                            </label>

                                                                                            <input id="deleteLevel" type="checkbox"
                                                                                                   checked={accessLevel >= AccessLevel.Delete}
                                                                                                   disabled={accessLevelEditDisabled}
                                                                                                   onChange={(e) => {
                                                                                                       e.preventDefault();
                                                                                                       const value = e.target.checked ? AccessLevel.Delete : AccessLevel.Create;
                                                                                                       this.updateFeatureAccessLevel(featureId, accessRoleId, value);
                                                                                                   }} />
                                                                                            <label className="access-feature-level--label" htmlFor="deleteLevel">
                                                                                                {AccessLevel[AccessLevel.Delete].toString()}
                                                                                            </label>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            }
                                                                        ]}
                                                                        rowData={this.props.accessRoleData.featureAccessLevels}>
                                                                    </AgGridReact>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })()}
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <button type="button" className="btn btn-primary btn--right"
                                                                    disabled={this.props.accessRoleData == null || this.hasError}
                                                                    onClick={e => {
                                                                        e.preventDefault();

                                                                        const error = !this.ValidateForm(this.props.accessRoleData);
                                                                        this.reloadForm();
                                                                        if(error) return;

                                                                        if (this.props.accessRoleData.id > 0) {
                                                                            this.props.editAccessRole(this.props.accessRoleData);
                                                                        } else {
                                                                            this.props.createAccessRole(this.props.accessRoleData);
                                                                        }
                                                                    }}>Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })()}
                                        {(() => { if(this.props.createEditLoading) return <div className="loading--bar"><span></span></div> })()}
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}

const mapStateToProps = (state: IAppState) => ({
    accessRoleData: state.accessRole.accessRoleData,
    dataLoading: state.accessRole.dataLoading,
    createEditLoading: state.accessRole.dataLoading,
    featureList: state.feature.featureList,
    featureListLoading: state.feature.listLoading,
    currentUser: state.userSession.currentUser
})

export default connect(mapStateToProps, { loadAccessRoleById, createAccessRole, editAccessRole, clearAccessRoleDataState, clearFeatureListState, updateAccessRoleDataState, loadFeatures })(AccessRoleCreateEditPage);
