import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DataLoggerBookingService} from "../service/data-logger-booking.service";
import {IDataLoggerBookingModel} from "../model/data-logger-booking.model";
import {dataLoggerBookingDefault} from "../model/defaults/data-logger.default";
import {IStoreSaveModel} from "../../../common/model/store-save.model";

export interface IDataLoggerBookingState {
    bookingList: IDataLoggerBookingModel[],
    bookingData: IDataLoggerBookingModel,
    dataLoading: boolean,
    listLoading: boolean,
    createEditLoading: boolean
}

const initialState: IDataLoggerBookingState = {
    bookingList: [],
    bookingData: dataLoggerBookingDefault,
    dataLoading: false,
    listLoading: false,
    createEditLoading: false
}

export const loadBookings = createAsyncThunk("data-logger/booking/loadAll", DataLoggerBookingService.LoadAll);
export const loadBookingById = createAsyncThunk("data-logger/booking/loadById", DataLoggerBookingService.GetById);
export const createBooking = createAsyncThunk("data-logger/booking/create", DataLoggerBookingService.Create);
export const editBooking = createAsyncThunk("data-logger/booking/edit", DataLoggerBookingService.Edit);

const dataLoggerBookingSlice = createSlice({
    name: 'data-logger-booking',
    initialState,
    reducers: {
        clearBookingDataState: (state) => { state.bookingData = dataLoggerBookingDefault },
        clearBookingListState: (state) => {
            state.bookingList = [];
        },
        updateBookingDataState: (state, action: PayloadAction<IStoreSaveModel>) => {
            state.bookingData[action.payload.name] = action.payload.value;
        }
    },
    extraReducers: (builder) => {
        // Load All
        builder.addCase(loadBookings.pending, (state, action) => {
            clearBookingListState();
            state.listLoading = true;
        });
        builder.addCase(loadBookings.rejected, (state, action) => {
            clearBookingListState();
            state.listLoading = false;
        });
        builder.addCase(loadBookings.fulfilled, (state, action) => {
            state.bookingList = action.payload;
            state.listLoading = false;
        });

        // Load By ID
        builder.addCase(loadBookingById.pending, (state, action) => {
            clearBookingDataState();
            state.dataLoading = true;
        });
        builder.addCase(loadBookingById.rejected, (state, action) => {
            clearBookingDataState();
            state.dataLoading = false;
        });
        builder.addCase(loadBookingById.fulfilled, (state, action) => {
            state.bookingData = action.payload;
            state.dataLoading = false;
        });

        // Create
        builder.addCase(createBooking.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(createBooking.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(createBooking.fulfilled, (state, action) => {
            state.bookingData = action.payload;
            state.createEditLoading = false;
        });

        // Edit
        builder.addCase(editBooking.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(editBooking.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(editBooking.fulfilled, (state, action) => {
            state.bookingData = action.payload;
            state.createEditLoading = false;
        });
    }
});

const { actions, reducer } = dataLoggerBookingSlice;

export const { clearBookingListState, clearBookingDataState, updateBookingDataState } = actions;

export default reducer;
