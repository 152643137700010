import store from "../../store";
import { FeatureNames } from "../enums";
import { featureAccessLevelDefault } from "../../features/access/model/defaults/feature-access-level.default";
import { IFeatureAccessLevelModel } from "../../features/access/model/feature-access-level.model";

export default class RouteHelper {
    static GetFeatureAccessLevel(feature: FeatureNames): IFeatureAccessLevelModel {
        const state = store.getState();
        const access = state.userSession.currentUser.featureAccessLevels?.filter(f => f.feature != null
            && f.feature.name.toString().toLowerCase() === FeatureNames[feature].toString().toLowerCase());
        return access != null && access.length > 0 ? access[0] : featureAccessLevelDefault;
    }

    static IsSystemAdmin(): boolean {
        const state = store.getState();
        return state.userSession.currentUser && state.userSession.currentUser.isSysAdmin;
    }

    static IsPortalAdmin(): boolean {
        const state = store.getState();
        if (!state.userSession.currentUser || !state.userSession.currentUser.accessRoles) {
            return false;
        }
        return state.userSession.currentUser?.accessRoles?.findIndex((u) => u.name == 'PortalAdmin') > -1;
    }
}
