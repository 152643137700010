import { IFieldValidationModel, IValidatorModel } from "../../../../../../common/model/validator.model";
import { ValidationScreens, ValidationType, ZoneClassificationType } from "../../../../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "recoveryTime",
        rules: [
            {
                type: ValidationType.Required,
                message: "Recovery time is required"
            }
        ]
    },
]

export const recoveryTestObservationValidator: IValidatorModel = {
    screen: ValidationScreens.RecoveryTestObservation,
    fields: fields
}
