import {IFieldValidationModel, IValidatorModel} from "../../../common/model/validator.model";
import {ValidationScreens, ValidationType} from "../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "code",
        rules: [
            {
                type: ValidationType.Required,
                message: "Code is required"
            }
        ]
    },
    {
        fieldName: "title",
        rules: [
            {
                type: ValidationType.Required,
                message: "Title is required"
            }
        ]
    },
    {
        fieldName: "hsnSacCode",
        rules: [
            {
                type: ValidationType.Required,
                message: "HSN/SAC code is required"
            }
        ]
    }
]

export const purchaseOrderServiceItemValidator: IValidatorModel = {
    screen: ValidationScreens.PurchaseOrderServiceItem,
    fields: fields
}
