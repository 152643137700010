import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IStoreSaveModel} from "../../../common/model/store-save.model";
import {IFeatureModel} from "../model/feature.model";
import {featureDefault} from "../model/defaults/feature.default";
import FeatureService from "../service/feature.service";

export interface IFeatureState {
    featureList: IFeatureModel[],
    featureData: IFeatureModel,
    dataLoading: boolean,
    listLoading: boolean,
    createEditLoading: boolean
}

const initialState: IFeatureState = {
    featureList: [],
    featureData: featureDefault,
    dataLoading: false,
    listLoading: false,
    createEditLoading: false
}

export const loadFeatures = createAsyncThunk("access/feature/loadAll", FeatureService.LoadAll);
export const loadFeatureById = createAsyncThunk("access/feature/loadById", FeatureService.GetById);
export const createFeature = createAsyncThunk("access/feature/create", FeatureService.Create);
export const editFeature = createAsyncThunk("access/feature/edit", FeatureService.Edit);

const featureSlice = createSlice({
    name: 'access/feature',
    initialState,
    reducers: {
        clearFeatureDataState: (state) => { state.featureData = featureDefault },
        clearFeatureListState: (state) => { state.featureList = [] },
        updateFeatureDataState: (state, action: PayloadAction<IStoreSaveModel>) => {
            state.featureData[action.payload.name] = action.payload.value;
        }
    },
    extraReducers: (builder) => {

        // Load All
        builder.addCase(loadFeatures.pending, (state, action) => {
            clearFeatureListState();
            state.listLoading = true;
        });
        builder.addCase(loadFeatures.rejected, (state, action) => {
            clearFeatureListState();
            state.listLoading = false;
        });
        builder.addCase(loadFeatures.fulfilled, (state, action) => {
            state.featureList = action.payload;
            state.listLoading = false;
        });

        // Load By ID
        builder.addCase(loadFeatureById.pending, (state, action) => {
            clearFeatureDataState();
            state.dataLoading = true;
        });
        builder.addCase(loadFeatureById.rejected, (state, action) => {
            clearFeatureDataState();
            state.dataLoading = false;
        });
        builder.addCase(loadFeatureById.fulfilled, (state, action) => {
            state.featureData = action.payload;
            state.dataLoading = false;
        });

        // Create
        builder.addCase(createFeature.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(createFeature.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(createFeature.fulfilled, (state, action) => {
            state.featureData = action.payload;
            state.createEditLoading = false;
        });

        // Edit
        builder.addCase(editFeature.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(editFeature.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(editFeature.fulfilled, (state, action) => {
            state.featureData = action.payload;
            state.createEditLoading = false;
        });
    }
});

const { actions, reducer } = featureSlice;

export const { clearFeatureListState, clearFeatureDataState, updateFeatureDataState } = actions;

export default reducer;
