import { Route, Routes } from "react-router-dom";
import React from "react";

import DefaultLayout from "../screen/layout/default.layout";
const LoginPage = React.lazy(() => import('../screen/anonymous/login.page'));
const LogoutPage = React.lazy(() => import('../screen/anonymous/logout.page'));
const RegisterPage = React.lazy(() => import('../screen/anonymous/register.page'));

const ForgotPasswordPage = React.lazy(() => import('../../features/password-recovery/screen/forgot.password.page'));
const ResetPasswordPage = React.lazy(() => import('../../features/password-recovery/screen/reset-password.page'));
const ResetPasswordConfirmPage = React.lazy(() => import('../../features/password-recovery/screen/reset-password-confirm.page'));


const AnonymousRoutes = () => {
    return <Routes>
        <Route element={<DefaultLayout />}>
            <Route path="login" element={<LoginPage />} />
            <Route path="logout" element={<LogoutPage />} />
            <Route path="register" element={<RegisterPage />} />
        </Route>

        <Route element={<DefaultLayout />}>
            <Route path="forgot-password" element={<ForgotPasswordPage />} />
            <Route path="reset" element={<ResetPasswordPage />} />
            <Route path="reset/confirm" element={<ResetPasswordConfirmPage />} />
        </Route>

    </Routes>;
}

export default AnonymousRoutes;
