import {IFieldValidationModel, IValidatorModel} from "../../../common/model/validator.model";
import {ValidationScreens, ValidationType} from "../../../common/enums";


const fields: IFieldValidationModel[] = [
    {
        fieldName: "displayName",
        rules: [
            { type: ValidationType.Required, message: "Display name is required" }
        ]
    },
    {
        fieldName: "buildingName",
        rules: [
            { type: ValidationType.Required, message: "Building name is required" }
        ]
    },
    {
        fieldName: "addressLine1",
        rules: [
            { type: ValidationType.Required, message: "Address Line 1 is required" }
        ]
    },
    {
        fieldName: "addressLine2",
        rules: [
            { type: ValidationType.Required, message: "Address Line 2 is required" }
        ]
    },
    {
        fieldName: "city",
        rules: [
            { type: ValidationType.Required, message: "City is required" }
        ]
    },
    {
        fieldName: "state",
        rules: [
            { type: ValidationType.Required, message: "State is required" }
        ]
    },
    {
        fieldName: "postalCode",
        rules: [
            { type: ValidationType.Required, message: "Postal code is required" }
        ]
    },
    {
        fieldName: "country",
        rules: [
            { type: ValidationType.Required, message: "Country is required" }
        ]
    },
    {
        fieldName: "email",
        rules: [
            { type: ValidationType.Required, message: "Email is required" },
            { type: ValidationType.IsEmail, message: "Invalid email" }
        ]
    },
    {
        fieldName: "contactNo",
        rules: [
            { type: ValidationType.Required, message: "Contact number is required" },
            { type: ValidationType.IsMobileNo, message: "Invalid mobile no" }
        ]
    }
]

export const clientAddressValidator: IValidatorModel = {
    screen: ValidationScreens.ClientAddress,
    fields: fields
}
