import { IAppState } from "../../../../store";
import { connect } from "react-redux";
import React from "react";
import { IPurchaseOrderLineItemModel } from "../../model/purchase-order-item.model";
import { IStoreSaveModel } from "../../../../common/model/store-save.model";
import { FormBaseComponent, IFormBaseState } from "../../../../common/component/form-base.component";
import { PoType, ValidationScreens } from "../../../../common/enums";
import { Link, Navigate } from "react-router-dom";
import AsyncSelect from "react-select/async";
import DropdownDisplayHelper from "../../../../common/helper/dropdown-display.helper";
import PurchaseOrderServiceItemService from "../../service/purchase-order-service-item.service";
import { DeferredLoadOptions } from "../../../../common/utils";
import PurchaseOrderLineItemService from "../../service/purchase-order-line-item.service";
import {
    clearPurchaseOrderLineItemDataState,
    createPurchaseOrderLineItem,
    editPurchaseOrderLineItem,
    loadPurchaseOrderLineItemById, updatePurchaseOrderLineItemDataState
} from "../../store/purchase-order-line-item.slice";
import ValidationMessageControl from "../../../../common/control/validation-message.control";

export interface IProps {
    accessLevel: number,
    id?: string,
    purchaseOrderId?: string,
    returnUrl: string,
    purchaseOrderLineItemData: IPurchaseOrderLineItemModel,
    dataLoading: boolean,
    createEditLoading: boolean,
    loadPurchaseOrderLineItemById: (id: number) => void;
    createPurchaseOrderLineItem: (data: IPurchaseOrderLineItemModel) => void;
    editPurchaseOrderLineItem: (data: IPurchaseOrderLineItemModel) => void;
    updatePurchaseOrderLineItemDataState: (payload: IStoreSaveModel) => void;
    clearPurchaseOrderLineItemDataState: () => void;
}

export interface IState extends IFormBaseState {
    redirectUrl: string
}

class PurchaseOrderLineItemCreateEditPage extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            redirectUrl: "",
            hideForm: false
        }
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.PurchaseOrderLineItem);

        if (this.props.id != null && Number(this.props.id) > 0) {
            await this.props.loadPurchaseOrderLineItemById(Number(this.props.id));
        }
        else {
            await this.props.clearPurchaseOrderLineItemDataState();
        }
        await this.props.updatePurchaseOrderLineItemDataState({ name: "purchaseOrderId", value: Number(this.props.purchaseOrderId) });
    }

    loadPoServiceItemOption = (inputValue: string) => DeferredLoadOptions(inputValue, PurchaseOrderServiceItemService.ServicesDropdown);
    loadPoTypeOption = (inputValue: string) => DeferredLoadOptions(inputValue, PurchaseOrderLineItemService.PurchaseOrderTypeDropdown);

    render() {
        if (this.state.redirectUrl != "") return <Navigate to={this.state.redirectUrl} replace />
        return <React.Fragment>
            <div className="pcoded-content">
                <div className="pcoded-inner-content">
                    <div className="page-header">
                        <div className="page-block">
                            <div className="row">
                                <div className="col-md-12">
                                    <Link to={this.props.returnUrl} className="btn drp-icon btn-rounded btn-primary dropdown-toggle">
                                        <i className="feather icon-arrow-left"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    {(() => {
                        if (this.state.hideForm) return;
                        return <div className="main-body">
                            <div className="page-wrapper">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <article className="card mb-4">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-8">
                                                        <div className={`form-group ${this.vResult("serviceId").className}`}>
                                                            <label htmlFor="serviceId">Service Item<span className="requried-span">*</span></label>
                                                            <AsyncSelect id="serviceId" cacheOptions defaultOptions
                                                                className="async-select-control"
                                                                loadOptions={this.loadPoServiceItemOption}
                                                                value={this.props.purchaseOrderLineItemData.service != null
                                                                    ? {
                                                                        label: DropdownDisplayHelper.ServiceItem(this.props.purchaseOrderLineItemData.service),
                                                                        value: this.props.purchaseOrderLineItemData.service.id.toString()
                                                                    }
                                                                    : { label: "Select Service Item", value: "" }}
                                                                onChange={async (e) => {
                                                                    const sId = Number(e?.value);
                                                                    this.ValidateField("serviceId", sId);

                                                                    if (sId === null || sId === 0 || (this.props.purchaseOrderLineItemData.service != null && sId === this.props.purchaseOrderLineItemData.service.id)) return;

                                                                    const serviceItem = await PurchaseOrderServiceItemService.GetById(sId);
                                                                    if (serviceItem == null) return;

                                                                    this.props.updatePurchaseOrderLineItemDataState({ name: "service", value: serviceItem });
                                                                    this.props.updatePurchaseOrderLineItemDataState({ name: "serviceId", value: serviceItem.id });
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("serviceId").message} />
                                                        </div>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className={`form-group ${this.vResult("type").className}`}>
                                                            <label htmlFor="type">PO Type<span className="requried-span">*</span></label>
                                                            <AsyncSelect id="type" cacheOptions defaultOptions
                                                                className="async-select-control"
                                                                loadOptions={this.loadPoTypeOption}
                                                                value={this.props.purchaseOrderLineItemData.type != null
                                                                    ? {
                                                                        label: PoType[this.props.purchaseOrderLineItemData.type],
                                                                        value: this.props.purchaseOrderLineItemData.type.toString()
                                                                    }
                                                                    : { label: "Select PO Type", value: "" }}
                                                                onChange={async (e) => {
                                                                    const type = Number(e?.value);
                                                                    this.ValidateField("type", type);
                                                                    if (type === null || type === 0) return;

                                                                    this.props.updatePurchaseOrderLineItemDataState({ name: "type", value: type });
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("type").message} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className={`form-group ${this.vResult("description").className}`}>
                                                            <label htmlFor="description">Description</label>
                                                            <input id="description" className="form-control" type="text"
                                                                value={this.props.purchaseOrderLineItemData.description}
                                                                onChange={async e => {
                                                                    e.preventDefault();
                                                                    this.ValidateField("description", e.target.value);
                                                                    this.props.updatePurchaseOrderLineItemDataState({ name: "description", value: e.target.value });
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("description").message} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("quantity").className}`}>
                                                            <label htmlFor="quantity">Qty<span className="requried-span">*</span></label>
                                                            <input id="quantity" className="form-control" type="text"
                                                                value={this.props.purchaseOrderLineItemData.quantity ?? ""}
                                                                onChange={async e => {
                                                                    e.preventDefault();
                                                                    const val = !isNaN(Number(e.target.value)) ? Number(e.target.value) : 0;
                                                                    this.ValidateField("quantity", val);
                                                                    this.props.updatePurchaseOrderLineItemDataState({ name: "quantity", value: val });
                                                                    let unitRate = Number(this.props.purchaseOrderLineItemData?.unitRate);
                                                                    if (val && !isNaN(unitRate)) {
                                                                        this.props.updatePurchaseOrderLineItemDataState({ name: "totalAmount", value: val * unitRate });
                                                                    } else if (this.props.purchaseOrderLineItemData?.totalAmount != 0) {
                                                                        this.props.updatePurchaseOrderLineItemDataState({ name: "totalAmount", value: 0 })
                                                                    }

                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("quantity").message} />
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("unitRate").className}`}>
                                                            <label htmlFor="unitRate">Unit Rate<span className="requried-span">*</span></label>
                                                            <input id="unitRate" className="form-control" type="text"
                                                                value={this.props.purchaseOrderLineItemData.unitRate}
                                                                onChange={async e => {
                                                                    e.preventDefault();
                                                                    const val = !isNaN(Number(e.target.value)) ? Number(e.target.value) : 0;
                                                                    this.ValidateField("unitRate", val);
                                                                    let quantity = Number(this.props.purchaseOrderLineItemData?.quantity);
                                                                    if (val && !isNaN(quantity)) {
                                                                        this.props.updatePurchaseOrderLineItemDataState({ name: "totalAmount", value: val * quantity });
                                                                    } else if (this.props.purchaseOrderLineItemData?.totalAmount != 0) {
                                                                        this.props.updatePurchaseOrderLineItemDataState({ name: "totalAmount", value: 0 })
                                                                    }
                                                                    this.props.updatePurchaseOrderLineItemDataState({ name: "unitRate", value: val });
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("unitRate").message} />
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("unitMeasurement").className}`}>
                                                            <label htmlFor="unitMeasurement">Unit of Measurement<span className="requried-span">*</span></label>
                                                            <input id="unitMeasurement" className="form-control" type="text"
                                                                value={this.props.purchaseOrderLineItemData.unitMeasurement}
                                                                onChange={async e => {
                                                                    e.preventDefault();
                                                                    this.ValidateField("unitMeasurement", e.target.value);
                                                                    await this.props.updatePurchaseOrderLineItemDataState({ name: "unitMeasurement", value: e.target.value });
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("unitMeasurement").message} />
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group`}>
                                                            <label htmlFor="totalAmount">Amount</label>
                                                            <input id="totalAmount" className="form-control not-allowed" type="text" readOnly
                                                                value={this.props.purchaseOrderLineItemData.totalAmount}
                                                                onChange={async e => {
                                                                    e.preventDefault();
                                                                    const val = !isNaN(Number(e.target.value)) ? Number(e.target.value) : 0;
                                                                    this.ValidateField("totalAmount", val);
                                                                    this.props.updatePurchaseOrderLineItemDataState({ name: "totalAmount", value: val });
                                                                }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                        <article className="">

                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <button type="button" className="btn btn-primary btn--right"
                                                            disabled={this.props.purchaseOrderLineItemData == null || this.hasError}
                                                            onClick={async (e) => {
                                                                e.preventDefault();

                                                                const error = !this.ValidateForm(this.props.purchaseOrderLineItemData);
                                                                this.reloadForm();
                                                                if (error) return;

                                                                if (this.props.purchaseOrderLineItemData.id != null && this.props.purchaseOrderLineItemData.id > 0) {
                                                                    await this.props.editPurchaseOrderLineItem(this.props.purchaseOrderLineItemData);
                                                                } else {
                                                                    await this.props.createPurchaseOrderLineItem(this.props.purchaseOrderLineItemData);
                                                                }

                                                                await this.props.clearPurchaseOrderLineItemDataState();
                                                                this.setState({ redirectUrl: this.props.returnUrl });
                                                            }}>Submit</button>
                                                    </div>
                                                </div>
                                            </div>

                                            {(() => { if (this.props.createEditLoading) return <div className="loading--bar"><span></span></div> })()}
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })()}
                </div>
            </div>
        </React.Fragment>
    }
}

const mapStateToProps = (state: IAppState) => ({
    purchaseOrderLineItemData: state.purchaseOrderLineItem.purchaseOrderLineItemData,
    dataLoading: state.purchaseOrderLineItem.dataLoading,
    createEditLoading: state.purchaseOrderLineItem.createEditLoading,
    returnUrl: state.userSession.returnUrl
})

export default connect(mapStateToProps, {
    loadPurchaseOrderLineItemById,
    createPurchaseOrderLineItem,
    editPurchaseOrderLineItem,
    updatePurchaseOrderLineItemDataState,
    clearPurchaseOrderLineItemDataState
})(PurchaseOrderLineItemCreateEditPage);

