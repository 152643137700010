import ApiUrls from "../../../common/api-urls";
import {IFormControlDropdownItemModel} from "../../../common/control/model/form.control.field.model";
import {IGetAllRequestModel} from "../../../common/model/get-all-request-model";
import DropdownDisplayHelper from "../../../common/helper/dropdown-display.helper";
import HttpService from "../../../common/service/http.service";
import { IClientEquipmentModel } from "../model/client-equipment.model";

export default class ClientEquipmentService {

    static async LoadAll(request?: IGetAllRequestModel): Promise<IClientEquipmentModel[]> {
        return await HttpService.Get<IClientEquipmentModel[]>(ApiUrls.ClientEquipment.GetAll());
    }

    static async LoadAllByClientId(id:number): Promise<IClientEquipmentModel[]> {
        return await HttpService.Get<IClientEquipmentModel[]>(ApiUrls.ClientEquipment.GetAllByClientId(id));
    }

    static async Create(data: IClientEquipmentModel): Promise<IClientEquipmentModel> {
        return await HttpService.Post<IClientEquipmentModel, IClientEquipmentModel>(ApiUrls.ClientEquipment.Create(), data);
    }

    static async Edit(data: IClientEquipmentModel): Promise<IClientEquipmentModel> {
        return await HttpService.Patch<IClientEquipmentModel, IClientEquipmentModel>(ApiUrls.ClientEquipment.Edit(data.id), data);
    }
    static async Delete(id: number) {
        await HttpService.Delete(ApiUrls.ClientEquipment.Delete(id));
   }

    static async GetById(id: number): Promise<IClientEquipmentModel> {
        return await HttpService.Get<IClientEquipmentModel>(ApiUrls.ClientEquipment.GetById(id));
    }

    static async ClientMakeDropdown(search: string): Promise<IFormControlDropdownItemModel[]> {
        let req: IGetAllRequestModel = { searchStr: search };
        return (await ClientEquipmentService.LoadAll(req)).map(d => {
            const obj: IFormControlDropdownItemModel = {
                label: DropdownDisplayHelper.ClientEquipment(d),
                value: d.id.toString()
            }
            return obj;
        });
    }
}
