import { IWorkflowModel } from "../../model/workflow.model";
import {
  DeferredLoadOptions,
  displayDate,
  fullDisplayDateInMilitaryTime,
} from "../../../../common/utils";
import UserService from "../../../user/service/user.service";
import { IPurchaseOrderModel } from "../../../purchase-order/model/purchase-order.model";
import { IAppState } from "../../../../store";
import { connect } from "react-redux";
import {
  clearWorkflowDataState,
  editWorkflow,
  loadWorkflowById,
  updateWorkflowDataState,
  moveToNextStage,
  moveToRejectStage,
  watchWorkflow,
} from "../../store/workflow.slice";
import CommentComponent from "../../../../common/component/comments.component";
import React from "react";
import { Link, Navigate } from "react-router-dom";
import ScreenUrls from "../../../../common/screen-urls";
import AsyncSelect from "react-select/async";
import { AgGridReact } from "ag-grid-react";
import StageFlowStepperComponent from "../../component/stage-flow-stepper.component";
import SalesDataEntryWorkflowComponent from "../../component/stages/1-sales-data-entry.workflow.component";
import SalesReviewWorkflowComponent from "../../component/stages/2-sales-review.workflow.component";
import TasksWorkflowComponent from "../../component/stages/4-tasks.workflow.component";
import ClosureWorkflowComponent from "../../component/stages/5-closure.workflow.component";
import {
  AccessLevel,
  EntityType,
  WorkflowStage,
} from "../../../../common/enums";
import { IFormControlDropdownItemModel } from "../../../../common/control/model/form.control.field.model";
import DropdownDisplayHelper from "../../../../common/helper/dropdown-display.helper";
import { IUserModel } from "../../../user/model/user.model";
import { IStoreSaveModel } from "../../../../common/model/store-save.model";
import PurchaseOrderDetailsAccordionComponent from "../../component/purchase-order-details-accordion.component";
import MultiFileControl from "../../../../common/control/file/multi-file.control";
import { AgGridDefaultColDef } from "../../../../common/app-defaults";
import ValidationMessageControl from "../../../../common/control/validation-message.control";
import {
  FormBaseComponent,
  IFormBaseState,
} from "../../../../common/component/form-base.component";
import StageAssignmentModalComponent from "../../../../common/component/stage-assignment-modal.component";
import { editPurchaseOrder } from "../../../purchase-order/store/purchase-order.slice";
import { IStageAssignment } from "../../../../common/stores/stage-assignment.slice";

export interface IProps {
  accessLevel: number;
  accessLevelTask: number;
  id?: string;
  workflowData: IWorkflowModel;
  loadWorkflowById: (id: number) => void;
  editWorkflow: (data: IWorkflowModel) => void;
  updateWorkflowDataState: (payload: IStoreSaveModel) => void;
  clearWorkflowDataState: () => void;
  moveToNextStage: (data: IWorkflowModel) => void;
  dataLoading: boolean;
  createEditLoading: boolean;
  currentUser: IUserModel;
  editPurchaseOrder: (data: IPurchaseOrderModel) => void;
  purchaseOrderData: IPurchaseOrderModel;
  moveToRejectStage: (data: IWorkflowModel) => void;
  stageAssignment: IStageAssignment;
  watchWorkflow: (query: { id: number, watching: boolean }) => void,
}

export interface IState extends IFormBaseState {
  hideAssignee: boolean;
}

class WorkflowCreateEditPage extends FormBaseComponent<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      hideAssignee: false,
      hideForm: false,
    };
  }

  async componentDidMount() {
    if (this.props.id != null && this.props.id != "") {
      await this.props.loadWorkflowById(Number(this.props.id));
    } else {
      await this.props.clearWorkflowDataState();
    }
  }

  loadAssigneeOption = (inputValue: string) =>
    DeferredLoadOptions(
      inputValue,
      (search: string) =>
        new Promise<IFormControlDropdownItemModel[]>((resolve) => {
          if (
            this.props.workflowData == null ||
            this.props.workflowData.currentStage == null ||
            this.props.workflowData.currentStage.team == null
          ) {
            resolve([]);
            return;
          }

          let assignees: IUserModel[] =
            this.props.workflowData.currentStage.onlyForLeads == true
              ? this.props.workflowData.currentStage.team.teamLeads
              : [
                ...this.props.workflowData.currentStage.team.teamLeads,
                ...this.props.workflowData.currentStage.team.members,
              ];

          if (assignees == null || assignees.length == 0) {
            resolve([]);
            return;
          }

          const list = assignees.map((d) => {
            const obj: IFormControlDropdownItemModel = {
              label: DropdownDisplayHelper.User(d),
              value: d.id.toString(),
            };
            return obj;
          });

          if (search == null || search === "") resolve(list);

          const filter = list.filter((i) =>
            i.label.toLowerCase().trim().includes(search.toLowerCase().trim())
          );
          resolve(filter);
        })
    );

  reloadAssignee = () => {
    this.setState({ hideAssignee: true });
    setTimeout(() => {
      this.setState({ hideAssignee: false });
    }, 1);
  };

  onStageComponentChange = () => {
    this.reloadAssignee();
  };
  componentWillUnmount(): void {
    this.props.clearWorkflowDataState();
  }
  render() {
    if (this.props.accessLevel < Number(AccessLevel.Edit) && !this.props.dataLoading && this.props.workflowData.assigneeId) {
      if (this.props.workflowData?.editEnabled
      ) {
        // continue
      } else {
        return <Navigate to={ScreenUrls.AccessDenied()} replace />;
      }
    }

    if (this.state.hideForm) return;

    return (
      <React.Fragment>
        {(() => {
          if (this.props.dataLoading || this.props.createEditLoading)
            return (
              <div className="loading--bar fixed--top">
                <span></span>
              </div>
            );
        })()}
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="page-header">
              <div className="page-block">
                <div className="row">
                  <div className="col-md-12 d-flex align-items-center justify-content-between">
                    <Link
                      to={ScreenUrls.Workflows.View(this.props.workflowData.id)}
                      className="btn drp-icon btn-rounded btn-primary dropdown-toggle"
                    >
                      <i className="feather icon-arrow-left"></i>
                    </Link>

                    <button className="border-0 bg-transparent d-flex align-items-center"
                      onClick={async () => {
                        await this.props.watchWorkflow({ id: this.props.workflowData.id, watching: !this.props.workflowData?.isWatching });
                        this.setState({ ...this.state, hideForm: true });
                        setTimeout(() => {
                          this.setState({ ...this.state, hideForm: false });
                        }, 1)
                      }}
                      disabled={this.props.workflowData?.isComplete}
                    >
                      <i
                        className={`feather mr-2 h5 mb-0 icon-eye${this.props.workflowData.isWatching ? "-off" : ""
                          }`}
                      ></i>
                      {this.props.workflowData.isWatching ? "Un-watch" : "Watch"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-body">
              <div className="page-wrapper">
                <div className="row">
                  <div className="col-sm-12">
                    {/* Purchase Order Details */}
                    {(() => {

                      if (
                        this.props.workflowData == null ||
                        this.props.workflowData.purchaseOrderId == 0
                      ) {
                        return;
                      }
                      return (
                        <PurchaseOrderDetailsAccordionComponent
                          id={this.props.workflowData.purchaseOrderId}
                        />
                      );
                    })()}

                    {/* Workflow Stage Stepper */}
                    {(() => {
                      if (
                        this.props.workflowData.stageFlow == null ||
                        this.props.workflowData.stageFlow.length == 0
                      )
                        return;
                      return (
                        <StageFlowStepperComponent
                          stageFlow={this.props.workflowData.stageFlow}
                          isCompleted={this.props.workflowData.isComplete}
                        />
                      );
                    })()}

                    {/* Workflow Basic Info */}
                    {(() => {
                      if (this.props.workflowData == null) return;
                      return (
                        <article className="card no-bg mb-4">
                          <div className="card-header card-form-header">
                            <div className="row">
                              <div className="col-2">
                                <div className="form-group read-only">
                                  <label>Team</label>
                                  <p>
                                    {this.props.workflowData?.currentStage?.team
                                      ?.displayName ?? ""}
                                  </p>
                                </div>
                              </div>
                              <div className="col-4">
                                <div
                                  className={`form-group ${this.vResult("assigneeId").className
                                    }`}
                                >
                                  <label htmlFor="assigneeId">
                                    Assignee
                                    <span className="requried-span">*</span>
                                  </label>
                                  {(() => {
                                    if (this.state.hideAssignee) return;
                                    return (
                                      <AsyncSelect
                                        id="assigneeId"
                                        cacheOptions
                                        defaultOptions
                                        isDisabled={true}
                                        className="async-select-control"
                                        value={
                                          this.props.workflowData.assignee !=
                                            null
                                            ? {
                                              label:
                                                DropdownDisplayHelper.User(
                                                  this.props.workflowData
                                                    .assignee
                                                ),
                                              value:
                                                this.props.workflowData.assignee.id.toString(),
                                            }
                                            : {
                                              label: "Select Assignee",
                                              value: "",
                                            }
                                        }
                                        loadOptions={this.loadAssigneeOption}
                                        onChange={async (e) => {
                                          const uId = Number(e?.value);
                                          this.ValidateField("assigneeId", uId);

                                          if (
                                            uId == null ||
                                            uId == 0 ||
                                            uId ==
                                            this.props.workflowData.assigneeId
                                          )
                                            return;

                                          const user =
                                            await UserService.GetById(uId);
                                          if (user == null) return;

                                          this.props.updateWorkflowDataState({
                                            name: "assignee",
                                            value: user,
                                          });
                                          this.props.updateWorkflowDataState({
                                            name: "assigneeId",
                                            value: user.id,
                                          });
                                          this.reloadAssignee();
                                        }}
                                      />
                                    );
                                  })()}
                                  <ValidationMessageControl
                                    message={this.vResult("assigneeId").message}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </article>
                      );
                    })()}


                    {/* Workflow Stage Components */}
                    {(() => {
                      let workflowStage: WorkflowStage = this.props.workflowData
                        .currentStage
                        ? WorkflowStage[
                        this.props.workflowData.currentStage.name
                        ]
                        : WorkflowStage.None;

                      switch (workflowStage) {
                        case WorkflowStage.SalesDataEntry:
                          return (
                            <SalesDataEntryWorkflowComponent
                              onChange={this.onStageComponentChange}
                            />
                          );
                        case WorkflowStage.SalesReview:
                          return (
                            <SalesReviewWorkflowComponent
                              onChange={this.onStageComponentChange}
                            />
                          );
                        case WorkflowStage.Tasks:
                          return (
                            <TasksWorkflowComponent
                              onChange={this.onStageComponentChange}
                            />
                          );
                        case WorkflowStage.Closure:
                          return (
                            <ClosureWorkflowComponent
                              onChange={this.onStageComponentChange}
                            />
                          );
                      }
                    })()}

                    {
                      (() => {
                        if (this.props.workflowData.id < 1) return;
                        return (
                          <CommentComponent
                            hideActions={this.props.workflowData?.isComplete}
                            entityType={EntityType.Workflow}
                            entityId={this.props.workflowData?.id}
                          />
                        )
                      })()
                    }


                    {/* Files */}
                    {(() => {
                      if (
                        this.props.workflowData != null &&
                        this.props.workflowData.id > 0
                      ) {
                        return (
                          <MultiFileControl
                            title="Workflow Files"
                            entityType={EntityType.Workflow}
                            entityId={this.props.workflowData.id}
                            editable={!this.props.workflowData.isComplete}
                          />
                        );
                      }
                    })()}

                    {/* Stage Change History */}
                    {(() => {
                      if (this.props.workflowData.stageChangeHistory == null)
                        return;
                      return (
                        <article className="card mb-4">
                          <div className="card-header card-form-header">
                            <div className="card-form-header-title">
                              Audit Trail
                            </div>
                          </div>
                          <div className="card-block ag-theme-alpine mini-grid">
                            <AgGridReact
                              unSortIcon={true}
                              rowHeight={60}
                              pagination={true}
                              paginationAutoPageSize={true}
                              rowData={
                                this.props.workflowData.stageChangeHistory
                              }
                              defaultColDef={AgGridDefaultColDef}
                              columnDefs={[
                                {
                                  headerName: "Created On",
                                  field: "createdOn",
                                  suppressMovable: true,
                                  flex: 1,
                                  sortable: true,
                                  cellClass: "grid-cell",
                                  sort: "desc",
                                  cellRenderer: (params) => (
                                    <span>
                                      {fullDisplayDateInMilitaryTime(params.value)}
                                    </span>
                                  ),
                                },
                                {
                                  headerName: "Stage Change",
                                  suppressMovable: true,
                                  field: "stageChangedFrom",
                                  flex: 3,
                                  sortable: true,
                                  cellClass: "grid-cell",
                                  cellRenderer: (params) => (
                                    <React.Fragment>
                                      <span className="grid-cell-stage-from">
                                        {params.value?.name}
                                      </span>
                                      <i className="feather icon-arrow-right grid-cell-stage-icon"></i>
                                      <span className="grid-cell-stage-to">
                                        {params.data.stageChangedTo?.name}
                                      </span>
                                    </React.Fragment>
                                  ),
                                },
                                {
                                  headerName: "Description",
                                  field: "description",
                                  suppressMovable: true,
                                  flex: 4,
                                  sortable: true,
                                  cellClass: "grid-cell",
                                  cellRenderer: (params) => (
                                    <span>{params.value}</span>
                                  ),
                                },
                                {
                                  headerName: "Created By",
                                  field: "createdBy",
                                  suppressMovable: true,
                                  flex: 1.5,
                                  sortable: true,
                                  cellClass: "grid-cell",
                                  cellRenderer: (params) => (
                                    <span>
                                      {params.value == null
                                        ? "Unassigned"
                                        : `${params.value?.firstName} ${params.value?.lastName}`}
                                    </span>
                                  ),
                                },
                              ]}
                            ></AgGridReact>
                          </div>
                        </article>
                      );
                    })()}

                    {/* Workflow Change History */}
                    {(() => {
                      if (this.props.workflowData.historyLog == null) return;
                      return (
                        <article className="card mb-4">
                          <div className="card-header card-form-header">
                            <div className="card-form-header-title">
                              Overall Change History
                            </div>
                          </div>
                          <div className="card-block ag-theme-alpine mini-grid">
                            <AgGridReact
                              unSortIcon={true}
                              rowHeight={60}
                              pagination={true}
                              paginationAutoPageSize={true}
                              rowData={this.props.workflowData.historyLog}
                              defaultColDef={AgGridDefaultColDef}
                              columnDefs={[
                                {
                                  headerName: "DateTime",
                                  field: "datetime",
                                  suppressMovable: true,
                                  flex: 1,
                                  sortable: true,
                                  cellClass: "grid-cell",
                                  sort: "desc",
                                  cellRenderer: (params) => (
                                    <span>{displayDate(params.value)}</span>
                                  ),
                                },
                                {
                                  headerName: "Description",
                                  field: "description",
                                  suppressMovable: true,
                                  flex: 1.5,
                                  sortable: true,
                                  cellClass: "grid-cell",
                                  cellRenderer: (params) => (
                                    <span>{params.value}</span>
                                  ),
                                },
                                {
                                  headerName: "User",
                                  field: "user",
                                  suppressMovable: true,
                                  flex: 2,
                                  sortable: true,
                                  cellClass: "grid-cell",
                                  cellRenderer: (params) => (
                                    <span>
                                      {params.value == null
                                        ? "Unassigned"
                                        : `${params.value?.firstName} ${params.value?.lastName}`}
                                    </span>
                                  ),
                                },
                              ]}
                            ></AgGridReact>
                          </div>
                        </article>
                      );
                    })()}

                    {/* ========= ========= */}
                    {this.props.stageAssignment.openModal && (
                      <StageAssignmentModalComponent
                        heading={"Assign workflow"}
                        proceedCallBack={async (obj: any) => {
                          if (obj) {
                            await this.props.moveToNextStage({
                              ...this.props.workflowData,
                              toBeAssignedTo: obj.id,
                            });
                            this.onStageComponentChange();
                          }
                        }}
                        rejectCallBack={async (obj: any) => {
                          if (obj) {
                            await this.props.moveToRejectStage({
                              ...this.props.workflowData,
                              toBeAssignedTo: obj.id,
                            });
                            this.onStageComponentChange();
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  workflowData: state.workflow.workflowData,
  dataLoading: state.workflow.dataLoading,
  createEditLoading: state.workflow.createEditLoading,
  currentUser: state.userSession.currentUser,
  purchaseOrderData: state.purchaseOrder.purchaseOrderData,
  stageAssignment: state.stageAssignment,
});
export default connect(mapStateToProps, {
  moveToRejectStage,
  editWorkflow,
  loadWorkflowById,
  updateWorkflowDataState,
  clearWorkflowDataState,
  editPurchaseOrder,
  moveToNextStage,
  watchWorkflow,
})(WorkflowCreateEditPage);
