
import {ICategoryModel, ICategoryRequestModel} from "../model/category.model";
import ApiUrls from "../api-urls";
import {IFormControlDropdownItemModel} from "../control/model/form.control.field.model";
import {IGetAllRequestModel} from "../model/get-all-request-model";
import DropdownDisplayHelper from "../helper/dropdown-display.helper";
import HttpService from "./http.service";

export default class CategoryService {
    static async LoadAll(request?: ICategoryRequestModel): Promise<ICategoryModel[]> {
        return await HttpService.Get<ICategoryModel[]>(ApiUrls.Category.GetAll(request));
    }

    static async Create(data: ICategoryModel): Promise<ICategoryModel> {
        return await HttpService.Post<ICategoryModel, ICategoryModel>(ApiUrls.Category.Create(), data);
    }

    static async Edit(data: ICategoryModel): Promise<ICategoryModel> {
        return await HttpService.Patch<ICategoryModel, ICategoryModel>(ApiUrls.Category.Edit(data.id), data);
    }

    static async GetById(id: number): Promise<ICategoryModel> {
        return await HttpService.Get<ICategoryModel>(ApiUrls.Category.GetById(id));
    }
    static async Delete(id: number) {
        await HttpService.Delete(ApiUrls.Category.Delete(id));
    }
    static async ParentCategoryDropdown(search: string): Promise<IFormControlDropdownItemModel[]> {
        let req: IGetAllRequestModel = { searchStr: search };
        return (await CategoryService.LoadAll({
            searchStr: search,
            onlyParents: true,
        })).map(d => {
            const obj: IFormControlDropdownItemModel = {
                label: DropdownDisplayHelper.Category(d),
                value: d.id.toString()
            }
            return obj;
        });
    }
    static async TaskCategoryDropdown(search: string): Promise<IFormControlDropdownItemModel[]> {
        let req: IGetAllRequestModel = { searchStr: search };
        return (await CategoryService.LoadAll({
            searchStr: search,
            onlyParents: true,
            forTasks: true
        }))?.filter((c) => c?.name != 'Calibration')?.map(d => {
            const obj: IFormControlDropdownItemModel = {
                label: DropdownDisplayHelper.Category(d),
                value: d.id.toString()
            }
            return obj;
        });
    }

    static async LoadCategoriesList(req: ICategoryRequestModel): Promise<IFormControlDropdownItemModel[]> {
        const list = await CategoryService.LoadAll(req);
        if(list == null || list.length == 0) return [];

        return list?.filter((c) => c?.name != 'Calibration')?.map(c => {
            return {
                label: c.name,
                value: c.id.toString(),
            } as IFormControlDropdownItemModel;
        })
    }
}
