import { matchPath, Route, Routes, useLocation } from "react-router-dom";
import AccountLayout from "../../common/screen/layout/account.layout";
import ScreenUrls from "../../common/screen-urls";
import DataLoggerPage from "./screen/data-logger.page";
import DataLoggerCreateEditPage from "./screen/form/data-logger.create-edit.page";
import DataLoggerMakePage from "./screen/data-logger-make.page";
import DataLoggerMakeCreateEditPage from "./screen/form/data-logger-make.create-edit.page";
import DataLoggerCategoryPage from "../../common/screen/category/category.page";
import DataLoggerCategoryCreateEditPage from "../../common/screen/category/category.create-edit.page";
import RouteHelper from "../../common/helper/route-helper";
import { AccessLevel, FeatureNames } from "../../common/enums";
import AccessDeniedPage from "../../common/screen/anonymous/access-denied.page";
import CameraDetalPage from "./screen/data-logger-camera-detail.page";
import CameraDetailCreateEditPage from "./screen/form/data-logger-camera-detail.create-edit.page";
const DataLoggerRoutes = () => {
    const dataLogger = RouteHelper.GetFeatureAccessLevel(FeatureNames.DataLogger);
    const dataLoggerMake = RouteHelper.GetFeatureAccessLevel(FeatureNames.DataLoggerMake);
    const dataLoggerCategory = RouteHelper.GetFeatureAccessLevel(FeatureNames.DataLoggerCategory);
    const dataLoggerCamera = RouteHelper.GetFeatureAccessLevel(FeatureNames.DataLoggerCamera);
    const location = useLocation();
    return <Routes>
        <Route element={<AccountLayout />}>

            <Route path={ScreenUrls.DataLoggers.Index()}>
                <Route index element={dataLogger.accessLevel >= AccessLevel.View ? <DataLoggerPage accessLevel={dataLogger.accessLevel} /> : <AccessDeniedPage />} />
                <Route path={ScreenUrls.CreateUri} element={dataLogger.accessLevel >= AccessLevel.Create
                    ? <DataLoggerCreateEditPage accessLevel={dataLogger.accessLevel} />
                    : <AccessDeniedPage />} />
                <Route path={ScreenUrls.EditUriParam}
                    element={dataLogger.accessLevel >= AccessLevel.Edit
                        ? <DataLoggerCreateEditPage accessLevel={dataLogger.accessLevel}
                            id={matchPath({ path: ScreenUrls.DataLoggers.Edit(":id") }, location.pathname)?.params["id"]} />
                        : <AccessDeniedPage />} />

                <Route path={ScreenUrls.DataLoggers.Make.indexPage}>
                    <Route index element={dataLoggerMake.accessLevel >= AccessLevel.View
                        ? <DataLoggerMakePage accessLevel={dataLoggerMake.accessLevel} />
                        : <AccessDeniedPage />} />
                    <Route path={ScreenUrls.CreateUri} element={dataLoggerMake.accessLevel >= AccessLevel.Create
                        ? <DataLoggerMakeCreateEditPage accessLevel={dataLoggerMake.accessLevel} />
                        : <AccessDeniedPage />} />
                    <Route path={ScreenUrls.EditUriParam}
                        element={dataLoggerMake.accessLevel >= AccessLevel.Edit
                            ? <DataLoggerMakeCreateEditPage
                                accessLevel={dataLoggerMake.accessLevel}
                                id={matchPath({ path: ScreenUrls.DataLoggers.Make.Edit(":id") }, location.pathname)?.params["id"]} />
                            : <AccessDeniedPage />} />
                </Route>

                <Route path={ScreenUrls.DataLoggers.Category.indexPage}>
                    <Route index element={dataLoggerCategory.accessLevel >= AccessLevel.View
                        ? <DataLoggerCategoryPage accessLevel={dataLoggerCategory.accessLevel} />
                        : <AccessDeniedPage />} />
                    <Route path={ScreenUrls.CreateUri} element={dataLoggerCategory.accessLevel >= AccessLevel.Create
                        ? <DataLoggerCategoryCreateEditPage accessLevel={dataLoggerCategory.accessLevel} />
                        : <AccessDeniedPage />} />
                    <Route path={ScreenUrls.EditUriParam}
                        element={dataLoggerCategory.accessLevel >= AccessLevel.Edit
                            ? <DataLoggerCategoryCreateEditPage
                                accessLevel={dataLoggerCategory.accessLevel}
                                id={matchPath({ path: ScreenUrls.DataLoggers.Category.Edit(":id") }, location.pathname)?.params["id"]} />
                            : <AccessDeniedPage />} />
                </Route>

                {/*  =------ camera detail -------- */}
                <Route path={ScreenUrls.DataLoggers.CameraDetail.indexPage}>
                    <Route index element={true
                        ? <CameraDetalPage accessLevel={dataLoggerCamera.accessLevel} />
                        : <AccessDeniedPage />} />
                    <Route path={ScreenUrls.CreateUri}
                        element={dataLoggerCamera.accessLevel >= AccessLevel.Create
                            ? <CameraDetailCreateEditPage accessLevel={dataLoggerCamera.accessLevel} />
                            : <AccessDeniedPage />} />
                    <Route path={ScreenUrls.EditUriParam}
                        element={dataLoggerCamera.accessLevel >= AccessLevel.Edit
                            ? <CameraDetailCreateEditPage
                                accessLevel={dataLoggerCamera.accessLevel}
                                id={matchPath({ path: ScreenUrls.DataLoggers.CameraDetail.Edit(":id") }, location.pathname)?.params["id"]} />
                            : <AccessDeniedPage />} />
                </Route>
            </Route>
        </Route>
    </Routes>
}

export default DataLoggerRoutes;
