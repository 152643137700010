import { IReportModel } from "../model/report.model";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IStoreSaveModel } from "../../../common/model/store-save.model";
import { reportDefault } from "../model/defaults/report.default";
import ReportService from "../service/report.service";

export interface IReportState {
    reportList: IReportModel[],
    reportData: IReportModel,
    dataLoading: boolean,
    listLoading: boolean,
    createEditLoading: boolean
    stageMoveLoading: boolean,
    refetchReportAPI: boolean,
}

const initialState: IReportState = {
    reportList: [],
    reportData: reportDefault,
    dataLoading: false,
    listLoading: false,
    createEditLoading: false,
    stageMoveLoading: false,
    refetchReportAPI: false,
}

export const loadReports = createAsyncThunk("report/loadAll", ReportService.LoadAll);
export const deleteReport = createAsyncThunk("report/cleanroom/equipment/delete", ReportService.Delete);

const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        clearReportDataState: (state) => { state.reportData = reportDefault },
        clearReportListState: (state) => { state.reportList = [] },
        updateReportDataState: (state, action: PayloadAction<IStoreSaveModel>) => {
            state.reportData[action.payload.name] = action.payload.value;
        }
    },
    extraReducers: (builder) => {
        // Load All
        builder.addCase(loadReports.pending, (state, action) => {
            clearReportListState();
            state.listLoading = true;
            if (state.refetchReportAPI) {
                state.refetchReportAPI = false;
            }
        });
        builder.addCase(loadReports.rejected, (state, action) => {
            clearReportListState();
            state.listLoading = false;
        });
        builder.addCase(loadReports.fulfilled, (state, action) => {
            state.reportList = action.payload;
            state.listLoading = false;
        });

        // Delete By ID
        builder.addCase(deleteReport.pending, (state, action) => {
            // clearWorkflowDataState();
            // state.dataLoading = true;
        });
        builder.addCase(deleteReport.rejected, (state, action) => {
            // clearWorkflowDataState();
            // state.dataLoading = false;
        });
        builder.addCase(deleteReport.fulfilled, (state, action) => {
            state.refetchReportAPI = true;
        });

    }
});

const { actions, reducer } = reportSlice;

export const { clearReportDataState, clearReportListState, updateReportDataState } = actions;

export default reducer;
