import { ZoneClassificationType, EUGMPZoneClassificationState, CleanroomEquipmentTests, AerosolGeneratorType, AerosolMedium, TestObservationOn, AVTestObservationUnit, ObservationLocationLabel } from "../enums";

export const ReportTests = {
    cleanroomEquipment: [
        ["AirFlow Velocity Test", CleanroomEquipmentTests.airflowvelocity, "api/report/cleanroom/equipment"],
        ["AirFlow Pattern Test", CleanroomEquipmentTests.airflowvelocity],
        ["Light Intensity Test", CleanroomEquipmentTests.lightintensity],
        ["Filter Integrity Test", CleanroomEquipmentTests.filterintegrity],
        ["Sound Level Test", CleanroomEquipmentTests.soundlevel],
        ["Recovery Test", CleanroomEquipmentTests.recovery],
        ["Non Viable Particle Count Test", CleanroomEquipmentTests.nonviableparticlecount],
    ],

}

export const Standards = [
    { value: ZoneClassificationType.ISO.toString(), label: "ISO" },
    { value: ZoneClassificationType.EUGMP.toString(), label: "EUGMP" },
    { value: ZoneClassificationType.ClientSpecification.toString(), label: "Client Specification" },
];

export const ReportTestEquipmentState = [
    { value: EUGMPZoneClassificationState.AtRest.toString(), label: "At Rest" },
    { value: EUGMPZoneClassificationState.InOperation.toString(), label: "In Operation" },
];

export const AerosolMediumList = [
    { label: AerosolMedium.PolyAlphaOlefin, value: "0" },
    { label: AerosolMedium.Dioctyl, value: "1" },
    { label: AerosolMedium.Phthalate, value: "2" },
    { label: AerosolMedium.Di2EthylHexylSebacate, value: "3" },
];

export const AerosolGeneratorTypeList = [
    { label: AerosolGeneratorType.HotGenerator, value: "0" },
    { label: AerosolGeneratorType.LaskinTypePolyDisperseAerosolGenerator, value: "1" },
];

export const AVTestTestOnList = [
    {
        label: "Filter Id", value: TestObservationOn.FilterId.toString()
    },
    {
        label: "Grill Id", value: TestObservationOn.GrillId.toString()
    },
];

export const AVTestTestObservationUnitList = [
    {
        label: "FPM", value: AVTestObservationUnit.FPM.toString()
    },
    {
        label: "M/S", value: AVTestObservationUnit.MS.toString()
    },
];

export const EUGMPConsideredParticleSizeList = [
    { label: '0.5um', value: "0.5um" },
    { label: '5um', value: "5um" },
];

export const ISOConsideredParticleSizeList = [
    // { label: '0.1um', value: "0.1um" },
    // { label: '0.2um', value: "0.2um" },
    // { label: '0.3um', value: "0.3um" },
    { label: '0.5um', value: "0.5um" },
    { label: '1um', value: "1um" },
    { label: '5um', value: "5um" },
];

export const TestObservationLabelList = [
    { label: "Location Name", value: ObservationLocationLabel.LocationName?.toString() },
    { label: "Location Number", value: ObservationLocationLabel.LocationNumber?.toString() },
    { label: "Location Name / Location Number", value: ObservationLocationLabel.LocationNameOrNumber?.toString() },
];


export const getObservationLocationLabel = (num:number)=>{
    if(num == ObservationLocationLabel.LocationName){
        return "Location Name";
    }
    if(num == ObservationLocationLabel.LocationNumber){
        return "Location Number";
    }
    return "Location Name / Location Number";
}