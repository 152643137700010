import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IStoreSaveModel } from "../../../common/model/store-save.model";
import { IWorkflowTaskModel } from "../model/workflow-task.model";
import { workflowTaskDefault } from "../model/defaults/workflow-task.default";
import WorkflowTaskService from "../service/workflow-task.service";
import { IStageFlowItemModel } from "../../workflow/model/_common.model";

export interface IWorkflowTaskState {
    workflowTaskList: IWorkflowTaskModel[],
    workflowTaskData: IWorkflowTaskModel,
    dataLoading: boolean,
    listLoading: boolean,
    createEditLoading: boolean
    stageMoveLoading: boolean,
    setToDefaultStageFlowLoading: boolean
}

const initialState: IWorkflowTaskState = {
    workflowTaskList: [],
    workflowTaskData: workflowTaskDefault,
    dataLoading: false,
    listLoading: false,
    createEditLoading: false,
    stageMoveLoading: false,
    setToDefaultStageFlowLoading: false
}

export const loadWorkflowTasks = createAsyncThunk("workflow/task/loadAll", WorkflowTaskService.LoadAll);
export const loadWorkflowTaskById = createAsyncThunk("workflow/task/loadById", WorkflowTaskService.GetById);
export const createWorkflowTask = createAsyncThunk("workflow/task/create", WorkflowTaskService.Create);
export const editWorkflowTask = createAsyncThunk("workflow/task/edit", WorkflowTaskService.Edit);
export const moveToNextWorkflowTaskStage = createAsyncThunk("workflow/task/stage/move/next", WorkflowTaskService.NextStage);
export const moveToWorkflowTaskRejectStage = createAsyncThunk("workflow/task/stage/move/reject", WorkflowTaskService.RejectStage);
export const completeWorkflowTask = createAsyncThunk("workflow/task/complete", WorkflowTaskService.Complete);
export const setToDefaultStageFlow = createAsyncThunk("workflow/task/defaultStageFlow", WorkflowTaskService.GetDefaultStageFlow);
export const createWorkflowTaskWhenCategoryChanges = createAsyncThunk("workflow/task/create", WorkflowTaskService.Create);
export const editWorkflowTaskWhenCategoryChanges = createAsyncThunk("workflow/task/edit", WorkflowTaskService.Edit);
export const watchWorkflowTask = createAsyncThunk("workflow/task/HandleWatching", WorkflowTaskService.HandleWatchWorkflowTask)
const workflowTaskSlice = createSlice({
    name: 'workflow-task',
    initialState,
    reducers: {
        clearWorkflowTaskDataState: (state) => { state.workflowTaskData = workflowTaskDefault },
        clearWorkflowTaskListState: (state) => { state.workflowTaskList = [] },
        updateWorkflowTaskDataState: (state, action: PayloadAction<IStoreSaveModel>) => {
            state.workflowTaskData[action.payload.name] = action.payload.value;
        },
        setWorkflowTaskDataState: (state, action: PayloadAction<IWorkflowTaskModel>) => {
            state.workflowTaskData = action.payload;
        }
    },
    extraReducers: (builder) => {
        // Load All
        builder.addCase(loadWorkflowTasks.pending, (state, action) => {
            clearWorkflowTaskListState();
            state.listLoading = true;
        });
        builder.addCase(loadWorkflowTasks.rejected, (state, action) => {
            clearWorkflowTaskListState();
            state.listLoading = false;
        });
        builder.addCase(loadWorkflowTasks.fulfilled, (state, action) => {
            state.workflowTaskList = action.payload;
            state.listLoading = false;
        });

        // Load By ID
        builder.addCase(loadWorkflowTaskById.pending, (state, action) => {
            clearWorkflowTaskDataState();
            state.dataLoading = true;
        });
        builder.addCase(loadWorkflowTaskById.rejected, (state, action) => {
            clearWorkflowTaskDataState();
            state.dataLoading = false;
        });
        builder.addCase(loadWorkflowTaskById.fulfilled, (state, action) => {
            state.workflowTaskData = action.payload;
            state.dataLoading = false;
        });

        // Create
        builder.addCase(createWorkflowTask.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(createWorkflowTask.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(createWorkflowTask.fulfilled, (state, action) => {
            state.createEditLoading = false;
            state.workflowTaskData = action.payload;
        });

        // Edit
        builder.addCase(editWorkflowTask.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(editWorkflowTask.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(editWorkflowTask.fulfilled, (state, action) => {
            state.workflowTaskData = action.payload;
            state.createEditLoading = false;
        });

        // Stage - Next
        builder.addCase(moveToNextWorkflowTaskStage.pending, (state, action) => { state.stageMoveLoading = true; });
        builder.addCase(moveToNextWorkflowTaskStage.fulfilled, (state, action) => {
            state.stageMoveLoading = false;
            state.workflowTaskData = action.payload;
        });
        builder.addCase(moveToNextWorkflowTaskStage.rejected, (state, action) => {
            state.stageMoveLoading = false;
        });

        // Stage - Reject
        builder.addCase(moveToWorkflowTaskRejectStage.pending, (state, action) => { state.stageMoveLoading = true; });
        builder.addCase(moveToWorkflowTaskRejectStage.fulfilled, (state, action) => {
            state.stageMoveLoading = false;
            state.workflowTaskData = action.payload;
        });
        builder.addCase(moveToWorkflowTaskRejectStage.rejected, (state, action) => {
            state.stageMoveLoading = false;
        });

        // Stage - Complete
        builder.addCase(completeWorkflowTask.pending, (state, action) => { state.stageMoveLoading = true; });
        builder.addCase(completeWorkflowTask.fulfilled, (state, action) => {
            state.stageMoveLoading = false;
            state.workflowTaskData = action.payload;
        });
        builder.addCase(completeWorkflowTask.rejected, (state, action) => {
            state.stageMoveLoading = false;
        });

        // Set to default stage flow
        builder.addCase(setToDefaultStageFlow.pending, (state, action) => {
            state.setToDefaultStageFlowLoading = true;
        });
        builder.addCase(setToDefaultStageFlow.rejected, (state, action) => {
            state.setToDefaultStageFlowLoading = false;
        });
        builder.addCase(setToDefaultStageFlow.fulfilled, (state, action) => {
            const stageFlow: IStageFlowItemModel[] = action.payload;
            const filtered = stageFlow?.filter(s => s.isActive);
            if (filtered == null || filtered.length == 0 || stageFlow == null) return;

            const currentStage = filtered[0];
            state.workflowTaskData = {
                ...state.workflowTaskData,
                currentStage: currentStage,
                currentStageId: currentStage?.id,
                stageFlow: stageFlow,
                assignee: currentStage.team?.defaultAssignee,
                assigneeId: currentStage.team?.defaultAssigneeId
            }
            state.setToDefaultStageFlowLoading = false;
        });

        builder.addCase(watchWorkflowTask.pending, (state, action) => {
            state.stageMoveLoading = true;
        });
        builder.addCase(watchWorkflowTask.fulfilled, (state, action) => {
            state.stageMoveLoading = false;
            state.workflowTaskData = action.payload;
        });
        builder.addCase(watchWorkflowTask.rejected, (state, action) => {
            state.stageMoveLoading = false;
        });
    }
});

const { actions, reducer } = workflowTaskSlice;

export const { clearWorkflowTaskDataState, clearWorkflowTaskListState, updateWorkflowTaskDataState, setWorkflowTaskDataState } = actions;

export default reducer;
