import { IFieldValidationModel, IValidatorModel } from "../../../../../../common/model/validator.model";
import { ValidationScreens, ValidationType } from "../../../../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "locationNameOrNumber",
        rules: [
            {
                type: ValidationType.Required,
                message: "LocationNameOrNumber is required"
            }
        ]
    },

]

export const lightIntensityTestObservationValidator: IValidatorModel = {
    screen: ValidationScreens.LightIntensityTestObservation,
    fields: fields
}
