import { Text, View, } from '@react-pdf/renderer';
import { CleanroomEquipmentTests, TestObservationResult } from "../../../../../common/enums";
import { AVTestTestObservationUnitList } from "../../../../../common/_static/report.data";
import BasePDFComponent from "./base-test-report-pdf.component";
import { IUserModel } from "../../../../user/model/user.model";
import { ICleanroomEquipmentModel } from "../../../model/report.model";

export const AirflowVelocityPDFComponent = ({ id, currentUser, data }: { id: number, currentUser: IUserModel, data: ICleanroomEquipmentModel }) => {
    return (
        <BasePDFComponent testName={CleanroomEquipmentTests.airflowvelocity} title="airflow velocity" id={id} data={data} currentUser={currentUser}
            callBack={(styles: any, data: any, maxReadings: number) => {
                return (
                    <View wrap={false}>
                        <View style={{ ...styles.paddingTB }}>
                            <Text style={{ ...styles.font_12, ...styles.bold }}>Observations :</Text>
                        </View>
                        <View style={{ ...styles.flexBox, ...styles.flexColumn, ...styles.font_12, }}>
                            <View style={{ ...styles.flexBox, ...styles.bold, ...styles.textCenter }}>
                                <View style={{ ...styles.label, width: 35, ...styles.col, }}>
                                    <Text style={{}}>Sr.No</Text>
                                </View>
                                <View style={{ ...styles.label, width: 120, ...styles.col, }}>
                                    <Text style={{ ...styles.label }}>
                                        Filter Id / Grill Id
                                    </Text>
                                </View>

                                <View style={{ ...styles.flex_2 }}>
                                    <View style={{ ...styles.flexBox, flexDirection: 'column' }}>
                                        <View style={{ ...styles.col, textAlign: 'center' }}>
                                            <Text style={{}}>Air Flow velocity Readings in {" "}
                                                {
                                                    data.observations && Array.isArray(data.observations) && data.observations?.length > 0 && data.observations[0].observationUnit ?
                                                        AVTestTestObservationUnitList?.find((item) => item.value == data.observations[0].observationUnit.toString())?.label
                                                        : ""
                                                }
                                            </Text>
                                        </View>
                                        <View style={{ ...styles.flexBox }}>
                                            {
                                                Array(maxReadings)?.fill(0).map((item, index) => {
                                                    return (
                                                        <View style={{ ...styles.flex_1, padding: 2, borderRight: "0.5px solid black" }}>
                                                            <Text>R{(index + 1)}</Text>
                                                        </View>
                                                    )
                                                })
                                            }
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.label, width: 70, ...styles.flexBox, ...styles.flexColumn }}>
                                    <View style={{ ...styles.col, ...styles.flex_1 }}>
                                        <Text style={{}}>Avg Velocity</Text>
                                    </View>
                                    <View style={{ ...styles.col, textAlign: 'center', ...styles.flex_1 }}>
                                        <Text style={{}}>
                                            {
                                                Array.isArray(data?.observations) && data.observations.length > 0 ?
                                                    AVTestTestObservationUnitList?.find((item) => item.value == data.observations[0].observationUnit?.toString())?.label
                                                    : ""
                                            }
                                        </Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.label, width: 100, ...styles.flexBox, ...styles.flexColumn }}>
                                    <View style={{ ...styles.col, textAlign: 'center', ...styles.flex_1 }}>
                                        <Text style={{}}>Acceptance criteria</Text>
                                    </View>
                                    <View style={{ ...styles.col, textAlign: 'center', ...styles.flex_1 }}>
                                        <Text style={{}}>
                                            {
                                                Array.isArray(data?.observations) && data.observations.length > 0 ?
                                                    AVTestTestObservationUnitList?.find((item) => item.value == data.observations[0].observationUnit?.toString())?.label
                                                    : ""
                                            }
                                        </Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.label, width: 60, ...styles.col }}>
                                    <Text style={{}}>Result</Text>
                                </View>
                            </View>
                            {
                                data.observations?.map((item, index) => {
                                    return (
                                        <View style={{ ...styles.flexBox, ...styles.textCenter }}>
                                            <View style={{ ...styles.label, width: 35, ...styles.col }}>
                                                <Text style={{}}>{index + 1}</Text>
                                            </View>
                                            <View style={{ ...styles.label, width: 120, ...styles.col }}>
                                                <Text style={{}}>{item.filterIdOrGrillName}</Text>
                                            </View>

                                            <View style={{ ...styles.flex_2, ...styles.flexBox, border: "0.5px solid black" }}>
                                                {
                                                    Array(maxReadings)?.fill(0).map((item2, index2) => {
                                                        return (
                                                            <View style={{ ...styles.flex_1, padding: 2, height: "100%", borderRight: "0.5px solid black" }}>
                                                                <Text style={{}}>{item?.roomReadings[index2]?.reading ?? ""}</Text>
                                                            </View>
                                                        )
                                                    })
                                                }

                                            </View>
                                            <View style={{ ...styles.label, width: 70, ...styles.col, }}>
                                                <Text style={{}}>{item.averageAirVelocity}</Text>
                                            </View>
                                            <View style={{ ...styles.label, width: 100, ...styles.col, }}>
                                                <Text style={{}}>
                                                    {item.acceptanceCriteriaMin} {` to `}
                                                    {item.acceptanceCriteriaMax}
                                                </Text>
                                            </View>

                                            <View style={{ ...styles.label, width: 60, ...styles.col }}>
                                                <Text style={{}}>{
                                                    item.result == TestObservationResult.Pass ? TestObservationResult[TestObservationResult.Pass] :
                                                        item.result == TestObservationResult.Fail ? TestObservationResult[TestObservationResult.Fail] : "NA"}

                                                </Text>
                                            </View>
                                        </View>
                                    )
                                })
                            }
                        </View>
                    </View>
                )
            }}
        />)
}

export default AirflowVelocityPDFComponent;





