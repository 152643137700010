import {IFieldValidationModel, IValidatorModel} from "../../../../common/model/validator.model";
import {ValidationScreens, ValidationType} from "../../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "assigneeId",
        rules: [
            {
                type: ValidationType.Required,
                message: "Assignee is required"
            }
        ]
    },
    {
        fieldName: "siteActivityStartDate",
        dependencyFieldName: "activityPlanningDate",
        rules: [
            {
                type: ValidationType.Required,
                message: "Site activity start date is required"
            },
            {
                type: ValidationType.DateShouldBeGreaterDependentDate,
                message: "Site activity start date should be greater than activity planning date"
            }
        ]
    },
    {
        fieldName: "siteActivityEndDate",
        dependencyFieldName: "siteActivityStartDate",
        rules: [
            {
                type: ValidationType.Required,
                message: "Site activity end date is required"
            },
            {
                type: ValidationType.DateShouldBeLessThenCurrentDate,
                message: "Site activity end date should be less than current date"
            },
            {
                type: ValidationType.DateShouldBeGreaterDependentDate,
                message: "Site activity end date should be greater than Site activity start date"
            }
        ]
    },
    {
        fieldName: "isPostVerificationCompleted",
        rules: [
            {
                type: ValidationType.RequiredIfTrue,
                message: "Please complete post verification"
            }
        ]
    },
    {
        fieldName: "isRawDataShared",
        rules: [
            {
                type: ValidationType.RequiredIfTrue,
                message: "Please complete raw data shared"
            }
        ]
    },
    {
        fieldName: "isActivitySheetShared",
        rules: [
            {
                type: ValidationType.RequiredIfTrue,
                message: "Please complete activity sheet shared"
            }
        ]
    },
    {
        fieldName: "isActivityAnnexuresSubmitted",
        rules: [
            {
                type: ValidationType.RequiredIfTrue,
                message: "Please complete activity annexures submitted"
            }
        ]
    },
    {
        fieldName: "isDocumentsUploaded",
        rules: [
            {
                type: ValidationType.RequiredIfTrue,
                message: "Please complete documents uploaded"
            }
        ]
    }
]

export const workflowTaskSiteActivityStageValidator: IValidatorModel = {
    screen: ValidationScreens.WorkflowTaskSiteActivityStage,
    fields: fields
}
