import {connect} from "react-redux";
import {BaseComponent} from "../../../common/component/base.component";
import {IAppState} from "../../../store";
import {IAccessRoleModel} from "../model/access-role.model";
import {loadAccessRoles} from "../store/access-role.slice";
import {AgGridReact} from 'ag-grid-react';
import {Link} from "react-router-dom";
import ScreenUrls from "../../../common/screen-urls";
import {IGetAllRequestModel} from "../../../common/model/get-all-request-model";
import {AccessLevel} from "../../../common/enums";
import AccessRoleService from "../service/access-role.service";
import GridDeleteButtonControl from "../../../common/control/component/grid/grid-delete-buton.control";

export interface IProps {
    accessLevel: number,
    accessRoleList: IAccessRoleModel[],
    listLoading: boolean,
    loadAccessRoles: (request?: IGetAllRequestModel) => void
}

class AccessRolePage extends BaseComponent<IProps, any> {
    componentDidMount() {
        this.props.loadAccessRoles();
    }

    render() {
        return  <div className="pcoded-content">
            <div className="pcoded-inner-content">
                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    {(() => { if(this.props.listLoading) return <div className="loading--bar"><span></span></div> })()}
                                    <div className="card-header">
                                        <h5>List of access roles</h5>
                                        <div className="card-header-right">
                                            <div className="btn-group card-option">
                                                {(() => {
                                                    if(this.props.accessLevel >= AccessLevel.Create) {
                                                        return <Link className="btn drp-icon btn-rounded btn-primary" to={ScreenUrls.Users.AccessRole.Create()}>
                                                            Add New Access Role
                                                        </Link>
                                                    }
                                                })()}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-block ag-theme-alpine x-large-grid">
                                        <AgGridReact
                                            pagination={true}
                                            paginationAutoPageSize={true}
                                            unSortIcon={true}
                                            rowHeight={60}
                                            columnDefs={[
                                                {
                                                    suppressMovable: true,
                                                    hide: this.props.accessLevel < AccessLevel.Edit,
                                                    headerName: "Edit", field: 'id', width: 70, cellClass: "grid-cell grid-cell-link",
                                                    cellRenderer: (params) => {
                                                        return (
                                                            <Link to={ScreenUrls.Users.AccessRole.Edit(params.value)}>
                                                                <i className={`feather icon-edit`}></i>
                                                            </Link>
                                                        )
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    hide: this.props.accessLevel < AccessLevel.Delete,
                                                    headerName: "Delete", field: 'id', width: 80, cellClass: "grid-cell grid-cell-link delete",
                                                    cellRenderer: (params) => {
                                                        return (
                                                            <GridDeleteButtonControl onDelete={async () => {                                                                 
                                                                   await AccessRoleService.Delete(params.value);
                                                                   this.props.loadAccessRoles();                                                                                                                                          
                                                                }}></GridDeleteButtonControl>
                                                        )
                                                    }
                                                },                                                
                                                {
                                                    suppressMovable: true,
                                                    headerName: "#", field: 'id', width: 50, cellClass: "grid-cell",
                                                    cellRenderer: (params) => {
                                                        return <div className="row">
                                                            <div className="col-sm-12">
                                                                {params.value}
                                                            </div>
                                                        </div>
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Name", field: "name", flex: 1, sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value}</span>
                                                    }
                                                }
                                            ]}
                                            rowData={this.props.accessRoleList}>
                                        </AgGridReact>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state: IAppState) => ({
    accessRoleList: state.accessRole.accessRoleList,
    listLoading: state.accessRole.listLoading
})

export default connect(mapStateToProps, { loadAccessRoles })(AccessRolePage)
