import { IClientModel } from "./client.model";

export interface IClientEquipmentModel{
    id:number,
    equipmentName:string,
    equipmentId:string,
    modelNumber:string,
    serialNumber:string,
    make:string,
    clientId:number,
    client?:null | IClientModel,
}

export const clientEquipmentDefaults:IClientEquipmentModel = {
    id:0,
    equipmentName:'',
    equipmentId:'',
    modelNumber:'',
    serialNumber:'',
    make:"",
    clientId:0,
}