import React from "react";
import ScreenUrls from "../../../../common/screen-urls";
import { IAppState } from "../../../../store";
import { connect } from "react-redux";
import {
    clearDataLoggerCameraDetailDataState, createDataLoggerCameraDetail, editDataLoggerCameraDetail, loadDataLoggerCameraDetailById, updateDataLoggerCameraDetailDataState,
} from "../../store/data-logger-camera-detail.slice";
import { Link } from "react-router-dom";
import { IStoreSaveModel } from "../../../../common/model/store-save.model";
import { FormBaseComponent, IFormBaseState } from "../../../../common/component/form-base.component";
import { ValidationScreens } from "../../../../common/enums";
import ValidationMessageControl from "../../../../common/control/validation-message.control";
import { IDataLoggerCameraDetailModel } from "../../model/data-logger-camera-detail.model";

export interface IProps {
    accessLevel: number;
    id?: string;
    dataLoggerCameraDetailData: IDataLoggerCameraDetailModel;
    loadDataLoggerCameraDetailById: (id: number) => void;
    dataLoading: boolean;
    createEditLoading: boolean;
    createDataLoggerCameraDetail: (data: IDataLoggerCameraDetailModel) => void;
    editDataLoggerCameraDetail: (data: IDataLoggerCameraDetailModel) => void;
    updateDataLoggerCameraDetailDataState: (payload: IStoreSaveModel) => void;
    clearDataLoggerCameraDetailDataState: () => void;
}

export interface IState extends IFormBaseState {
    redirectUrl: string
}

class DataLoggerCameraDetailCreateEditPage extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            redirectUrl: "",
            hideForm: false
        }
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.DataLoggerCameraDetail);

        if (this.props.id != null && Number(this.props.id) > 0) {
            await this.props.loadDataLoggerCameraDetailById(Number(this.props.id));
        }
        else {
            await this.props.clearDataLoggerCameraDetailDataState();
        }
    }
    async componentWillUnmount() {
        await this.setValidator(ValidationScreens.None);
    }
    render() {
        return <React.Fragment>
            <div className="pcoded-content">
                <div className="pcoded-inner-content">
                    <div className="page-header">
                        <div className="page-block">
                            <div className="row">
                                <div className="col-md-12">
                                    <Link to={ScreenUrls.DataLoggers.CameraDetail.Index()}
                                        className="btn drp-icon btn-rounded btn-primary dropdown-toggle">
                                        <i className="feather icon-arrow-left"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    {(() => { if (this.props.dataLoading || this.props.createEditLoading) return <div className="loading--bar fixed--top"><span></span></div> })()}
                    <div className="main-body">
                        <div className="page-wrapper">
                            <div className="row">
                                <div className="col-sm-12">
                                    <article className="card mb-4">
                                        <div className="card-body">
                                            <div className="row align-items-center">
                                                <div className="col-4">
                                                    <div className={`form-group ${this.vResult("cameraName").className}`}>
                                                        <label htmlFor="cameraName">Camera Name<span className="requried-span">*</span></label>
                                                        <input id="cameraName" className="form-control" type="text"
                                                            value={this.props.dataLoggerCameraDetailData.cameraName}
                                                            onChange={e => {
                                                                e.preventDefault();
                                                                this.ValidateField("cameraName", e.target.value);
                                                                this.props.updateDataLoggerCameraDetailDataState({ name: "cameraName", value: e.target.value });
                                                            }} />
                                                        <ValidationMessageControl message={this.vResult("cameraName").message} />
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className={`form-group ${this.vResult("cameraId").className}`}>
                                                        <label htmlFor="cameraId">Camera Id<span className="requried-span">*</span></label>
                                                        <input id="cameraId" className="form-control" type="text"
                                                            value={this.props.dataLoggerCameraDetailData.cameraId}
                                                            onChange={e => {
                                                                e.preventDefault();
                                                                this.ValidateField("cameraId", e.target.value);
                                                                this.props.updateDataLoggerCameraDetailDataState({ name: "cameraId", value: e.target.value });
                                                            }} />
                                                        <ValidationMessageControl message={this.vResult("cameraId").message} />
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className={`form-group ${this.vResult("modelNumber").className}`}>
                                                        <label htmlFor="modelNumber">Model Number<span className="requried-span">*</span></label>
                                                        <input id="modelNumber" className="form-control" type="text"
                                                            value={this.props.dataLoggerCameraDetailData.modelNumber}
                                                            onChange={e => {
                                                                e.preventDefault();
                                                                this.ValidateField("modelNumber", e.target.value);
                                                                this.props.updateDataLoggerCameraDetailDataState({ name: "modelNumber", value: e.target.value });
                                                            }} />
                                                        <ValidationMessageControl message={this.vResult("modelNumber").message} />
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className={`form-group ${this.vResult("").className}`}>
                                                        <label htmlFor="roleName">Serial Number</label>
                                                        <input id="" className="form-control" type="text"
                                                            value={this.props.dataLoggerCameraDetailData.serialNumber}
                                                            onChange={e => {
                                                                e.preventDefault();
                                                                this.props.updateDataLoggerCameraDetailDataState({ name: "serialNumber", value: e.target.value });
                                                            }} />

                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className={`form-group ${this.vResult("name").className}`}>
                                                        <label htmlFor="roleName">Make</label>
                                                        <input id="roleName" className="form-control" type="text"
                                                            value={this.props.dataLoggerCameraDetailData.make}
                                                            onChange={e => {
                                                                e.preventDefault();

                                                                this.props.updateDataLoggerCameraDetailDataState({ name: "make", value: e.target.value });
                                                            }} />

                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row justify-content-end">
                                                <div className="col-auto">
                                                    <div className="form-group">
                                                        <button type="button" className="btn btn-primary m-0"
                                                            disabled={this.props.dataLoggerCameraDetailData == null || this.hasError}
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                const error = !this.ValidateForm(this.props.dataLoggerCameraDetailData);
                                                                this.reloadForm();
                                                                if (error) return;

                                                                if (this.props?.dataLoggerCameraDetailData.id > 0) {
                                                                    this.props.editDataLoggerCameraDetail(this.props.dataLoggerCameraDetailData);
                                                                } else {
                                                                    this.props.createDataLoggerCameraDetail(this.props.dataLoggerCameraDetailData);
                                                                }
                                                            }}>Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}

const mapStateToProps = (state: IAppState) => ({
    dataLoggerCameraDetailData: state.dataLoggerCameraDetail.dataLoggerCameraDetailData,
    dataLoading: state.dataLoggerCameraDetail.dataLoading,
    createEditLoading: state.dataLoggerCameraDetail.createEditLoading
})

export default connect(mapStateToProps, { clearDataLoggerCameraDetailDataState, updateDataLoggerCameraDetailDataState, loadDataLoggerCameraDetailById, createDataLoggerCameraDetail, editDataLoggerCameraDetail })(DataLoggerCameraDetailCreateEditPage);
