import { IFieldValidationModel, IValidatorModel } from "../../../common/model/validator.model";
import { ValidationScreens, ValidationType } from "../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "companyName",
        rules: [
            {
                type: ValidationType.Required,
                message: "Company name is required"
            }
        ]
    },
    {
        fieldName: "panNumber",
        rules: [
            {
                type: ValidationType.IsOptional,
                message: "",
            },
            {
                type: ValidationType.IsPanCardNumber,
                message: "Invalid pancard no"
            }
        ]
    },
    {
        fieldName: "gstInNumber",
        rules: [
            {
                type: ValidationType.IsOptional,
                message: "",
            },
            {
                type: ValidationType.IsValidGSTIN,
                message: "Invalid GSTIN"
            }
        ]
    }
]

export const clientValidator: IValidatorModel = {
    screen: ValidationScreens.Client,
    fields: fields
}
