import {IFieldValidationModel, IValidatorModel} from "../../../../common/model/validator.model";
import {ValidationScreens, ValidationType} from "../../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "activityPlanningDate",
        rules: [
            {
                type: ValidationType.Required,
                message: "Activity Planing date is required"
            },
        ]
    },
]

export const workflowActivityPlaningStageValidator: IValidatorModel = {
    screen: ValidationScreens.WorkflowTaskActivityPlaningStage,
    fields: fields
}
