
export class AppConstants {
    static DatePickerFormat: string = "dd MMM yyyy";
    static DatePickerWithTimeFormat: string = "dd MMM yyyy hh:mm a";
    static DatePickerInterval: number = 30;
    static DisplayDateFormat: string = "DD MMM YYYY";
    static DisplayDateMilitaryFormat: string = "DD-MM-YY hh:mm:ss";
    static FullDisplayDateFormat: string = "dddd, DD MMMM YYYY hh:mm A";
    static UtcDateTimeFormat: string = "ddd MMM DD YYYY HH:mm";
    static SystemAdminAccessRoleId = 1;
    static NotificationQuickViewSchedulerSec = 60;
    static TimeFormat = "hh:mm:ss"
}
