import React from "react";
import {BaseComponent} from "../../../common/component/base.component";

interface IProps {
    title: string,
    count: number|string,
    cardClass?: string
}

class SummaryCardComponent extends BaseComponent<IProps, any> {
    render() {
        return <div className={`dashboard-summary--card ${this.props.cardClass ?? ""}`}>
            <p className="dashboard-summary--card-title">
                {this.props.title}
            </p>
            <p className="dashboard-summary--card-count">
                {this.props.count}
            </p>
        </div>
    }
}

export default SummaryCardComponent;
