import { BaseComponent } from "./base.component";
import FooterComponent from "./footer.component";


export interface IProps {
    isNavCollapsed?: boolean,
    children: JSX.Element,
}

class ButtonGroupComponent extends BaseComponent<IProps, any> {
    render() {
        return (
            <div className={`workflow--stage-cta-container ${this.props.isNavCollapsed ? "collapsed":""}`}> 
                <FooterComponent customClass="text-white p-0" />
                <div className="form-group">
                   {this.props.children}
                </div>
            </div>
        )
    }
}

export default ButtonGroupComponent;