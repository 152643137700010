    import { Outlet } from "react-router-dom";
    import "../../../assets/styles/base/global.scss";
    import {BaseComponent} from "../../component/base.component";
    import FooterComponent from "../../component/footer.component";

    export interface IProps {}

    class DefaultLayout extends BaseComponent<IProps, any> {
        render() {
            return <div className="default-layout">
                <Outlet />
                <FooterComponent customClass="text-center" />
            </div>
        }
    }

    export default DefaultLayout;
