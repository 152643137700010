import {IFieldValidationModel, IValidatorModel} from "../../../../common/model/validator.model";
import {ValidationScreens, ValidationType} from "../../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "assigneeId",
        rules: [
            {
                type: ValidationType.Required,
                message: "Assignee is required"
            }
        ]
    },
    {
        fieldName: "reportDeliveryStartDate",
        dependencyFieldName:"qaReviewEndDate",
        rules: [
            {
                type: ValidationType.Required,
                message: "Report delivery start date is required"
            },
            {
                type: ValidationType.DateShouldBeGreaterDependentDate,
                message: "Report delivery start date should be greater than QA review end date"
            }
        ]
    },
    {
        fieldName: "reportDeliveryEndDate",
        dependencyFieldName:"reportDeliveryStartDate",
        rules: [
            {
                type: ValidationType.Required,
                message: "Report delivery end date is required"
            },
            {
                type: ValidationType.DateShouldBeLessThenCurrentDate,
                message: "Report delivery end date should be less than current date"
            },
            {
                type: ValidationType.DateShouldBeGreaterDependentDate,
                message: "Report delivery end date should be greater than Report delivery start date"
            }
        ]
    },
    {
        fieldName: "isReportPrintingCompleted",
        rules: [
            {
                type: ValidationType.RequiredIfTrue,
                message: "Please complete reportPrinting"
            }
        ]
    },
    {
        fieldName: "isReportSignScanCompleted",
        rules: [
            {
                type: ValidationType.RequiredIfTrue,
                message: "Please complete reportSign scan"
            }
        ]
    },
    {
        fieldName: "isReportSubmittedToClient",
        rules: [
            {
                type: ValidationType.RequiredIfTrue,
                message: "Please complete report submitted to client"
            }
        ]
    },
    {
        fieldName: "isClientAcknowledgmentReceived",
        rules: [
            {
                type: ValidationType.RequiredIfTrue,
                message: "Please complete client acknowledgment received"
            }
        ]
    }
]

export const workflowTaskReportDeliveryStageValidator: IValidatorModel = {
    screen: ValidationScreens.WorkflowTaskReportDeliveryStage,
    fields: fields
}
