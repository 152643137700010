import React from "react";
import ScreenUrls from "../../../../common/screen-urls";
import {Link} from "react-router-dom";
import {IAppState} from "../../../../store";
import {connect} from "react-redux";
import {IPurchaseOrderServiceItemModel} from "../../model/purchase-order-item.model";
import {
    clearPurchaseOrderServiceItemDataState,
    createPurchaseOrderServiceItem,
    editPurchaseOrderServiceItem, loadPurchaseOrderServiceItemById, updatePurchaseOrderServiceItemDataState
} from "../../store/purchase-order-service-item.slice";
import {IStoreSaveModel} from "../../../../common/model/store-save.model";
import {FormBaseComponent, IFormBaseState} from "../../../../common/component/form-base.component";
import {ValidationScreens} from "../../../../common/enums";
import ValidationMessageControl from "../../../../common/control/validation-message.control";

export interface IProps {
    accessLevel: number,
    id?: string,
    purchaseOrderServiceItemData: IPurchaseOrderServiceItemModel,
    loadPurchaseOrderServiceItemById: (id: number) => void;
    createPurchaseOrderServiceItem: (data: IPurchaseOrderServiceItemModel) => void;
    editPurchaseOrderServiceItem: (data: IPurchaseOrderServiceItemModel) => void;
    updatePurchaseOrderServiceItemDataState:  (data: IStoreSaveModel) => void;
    clearPurchaseOrderServiceItemDataState: () => void;
    createEditLoading: boolean;
    dataLoading: boolean
}

export interface IState extends IFormBaseState { }

class PurchaseOrderServiceItemCreateEditPage extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            hideForm: false
        }
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.PurchaseOrderServiceItem);

        if(this.props.id != null && Number(this.props.id) > 0) {
            await this.props.loadPurchaseOrderServiceItemById(Number(this.props.id));
        } else {
            await this.props.clearPurchaseOrderServiceItemDataState();
        }
    }

    render() {
        return <React.Fragment>
            <div className="pcoded-content">
                <div className="pcoded-inner-content">
                    <div className="page-header">
                        <div className="page-block">
                            <div className="row">
                                <div className="col-md-12">
                                    <Link to={ScreenUrls.PurchaseOrder.ServiceItem.Index()}
                                          className="btn drp-icon btn-rounded btn-primary dropdown-toggle">
                                        <i className="feather icon-arrow-left"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-body">

                        <div className="page-wrapper">
                            <div className="row">
                                <div className="col-sm-12">
                                    <article className="card mb-4">
                                        {(() => {
                                            if(this.state.hideForm) return;
                                            return <div className="card-body">
                                                <div className="row">
                                                    <div className="col-2">
                                                        <div className={`form-group ${this.vResult("code").className}`}>
                                                            <label htmlFor="itemCode">Code<span className="requried-span">*</span></label>
                                                            <input id="itemCode" className="form-control" type="text"
                                                                   value={this.props.purchaseOrderServiceItemData.code?.toString()}
                                                                   onChange={e => { e.preventDefault();
                                                                       this.ValidateField("code", e.target.value);
                                                                       this.props.updatePurchaseOrderServiceItemDataState({ name: "code", value: e.target.value });
                                                                   }} />
                                                            <ValidationMessageControl message={this.vResult("code").message} />
                                                        </div>
                                                    </div>
                                                    <div className="col-5">
                                                        <div className={`form-group ${this.vResult("title").className}`}>
                                                            <label htmlFor="itemTitle">Title<span className="requried-span">*</span></label>
                                                            <input id="itemTitle" className="form-control" type="text"
                                                                   value={this.props.purchaseOrderServiceItemData.title?.toString()}
                                                                   onChange={e => { e.preventDefault();
                                                                       this.ValidateField("title", e.target.value);
                                                                       this.props.updatePurchaseOrderServiceItemDataState({ name: "title", value: e.target.value });
                                                                   }} />
                                                            <ValidationMessageControl message={this.vResult("title").message} />
                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        <div className={`form-group ${this.vResult("hsnSacCode").className}`}>
                                                            <label htmlFor="itemHsnSacCode">HSN/SAC Code<span className="requried-span">*</span></label>
                                                            <input id="itemHsnSacCode" className="form-control" type="text"
                                                                   value={this.props.purchaseOrderServiceItemData.hsnSacCode?.toString()}
                                                                   onChange={e => { e.preventDefault();
                                                                       this.ValidateField("hsnSacCode", e.target.value);
                                                                       this.props.updatePurchaseOrderServiceItemDataState({ name: "hsnSacCode", value: e.target.value });
                                                                   }} />
                                                            <ValidationMessageControl message={this.vResult("hsnSacCode").message} />
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className="form-group">
                                                            <button type="button" className="btn btn-primary btn--row-right"
                                                                    disabled={this.props.createEditLoading || this.hasError}
                                                                    onClick={e => {
                                                                        e.preventDefault();

                                                                        const error = !this.ValidateForm(this.props.purchaseOrderServiceItemData);
                                                                        this.reloadForm();
                                                                        if(error) return;

                                                                        if (this.props.purchaseOrderServiceItemData.id > 0) {
                                                                            this.props.editPurchaseOrderServiceItem(this.props.purchaseOrderServiceItemData);
                                                                        } else {
                                                                            this.props.createPurchaseOrderServiceItem(this.props.purchaseOrderServiceItemData);
                                                                        }
                                                                    }}>
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })()}
                                        {(() => { if(this.props.createEditLoading) return <div className="loading--bar"><span></span></div> })()}
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}

const mapStateToProps = (state: IAppState) => ({
    purchaseOrderServiceItemData: state.purchaseOrderServiceItem.purchaseOrderServiceItemData,
    createEditLoading: state.purchaseOrderServiceItem.createEditLoading,
    dataLoading: state.purchaseOrderServiceItem.dataLoading
})

export default connect(mapStateToProps, {
    loadPurchaseOrderServiceItemById,
    createPurchaseOrderServiceItem,
    editPurchaseOrderServiceItem,
    updatePurchaseOrderServiceItemDataState,
    clearPurchaseOrderServiceItemDataState
})(PurchaseOrderServiceItemCreateEditPage);
