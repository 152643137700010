
import {IPurchaseOrderServiceItemModel} from "../model/purchase-order-item.model";
import ApiUrls from "../../../common/api-urls";
import {IFormControlDropdownItemModel} from "../../../common/control/model/form.control.field.model";
import {IGetAllRequestModel} from "../../../common/model/get-all-request-model";
import DropdownDisplayHelper from "../../../common/helper/dropdown-display.helper";
import HttpService from "../../../common/service/http.service";

export default class PurchaseOrderServiceItemService {
    static async LoadAll(request?: IGetAllRequestModel): Promise<IPurchaseOrderServiceItemModel[]> {
        return await HttpService.Get<IPurchaseOrderServiceItemModel[]>(ApiUrls.PurchaseOrderItemService.GetAll(request));
    }

    static async Create(data: IPurchaseOrderServiceItemModel): Promise<IPurchaseOrderServiceItemModel> {
        return await HttpService.Post<IPurchaseOrderServiceItemModel, IPurchaseOrderServiceItemModel>(ApiUrls.PurchaseOrderItemService.Create(), data);
    }

    static async Edit(data: IPurchaseOrderServiceItemModel): Promise<IPurchaseOrderServiceItemModel> {
        return await HttpService.Patch<IPurchaseOrderServiceItemModel, IPurchaseOrderServiceItemModel>(ApiUrls.PurchaseOrderItemService.Edit(data.id), data);
    }
    static async Delete(id: number) {
        await HttpService.Delete(ApiUrls.PurchaseOrderItemService.Delete(id));
    }
    static async GetById(id: number): Promise<IPurchaseOrderServiceItemModel> {
        return await HttpService.Get<IPurchaseOrderServiceItemModel>(ApiUrls.PurchaseOrderItemService.GetById(id));
    }

    static async ServicesDropdown(search: string): Promise<IFormControlDropdownItemModel[]> {
        let req: IGetAllRequestModel = {searchStr: search};
        return (await PurchaseOrderServiceItemService.LoadAll(req)).map(d => {
            const obj: IFormControlDropdownItemModel = {
                label: DropdownDisplayHelper.ServiceItem(d),
                value: d.id.toString()
            }
            return obj;
        });
    }
}
