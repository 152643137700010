import { ICleanroomEquipmentModel } from "../../../../model/report.model";
import {
  DeferredLoadOptions,
} from "../../../../../../common/utils";
import ClientService from "../../../../../client/service/client.service";
import ClientAddressService from "../../../../../client/service/client-address.service";
import { IAppState } from "../../../../../../store";
import { connect } from "react-redux";
import ReactSelect from "react-select";
import React from "react";
import { Link } from "react-router-dom";
import ScreenUrls from "../../../../../../common/screen-urls";
import AsyncSelect from "react-select/async";
import { IFormControlDropdownItemModel } from "../../../../../../common/control/model/form.control.field.model";
import DropdownDisplayHelper from "../../../../../../common/helper/dropdown-display.helper";
import { IUserModel } from "../../../../../user/model/user.model";
import { AgGridDefaultColDef } from "../../../../../../common/app-defaults";
import { AgGridReact } from "ag-grid-react";
import ValidationMessageControl from "../../../../../../common/control/validation-message.control";
import {
  FormBaseComponent,
  IFormBaseState,
} from "../../../../../../common/component/form-base.component";
import { createEquipment, clearCleanroomEquipmentDataState, loadCleanroomEquipmentById, updateCleanroomEquipmentDataState, editEquipment } from "../../../../store/cleanroom-equipment/cleanroom-equipment.slice";
import { deleteTest } from "../../../../store/cleanroom-equipment/test.slice";
import SelectTestComponent from "../../../../component/test-list.component";
import { Standards, ReportTestEquipmentState } from "../../../../../../common/_static/report.data";
import { CleanroomEquipmentTests, ZoneClassificationType, ValidationScreens, AccessLevel } from "../../../../../../common/enums";
import DropdownService from "../../../../../../common/service/dropdown.service";
import ClientEquipmentSelector from "../../../../component/cleanroom/client-equipment-selector.component";
import ReportService from "../../../../service/report.service";
import AirflowVelocityPDFComponent from "../../../../component/cleanroom/cleanroom-pdf/airflow-velocity.component";
import { cleanroomEquipmentValidator } from "../../../../validator/cleanroom-equipment/cleanroom-equipment.validator";
import FilterIntegrityPDFComponent from "../../../../component/cleanroom/cleanroom-pdf/filter-integrity.componet";
import { IBaseTestPayload } from "../../../../model/test/test.model";
import LightIntensityPDFComponent from "../../../../component/cleanroom/cleanroom-pdf/light-intensity.component";
import ParticleCountTestPDFComponent from "../../../../component/cleanroom/cleanroom-pdf/particle-count-test.component";
import SoundLevelPDFComponent from "../../../../component/cleanroom/cleanroom-pdf/sound-level-test.component";
import UVLightTestPDFComponent from "../../../../component/cleanroom/cleanroom-pdf/uv-light-intensity-test.component";
import AirflowPatternTestPDFComponent from "../../../../component/cleanroom/cleanroom-pdf/airflow-patter.component";
import RecoveryTestPDFComponent from "../../../../component/cleanroom/cleanroom-pdf/recovery-test.component";

export interface IProps {
  id?: string;
  equipmentData: ICleanroomEquipmentModel;
  createEquipment: (data: ICleanroomEquipmentModel) => void,
  editCleanroomEquipment: (data: ICleanroomEquipmentModel) => void,
  loadCleanroomEquipmentById: (id: number) => void;
  clearEquipmentDataState: () => void;
  updateEquipmentDataState: (data) => void,
  dataLoading: boolean;
  createEditLoading: boolean;
  currentUser: IUserModel;
  accessLevel: number,
  deleteTest: (data: IBaseTestPayload) => void,
  refetchReportData: boolean,
}

export interface IState extends IFormBaseState {
  hideClientAddress: boolean;
  openTestPopup: boolean,
  hideZoneClassification: boolean,
  avTestSectionCollapsed: boolean,
  fiTestSectionCollapsed: boolean,
  liTestSectionCollapsed: boolean,
  pcTestSectionCollapsed: boolean,
  recoveryTestSectionCollapsed: boolean,
  airflowPatternTestSectionCollapsed: boolean,
  soundLevelTestSectionCollapsed: boolean,
  UVLITestSectionCollapsed: boolean,
}

class CleanroomEquipmentCreateEditPage extends FormBaseComponent<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      hideClientAddress: false,
      hideZoneClassification: false,
      hideForm: false,
      openTestPopup: false,
      avTestSectionCollapsed: false,
      fiTestSectionCollapsed: false,
      liTestSectionCollapsed: false,
      pcTestSectionCollapsed: false,
      recoveryTestSectionCollapsed: false,
      airflowPatternTestSectionCollapsed: false,
      soundLevelTestSectionCollapsed: false,
      UVLITestSectionCollapsed: false,
    };
  }

  async componentDidMount() {
    await this.setValidator(ValidationScreens.ReportCleanroomEquipment);

    if (this.props.id != null && this.props.id != "") {
      await this.props.loadCleanroomEquipmentById(Number(this.props.id));
    } else {
      await this.props.clearEquipmentDataState();
    }
  }
  loadClientOption = (inputValue: string) => DeferredLoadOptions(inputValue, ClientService.ClientDropdown);

  loadEUGMPClasses = (inputValue: string) => DeferredLoadOptions(inputValue, DropdownService.LoadEUGMPClasses);

  loadISOClasses = (inputValue: string) => DeferredLoadOptions(inputValue, DropdownService.LoadISOClasses);

  loadClientAddressOption = (inputValue: string) => DeferredLoadOptions(inputValue, (search: string) =>
    new Promise<IFormControlDropdownItemModel[]>(async (resolve) => {
      if (this.props.equipmentData.client == null || this.props.equipmentData.client.id == null || this.props.equipmentData.client.id === 0) {
        resolve([]);
        return;
      }

      const client = await ClientService.GetById(Number(this.props.equipmentData.client?.id));
      if (client == null || client.clientAddresses == null) {
        resolve([]);
        return;
      }

      const list = client.clientAddresses.map(d => {
        const obj: IFormControlDropdownItemModel = {
          label: DropdownDisplayHelper.ClientAddress(d),
          value: d.id.toString()
        }
        return obj;
      });

      if (search == null || search === "") resolve(list);
      resolve(list.filter(l => l.label.indexOf(search) > 0))
    }))

  reloadBillingAddressDropdown = () => {
    this.setState({ hideClientAddress: true })

    setTimeout(() => {
      this.setState({ hideClientAddress: false })
    }, 1);
  }

  reloadZoneValueDropdown = () => {
    this.setState({ hideZoneClassification: true })

    setTimeout(() => {
      this.setState({ hideZoneClassification: false })
    }, 1);
  }

  async componentWillUnmount() {
    this.props.clearEquipmentDataState();
    await this.setValidator(ValidationScreens.None);
  }

  async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    if (prevProps.refetchReportData != this.props.refetchReportData) {
      if (this.props.id) {
        await this.props.loadCleanroomEquipmentById(Number(this.props.id));
      }
    }
  }

  render() {
    if (this.state.hideForm) return;
    return (
      <React.Fragment>
        {(() => {
          if (this.props.dataLoading || this.props.createEditLoading)
            return (
              <div className="loading--bar fixed--top">
                <span></span>
              </div>
            );
        })()}
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="page-header">
              <div className="page-block">
                <div className="row">
                  <div className="col-md-12 d-flex align-items-center justify-content-between">
                    <Link
                      to={ScreenUrls.Report.Index()}
                      className="btn drp-icon btn-rounded btn-primary dropdown-toggle"
                    >
                      <i className="feather icon-arrow-left"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-body">
              <div className="page-wrapper">
                <div className="row">
                  <div className="col-sm-12">
                    <article className="card mb-4">
                      <div className="card-header card-form-header">
                        <div className="card-form-header-title">
                          Basic Details
                        </div>
                      </div>
                      <div className="card-body pt-0">
                        <div className="row">
                          <div className="col-3">
                            <div className={`form-group ${this.vResult("reportNumber").className}`}>
                              <label htmlFor="reportNumber">Report Number<span className="requried-span">*</span></label>
                              <input id="reportNumber" className="form-control" type="text"
                                value={this.props.equipmentData?.reportNumber ?? ""}
                                onChange={e => {
                                  e.preventDefault();
                                  this.ValidateField("reportNumber", e.target.value);
                                  this.props.updateEquipmentDataState({ name: "reportNumber", value: e.target.value });
                                }} />
                              <ValidationMessageControl message={this.vResult("reportNumber").message} />
                            </div>
                          </div>

                          <div className="col-3">
                            <div className={`form-group ${this.vResult("clientId").className}`}>
                              <label htmlFor="clientid">Client<span className="requried-span">*</span></label>
                              <AsyncSelect id="clientId" cacheOptions defaultOptions
                                className="async-select-control"
                                loadOptions={this.loadClientOption}
                                value={this.props.equipmentData.client?.id
                                  ? { label: DropdownDisplayHelper.Client(this.props.equipmentData.client), value: this.props.equipmentData.client.id.toString() }
                                  : { label: "Select Client", value: "" }}
                                onChange={async (e) => {
                                  const cId = Number(e?.value);
                                  this.ValidateField("clientId", cId);
                                  if (cId === null || cId === 0 || (this.props.equipmentData.client != null && cId === this.props.equipmentData.client.id)) return;

                                  const client = await ClientService.GetById(cId);

                                  this.props.updateEquipmentDataState({ name: "client", value: client });
                                  this.props.updateEquipmentDataState({ name: "clientId", value: client.id });

                                  this.props.updateEquipmentDataState({ name: "clientAddress", value: null });
                                  this.props.updateEquipmentDataState({ name: "clientAddressId", value: null });

                                  this.reloadBillingAddressDropdown();

                                  //this.props.updateHasError(this.hasError);
                                }} />
                              <ValidationMessageControl message={this.vResult("clientId").message} />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className={`form-group ${this.vResult("clientAddressId").className}`}>
                              <label htmlFor="clientAddressId">Client Address</label>
                              {(() => {
                                if (this.state.hideClientAddress) return;
                                return (
                                  <AsyncSelect id="clientAddressId" cacheOptions defaultOptions
                                    className="async-select-control"
                                    loadOptions={this.loadClientAddressOption}
                                    value={this.props.equipmentData.clientAddress?.id
                                      ? { label: DropdownDisplayHelper.ClientAddress(this.props.equipmentData.clientAddress), value: this.props.equipmentData.clientAddress.id.toString() }
                                      : { label: "Select ClientAddress", value: "" }}
                                    onChange={async (e) => {
                                      const cId = Number(e?.value);
                                      this.ValidateField("clientAddressId", cId);
                                      if (cId === null || cId === 0 || (this.props.equipmentData.clientAddress != null && cId === this.props.equipmentData.clientAddress.id)) return;

                                      const client = await ClientAddressService.GetById(cId);

                                      this.props.updateEquipmentDataState({ name: "clientAddress", value: client });
                                      this.props.updateEquipmentDataState({ name: "clientAddressId", value: client.id });
                                    }} />
                                )
                              })()}

                              <ValidationMessageControl message={this.vResult("clientAddressId").message} />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className={`form-group`}>
                              <label htmlFor="standard">Standard<span className="requried-span">*</span></label>
                              <ReactSelect id="standard"
                                className="async-select-control"
                                options={Standards}
                                value={this.props.equipmentData?.zoneClassificationType
                                  ? Standards.find((item) => item.value == this.props.equipmentData?.zoneClassificationType.toString())
                                  : { label: "Select standard", value: "" }}

                                onChange={async (e) => {
                                  const cId = Number(e?.value);
                                  if (cId === null || cId === 0) return;
                                  let standard = Standards.find((item) => Number(item.value) == cId);
                                  this.props.updateEquipmentDataState({ name: "zoneClassificationType", value: Number(standard?.value) });
                                  if (Number(standard?.value) == ZoneClassificationType.EUGMP) {
                                    this.props.updateEquipmentDataState({ name: 'eugpmStandardId', value: 0 });
                                    this.reloadZoneValueDropdown();
                                  }
                                  else if (Number(standard?.value) == ZoneClassificationType.ISO) {
                                    this.props.updateEquipmentDataState({ name: 'isoStandardId', value: 0 });
                                    this.reloadZoneValueDropdown();
                                  }
                                }}
                              />
                            </div>
                          </div>
                          {
                            this.props.equipmentData.zoneClassificationType == ZoneClassificationType.ClientSpecification ?
                              null
                              :
                              <div className="col-3">
                                {(() => {
                                  if (this.state.hideZoneClassification) return;
                                  if (this.props.equipmentData.zoneClassificationType == ZoneClassificationType.EUGMP) {
                                    return (
                                      <div className={`form-group ${this.vResult("eugmpStandardId").className}`}>
                                        <label htmlFor="eugmpStandardId">Zone Classification Value<span className="requried-span">*</span></label>
                                        <AsyncSelect id="eugmpStandardId" cacheOptions defaultOptions
                                          className="async-select-control"
                                          loadOptions={this.loadEUGMPClasses}
                                          value={this.props.equipmentData.eugmpStandardId ?
                                            { label: DropdownDisplayHelper.EUGMP(this.props.equipmentData.eugmpStandard ?? null), value: this.props.equipmentData.eugmpStandard?.id.toString() }
                                            : { label: "Select Grade", value: "" }}
                                          onChange={async (e) => {
                                            const cId = Number(e?.value);
                                            this.ValidateField("eugmpStandardId", cId, this.props.equipmentData?.zoneClassificationType, undefined, cleanroomEquipmentValidator.fields[3]?.callBack);
                                            if (cId === null) return;
                                            const value = await ReportService.GetEUGMPtandardById(cId.toString());
                                            this.props.updateEquipmentDataState({ name: "eugmpStandardId", value: value.id });
                                            this.props.updateEquipmentDataState({ name: "eugmpStandard", value: value });
                                            this.props.updateEquipmentDataState({ name: "isoStandardId", value: null });
                                            this.props.updateEquipmentDataState({ name: "isoStandard", value: null });
                                          }} />
                                        <ValidationMessageControl message={this.vResult("eugmpStandardId").message} />
                                      </div>
                                    )
                                  }
                                  if (this.props.equipmentData.zoneClassificationType == ZoneClassificationType.ISO) {
                                    return (
                                      <div className={`form-group ${this.vResult("isoStandardId").className}`}>
                                        <label htmlFor="isoStandardId">Zone Classification Value<span className="requried-span">*</span></label>
                                        <AsyncSelect id="isoStandardId" cacheOptions defaultOptions
                                          className="async-select-control"
                                          loadOptions={this.loadISOClasses}
                                          value={this.props.equipmentData.isoStandardId ?
                                            { label: this.props.equipmentData.isoStandard?.classNumber, value: this.props.equipmentData.isoStandard?.id?.toString() }
                                            : { label: "Select class", value: "" }}
                                          onChange={async (e) => {
                                            const cId = Number(e?.value);
                                            this.ValidateField("isoStandardId", cId, this.props.equipmentData?.zoneClassificationType, undefined, cleanroomEquipmentValidator?.fields[4]?.callBack);
                                            if (cId === null) return;
                                            const value = await ReportService.GetISOStandardById(cId.toString());
                                            this.props.updateEquipmentDataState({ name: "isoStandardId", value: value.id });
                                            this.props.updateEquipmentDataState({ name: "isoStandard", value: value });
                                            this.props.updateEquipmentDataState({ name: "eugmpStandardId", value: null });
                                            this.props.updateEquipmentDataState({ name: "eugmpStandardId", value: null });
                                          }} />
                                        <ValidationMessageControl message={this.vResult("isoStandardId").message} />
                                      </div>
                                    )
                                  }
                                })()}
                              </div>
                          }
                          {this.props.equipmentData.zoneClassificationType == ZoneClassificationType.EUGMP &&
                            <div className="col-3">
                              <div className={`form-group`}>
                                <label htmlFor="abc">Occupancy State</label>
                                <ReactSelect id="abc"
                                  className="async-select-control"
                                  options={ReportTestEquipmentState}
                                  value={this.props.equipmentData?.occupancyState
                                    ? ReportTestEquipmentState.find((item) => item.value == this.props.equipmentData?.occupancyState?.toString())
                                    : { label: "Select Type", value: "" }}

                                  onChange={async (e) => {
                                    const cId = Number(e?.value);
                                    if (cId === null || cId === 0) return;
                                    let standard = ReportTestEquipmentState.find((item) => Number(item.value) == cId);
                                    this.props.updateEquipmentDataState({ name: "occupancyState", value: Number(standard?.value) });
                                  }}
                                />
                              </div>
                            </div>
                          }
                          <div className="col-3">
                            <div className={`form-group ${this.vResult("name").className}`}>
                              <label htmlFor="name">Area of Test</label>
                              <input id="name" className="form-control" type="text"
                                value={this.props.equipmentData?.areaOfTest ?? ""}
                                onChange={e => {
                                  e.preventDefault();
                                  this.props.updateEquipmentDataState({ name: "areaOfTest", value: e.target.value })
                                }} />

                            </div>
                          </div>
                          <div className="col-3">
                            <div className={`form-group ${this.vResult("name").className}`}>
                              <label htmlFor="name">Location</label>
                              <input id="name" className="form-control" type="text"
                                value={this.props.equipmentData?.location ?? ""}
                                onChange={e => {
                                  e.preventDefault();
                                  this.props.updateEquipmentDataState({ name: "location", value: e.target.value })
                                }} />

                            </div>
                          </div>
                          <div className="col-3">
                            <div className={`form-group ${this.vResult("name").className}`}>
                              <label htmlFor="name">Filter Type</label>
                              <input id="name" className="form-control" type="text"
                                value={this.props.equipmentData?.filterType ?? ""}
                                onChange={e => {
                                  e.preventDefault();

                                  this.props.updateEquipmentDataState({ name: "filterType", value: e.target.value })
                                }} />

                            </div>
                          </div>
                          <div className="col-3">
                            <div className={`form-group ${this.vResult("name").className}`}>
                              <label htmlFor="name">Filter Efficiency</label>
                              <input id="name" className="form-control" type="text"
                                value={this.props.equipmentData?.filterEfficiency ?? ""}
                                onChange={e => {
                                  e.preventDefault();

                                  this.props.updateEquipmentDataState({ name: "filterEfficiency", value: e.target.value })
                                }} />

                            </div>
                          </div>
                          <div className="col-3">
                            <div className={`form-group ${this.vResult("name").className}`}>
                              <label htmlFor="name">Protocol Name</label>
                              <input id="name" className="form-control" type="text"
                                value={this.props.equipmentData?.refStandardOrProtocolOrDocumentName ?? ""}
                                onChange={e => {
                                  e.preventDefault();
                                  this.props.updateEquipmentDataState({ name: "refStandardOrProtocolOrDocumentName", value: e.target.value })

                                }} />

                            </div>
                          </div>
                          <div className="col-3">
                            <div className={`form-group ${this.vResult("name").className}`}>
                              <label htmlFor="name">Protocol Number</label>
                              <input id="name" className="form-control" type="text"
                                value={this.props.equipmentData?.procedureOrProtocolOrDocumentNumber ?? ""}
                                onChange={e => {
                                  e.preventDefault();

                                  this.props.updateEquipmentDataState({ name: "procedureOrProtocolOrDocumentNumber", value: e.target.value })

                                }} />

                            </div>
                          </div>
                          {
                            this.props.equipmentData?.client?.id ?
                              <div className="col-12">
                                <div className={`form-group ${this.vResult("clientEquipmentId").className}`}>
                                  <ValidationMessageControl message={this.vResult("clientEquipmentId").message} />
                                  <ClientEquipmentSelector
                                    clientId={this.props.equipmentData?.client?.id}
                                    defaultSelections={this.props.equipmentData?.clientEquipment ? [this.props.equipmentData?.clientEquipment] : null}
                                    onSelection={(d) => {
                                      this.ValidateField('clientEquipmentId', d.id);
                                      this.props.updateEquipmentDataState({ name: "clientEquipmentId", value: d.id });
                                      this.props.updateEquipmentDataState({ name: "clientEquipment", value: d });
                                    }}
                                    onUnSelection={(d) => {
                                      this.ValidateField('clientEquipmentId', d.id);
                                      if (d.id == this.props.equipmentData?.clientEquipmentId) {
                                        this.props.updateEquipmentDataState({ name: "clientEquipmentId", value: 0 });
                                        this.props.updateEquipmentDataState({ name: "clientEquipment", value: null });
                                      }
                                    }}
                                  />
                                </div>
                              </div> : ""
                          }
                        </div>
                      </div>
                    </article>
                    <article className="card no-bg">
                      <div className="row">
                        <div className="col-12 text-right">

                          <button type="button" className="btn mr-0 mb-0 btn-primary"
                            onClick={async (e) => {
                              e.preventDefault();
                              const error = !this.ValidateForm(this.props.equipmentData, this.props.equipmentData);
                              this.reloadForm();
                              if (error) return;
                              if (this.props.equipmentData?.id) {
                                await this.props.editCleanroomEquipment(this.props.equipmentData);
                              } else {
                                const { client, clientAddress, ...rest } = this.props.equipmentData;
                                this.props.createEquipment({ ...rest });
                              }
                            }}>Submit</button>

                        </div>
                      </div>
                    </article>

                    {/* --- AV Test --- */}
                    {
                      (() => {
                        if (!this.props.equipmentData?.id) return;
                        return (
                          <div className="accordion no-bg mt-3" id="avTestAccordionContainer">
                            <div className="card">
                              <div className="card-header">
                                <button className="btn btn-primary accordion--btn" type="button" data-toggle="collapse"
                                  data-target="#avTestAccordionControl" aria-expanded="false" aria-controls="avTestAccordionControl"
                                  onClick={() => { this.setState({ ...this.state, avTestSectionCollapsed: !this.state.avTestSectionCollapsed }) }}>
                                  <i className={`button-icon feather ${this.state.avTestSectionCollapsed ? 'icon-chevrons-up' : 'icon-chevrons-down'}`}></i>
                                  AirFlow Velocity Tests <span className="ml-2 btn-rounded btn-secondary m-0 font-12 pill">{this.props.equipmentData?.airFlowVelocityTests?.length}</span>
                                </button>
                              </div>

                              <div id="avTestAccordionControl" className="collapse" aria-labelledby="headingOne"
                                data-parent="#avTestAccordionContainer">
                                <article className="card">
                                  <div className="card-body pt-0">
                                    <div className="row">
                                      <div className="col-12 text-right my-2">
                                        <Link className={"btn drp-icon btn-primary m-0"}
                                          to={`${ScreenUrls.Report.CleanroomEquipment.Edit(this.props.equipmentData?.id)}/${CleanroomEquipmentTests.airflowvelocity}/${ScreenUrls.CreateUri}`}>Add Test</Link>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-12 grid-wrapper">
                                        <div className="card-block px-0 ag-theme-alpine">
                                          <AgGridReact
                                            pagination={true}
                                            paginationPageSize={10}
                                            unSortIcon={true}
                                            rowHeight={60}
                                            domLayout="autoHeight"
                                            defaultColDef={AgGridDefaultColDef}
                                            columnDefs={[
                                              {
                                                hide: this.props.accessLevel < AccessLevel.Edit,
                                                headerName: "Edit", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                suppressMovable: true,
                                                cellRenderer: (params) => {
                                                  return (

                                                    <Link
                                                      to={`${ScreenUrls.Report.CleanroomEquipment.Edit(this.props.equipmentData?.id)}/${CleanroomEquipmentTests.airflowvelocity}/${ScreenUrls.EditUri}/${params.value}`}
                                                    >
                                                      <i className={`feather icon-edit`}></i>
                                                    </Link>

                                                  )
                                                }
                                              },
                                              {
                                                hide: this.props.accessLevel < AccessLevel.Delete,
                                                headerName: "Delete", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                suppressMovable: true,
                                                cellRenderer: (params) => {
                                                  return (

                                                    <span
                                                      className=""
                                                      onClick={() => { this.props.deleteTest({ testName: CleanroomEquipmentTests.airflowvelocity, id: params.value }) }}

                                                    >
                                                      <i className={`feather icon-trash f-16 text-green`}></i>
                                                    </span>

                                                  )
                                                }
                                              },
                                              {
                                                headerName: "ID#", field: "id", width: 80, sortable: true, cellClass: "grid-cell",
                                                suppressMovable: true,
                                                cellRenderer: (params) => <span>{params.value}</span>
                                              },
                                              {
                                                headerName: "Verified By", field: "verifiedByName", flex: 1, sortable: true, cellClass: "grid-cell",
                                                suppressMovable: true,
                                                cellRenderer: (params) => <span>{params.value}</span>
                                              },

                                              {
                                                suppressMovable: true,
                                                headerName: "Conclusion", field: 'remarks', flex: 2, cellClass: "grid-cell grid-cell-button",
                                                cellRenderer: (params) => {
                                                  return (params.value)
                                                }
                                              },
                                              {
                                                suppressMovable: true,
                                                headerName: "Action", field: 'id', width: 170, cellClass: "grid-cell",
                                                cellRenderer: (params) => {
                                                  return <AirflowVelocityPDFComponent id={params.value} data={{ ...this.props.equipmentData, ...params.data }} currentUser={this.props.currentUser} />
                                                }
                                              }
                                            ]}
                                            rowData={this.props.equipmentData?.airFlowVelocityTests ?? []}>
                                          </AgGridReact>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </article>
                              </div>
                            </div>
                          </div>
                        )
                      })()
                    }

                    {/* --- FI Test --- */}
                    {
                      (() => {
                        if (!this.props.equipmentData?.id) return;
                        return (
                          <div className="accordion no-bg mt-3" id="fiTestAccordionContainer">
                            <div className="card">
                              <div className="card-header">
                                <button className="btn btn-primary accordion--btn" type="button" data-toggle="collapse"
                                  data-target="#fiTestAccordionControl" aria-expanded="false" aria-controls="fiTestAccordionControl"
                                  onClick={() => { this.setState({ ...this.state, fiTestSectionCollapsed: !this.state.fiTestSectionCollapsed }) }}>
                                  <i className={`button-icon feather ${!this.state.fiTestSectionCollapsed ? 'icon-chevrons-down' : 'icon-chevrons-up'}`}></i>
                                  Filter Integrity Tests <span className="ml-2 btn-rounded btn-secondary m-0 font-12 pill">{this.props.equipmentData?.filterIntegrityTests?.length}</span>
                                </button>
                              </div>

                              <div id="fiTestAccordionControl" className="collapse" aria-labelledby="headingOne"
                                data-parent="#fiTestAccordionContainer">
                                <article className="card">
                                  <div className="card-body pt-0">
                                    <div className="row">
                                      <div className="col-12 text-right my-2">
                                        <Link className={"btn drp-icon btn-primary m-0"}
                                          to={`${ScreenUrls.Report.CleanroomEquipment.Edit(this.props.equipmentData?.id)}/${CleanroomEquipmentTests.filterintegrity}/${ScreenUrls.CreateUri}`}>Add Test</Link>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-12 grid-wrapper">
                                        <div className="card-block px-0 ag-theme-alpine">
                                          <AgGridReact
                                            pagination={true}
                                            paginationPageSize={10}
                                            unSortIcon={true}
                                            rowHeight={60}
                                            domLayout="autoHeight"
                                            defaultColDef={AgGridDefaultColDef}
                                            columnDefs={[
                                              {
                                                hide: this.props.accessLevel < AccessLevel.Edit,
                                                headerName: "Edit", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                suppressMovable: true,
                                                cellRenderer: (params) => {
                                                  return (
                                                    <Link
                                                      to={`${ScreenUrls.Report.CleanroomEquipment.Edit(this.props.equipmentData?.id)}/${CleanroomEquipmentTests.filterintegrity}/${ScreenUrls.EditUri}/${params.value}`}
                                                    >
                                                      <i className={`feather icon-edit`}></i>
                                                    </Link>
                                                  )

                                                },
                                              },
                                              {
                                                hide: this.props.accessLevel < AccessLevel.Delete,
                                                headerName: "Delete", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                suppressMovable: true,
                                                cellRenderer: (params) => {
                                                  return (
                                                    <span
                                                      className=""
                                                      onClick={() => { this.props.deleteTest({ testName: CleanroomEquipmentTests.filterintegrity, id: params.value }) }}
                                                    >
                                                      <i className={`feather icon-trash f-16 text-green`}></i>
                                                    </span>

                                                  )
                                                }
                                              },
                                              {
                                                headerName: "ID#", field: "id", width: 80, sortable: true, cellClass: "grid-cell",
                                                suppressMovable: true,
                                                cellRenderer: (params) => <span>{params.value}</span>
                                              },
                                              {
                                                headerName: "Verified By", field: "verifiedByName", flex: 1, sortable: true, cellClass: "grid-cell",
                                                suppressMovable: true,
                                                cellRenderer: (params) => <span>{params.value}</span>
                                              },

                                              {
                                                suppressMovable: true,
                                                headerName: "Conclusion", field: 'remarks', flex: 2, cellClass: "grid-cell grid-cell-button",
                                                cellRenderer: (params) => {
                                                  return (params.value)
                                                }
                                              },
                                              {
                                                suppressMovable: true,
                                                headerName: "Action", field: 'id', width: 160, cellClass: "grid-cell grid-cell-button",
                                                cellRenderer: (params) => {
                                                  return <FilterIntegrityPDFComponent id={params.value} data={{ ...this.props.equipmentData, ...params.data }} currentUser={this.props.currentUser} />
                                                }
                                              }
                                            ]}
                                            rowData={this.props.equipmentData?.filterIntegrityTests ?? []}
                                          >
                                          </AgGridReact>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </article>
                              </div>
                            </div>
                          </div>
                        )
                      })()
                    }


                    {/* --- PC Test --- */}
                    {
                      (() => {
                        if (!this.props.equipmentData?.id) return;
                        return (
                          <div className="accordion no-bg mt-3" id="pcTestAccordionContainer">
                            <div className="card">
                              <div className="card-header">
                                <button className="btn btn-primary accordion--btn" type="button" data-toggle="collapse"
                                  data-target="#pcTestAccordionControl" aria-expanded="false" aria-controls="pcTestAccordionControl"
                                  onClick={() => { this.setState({ ...this.state, pcTestSectionCollapsed: !this.state.pcTestSectionCollapsed }) }}>
                                  <i className={`button-icon feather ${!this.state.pcTestSectionCollapsed ? 'icon-chevrons-down' : 'icon-chevrons-up'}`}></i>
                                  Non Viable Particle Count Tests <span className="ml-2 btn-rounded btn-secondary m-0 font-12 pill">{this.props.equipmentData?.nonViableParticleCountTests?.length}</span>
                                </button>
                              </div>

                              <div id="pcTestAccordionControl" className="collapse" aria-labelledby="headingOne"
                                data-parent="#pcTestAccordionContainer">
                                <article className="card">
                                  <div className="card-body pt-0">
                                    <div className="row">
                                      <div className="col-12 text-right my-2">
                                        <Link className={"btn drp-icon btn-primary m-0"}
                                          to={`${ScreenUrls.Report.CleanroomEquipment.Edit(this.props.equipmentData?.id)}/${CleanroomEquipmentTests.nonviableparticlecount}/${ScreenUrls.CreateUri}`}>Add Test</Link>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-12 grid-wrapper">
                                        <div className="card-block px-0 ag-theme-alpine">
                                          <AgGridReact
                                            pagination={true}
                                            paginationPageSize={10}
                                            unSortIcon={true}
                                            rowHeight={60}
                                            domLayout="autoHeight"
                                            defaultColDef={AgGridDefaultColDef}
                                            columnDefs={[
                                              {
                                                hide: this.props.accessLevel < AccessLevel.Edit,
                                                headerName: "Edit", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                suppressMovable: true,
                                                cellRenderer: (params) => {
                                                  return (
                                                    <Link
                                                      to={`${ScreenUrls.Report.CleanroomEquipment.Edit(this.props.equipmentData?.id)}/${CleanroomEquipmentTests.nonviableparticlecount}/${ScreenUrls.EditUri}/${params.value}`}
                                                    >
                                                      <i className={`feather icon-edit`}></i>
                                                    </Link>
                                                  )

                                                },
                                              },
                                              {
                                                hide: this.props.accessLevel < AccessLevel.Delete,
                                                headerName: "Delete", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                suppressMovable: true,
                                                cellRenderer: (params) => {
                                                  return (
                                                    <span
                                                      className=""
                                                      onClick={() => { this.props.deleteTest({ testName: CleanroomEquipmentTests.nonviableparticlecount, id: params.value }) }}
                                                    >
                                                      <i className={`feather icon-trash f-16 text-green`}></i>
                                                    </span>

                                                  )
                                                }
                                              },
                                              {
                                                headerName: "ID#", field: "id", width: 80, sortable: true, cellClass: "grid-cell",
                                                suppressMovable: true,
                                                cellRenderer: (params) => <span>{params.value}</span>
                                              },
                                              {
                                                headerName: "Verified By", field: "verifiedByName", flex: 1, sortable: true, cellClass: "grid-cell",
                                                suppressMovable: true,
                                                cellRenderer: (params) => <span>{params.value}</span>
                                              },

                                              {
                                                suppressMovable: true,
                                                headerName: "Conclusion", field: 'remarks', flex: 2, cellClass: "grid-cell grid-cell-button",
                                                cellRenderer: (params) => {
                                                  return (params.value)
                                                }
                                              },
                                              {
                                                suppressMovable: true,
                                                headerName: "Action", field: 'id', width: 160, cellClass: "grid-cell grid-cell-button",
                                                cellRenderer: (params) => {
                                                  return <ParticleCountTestPDFComponent id={params.value} data={{ ...this.props.equipmentData, ...params.data }} currentUser={this.props.currentUser} />
                                                }
                                              }
                                            ]}
                                            rowData={this.props.equipmentData?.nonViableParticleCountTests ?? []}
                                          >
                                          </AgGridReact>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </article>
                              </div>
                            </div>
                          </div>
                        )
                      })()
                    }


                    {/* --- LI Test --- */}
                    {
                      (() => {
                        if (!this.props.equipmentData?.id) return;
                        return (
                          <div className="accordion no-bg mt-3" id="liTestAccordionContainer">
                            <div className="card">
                              <div className="card-header">
                                <button className="btn btn-primary accordion--btn" type="button" data-toggle="collapse"
                                  data-target="#liTestAccordionControl" aria-expanded="false" aria-controls="liTestAccordionControl"
                                  onClick={() => { this.setState({ ...this.state, liTestSectionCollapsed: !this.state.liTestSectionCollapsed }) }}>
                                  <i className={`button-icon feather ${!this.state.liTestSectionCollapsed ? 'icon-chevrons-down' : 'icon-chevrons-up'}`}></i>
                                  Light Intensity Tests <span className="ml-2 btn-rounded btn-secondary m-0 font-12 pill">{this.props.equipmentData?.lightIntensityTests?.length}</span>
                                </button>
                              </div>

                              <div id="liTestAccordionControl" className="collapse" aria-labelledby="headingOne"
                                data-parent="#liTestAccordionContainer">
                                <article className="card">
                                  <div className="card-body pt-0">
                                    <div className="row">
                                      <div className="col-12 text-right my-2">

                                        <Link className={"btn drp-icon btn-primary m-0"}
                                          to={`${ScreenUrls.Report.CleanroomEquipment.Edit(this.props.equipmentData?.id)}/${CleanroomEquipmentTests.lightintensity}/${ScreenUrls.CreateUri}`}>Add Test</Link>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-12 grid-wrapper">
                                        <div className="card-block px-0 ag-theme-alpine">
                                          <AgGridReact
                                            pagination={true}
                                            paginationPageSize={10}
                                            unSortIcon={true}
                                            rowHeight={60}
                                            domLayout="autoHeight"
                                            defaultColDef={AgGridDefaultColDef}
                                            columnDefs={[
                                              {
                                                hide: this.props.accessLevel < AccessLevel.Edit,
                                                headerName: "Edit", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                suppressMovable: true,
                                                cellRenderer: (params) => {
                                                  return (
                                                    <Link
                                                      to={`${ScreenUrls.Report.CleanroomEquipment.Edit(this.props.equipmentData?.id)}/${CleanroomEquipmentTests.lightintensity}/${ScreenUrls.EditUri}/${params.value}`}
                                                    >
                                                      <i className={`feather icon-edit`}></i>
                                                    </Link>
                                                  )

                                                },
                                              },
                                              {
                                                hide: this.props.accessLevel < AccessLevel.Delete,
                                                headerName: "Delete", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                suppressMovable: true,
                                                cellRenderer: (params) => {
                                                  return (
                                                    <span
                                                      className=""
                                                      onClick={() => { this.props.deleteTest({ testName: CleanroomEquipmentTests.lightintensity, id: params.value }) }}
                                                    >
                                                      <i className={`feather icon-trash f-16 text-green`}></i>
                                                    </span>

                                                  )
                                                }
                                              },
                                              {
                                                headerName: "ID#", field: "id", width: 80, sortable: true, cellClass: "grid-cell",
                                                suppressMovable: true,
                                                cellRenderer: (params) => <span>{params.value}</span>
                                              },
                                              {
                                                headerName: "Verified By", field: "verifiedByName", flex: 1, sortable: true, cellClass: "grid-cell",
                                                suppressMovable: true,
                                                cellRenderer: (params) => <span>{params.value}</span>
                                              },

                                              {
                                                suppressMovable: true,
                                                headerName: "Conclusion", field: 'remarks', flex: 2, cellClass: "grid-cell grid-cell-button",
                                                cellRenderer: (params) => {
                                                  return (params.value)
                                                }
                                              },
                                              {
                                                suppressMovable: true,
                                                headerName: "Action", field: 'id', width: 160, cellClass: "grid-cell grid-cell-button",
                                                cellRenderer: (params) => {
                                                  return <LightIntensityPDFComponent id={params.value} data={{ ...this.props.equipmentData, ...params.data }} currentUser={this.props.currentUser} />
                                                }
                                              }
                                            ]}
                                            rowData={this.props.equipmentData?.lightIntensityTests ?? []}
                                          >
                                          </AgGridReact>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </article>
                              </div>
                            </div>
                          </div>

                        )
                      })()
                    }

                    {/* --- Recovery Test --- */}
                    {
                      (() => {
                        if (!this.props.equipmentData?.id) return;
                        return (
                          <div className="accordion no-bg mt-3" id="recoveryTestAccordionContainer">
                            <div className="card">
                              <div className="card-header">
                                <button className="btn btn-primary accordion--btn" type="button" data-toggle="collapse"
                                  data-target="#recoveryTestAccordionControl" aria-expanded="false" aria-controls="recoveryTestAccordionControl"
                                  onClick={() => { this.setState({ ...this.state, recoveryTestSectionCollapsed: !this.state.recoveryTestSectionCollapsed }) }}>
                                  <i className={`button-icon feather ${!this.state.recoveryTestSectionCollapsed ? 'icon-chevrons-down' : 'icon-chevrons-up'}`}></i>
                                  Recovery Tests <span className="ml-2 btn-rounded btn-secondary m-0 font-12 pill">{this.props.equipmentData?.recoveryTests?.length}</span>
                                </button>
                              </div>

                              <div id="recoveryTestAccordionControl" className="collapse" aria-labelledby="headingOne"
                                data-parent="#recoveryTestAccordionContainer">
                                <article className="card">
                                  <div className="card-body pt-0">
                                    <div className="row">
                                      <div className="col-12 text-right my-2">

                                        <Link className={"btn drp-icon btn-primary m-0"}
                                          to={`${ScreenUrls.Report.CleanroomEquipment.Edit(this.props.equipmentData?.id)}/${CleanroomEquipmentTests.recovery}/${ScreenUrls.CreateUri}`}>Add Test</Link>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-12 grid-wrapper">
                                        <div className="card-block px-0 ag-theme-alpine">
                                          <AgGridReact
                                            pagination={true}
                                            paginationPageSize={10}
                                            unSortIcon={true}
                                            rowHeight={60}
                                            domLayout="autoHeight"
                                            defaultColDef={AgGridDefaultColDef}
                                            columnDefs={[
                                              {
                                                hide: this.props.accessLevel < AccessLevel.Edit,
                                                headerName: "Edit", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                suppressMovable: true,
                                                cellRenderer: (params) => {
                                                  return (
                                                    <Link
                                                      to={`${ScreenUrls.Report.CleanroomEquipment.Edit(this.props.equipmentData?.id)}/${CleanroomEquipmentTests.recovery}/${ScreenUrls.EditUri}/${params.value}`}
                                                    >
                                                      <i className={`feather icon-edit`}></i>
                                                    </Link>
                                                  )

                                                },
                                              },
                                              {
                                                hide: this.props.accessLevel < AccessLevel.Delete,
                                                headerName: "Delete", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                suppressMovable: true,
                                                cellRenderer: (params) => {
                                                  return (
                                                    <span
                                                      className=""
                                                      onClick={() => { this.props.deleteTest({ testName: CleanroomEquipmentTests.recovery, id: params.value }) }}
                                                    >
                                                      <i className={`feather icon-trash f-16 text-green`}></i>
                                                    </span>
                                                  )
                                                }
                                              },
                                              {
                                                headerName: "ID#", field: "id", width: 80, sortable: true, cellClass: "grid-cell",
                                                suppressMovable: true,
                                                cellRenderer: (params) => <span>{params.value}</span>
                                              },
                                              {
                                                headerName: "Verified By", field: "verifiedByName", flex: 1, sortable: true, cellClass: "grid-cell",
                                                suppressMovable: true,
                                                cellRenderer: (params) => <span>{params.value}</span>
                                              },

                                              {
                                                suppressMovable: true,
                                                headerName: "Conclusion", field: 'remarks', flex: 2, cellClass: "grid-cell grid-cell-button",
                                                cellRenderer: (params) => {
                                                  return (params.value)
                                                }
                                              },
                                              {
                                                suppressMovable: true,
                                                headerName: "Action", field: 'id', width: 160, cellClass: "grid-cell grid-cell-button",
                                                cellRenderer: (params) => {
                                                  return <RecoveryTestPDFComponent id={params.value} data={{ ...this.props.equipmentData, ...params.data }} currentUser={this.props.currentUser} />
                                                }
                                              }
                                            ]}
                                            rowData={this.props.equipmentData?.recoveryTests ?? []}
                                          >
                                          </AgGridReact>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </article>
                              </div>
                            </div>
                          </div>

                        )
                      })()
                    }

                    {/* --- Airflow Patter Test --- */}
                    {
                      (() => {
                        if (!this.props.equipmentData?.id) return;
                        return (
                          <div className="accordion no-bg mt-3" id="airflowpatternTestAccordionContainer">
                            <div className="card">
                              <div className="card-header">
                                <button className="btn btn-primary accordion--btn" type="button" data-toggle="collapse"
                                  data-target="#airflowpatternTestAccordionControl" aria-expanded="false" aria-controls="airflowpatternTestAccordionControl"
                                  onClick={() => { this.setState({ ...this.state, airflowPatternTestSectionCollapsed: !this.state.airflowPatternTestSectionCollapsed }) }}>
                                  <i className={`button-icon feather ${!this.state.airflowPatternTestSectionCollapsed ? 'icon-chevrons-down' : 'icon-chevrons-up'}`}></i>
                                  Airflow Pattern Tests <span className="ml-2 btn-rounded btn-secondary m-0 font-12 pill">{this.props.equipmentData?.airFlowPatternTests?.length}</span>
                                </button>
                              </div>

                              <div id="airflowpatternTestAccordionControl" className="collapse" aria-labelledby="headingOne"
                                data-parent="#airflowpatternTestAccordionContainer">
                                <article className="card">
                                  <div className="card-body pt-0">
                                    <div className="row">
                                      <div className="col-12 text-right my-2">

                                        <Link className={"btn drp-icon btn-primary m-0"}
                                          to={`${ScreenUrls.Report.CleanroomEquipment.Edit(this.props.equipmentData?.id)}/${CleanroomEquipmentTests.airflowpattern}/${ScreenUrls.CreateUri}`}>Add Test</Link>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-12 grid-wrapper">
                                        <div className="card-block px-0 ag-theme-alpine">
                                          <AgGridReact
                                            pagination={true}
                                            paginationPageSize={10}
                                            unSortIcon={true}
                                            rowHeight={60}
                                            domLayout="autoHeight"
                                            defaultColDef={AgGridDefaultColDef}
                                            columnDefs={[
                                              {
                                                hide: this.props.accessLevel < AccessLevel.Edit,
                                                headerName: "Edit", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                suppressMovable: true,
                                                cellRenderer: (params) => {
                                                  return (
                                                    <Link
                                                      to={`${ScreenUrls.Report.CleanroomEquipment.Edit(this.props.equipmentData?.id)}/${CleanroomEquipmentTests.airflowpattern}/${ScreenUrls.EditUri}/${params.value}`}
                                                    >
                                                      <i className={`feather icon-edit`}></i>
                                                    </Link>
                                                  )

                                                },
                                              },
                                              {
                                                hide: this.props.accessLevel < AccessLevel.Delete,
                                                headerName: "Delete", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                suppressMovable: true,
                                                cellRenderer: (params) => {
                                                  return (
                                                    <span
                                                      className=""
                                                      onClick={() => { this.props.deleteTest({ testName: CleanroomEquipmentTests.airflowpattern, id: params.value }) }}
                                                    >
                                                      <i className={`feather icon-trash f-16 text-green`}></i>
                                                    </span>
                                                  )
                                                }
                                              },
                                              {
                                                headerName: "ID#", field: "id", width: 80, sortable: true, cellClass: "grid-cell",
                                                suppressMovable: true,
                                                cellRenderer: (params) => <span>{params.value}</span>
                                              },
                                              {
                                                headerName: "Verified By", field: "verifiedByName", flex: 1, sortable: true, cellClass: "grid-cell",
                                                suppressMovable: true,
                                                cellRenderer: (params) => <span>{params.value}</span>
                                              },

                                              {
                                                suppressMovable: true,
                                                headerName: "Conclusion", field: 'remarks', flex: 2, cellClass: "grid-cell grid-cell-button",
                                                cellRenderer: (params) => {
                                                  return (params.value)
                                                }
                                              },
                                              {
                                                suppressMovable: true,
                                                headerName: "Action", field: 'id', width: 160, cellClass: "grid-cell grid-cell-button",
                                                cellRenderer: (params) => {
                                                  return <AirflowPatternTestPDFComponent id={params.value} data={{ ...this.props.equipmentData, ...params.data }} currentUser={this.props.currentUser} />
                                                }
                                              }
                                            ]}
                                            rowData={this.props.equipmentData?.airFlowPatternTests ?? []}
                                          >
                                          </AgGridReact>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </article>
                              </div>
                            </div>
                          </div>

                        )
                      })()
                    }

                    {/* --- Sound Level Test --- */}
                    {
                      (() => {
                        if (!this.props.equipmentData?.id) return;
                        return (
                          <div className="accordion no-bg mt-3" id="soundLevelTestAccordionContainer">
                            <div className="card">
                              <div className="card-header">
                                <button className="btn btn-primary accordion--btn" type="button" data-toggle="collapse"
                                  data-target="#soundLevelTestAccordionControl" aria-expanded="false" aria-controls="soundLevelTestAccordionControl"
                                  onClick={() => { this.setState({ ...this.state, soundLevelTestSectionCollapsed: !this.state.soundLevelTestSectionCollapsed }) }}>
                                  <i className={`button-icon feather ${!this.state.soundLevelTestSectionCollapsed ? 'icon-chevrons-down' : 'icon-chevrons-up'}`}></i>
                                  Sound Level Tests <span className="ml-2 btn-rounded btn-secondary m-0 font-12 pill">{this.props.equipmentData?.soundLevelTests?.length}</span>
                                </button>
                              </div>

                              <div id="soundLevelTestAccordionControl" className="collapse" aria-labelledby="headingOne"
                                data-parent="#soundLevelTestAccordionContainer">
                                <article className="card">
                                  <div className="card-body pt-0">
                                    <div className="row">
                                      <div className="col-12 text-right my-2">

                                        <Link className={"btn drp-icon btn-primary m-0"}
                                          to={`${ScreenUrls.Report.CleanroomEquipment.Edit(this.props.equipmentData?.id)}/${CleanroomEquipmentTests.soundlevel}/${ScreenUrls.CreateUri}`}>Add Test</Link>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-12 grid-wrapper">
                                        <div className="card-block px-0 ag-theme-alpine">
                                          <AgGridReact
                                            pagination={true}
                                            paginationPageSize={10}
                                            unSortIcon={true}
                                            rowHeight={60}
                                            domLayout="autoHeight"
                                            defaultColDef={AgGridDefaultColDef}
                                            columnDefs={[
                                              {
                                                hide: this.props.accessLevel < AccessLevel.Edit,
                                                headerName: "Edit", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                suppressMovable: true,
                                                cellRenderer: (params) => {
                                                  return (
                                                    <Link
                                                      to={`${ScreenUrls.Report.CleanroomEquipment.Edit(this.props.equipmentData?.id)}/${CleanroomEquipmentTests.soundlevel}/${ScreenUrls.EditUri}/${params.value}`}
                                                    >
                                                      <i className={`feather icon-edit`}></i>
                                                    </Link>
                                                  )

                                                },
                                              },
                                              {
                                                hide: this.props.accessLevel < AccessLevel.Delete,
                                                headerName: "Delete", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                suppressMovable: true,
                                                cellRenderer: (params) => {
                                                  return (
                                                    <span
                                                      className=""
                                                      onClick={() => { this.props.deleteTest({ testName: CleanroomEquipmentTests.soundlevel, id: params.value }) }}
                                                    >
                                                      <i className={`feather icon-trash f-16 text-green`}></i>
                                                    </span>
                                                  )
                                                }
                                              },
                                              {
                                                headerName: "ID#", field: "id", width: 80, sortable: true, cellClass: "grid-cell",
                                                suppressMovable: true,
                                                cellRenderer: (params) => <span>{params.value}</span>
                                              },
                                              {
                                                headerName: "Verified By", field: "verifiedByName", flex: 1, sortable: true, cellClass: "grid-cell",
                                                suppressMovable: true,
                                                cellRenderer: (params) => <span>{params.value}</span>
                                              },

                                              {
                                                suppressMovable: true,
                                                headerName: "Conclusion", field: 'remarks', flex: 2, cellClass: "grid-cell grid-cell-button",
                                                cellRenderer: (params) => {
                                                  return (params.value)
                                                }
                                              },
                                              {
                                                suppressMovable: true,
                                                headerName: "Action", field: 'id', width: 160, cellClass: "grid-cell grid-cell-button",
                                                cellRenderer: (params) => {
                                                  return <SoundLevelPDFComponent id={params.value} data={{ ...this.props.equipmentData, ...params.data }} currentUser={this.props.currentUser} />
                                                }
                                              }
                                            ]}
                                            rowData={this.props.equipmentData?.soundLevelTests ?? []}
                                          >
                                          </AgGridReact>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </article>
                              </div>
                            </div>
                          </div>

                        )
                      })()
                    }

                    {/* --- UV Light Intensity Test --- */}
                    {
                      (() => {
                        if (!this.props.equipmentData?.id) return;
                        return (
                          <div className="accordion no-bg mt-3" id="UVLITestAccordionContainer">
                            <div className="card">
                              <div className="card-header">
                                <button className="btn btn-primary accordion--btn" type="button" data-toggle="collapse"
                                  data-target="#UVLITestAccordionControl" aria-expanded="false" aria-controls="UVLITestAccordionControl"
                                  onClick={() => { this.setState({ ...this.state, UVLITestSectionCollapsed: !this.state.UVLITestSectionCollapsed }) }}>
                                  <i className={`button-icon feather ${!this.state.UVLITestSectionCollapsed ? 'icon-chevrons-down' : 'icon-chevrons-up'}`}></i>
                                  UV Light Intensity Tests <span className="ml-2 btn-rounded btn-secondary m-0 font-12 pill">{this.props.equipmentData?.uvLightIntensityTests?.length}</span>
                                </button>
                              </div>

                              <div id="UVLITestAccordionControl" className="collapse" aria-labelledby="headingOne"
                                data-parent="#UVLITestAccordionContainer">
                                <article className="card">
                                  <div className="card-body pt-0">
                                    <div className="row">
                                      <div className="col-12 text-right my-2">

                                        <Link className={"btn drp-icon btn-primary m-0"}
                                          to={`${ScreenUrls.Report.CleanroomEquipment.Edit(this.props.equipmentData?.id)}/${CleanroomEquipmentTests.uvlightintensity}/${ScreenUrls.CreateUri}`}>Add Test</Link>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-12 grid-wrapper">
                                        <div className="card-block px-0 ag-theme-alpine">
                                          <AgGridReact
                                            pagination={true}
                                            paginationPageSize={10}
                                            unSortIcon={true}
                                            rowHeight={60}
                                            domLayout="autoHeight"
                                            defaultColDef={AgGridDefaultColDef}
                                            columnDefs={[
                                              {
                                                hide: this.props.accessLevel < AccessLevel.Edit,
                                                headerName: "Edit", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                suppressMovable: true,
                                                cellRenderer: (params) => {
                                                  return (
                                                    <Link
                                                      to={`${ScreenUrls.Report.CleanroomEquipment.Edit(this.props.equipmentData?.id)}/${CleanroomEquipmentTests.uvlightintensity}/${ScreenUrls.EditUri}/${params.value}`}
                                                    >
                                                      <i className={`feather icon-edit`}></i>
                                                    </Link>
                                                  )

                                                },
                                              },
                                              {
                                                hide: this.props.accessLevel < AccessLevel.Delete,
                                                headerName: "Delete", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                suppressMovable: true,
                                                cellRenderer: (params) => {
                                                  return (
                                                    <span
                                                      className=""
                                                      onClick={() => { this.props.deleteTest({ testName: CleanroomEquipmentTests.uvlightintensity, id: params.value }) }}
                                                    >
                                                      <i className={`feather icon-trash f-16 text-green`}></i>
                                                    </span>
                                                  )
                                                }
                                              },
                                              {
                                                headerName: "ID#", field: "id", width: 80, sortable: true, cellClass: "grid-cell",
                                                suppressMovable: true,
                                                cellRenderer: (params) => <span>{params.value}</span>
                                              },
                                              {
                                                headerName: "Verified By", field: "verifiedByName", flex: 1, sortable: true, cellClass: "grid-cell",
                                                suppressMovable: true,
                                                cellRenderer: (params) => <span>{params.value}</span>
                                              },

                                              {
                                                suppressMovable: true,
                                                headerName: "Conclusion", field: 'remarks', flex: 2, cellClass: "grid-cell grid-cell-button",
                                                cellRenderer: (params) => {
                                                  return (params.value)
                                                }
                                              },
                                              {
                                                suppressMovable: true,
                                                headerName: "Action", field: 'id', width: 160, cellClass: "grid-cell grid-cell-button",
                                                cellRenderer: (params) => {
                                                  return <UVLightTestPDFComponent id={params.value} data={{ ...this.props.equipmentData, ...params.data }} currentUser={this.props.currentUser} />
                                                }
                                              }
                                            ]}
                                            rowData={this.props.equipmentData?.uvLightIntensityTests ?? []}
                                          >
                                          </AgGridReact>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </article>
                              </div>
                            </div>
                          </div>
                        )
                      })()
                    }

                    {
                      this.props.equipmentData?.id && this.state.openTestPopup &&
                      <SelectTestComponent toggle={this.state.openTestPopup}
                        handleToggleState={() => this.setState({ ...this.state, openTestPopup: false })}
                        reportData={this.props.equipmentData}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </React.Fragment >
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  equipmentData: state.cleanroomequipment.equipmentData,
  refetchReportData: state.cleanroomequipment.refetchReportData,
  dataLoading: state.report.dataLoading,
  createEditLoading: state.report.createEditLoading,
  currentUser: state.userSession.currentUser,
});
export default connect(mapStateToProps, {
  deleteTest,
  createEquipment,
  clearEquipmentDataState: clearCleanroomEquipmentDataState,
  updateEquipmentDataState: updateCleanroomEquipmentDataState,
  editCleanroomEquipment: editEquipment,
  loadCleanroomEquipmentById,
})(CleanroomEquipmentCreateEditPage);


