import ApiUrls from "../api-urls";
import HttpService from "./http.service";
import {IFileModel, IGetAllFileRequestModel} from "../model/file.model";
import {LocalStorageKeys} from "../enums";

export default class FileService {
    static async LoadAll(request?: IGetAllFileRequestModel): Promise<IFileModel[]> {
        return await HttpService.Get<IFileModel[]>(ApiUrls.File.GetAll(request));
    }

    static async Create(data: IFileModel): Promise<any> {
        const formData = new FormData();
        formData.append('entityType', data.entityType.toString());
        formData.append('storageType', data.storageType.toString());
        formData.append('name', data.name != null && data.name != ""? data.name.toString(): "");
        formData.append('description', data.description != null && data.description != ""? data.description.toString(): "");
        formData.append('publicLink', data.publicLink != null && data.publicLink != ""? data.publicLink.toString(): "");
        formData.append('entityId', data.entityId != null ? data.entityId.toString(): "");
        formData.append("fileData", data.fileData);

        const url = ApiUrls.File.Create();
        const token = localStorage.getItem(LocalStorageKeys.AccessToken.toString());
        const response = await fetch(ApiUrls.File.Create(), {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'processData': 'false',
                'contentType': 'false'
            },
            body: formData
        });
        HttpService.RequestLog(url, response);
        return await response.json() as IFileModel;
    }

    static async Edit(data: IFileModel): Promise<IFileModel> {
        return await HttpService.Patch<IFileModel, IFileModel>(ApiUrls.File.Edit(data.id), data);
    }

    static async GetById(id: number): Promise<IFileModel> {
        return await HttpService.Get<IFileModel>(ApiUrls.File.GetById(id));
    }

    static async Download(id: number): Promise<any> {
        const url = ApiUrls.File.Download(id);
        const token = localStorage.getItem(LocalStorageKeys.AccessToken.toString());
        const response = await fetch(url, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'ContentType': 'application/octet-stream'
            },
        });

        HttpService.RequestLog(url, response);
        return response;
    }
    static async Delete(id: number): Promise<any> {
        return await HttpService.Delete(ApiUrls.File.Delete(id));
}
}
