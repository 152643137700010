import {Route, Routes} from "react-router-dom";
import React from "react";
import AccountLayout from "../../common/screen/layout/account.layout";
import WelcomePage from "./welcome.page";
import ScreenUrls from "../../common/screen-urls";

const WelcomeRoutes = () => {
    return <Routes>
        <Route element={<AccountLayout />}>
            <Route path={ScreenUrls.Home()}>
                <Route index element={<WelcomePage  />} />
            </Route>
            <Route path={ScreenUrls.Welcome()}>
                <Route index element={<WelcomePage  />} />
            </Route>
        </Route>
    </Routes>
}

export default WelcomeRoutes;
