import {iNotification} from "react-notifications-component/dist/src/typings";
import {ColDef} from "ag-grid-community/dist/lib/entities/colDef";

export const AppDefaults = {
    EntityType: 1,
    FileStorageType: 1
}

export const NotificationOptions: iNotification = {
    type: "default",
    insert: "top",
    container: "top-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 1000,
        onScreen: true,
        pauseOnHover: true
    }
}

export const FailureNotificationOptions: iNotification = {
    type: "danger",
    insert: "top",
    container: "top-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 6000,
        onScreen: true,
        pauseOnHover: true
    }
}

export const AgGridDefaultColDef: ColDef<any> = {
    resizable: true,
}
