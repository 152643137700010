    import {BaseComponent} from "./base.component";

    export interface IProps {}

    class PreLoaderComponent extends BaseComponent<IProps, any> {
        render() {
            return <div className="loader-bg" hidden>
                        <div className="loader-track">
                            <div className="loader-fill"></div>
                        </div>
                    </div>
        }
    }

    export default PreLoaderComponent;
