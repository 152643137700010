import { BaseComponent } from "../../../common/component/base.component";
import { IAppState } from "../../../store";
import { connect } from "react-redux";
import { IWorkflowModel } from "../model/workflow.model";
import { loadWorkflows } from "../store/workflow.slice";
import { Link } from "react-router-dom";
import ScreenUrls from "../../../common/screen-urls";
import { AgGridReact } from "ag-grid-react";
import { displayDate } from "../../../common/utils";
import { AccessLevel } from "../../../common/enums";
import { AgGridDefaultColDef } from "../../../common/app-defaults";

export interface IProps {
    accessLevel: number,
    workflowList: IWorkflowModel[],
    loadWorkflows: () => void,
    listLoading: boolean
}

class WorkflowPage extends BaseComponent<IProps, any> {

    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.props.loadWorkflows();
    }

    render() {
        return <div className="pcoded-content">
            <div className="pcoded-inner-content">
                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h5>List of workflows</h5>
                                    </div>
                                    {(() => { if (this.props.listLoading) return <div className="loading--bar"><span></span></div> })()}
                                    <div className="grid-wrapper">
                                        <div className="card-block workflow-index-page-grid ag-theme-alpine">
                                            <AgGridReact
                                                pagination={true}
                                                paginationPageSize={10}
                                                unSortIcon={true}
                                                rowHeight={60}
                                                defaultColDef={AgGridDefaultColDef}
                                                columnDefs={[
                                                    {
                                                        hide: this.props.accessLevel < AccessLevel.Edit,
                                                        headerName: "Edit", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link  grid-cell-link",
                                                        suppressMovable: true,
                                                        cellRenderer: (params) => {
                                                            return (
                                                                <Link to={ScreenUrls.Workflows.Edit(params.value)}>
                                                                    <i className={`feather icon-edit`}></i>
                                                                </Link>
                                                            )
                                                        }
                                                    },
                                                    // {
                                                    //     hide: this.props.accessLevel < AccessLevel.Delete,
                                                    //     headerName: "Delete", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link delete",
                                                    //     suppressMovable: true,
                                                    //     cellRenderer: (params) => {
                                                    //         return (
                                                    //             <GridDeleteButtonControl onDelete={async () => {
                                                    //                 await WorkflowService.Delete(params.value);
                                                    //                 this.props.loadWorkflows();
                                                    //             }}></GridDeleteButtonControl>
                                                    //         )
                                                    //     }
                                                    // },
                                                    {
                                                        headerName: "PO#", field: "purchaseOrder", flex: 1, sortable: true, cellClass: "grid-cell",
                                                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                            if (valueA?.poNumber == valueB?.poNumber) return 0;
                                                            return (valueA?.poNumber > valueB?.poNumber) ? 1 : -1;
                                                        },
                                                        suppressMovable: true,
                                                        cellRenderer: (params) => <span>{params.value?.poNumber}</span>
                                                    },
                                                    {
                                                        headerName: "Date", field: "purchaseOrder", width: 150, sortable: true, cellClass: "grid-cell",
                                                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                            if (valueA?.poDate == valueB?.poDate) return 0;
                                                            return (valueA?.poDate > valueB?.poDate) ? 1 : -1;
                                                        },
                                                        suppressMovable: true,
                                                        cellRenderer: (params) => <span>{displayDate(params.value?.poDate)}</span>
                                                    },
                                                    {
                                                        headerName: "Client", field: "purchaseOrder", flex: 3, sortable: true, cellClass: "grid-cell",
                                                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                            if (valueA?.client?.companyName == valueB?.client?.companyName) return 0;
                                                            return (valueA?.client?.companyName > valueB?.client?.companyName) ? 1 : -1;
                                                        },
                                                        suppressMovable: true,
                                                        cellRenderer: (params) => <span>{params.value?.client?.companyName}</span>
                                                    },
                                                    {
                                                        headerName: "Stage", field: "currentStage", flex: 2.5, sortable: true, cellClass: "grid-cell",
                                                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                            if (valueA?.name == valueB?.name) return 0;
                                                            return (valueA?.name > valueB?.name) ? 1 : -1;
                                                        },
                                                        suppressMovable: true,
                                                        cellRenderer: (params) => <span>{params.value?.name}</span>
                                                    },
                                                    {
                                                        headerName: "Assigned To", field: "assignee", flex: 2, sortable: true, cellClass: "grid-cell",
                                                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                            if (valueA?.firstName == valueB?.firstName) return 0;
                                                            return (valueA?.firstName > valueB?.firstName) ? 1 : -1;
                                                        },
                                                        suppressMovable: true,
                                                        cellRenderer: (params) => {
                                                            if (params.value == null) return <span>Unassigned</span>;
                                                            return <span>{`${params.value?.firstName} ${params.value?.lastName}`}</span>
                                                        }
                                                    },
                                                    {
                                                        suppressMovable: true,
                                                        headerName: "Action", field: 'id', width: 180, cellClass: "grid-cell grid-cell-button",
                                                        cellRenderer: (params) => {
                                                            return (<Link to={ScreenUrls.Workflows.View(params.value)}>
                                                                View
                                                            </Link>)
                                                        }
                                                    }
                                                ]}
                                                rowData={this.props.workflowList}>
                                            </AgGridReact>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state: IAppState) => ({
    workflowList: state.workflow.workflowList,
    listLoading: state.workflow.listLoading
})

export default connect(mapStateToProps, { loadWorkflows })(WorkflowPage);
