import {IFieldValidationModel, IValidatorModel} from "../../../common/model/validator.model";
import {ValidationScreens, ValidationType} from "../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "poNumber",
        rules: [
            {
                type: ValidationType.Required,
                message: "PO number is required"
            }
        ]
    },
    {
        fieldName: "poDate",
        rules: [
            {
                type: ValidationType.Required,
                message: "PO Date is required"
            }
        ]
    },
    {
        fieldName: "clientId",
        rules: [
            {
                type: ValidationType.Required,
                message: "Client is required"
            }
        ]
    },
    {
        fieldName: "billingAddressId",
        rules: [
            {
                type: ValidationType.Required,
                message: "Billing address is required"
            }
        ]
    },
    {
        fieldName: "deliveryAddressId",
        rules: [
            {
                type: ValidationType.Required,
                message: "Delivery address is required"
            }
        ]
    },
    {
        fieldName: "lineItems",
        rules: [
            {
                type: ValidationType.ListGreaterThanZero,
                message: "At least one line item is required"
            }
        ]
    }
]

export const purchaseOrderWithPOItemValidator: IValidatorModel = {
    screen: ValidationScreens.PurchaseOrderWithPOItem,
    fields: fields
}
