import { IPaginationModel } from "../pagination.model";
import { ICategoryModel } from "../category.model";
import { IFileModel } from "../file.model";
import { EntityType, FileStorageType } from "../../enums";

const defaultPagination: IPaginationModel = {
    hasNextPage: true,
    hasPreviousPage: false,
    page: 1,
    pageSize: 10,
    total: 0,
}


export const categoryDefault: ICategoryModel = {
    id: 0,
    name: ""
}

export const fileDefault: IFileModel = {
    id: 0,
    name: '',
    description: '',
    storageType: FileStorageType.Link,
    entityType: EntityType.Others,
    filePath: '',
    fileSizeInBytes: 0,
    fileData: null,
    publicLink: "",
    entityId: 0,
    contentType: null,
    isDeleted: false
}
