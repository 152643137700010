import { BaseComponent } from "./base.component";
import { Link } from "react-router-dom";
import { setActiveNav } from "../stores/navigation.slice";
import { IAppState } from "../../store";
import { connect } from "react-redux";
import { INavItem, INavSubItem } from "../model/navigation.model";
import { IUserModel } from "../../features/user/model/user.model";
import { toggleNavCollapsed } from "../../features/user/store/user-session.slice";
import RouteHelper from "../helper/route-helper";
import { AccessLevel } from "../enums";

export interface IProps {
    activeNavIndex: 0;
    activeSubNavIndex: 0;
    navItems: INavItem[];
    setActiveNav: (i: number) => void;
    currentUser: IUserModel,
    isNavCollapsed: boolean,
    toggleNavCollapsed: () => void
}

class NavigationComponent extends BaseComponent<IProps, any> {
    renderSubMenu = (parentActive: boolean, subMenuItems?: INavSubItem[]) => {
        if (subMenuItems != null && subMenuItems.length > 0) {
            return <ul className="nav pcoded-inner-navbar nav-sub-menu"
                style={{ display: parentActive ? '' : 'none' }}>
                {
                    subMenuItems.map((mi, i) => {
                        const access = RouteHelper.GetFeatureAccessLevel(mi.feature);
                        if ((mi.hide != null && mi.hide) || access.accessLevel < AccessLevel.View) return;

                        return <li key={`${mi.index}-${i}`} className={`nav-nav-item ${mi.index === this.props.activeSubNavIndex ? "active" : ""}`}>
                            <Link to={mi.linkTo} className="nav-link">
                                <span className="pcoded-micon">
                                    <i className="feather icon-corner-down-right"></i>
                                </span>
                                <span className="pcoded-mtext">{mi.title}</span>
                            </Link>
                        </li>
                    })
                }
            </ul>
        }
        return;
    }
    render() {
        return <nav className={`pcoded-navbar ${this.props.isNavCollapsed ? 'navbar-collapsed' : ''}`}>
            <div className="navbar-wrapper">
                <div className="navbar-brand header-logo">
                    <Link to="/" className="b-brand">
                        <div className="b-bg" style={{ background: "none" }}>
                            <i className="feather icon-server"></i>
                        </div>
                        <span className="b-title">IncepOne</span>
                    </Link>
                    <span className="mobile-menu cursor-pointer" id="mobile-collapse" onClick={() => this.props.toggleNavCollapsed()}><span></span></span>
                </div>
                <div className="navbar-content scroll-div">
                    <ul className="nav pcoded-inner-navbar">
                        {
                            this.props.navItems.map((e, i) => {
                                const access = RouteHelper.GetFeatureAccessLevel(e.feature);
                                if ((e.hide != null && e.hide) || access.accessLevel < AccessLevel.View) return;

                                return <li key={`${e.index}-${i}`} className={`nav-nav-item ${e.index === this.props.activeNavIndex ? "active" : ""}`}>
                                    <Link to={e.linkTo} className="nav-link">
                                        <span className="pcoded-micon">
                                            <i className={`feather ${e.iconClass}`}></i>
                                        </span>
                                        <span className="pcoded-mtext">{e.title}</span>
                                    </Link>
                                    {this.renderSubMenu(e.index === this.props.activeNavIndex, e.subMenu)}
                                </li>
                            })
                        }
                    </ul>
                </div>
            </div>
        </nav>
    }
}

const mapStateToProps = (state: IAppState) => ({
    navItems: state.navigation.navItems,
    activeNavIndex: state.navigation.activeNavIndex,
    activeSubNavIndex: state.navigation.activeSubNavIndex,
    currentUser: state.userSession.currentUser,
    isNavCollapsed: state.userSession.isNavCollapsed
});

export default connect(mapStateToProps, { setActiveNav, toggleNavCollapsed })(NavigationComponent);
