import { INavItem } from "../model/navigation.model";
import ScreenUrls from "../screen-urls";
import { FeatureNames } from "../enums";

export const navItems: INavItem[] = [
    {
        index: 10,
        title: "Dashboard",
        linkTo: `${ScreenUrls.Dashboard.Index()}`,
        iconClass: "icon-activity",
        feature: FeatureNames.Workflow
    },
    {
        index: 20,
        title: "My Work",
        linkTo: `${ScreenUrls.MyWork.Index()}`,
        iconClass: "icon-bookmark",
        feature: FeatureNames.MyWork
    },
    {
        index: 30,
        title: "Workflow",
        linkTo: ScreenUrls.Workflows.Index(),
        iconClass: "icon-airplay",
        feature: FeatureNames.Workflow
    },
    {
        index: 40,
        title: "Purchase Order",
        linkTo: ScreenUrls.PurchaseOrder.Index(),
        iconClass: "icon-file-text",
        feature: FeatureNames.PurchaseOrder,
        subMenu: [
            {
                index: 41,
                title: "Service Items",
                linkTo: ScreenUrls.PurchaseOrder.ServiceItem.Index(),
                feature: FeatureNames.PurchaseOrderServiceItem
            }
        ]
    },
    {
        index: 50,
        title: "Report",
        linkTo: ScreenUrls.Report.Index(),
        iconClass: "icon-trending-up",
        feature: FeatureNames.Report
    },
    {
        index: 60,
        title: "Data Logger",
        linkTo: ScreenUrls.DataLoggers.Index(),
        iconClass: "icon-layers",
        feature: FeatureNames.DataLogger,
        subMenu: [
            {
                index: 61,
                title: "Make",
                linkTo: ScreenUrls.DataLoggers.Make.Index(),
                feature: FeatureNames.DataLoggerMake
            },
            {
                index: 62,
                title: "Category",
                linkTo: ScreenUrls.DataLoggers.Category.Index(),
                feature: FeatureNames.DataLoggerCategory
            },
            {
                index: 63,
                title: "Camera",
                linkTo: ScreenUrls.DataLoggers.CameraDetail.Index(),
                feature: FeatureNames.DataLoggerCamera
            }
        ]
    },
    {
        index: 70,
        title: "Client",
        linkTo: ScreenUrls.Clients.Index(),
        iconClass: "icon-users",
        feature: FeatureNames.Client,
        subMenu: [
            {
                index: 71,
                title: "Equipment",
                linkTo: ScreenUrls.Clients.Equipment.Index(),
                feature: FeatureNames.ClientEquipment
            },
        ]
    },
    {
        index: 80,
        title: "User",
        linkTo: ScreenUrls.Users.Index(),
        iconClass: "icon-user-check",
        feature: FeatureNames.User,
        subMenu: [
            {
                index: 81,
                title: "Access Role",
                linkTo: ScreenUrls.Users.AccessRole.Index(),
                feature: FeatureNames.AccessRole
            }
        ]
    },
]
