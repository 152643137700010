import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import AccessRoleService from "../service/access-role.service";
import { IAccessRoleModel } from "../model/access-role.model";
import { accessRoleDefault } from "../model/defaults/access-role.default";
import { IStoreSaveModel } from "../../../common/model/store-save.model";
import ScreenUrls,{redirectHandler} from "../../../common/screen-urls";

export interface IAccessRoleState {
    accessRoleList: IAccessRoleModel[],
    accessRoleData: IAccessRoleModel,
    dataLoading: boolean,
    listLoading: boolean,
    createEditLoading: boolean
}

const initialState: IAccessRoleState = {
    accessRoleList: [],
    accessRoleData: accessRoleDefault,
    dataLoading: false,
    listLoading: false,
    createEditLoading: false
}

export const loadAccessRoles = createAsyncThunk("access-role/loadAll", AccessRoleService.LoadAll);
export const loadAccessRoleById = createAsyncThunk("access-role/loadById", AccessRoleService.GetById);
export const createAccessRole = createAsyncThunk("access-role/create", AccessRoleService.Create);
export const editAccessRole = createAsyncThunk("access-role/edit", AccessRoleService.Edit);

const accessRoleSlice = createSlice({
    name: 'access-role',
    initialState,
    reducers: {
        clearAccessRoleDataState: (state) => { state.accessRoleData = accessRoleDefault },
        clearAccessRoleListState: (state) => { state.accessRoleList = [] },
        updateAccessRoleDataState: (state, action: PayloadAction<IStoreSaveModel>) => {
            state.accessRoleData[action.payload.name] = action.payload.value;
        }
    },
    extraReducers: (builder) => {

        // Load All
        builder.addCase(loadAccessRoles.pending, (state, action) => {
            clearAccessRoleListState();
            state.listLoading = true;
        });
        builder.addCase(loadAccessRoles.rejected, (state, action) => {
            clearAccessRoleListState();
            state.listLoading = false;
        });
        builder.addCase(loadAccessRoles.fulfilled, (state, action) => {
            state.accessRoleList = action.payload;
            state.listLoading = false;
        });

        // Load By ID
        builder.addCase(loadAccessRoleById.pending, (state, action) => {
            clearAccessRoleDataState();
            state.dataLoading = true;
        });
        builder.addCase(loadAccessRoleById.rejected, (state, action) => {
            clearAccessRoleDataState();
            state.dataLoading = false;
        });
        builder.addCase(loadAccessRoleById.fulfilled, (state, action) => {
            state.accessRoleData = action.payload;
            state.dataLoading = false;
        });

        // Create
        builder.addCase(createAccessRole.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(createAccessRole.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(createAccessRole.fulfilled, (state, action) => {
            state.accessRoleData = action.payload;
            state.createEditLoading = false;
            redirectHandler(ScreenUrls.Users.AccessRole.Edit(action.payload.id));
        });

        // Edit
        builder.addCase(editAccessRole.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(editAccessRole.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(editAccessRole.fulfilled, (state, action) => {
            state.accessRoleData = action.payload;
            state.createEditLoading = false;
        });
    }
});

const { actions, reducer } = accessRoleSlice;

export const { clearAccessRoleDataState, clearAccessRoleListState, updateAccessRoleDataState } = actions;

export default reducer;
