import ApiUrls from "../api-urls";
import HttpService from "./http.service";
import { ICommentModel, IGetAllCommentRequestModel, IGetAllCommentResponseModel } from "../model/comment.model";

export default class CommentService {
    static async LoadAll(request?: IGetAllCommentRequestModel): Promise<IGetAllCommentResponseModel> {
        return await HttpService.Get<IGetAllCommentResponseModel>(ApiUrls.Comment.GetAll(request));
    }

    static async Create(data: ICommentModel): Promise<ICommentModel> {
        return await HttpService.Post<ICommentModel, ICommentModel>(ApiUrls.Comment.Create(), data);
    }

    static async Edit(data: ICommentModel): Promise<ICommentModel> {
        return await HttpService.Patch<ICommentModel, ICommentModel>(ApiUrls.Comment.Edit(data.id ?? 0), data);
    }

    static async Delete(id: number): Promise<any> {
        return await HttpService.Delete(ApiUrls.Comment.Delete(id));
    }
}
