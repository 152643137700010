import {Route, Routes, useLocation} from "react-router-dom";
import AccountLayout from "../../common/screen/layout/account.layout";
import ScreenUrls from "../../common/screen-urls";
import AccessDeniedPage from "../../common/screen/anonymous/access-denied.page";
import RouteHelper from "../../common/helper/route-helper";
import {AccessLevel, FeatureNames} from "../../common/enums";
import MyWorkPage from "./screen/my-work.page";

const MyWorkRoutes = () => {
    const accessRole = RouteHelper.GetFeatureAccessLevel(FeatureNames.MyWork);
    const location = useLocation();
    return <Routes>
        <Route element={<AccountLayout />}>

            <Route path={ScreenUrls.MyWork.Index()}>
                <Route index element={accessRole.accessLevel >= AccessLevel.View
                    ? <MyWorkPage accessLevel={accessRole.accessLevel} />
                    : <AccessDeniedPage />} />
            </Route>
        </Route>
    </Routes>
}

export default MyWorkRoutes;
