    import {BaseComponent} from "./base.component";
    import PreLoaderComponent from "./pre-loader.component";
    import React from "react";

    export interface IProps {}
    class LoadingComponent extends BaseComponent<IProps, any> {
        render() {
            return <React.Fragment>
                        <PreLoaderComponent />
                    </React.Fragment>;
        }
    };

    export default LoadingComponent;
