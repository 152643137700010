import { Route, Routes } from "react-router-dom";
import AccountLayout from "../../common/screen/layout/account.layout";
import ScreenUrls from "../../common/screen-urls";
import DashboardPage from "./screen/dashboard.page";
import { FeatureNames } from "../../common/enums";
import RouteHelper from "../../common/helper/route-helper";
import Notifications from "../notifications/screen/notifications.page";

const DashboardRoutes = () => {
    const dashboardAccess = RouteHelper.GetFeatureAccessLevel(FeatureNames.Dashboard);
    return <Routes>
        <Route element={<AccountLayout />}>
            <Route path="/">
                <Route index element={<DashboardPage accessLevel={dashboardAccess.accessLevel} />} />
            </Route>
            <Route path={ScreenUrls.Dashboard.Index()}>
                <Route index element={<DashboardPage accessLevel={dashboardAccess.accessLevel} />} />
            </Route>
            <Route path={ScreenUrls.Dashboard.Notifications()} element={<Notifications />} />
        </Route>
    </Routes>
}

export default DashboardRoutes;
