import React from "react";
import { BaseComponent } from "../../../common/component/base.component";
import { IWorkflowModel } from "../model/workflow.model";
import { AgGridReact } from "ag-grid-react";
import { Link, Navigate } from "react-router-dom";
import ScreenUrls from "../../../common/screen-urls";
import StageFlowStepperComponent from "../component/stage-flow-stepper.component";
import MultiFileControl from "../../../common/control/file/multi-file.control";
import { fullDisplayDateInMilitaryTime } from "../../../common/utils";
import { loadWorkflowById, watchWorkflow, clearWorkflowDataState } from "../store/workflow.slice";
import { IAppState } from "../../../store";
import { connect } from "react-redux";
import PurchaseOrderDetailsAccordionComponent from "../component/purchase-order-details-accordion.component";
import { AccessLevel, EntityType, PoType, WorkflowTaskPriority } from "../../../common/enums";
import { IUserModel } from "../../user/model/user.model";
import { AgGridDefaultColDef } from "../../../common/app-defaults";
import DropdownDisplayHelper from "../../../common/helper/dropdown-display.helper";
import CommentComponent from "../../../common/component/comments.component";

export interface IProps {
    accessLevel: number;
    id?: string;
    workflowData: IWorkflowModel;
    dataLoading: false;
    loadWorkflowById: (id: number) => void;
    currentUser: IUserModel;
    watchWorkflow: (query: { id: number, watching: boolean }) => void,
    clearWorkflowDataState: () => void,
}

class WorkflowViewPage extends BaseComponent<IProps, { redirectUrl?: string, hideForm: boolean }> {
    constructor(props) {
        super(props);
        this.state = {
            hideForm: false,
        };
    }
    componentDidMount() {
        if (this.props.id != null && this.props.id != "" && Number(this.props.id) > 0) {
            this.props.loadWorkflowById(Number(this.props.id));
        }
    }

    componentWillUnmount(): void {
        this.props.clearWorkflowDataState();
    }

    render() {
        if (this.props.accessLevel < Number(AccessLevel.View) && !this.props.dataLoading && this.props.workflowData.assigneeId) {
            if (this.props.workflowData.assigneeId === this.props.currentUser.id) {
                // continue
            } else {
                return <Navigate to={ScreenUrls.AccessDenied()} replace />
            }
        }
        
        return <React.Fragment>
            {(() => { if (this.props.dataLoading) return <div className="loading--bar fixed--top"><span></span></div> })()}
            <div className="pcoded-content">
                <div className="pcoded-inner-content">
                    <div className="main-body">
                        <div className="page-wrapper">
                            <div className="row">
                                <div className="col-sm-12">

                                    {(() => {
                                        if (this.props.workflowData == null || this.props.workflowData.purchaseOrderId == 0) return;
                                        return <PurchaseOrderDetailsAccordionComponent id={this.props.workflowData.purchaseOrderId} />
                                    })()}

                                    {(() => {
                                        if (this.props.workflowData.stageFlow == null || this.props.workflowData.stageFlow.length == 0) return;
                                        return <StageFlowStepperComponent stageFlow={this.props.workflowData.stageFlow}
                                            isCompleted={this.props.workflowData.isComplete} />
                                    })()}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">

                                    {/* Basic Info */}
                                    {(() => {
                                        if (this.props.dataLoading || this.props.workflowData == null) return;
                                        return <article className="card no-bg mb-4">
                                            <div className="card-header card-form-header">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group read-only">
                                                            <label>Assignee</label>
                                                            <p className="mb-0">{this.props.workflowData.assignee == null
                                                                ? "Unassigned"
                                                                : `${this.props.workflowData.assignee?.firstName} ${this.props.workflowData.assignee?.lastName}`}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 d-flex align-items-center justify-content-end">

                                                        <button className="border-0 mr-3 bg-transparent d-flex align-items-center"
                                                            onClick={async () => {
                                                                await this.props.watchWorkflow({ id: this.props.workflowData.id, watching: !this.props.workflowData?.isWatching });
                                                                this.setState({ ...this.state, hideForm: true });
                                                                setTimeout(() => {
                                                                    this.setState({ ...this.state, hideForm: false });
                                                                }, 1)
                                                            }}
                                                            disabled={this.props.workflowData?.isComplete}
                                                        >
                                                            <i
                                                                className={`feather mr-2 h5 mb-0 icon-eye${this.props.workflowData.isWatching ? "-off" : ""
                                                                    }`}
                                                            ></i>
                                                            {this.props.workflowData.isWatching ? "Un-watch" : "Watch"}
                                                        </button>

                                                        <Link to={ScreenUrls.Workflows.Edit(this.props.workflowData.id)}
                                                            className="btn btn-primary m-0 btn--row-right">
                                                            Edit Workflow
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    })()}

                                    {/* Work allocation */}
                                    {
                                        (() => {
                                            if (!this.props.workflowData?.purchaseOrderItems) return;
                                            return (
                                                <article className="card mb-4">
                                                    <div className="card-header card-form-header">
                                                        <div className="card-form-header-title">Work Allocation</div>
                                                    </div>
                                                    <div className="card-block ag-theme-alpine medium-grid">
                                                        {(() => {
                                                            return <AgGridReact
                                                                unSortIcon={true}
                                                                rowHeight={60}
                                                                defaultColDef={AgGridDefaultColDef}
                                                                columnDefs={[
                                                                    {
                                                                        suppressMovable: true,
                                                                        headerName: "Code", field: "service",
                                                                        flex: 1, sortable: true, cellClass: "grid-cell",
                                                                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                                            if (valueA?.code == valueB?.code) return 0;
                                                                            return (valueA?.code > valueB?.code) ? 1 : -1;
                                                                        },
                                                                        cellRenderer: (params) => <span>{params.value?.code}</span>
                                                                    },
                                                                    {
                                                                        suppressMovable: true,
                                                                        headerName: "Service", field: "service",
                                                                        flex: 3, sortable: true, cellClass: "grid-cell",
                                                                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                                            if (valueA?.title == valueB?.title) return 0;
                                                                            return (valueA?.title > valueB?.title) ? 1 : -1;
                                                                        },
                                                                        cellRenderer: (params) => <span>{params.value?.title}</span>
                                                                    },
                                                                    {
                                                                        suppressMovable: true,
                                                                        headerName: "HSN/SAC", field: "service",
                                                                        flex: 1.5, sortable: true, cellClass: "grid-cell",
                                                                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                                            if (valueA?.hsnSacCode == valueB?.hsnSacCode) return 0;
                                                                            return (valueA?.hsnSacCode > valueB?.hsnSacCode) ? 1 : -1;
                                                                        },
                                                                        cellRenderer: (params) => <span>{params.value?.hsnSacCode}</span>
                                                                    },
                                                                    {
                                                                        suppressMovable: true,
                                                                        headerName: "Type", field: "type",
                                                                        flex: 1.5, sortable: true, cellClass: "grid-cell",
                                                                        cellRenderer: (params) => <span>{PoType[Number(params.value)]}</span>
                                                                    },
                                                                    {
                                                                        suppressMovable: true,
                                                                        tooltipField: "description",
                                                                        headerName: "Description", field: "description",
                                                                        flex: 3, sortable: true, cellClass: "grid-cell",
                                                                        cellRenderer: (params) => <span>{params.value}</span>
                                                                    },
                                                                    {
                                                                        suppressMovable: true,
                                                                        headerName: "Total Qty", field: "quantity",
                                                                        flex: 1, sortable: true, cellClass: "grid-cell",
                                                                        cellRenderer: (params) => <span>{params.value}</span>
                                                                    },

                                                                    {
                                                                        suppressMovable: true,
                                                                        headerName: "Rate", field: "unitRate",
                                                                        flex: 1.5, sortable: true, cellClass: "grid-cell",
                                                                        cellRenderer: (params) => <span>{params.value}</span>
                                                                    },
                                                                    {
                                                                        suppressMovable: true,
                                                                        headerName: "Measurement", field: "unitMeasurement",
                                                                        flex: 2, sortable: true, cellClass: "grid-cell",
                                                                        cellRenderer: (params) => <span>{params.value}</span>
                                                                    },
                                                                    {
                                                                        suppressMovable: true,
                                                                        headerName: "Assignee", field: "totalAmount",
                                                                        flex: 3, sortable: true, cellClass: "grid-cell",
                                                                        cellRenderer: (params) => <span>
                                                                            {params.data?.
                                                                                purchaseOrderItemWorkAllocation?.user ?
                                                                                params.data?.
                                                                                    purchaseOrderItemWorkAllocation?.user?.firstName +
                                                                                '\t'
                                                                                +
                                                                                params.data?.
                                                                                    purchaseOrderItemWorkAllocation?.user?.lastName
                                                                                : "Not Assigned"
                                                                            }</span>
                                                                    }
                                                                ]}
                                                                pagination={true}
                                                                paginationAutoPageSize={true}
                                                                rowData={this.props.workflowData?.purchaseOrderItems}>
                                                            </AgGridReact>
                                                        })()}
                                                    </div>
                                                </article>
                                            )
                                        })()
                                    }

                                    {/* Tasks */}
                                    {(() => {
                                        if (this.props.workflowData.tasks == null) return;
                                        return <article className="card mb-4">
                                            <div className="card-header card-form-header">
                                                <div className="card-form-header-title">Tasks</div>
                                            </div>
                                            <div className="card-block ag-theme-alpine" style={{ height: "350px" }}>
                                                <AgGridReact
                                                    unSortIcon={true}
                                                    rowHeight={60}
                                                    pagination={true}
                                                    paginationAutoPageSize={true}
                                                    rowData={this.props.workflowData.tasks}
                                                    defaultColDef={AgGridDefaultColDef}
                                                    columnDefs={[
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "#", field: "id", width: 100, sortable: true, cellClass: "grid-cell",
                                                            cellRenderer: (params) => <span>{params.value}</span>
                                                        },
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "Report No", field: "reportNumber", flex: 1, sortable: true, cellClass: "grid-cell",
                                                            cellRenderer: (params) => <span>{params?.value ?? ""}</span>
                                                        },
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "Assignee", field: "assignee", flex: 1, sortable: true, cellClass: "grid-cell",
                                                            cellRenderer: (params) => <span>{DropdownDisplayHelper.User(params.value)}</span>
                                                        },
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "Category", field: "category", flex: 1, sortable: true, cellClass: "grid-cell",
                                                            comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                                if (valueA?.name == valueB?.name) return 0;
                                                                return (valueA?.name > valueB?.name) ? 1 : -1;
                                                            },
                                                            cellRenderer: (params) => <span>{params.value?.name}</span>
                                                        },
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "Type", field: "purchaseOrderItem", flex: 1, sortable: true, cellClass: "grid-cell",
                                                            comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                                if (valueA?.type == valueB?.type) return 0;
                                                                return (valueA?.type > valueB?.type) ? 1 : -1;
                                                            },
                                                            cellRenderer: (params) => <span>{PoType[params.value?.type]}</span>
                                                        },
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "Description",
                                                            tooltipField: "description", field: "description", flex: 3, sortable: true, cellClass: "grid-cell",
                                                            cellRenderer: (params) => <span>{params.value}</span>
                                                        },
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "Stage", field: "currentStage", flex: 1, sortable: true, cellClass: "grid-cell",
                                                            comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                                if (valueA?.name == valueB?.name) return 0;
                                                                return (valueA?.name > valueB?.name) ? 1 : -1;
                                                            },
                                                            cellRenderer: (params) => <span>{params.value?.name}</span>
                                                        },
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "Quantity", field: "quantity", flex: 1, sortable: true, cellClass: "grid-cell",
                                                            cellRenderer: (params) => <span>{params.value}</span>
                                                        },
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "Priority", field: "priority", flex: 1, sortable: true, cellClass: "grid-cell",
                                                            cellRenderer: (params) => <span>{WorkflowTaskPriority[params.value]}</span>
                                                        },
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "Action", field: "id", width: 150, cellClass: "grid-cell grid-cell-button",
                                                            cellRenderer: (params) => <Link className="btn-primary" to={ScreenUrls.Workflows.Task.View(params.value, this.props.id)}>
                                                                View Task
                                                            </Link>
                                                        },
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "Status", field: "", width: 100, cellClass: "grid-cell grid-cell-button",
                                                            cellRenderer: (params) => {
                                                                return <span className={`pill ${params.data?.isComplete ? "inactive-pill" : "active-pill"}`}>{params.data?.isComplete ? "Closed" : "Open"}</span>
                                                            }
                                                        }
                                                    ]}>
                                                </AgGridReact>
                                            </div>
                                        </article>
                                    })()}

                                    {
                                        (() => {
                                            if (this.props.workflowData.id < 1) return;
                                            return (
                                                <CommentComponent hideActions={true} entityType={EntityType.Workflow} entityId={this.props.workflowData?.id} />
                                            )
                                        })()
                                    }

                                    {/* Files */}
                                    {(() => {
                                        if (this.props.workflowData.id != null && this.props.workflowData.id > 0) {
                                            return <MultiFileControl title="Files"
                                                entityType={EntityType.Workflow}
                                                entityId={this.props.workflowData.id} editable={false} />
                                        }
                                    })()}


                                    {/* Stage Change History */}
                                    {(() => {
                                        if (this.props.workflowData.stageChangeHistory == null) return;
                                        return <article className="card mb-4">
                                            <div className="card-header card-form-header">
                                                <div className="card-form-header-title">Audit Trail</div>
                                            </div>
                                            <div className="card-block ag-theme-alpine mini-grid">
                                                <AgGridReact
                                                    unSortIcon={true}
                                                    rowHeight={60}
                                                    pagination={true}
                                                    paginationAutoPageSize={true}
                                                    rowData={this.props.workflowData.stageChangeHistory}
                                                    defaultColDef={AgGridDefaultColDef}
                                                    columnDefs={[
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "Created On", field: "createdOn", flex: 1, sortable: true, cellClass: "grid-cell",
                                                            cellRenderer: (params) => <span>{fullDisplayDateInMilitaryTime(params.value)}</span>
                                                        },
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "Stage Change", field: "stageChangedFrom", flex: 3, sortable: true, cellClass: "grid-cell",
                                                            cellRenderer: (params) => <React.Fragment>
                                                                <span className="grid-cell-stage-from">{params.value?.name}</span>
                                                                <i className="feather icon-arrow-right grid-cell-stage-icon"></i>
                                                                <span className="grid-cell-stage-to">{params.data.stageChangedTo?.name}</span>
                                                            </React.Fragment>
                                                        },
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "Description", field: "description", flex: 4, sortable: true, cellClass: "grid-cell",
                                                            cellRenderer: (params) => <span>{params.value}</span>
                                                        },
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "Created By", field: "createdBy", flex: 1.5, sortable: true, cellClass: "grid-cell",
                                                            cellRenderer: (params) => <span>
                                                                {params.value == null ? "Unassigned" : `${params.value?.firstName} ${params.value?.lastName}`}
                                                            </span>
                                                        }
                                                    ]}>
                                                </AgGridReact>
                                            </div>
                                        </article>
                                    })()}


                                    {/* Workflow Change History */}
                                    {(() => {
                                        if (this.props.workflowData.historyLog == null) return;
                                        return <article className="card mb-4">
                                            <div className="card-header card-form-header">
                                                <div className="card-form-header-title">Overall Change History</div>
                                            </div>
                                            <div className="card-block ag-theme-alpine" style={{ height: "350px" }}>
                                                <AgGridReact
                                                    unSortIcon={true}
                                                    rowHeight={60}
                                                    pagination={true}
                                                    paginationAutoPageSize={true}
                                                    rowData={this.props.workflowData.historyLog}
                                                    defaultColDef={AgGridDefaultColDef}
                                                    columnDefs={[
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "DateTime", field: "datetime", flex: 1, sortable: true, cellClass: "grid-cell",
                                                            cellRenderer: (params) => <span>{params.value}</span>
                                                        },
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "Description", field: "description", flex: 1.5, sortable: true, cellClass: "grid-cell",
                                                            cellRenderer: (params) => <span>{params.value}</span>
                                                        },
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "User", field: "user", flex: 2, sortable: true, cellClass: "grid-cell",
                                                            cellRenderer: (params) => <span>
                                                                {params.value == null ? "Unassigned" : `${params.value?.firstName} ${params.value?.lastName}`}
                                                            </span>
                                                        }
                                                    ]}>
                                                </AgGridReact>
                                            </div>
                                        </article>
                                    })()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>

    }
}

const mapStateToProps = (state: IAppState) => ({
    workflowData: state.workflow.workflowData,
    dataLoading: state.workflow.dataLoading,
    currentUser: state.userSession.currentUser
})
export default connect(mapStateToProps, { loadWorkflowById, watchWorkflow, clearWorkflowDataState })(WorkflowViewPage);
