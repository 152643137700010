import { BaseComponent } from "./base.component";
import { IUserModel } from "../../features/user/model/user.model";
import { IAppState } from "../../store";
import { connect } from "react-redux";
import { logoutUser } from "../../features/user/store/user-session.slice";
import { Link } from "react-router-dom";
import ScreenUrls from "../screen-urls";
import IncepOne from "../../assets/images/incepone.png";
import RecentNotification from "../../features/notifications/components/recent-notifications.component";

export interface IProps {
  currentUser: IUserModel;
  logoutUser: () => void;
  currentUserLoading: boolean;
}

class HeaderComponent extends BaseComponent<IProps, any> {
  render() {
    return (
      <header className="navbar pcoded-header navbar-expand-lg navbar-light">
        <div className="m-header">
          <a
            className="mobile-menu"
            id="mobile-collapse1"
            href="src/views/compoments/layout#!"
          >
            <span></span>
          </a>
          <a href="/" className="b-brand">
            <div className="b-bg" style={{ background: "none" }}>
              <i className="feather icon-server"></i>
            </div>
            <span className="b-title">IncepOne</span>
          </a>
        </div>

        <a
          className="mobile-menu"
          id="mobile-header"
          href="src/views/compoments/layout#"
          onClick={(e) => e.preventDefault()}
        >
          <i className="feather icon-more-horizontal"></i>
        </a>
        <div className="collapse navbar-collapse">
          <div className="header-logo-container">
            <img src={IncepOne} alt="IncepOne" />
          </div>
          {(() => {
            if (this.props.currentUserLoading || !this.props.currentUser.email)
              return;
            return (
              <ul className="navbar-nav ml-auto">
                <label className="mb-0">
                  Hello{" "}
                  {`${this.props.currentUser.firstName} ${this.props.currentUser.lastName}`}
                </label>
                <li>
                  <div className="dropdown drp-user">
                    <a
                      href="src/views/compoments/layout#"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      <i className="icon feather icon-settings"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right profile-notification">
                      <div className="pro-head profile--head">
                        <div className="profile--icon">
                          <i className="icon feather icon-user-check"></i>
                        </div>
                        <div className="profile--details">
                          <p className="profile--email">
                            {this.props.currentUser.email}
                          </p>
                        </div>
                      </div>
                      <ul className="pro-body profile--body">
                        <li>
                          <Link
                            type="button"
                            className="dropdown-item"
                            to={ScreenUrls.Users.PasswordChange(
                              this.props.currentUser.id
                            )}
                          >
                            <i className="feather icon-briefcase"></i> Change
                            Password
                          </Link>
                        </li>
                        <li>
                          <Link
                            type="button"
                            className="dropdown-item"
                            to={ScreenUrls.Logout()}
                          >
                            <i className="feather icon-lock"></i> Logout
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                {
                  this.props.currentUser?.id > 0 &&
                  <li>
                    <RecentNotification />
                  </li>
                }

              </ul>
            );
          })()}
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  currentUser: state.userSession.currentUser,
  currentUserLoading: state.userSession.currentUserLoading,
});

export default connect(mapStateToProps, { logoutUser })(HeaderComponent);
