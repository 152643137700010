import { IFieldValidationModel, IValidatorModel } from "../../../../common/model/validator.model";
import { ValidationScreens, ValidationType } from "../../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "assigneeId",
        rules: [
            {
                type: ValidationType.Required,
                message: "Assignee is required"
            }
        ]
    },
    {
        fieldName: "invoiceGenerationDate",
        dependencyFieldName: "reportDeliveryEndDate",
        rules: [
            {
                type: ValidationType.Required,
                message: "Invoice generation date is required"
            },
            {
                type: ValidationType.DateShouldBeGreaterDependentDate,
                message: "Invoice generation date should be greater than report delivery end date"
            },
            {
                type: ValidationType.DateShouldBeLessThenCurrentDate,
                message: "Invoice generation date should be less than current date"
            }
        ]
    },
    {
        fieldName: "hasServiceReportGenerated",
        rules: [
            {
                type: ValidationType.RequiredIfTrue,
                message: "Please complete service report generation"
            }
        ]
    },
    {
        fieldName: "hasClientAcknowledgeFinalReport",
        rules: [
            {
                type: ValidationType.RequiredIfTrue,
                message: "Please complete client acknowledgement"
            }
        ]
    },
    {
        fieldName: "hasInvoicePrepared",
        rules: [
            {
                type: ValidationType.RequiredIfTrue,
                message: "Please complete invoice preparation"
            }
        ]
    },
    {
        fieldName: "hasInvoiceReviewed",
        rules: [
            {
                type: ValidationType.RequiredIfTrue,
                message: "Please complete invoice review"
            }
        ]
    },
    {
        fieldName: "hasInvoiceBookedByClient",
        rules: [
            {
                type: ValidationType.RequiredIfTrue,
                message: "Please complete invoice booking by client"
            }
        ]
    }
]

export const workflowTaskInvoicingStageValidator: IValidatorModel = {
    screen: ValidationScreens.WorkflowTaskInvoicingStage,
    fields: fields
}
