import HttpService from "../../../common/service/http.service";
import ApiUrls from "../../../common/api-urls";
import {IGetAllRequestModel} from "../../../common/model/get-all-request-model";
import {IFormControlDropdownItemModel} from "../../../common/control/model/form.control.field.model";
import DropdownDisplayHelper from "../../../common/helper/dropdown-display.helper";
import {IDataLoggerMakeModel} from "../model/data-logger-make.model";

export default class DataLoggerMakeService {
    static async LoadAll(request?: IGetAllRequestModel): Promise<IDataLoggerMakeModel[]> {
        return await HttpService.Get<IDataLoggerMakeModel[]>(ApiUrls.DataLoggerMake.GetAll(request));
    }

    static async Create(data: IDataLoggerMakeModel): Promise<IDataLoggerMakeModel> {
        return await HttpService.Post<IDataLoggerMakeModel, IDataLoggerMakeModel>(ApiUrls.DataLoggerMake.Create(), data);
    }

    static async Edit(data: IDataLoggerMakeModel): Promise<IDataLoggerMakeModel> {
        return await HttpService.Patch<IDataLoggerMakeModel, IDataLoggerMakeModel>(ApiUrls.DataLoggerMake.Edit(data.id), data);
    }
    static async Delete(id: number) {
        await HttpService.Delete(ApiUrls.DataLoggerMake.Delete(id));
    }

    static async GetById(id: number): Promise<IDataLoggerMakeModel> {
        return await HttpService.Get<IDataLoggerMakeModel>(ApiUrls.DataLoggerMake.GetById(id));
    }

    static async MakeDropdown(search: string): Promise<IFormControlDropdownItemModel[]> {
        let req: IGetAllRequestModel = { searchStr: search };
        return (await DataLoggerMakeService.LoadAll(req)).map(d => {
            const obj: IFormControlDropdownItemModel = {
                label: DropdownDisplayHelper.DataLoggerMake(d),
                value: d.id.toString()
            }
            return obj;
        });
    }
}
