import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import FileService from "../service/file.service";

export interface IFileState {
    //fileList: IFileModel[],
    //fileData: IFileModel,
    //dataLoading: boolean,
    //listLoading: boolean,
    createEditLoading: boolean
}

const initialState: IFileState = {
    //fileList: [],
    //fileData: fileDefault,
    //dataLoading: false,
    //listLoading: false,
    createEditLoading: false
}

//export const loadFiles = createAsyncThunk("common/file/loadAll", FileService.LoadAll);
//export const loadFileById = createAsyncThunk("common/file/loadById", FileService.GetById);
export const createFile = createAsyncThunk("common/file/create", FileService.Create);
//export const editFile = createAsyncThunk("common/file/edit", FileService.Edit);

const fileSlice = createSlice({
    name: 'file',
    initialState,
    reducers: {
        /*clearFileListState: (state) => { state.fileList = [] },
        clearFileDataState: (state) => { state.fileData = fileDefault },
        updateFileDataState: (state, action: PayloadAction<IStoreSaveModel>) => {
            state.fileData[action.payload.name] = action.payload.value;
        }*/
    },
    extraReducers: (builder) => {
        /*// Load All
        builder.addCase(loadFiles.pending, (state, action) => {
            clearFileListState();
            state.listLoading = true; });
        builder.addCase(loadFiles.rejected, (state, action) => {
            clearFileListState();
            state.listLoading = false;
            NotificationHelper.Error(action?.error?.message);
        });
        builder.addCase(loadFiles.fulfilled, (state, action) => {
            state.fileList = action.payload;
            state.listLoading = false;
        });*/

        /*// Load By ID
        builder.addCase(loadFileById.pending, (state, action) => { state.dataLoading = true; });
        builder.addCase(loadFileById.rejected, (state, action) => {
            state.dataLoading = false;
            NotificationHelper.Error(action?.error?.message);
        });
        builder.addCase(loadFileById.fulfilled, (state, action) => {
            state.fileData = action.payload;
            state.dataLoading = false;
        });*/

        // Create
        builder.addCase(createFile.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(createFile.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(createFile.fulfilled, (state, action) => {
            state.createEditLoading = false;
        });

        /*// Edit
        builder.addCase(editFile.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(editFile.rejected, (state, action) => {
            state.createEditLoading = false;
            NotificationHelper.Error(action?.error?.message);
        });
        builder.addCase(editFile.fulfilled, (state, action) => {
            state.fileData = action.payload;
            state.createEditLoading = false;
            NotificationHelper.Updated();
        });*/
    }
});

const { actions, reducer } = fileSlice;

export const { } = actions;

export default reducer;
