import { BaseComponent } from "./base.component";
import ReactPaginate from "react-paginate";

export interface IProps {
    itemsPerPage: number;
    currentPage: number;
    totalRecords: number;
    onPageChangeHandler: (data) => void;
}

class PaginationComponent extends BaseComponent<IProps, any> {
    render() {
        return (
            <div className={``}>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={this.props.onPageChangeHandler}
                    pageRangeDisplayed={5}
                    pageCount={Math.ceil(this.props.totalRecords / this.props.itemsPerPage)}
                    previousLabel="<"
                    forcePage={this.props.currentPage -1}
                    renderOnZeroPageCount={null}
                    activeClassName={"currentPage"}
                    className="d-flex w-100 paginationContainer mt-2 mb-0"
                    pageLinkClassName={"p-3"}
                />
            </div>
        )
    }
}

export default PaginationComponent;