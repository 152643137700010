import React from "react";
import { IFormBaseState, FormBaseComponent, } from "../../../../../../../common/component/form-base.component";
import Modal from 'react-modal';
import { utcToLocalDate, localToUtcDate } from "../../../../../../../common/utils";
import { AppConstants } from "../../../../../../../common/app-constants";
import DatePicker from "react-datepicker";
import { ValidationScreens } from "../../../../../../../common/enums";
import { connect } from "react-redux";
import { IAppState } from "../../../../../../../store";
import { clearObservationDataState, updateObservationDataState, deleteReading, createRecoveryReading, editRecoveryReading } from "../../../../../store/cleanroom-equipment/observation.slice";
import { recoveryReadingDefault, } from "../../../../../model/defaults/test.default";
import ValidationMessageControl from "../../../../../../../common/control/validation-message.control";
import { IRecoveryReadingPayload, IRecoveryReadings, IRecoveryTestModel } from "../../../../../model/test/recovery-test.model";
export interface IProps {
    toggle: boolean,
    handleToggleState: any,
    testData: IRecoveryTestModel,
    readingData: IRecoveryReadings,
    clearObservationDataState: (data: IRecoveryReadings) => void,
    updateObservationDataState: (data) => void,
    createObservation: (data: IRecoveryReadingPayload) => void,
    editObservation: (data: IRecoveryReadingPayload) => void,
    testName: string,
    defaultData?: null | IRecoveryReadings,
}

export interface IState extends IFormBaseState {

}

class TestObservationComponent extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            hideForm: false,
        }
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.RecoveryTestObservation);
        this.props.clearObservationDataState(this.props.defaultData ?? recoveryReadingDefault);
    }

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.defaultData && this.props.defaultData != prevProps.defaultData) {
            this.props.clearObservationDataState(this.props.defaultData);
        }
    }

    async componentWillUnmount() {
        await this.setValidator(ValidationScreens.None);
    }

    render() {
        return <React.Fragment>
            <Modal
                ariaHideApp={false}
                isOpen={this.props.toggle}
                onRequestClose={this.props.handleToggleState}
            >
                <div className={`d-flex f-w-500 flex-column`} style={{ maxHeight: "90vh", minHeight: '350px' }}>
                    <div className="f-18">
                        Add Reading
                    </div>
                    <div className="row my-2">
                        <div className="col-6">
                            <div className={`form-group ${this.vResult("recoveryTime").className} suggetion-box d-flex flex-column`}>
                                <label htmlFor="recoveryTime">Recovery Time<span className="requried-span">*</span></label>
                                <DatePicker key=""
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={10}
                                    timeCaption="Time"
                                    dateFormat={AppConstants.TimeFormat}
                                    selected={this.props.readingData?.recoveryTime ? utcToLocalDate(this.props.readingData?.recoveryTime ?? "") : ""}
                                    onChange={(d) => {
                                        this.ValidateField("recoveryTime", localToUtcDate(d));
                                        this.props.updateObservationDataState({ name: "recoveryTime", value: localToUtcDate(d) });
                                        // this.props.updateHasError(this.hasError);
                                    }} />
                                <ValidationMessageControl message={this.vResult("recoveryTime").message} />
                            </div>
                        </div>
                        {
                            this.props.testData?.consideredParticleSizes?.includes("0.5um") ?
                                <div className="col-6">
                                    <div className={`form-group ${this.vResult("pointFiveOrMoreMicron").className}`}>
                                        <label htmlFor="pointFiveOrMoreMicron">No. of particles / m3 ( ≥ 0.5µm)</label>
                                        <input id="pointFiveOrMoreMicron" className="form-control" type="number"
                                            value={this.props.readingData?.recoveryReadingAtPointFiveParticleSize ?? ""}
                                            onChange={e => {
                                                e.preventDefault();
                                                this.ValidateField("recoveryReadingAtPointFiveParticleSize", +e.target.value);
                                                this.props.updateObservationDataState({ name: "recoveryReadingAtPointFiveParticleSize", value: e.target.value })
                                                // this.props.updateHasError(this.hasError);
                                            }}
                                        />
                                        <ValidationMessageControl message={this.vResult("pointFiveOrMoreMicron").message} />
                                    </div>
                                </div>
                                : null
                        }

                        {
                            this.props.testData?.consideredParticleSizes?.includes("1um") ?
                                <div className="col-6">
                                    <div className={`form-group ${this.vResult("oneOrMoreMicron").className}`}>
                                        <label htmlFor="oneOrMoreMicron">No. of particles / m3 ( ≥ 1µm)</label>
                                        <input id="oneOrMoreMicron" className="form-control" type="number"
                                            value={this.props.readingData?.recoveryReadingAtOneParticleSize ?? ""}
                                            onChange={e => {
                                                e.preventDefault();
                                                this.ValidateField("recoveryReadingAtOneParticleSize", +e.target.value);
                                                this.props.updateObservationDataState({ name: "recoveryReadingAtOneParticleSize", value: e.target.value })
                                                // this.props.updateHasError(this.hasError);
                                            }}
                                        />
                                        <ValidationMessageControl message={this.vResult("oneOrMoreMicron").message} />
                                    </div>
                                </div>
                                : null
                        }

                        {
                            this.props.testData?.consideredParticleSizes?.split(",")?.includes("5um") ?
                                <div className="col-6">
                                    <div className={`form-group ${this.vResult("fiveOrMoreMicron").className}`}>
                                        <label htmlFor="fiveOrMoreMicron">No. of particles / m3 ( ≥ 5µm)</label>
                                        <input id="fiveOrMoreMicron" className="form-control" type="number"
                                            value={this.props.readingData?.recoveryReadingAtFiveParticleSize ?? ""}
                                            onChange={e => {
                                                e.preventDefault();
                                                this.ValidateField("recoveryReadingAtFiveParticleSize", +e.target.value);
                                                this.props.updateObservationDataState({ name: "recoveryReadingAtFiveParticleSize", value: e.target.value })
                                                // this.props.updateHasError(this.hasError);
                                            }}
                                        />
                                        <ValidationMessageControl message={this.vResult("fiveOrMoreMicron").message} />
                                    </div>
                                </div>
                                : null
                        }

                    </div>
                    <div className="row">
                        <div className="col-12 text-right">
                            <button
                                onClick={(e: any) => {
                                    const error = !this.ValidateForm(this.props.readingData);
                                    this.reloadForm();
                                    if (error) return;
                                    if (this.props.readingData?.id) {
                                        this.props.editObservation({ testName: this.props.testName, data: this.props.readingData })
                                    } else {
                                        let payload = { ...this.props.readingData, recoveryTestId: this.props.testData?.id ?? 0 };
                                        this.props.createObservation({ testName: this.props.testName, data: payload })
                                    }
                                    this.props.handleToggleState();
                                }}
                                className="text-capitalize f-w-800 border-0 btn-primary btn-sm align-self-end">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </React.Fragment >
    }
}


const mapStateToProps = (state: IAppState) => ({
    readingData: state.testObservation.observationData,
    dataLoading: state.reportTest.dataLoading,
    createEditLoading: state.reportTest.createEditLoading,
    deleteRoomId: state.testObservation.deletedRoomId,
});
export default connect(mapStateToProps, {
    clearObservationDataState, updateObservationDataState, createObservation: createRecoveryReading, editObservation: editRecoveryReading
})(TestObservationComponent);

