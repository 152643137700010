import { BaseComponent } from "../../../common/component/base.component";
import { IAppState } from "../../../store";
import { connect } from "react-redux";
import { loadDataLoggerMakers } from "../store/data-logger-make.slice";
import { AgGridReact } from "ag-grid-react";
import ScreenUrls from "../../../common/screen-urls";
import { Link } from "react-router-dom";
import { AccessLevel } from "../../../common/enums";
import { AgGridDefaultColDef } from "../../../common/app-defaults";
import GridDeleteButtonControl from "../../../common/control/component/grid/grid-delete-buton.control";
import DataLoggerCameraDetailService from "../service/data-logger-camera-detail.service";
import { IDataLoggerCameraDetailModel } from "../model/data-logger-camera-detail.model";
import { loadDataLoggerCameraDetails } from "../store/data-logger-camera-detail.slice";

export interface IProps {
    accessLevel: number,
    dataLoggerCameraDetailList: IDataLoggerCameraDetailModel[],
    listLoading: boolean,
    loadDataLoggerCameraDetails: () => void
}

class CameraDetailPage extends BaseComponent<IProps, any> {
    componentDidMount() {
        this.props.loadDataLoggerCameraDetails();
    }

    render() {
        return <div className="pcoded-content">
            <div className="pcoded-inner-content">
                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    {(() => { if (this.props.listLoading) return <div className="loading--bar"><span></span></div> })()}
                                    <div className="card-header">
                                        <h5>List of camera</h5>
                                        <div className="card-header-right">
                                            <div className="btn-group card-option">
                                                {(() => {
                                                    if (this.props.accessLevel >= AccessLevel.Create) {
                                                        return <Link className="btn drp-icon btn-rounded btn-primary" to={ScreenUrls.DataLoggers.CameraDetail.Create()}>
                                                            Add New
                                                        </Link>
                                                    }
                                                })()}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-block ag-theme-alpine xx-large-grid">
                                        <AgGridReact
                                            pagination={true}
                                            paginationAutoPageSize={true}
                                            unSortIcon={true}
                                            rowHeight={60}
                                            defaultColDef={AgGridDefaultColDef}
                                            columnDefs={[
                                                {
                                                    suppressMovable: true,
                                                    hide: this.props.accessLevel < AccessLevel.Edit,
                                                    headerName: "Edit", field: 'id', width: 70, cellClass: "grid-cell grid-cell-link",
                                                    cellRenderer: (params) => {
                                                        return (
                                                            <Link to={ScreenUrls.DataLoggers.CameraDetail.Edit(params.value)}>
                                                                <i className={`feather icon-edit`}></i>
                                                            </Link>
                                                        )
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    hide: this.props.accessLevel < AccessLevel.Delete,
                                                    headerName: "Delete", field: 'id', width: 70, cellClass: "grid-cell grid-cell-link delete",
                                                    cellRenderer: (params) => {
                                                        return (
                                                            <GridDeleteButtonControl onDelete={async () => {
                                                                await DataLoggerCameraDetailService.Delete(params.value);
                                                                this.props.loadDataLoggerCameraDetails();
                                                            }}></GridDeleteButtonControl>
                                                        )
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "#", field: 'id',width:70, cellClass: "grid-cell",
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value}</span>
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Camera Name", field: "cameraName", flex:1, sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value}</span>
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Camera Id", flex: 1, field: "cameraId", sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value}</span>
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Model Number", flex: 1, field: "modelNumber", sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value}</span>
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Serial Number", flex: 1, field: "serialNumber", sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value}</span>
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Make", flex: 1, field: "make", sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value}</span>
                                                    }
                                                },

                                            ]}
                                            rowData={this.props.dataLoggerCameraDetailList}>
                                        </AgGridReact>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state: IAppState) => ({
    dataLoggerCameraDetailList: state.dataLoggerCameraDetail.dataLoggerCameraDetailList,
    listLoading: state.dataLoggerCameraDetail.listLoading
})

export default connect(mapStateToProps, { loadDataLoggerCameraDetails })(CameraDetailPage);
