import { IAirflowVelocityModel, IAirflowVelocityTestObservationModel, IRoomReadingModel, ITestModel } from "../test/test.model";
import { IFilterIntegrityModel, IFilterIntegrityObservationModel } from "../test/filter-integrity-test.model";
import { ILightIntensityTestModel, ILITestObservationModel } from "../test/light-intensity-test.model";
import { IPCObservation, IPCTestLocationModel, IPCTestModel } from "../test/particle-count-test.model";
import { IRecoveryReadings, IRecoveryTestModel } from "../test/recovery-test.model";
import { ISoundLevelObservationModel, ISoundLevelTestModel } from "../test/sound-level-test.model";
import { IUVLightTestModel, IUVLightTestObservationModel } from "../test/uv-light-test.model";
import { IAirflowPatterTestModel } from "../test/airflow-pattern-test.model";
import { ObservationLocationLabel } from "../../../../common/enums";

export const baseTestDefaults: ITestModel = {
    id: 0,
    dataLoggerId: 0,
    dataLogger: null,
    dateOfTest: "",
    dueDate: "",
    cleanroomReportId: 0,
    preparedById: 0,
    preparedOnDate: "",
    checkedOnDate: "",
    verifiedByName: "",
    verifiedOnDate: "",
    clientId: 0,
    clientAddressId: 0,
    clientEquipmentId: 0,
    clientEquipment: null,
    filterType: "",
    filterEfficiency: "",
    zoneClassificationType: 0,
   remarks:""
}

// --- AV ---
export const airflowVelocityTestDefaults: IAirflowVelocityModel = {
    ...baseTestDefaults,
    observations: [],
}

export const lightIntensityTestDefaults: ILightIntensityTestModel = {
    ...baseTestDefaults,
    observations: [],
    observationLocationLabel: ObservationLocationLabel.LocationNameOrNumber,
}

export const TestObservationDefaults: IAirflowVelocityTestObservationModel = {
    id: 0,
    filterIdOrGrillName: "",
    filterIdOrGrillId: 0,
    observationUnit: 0,
    airFlowVelocityTestId: 0,
    roomReadings: [],
}

export const roomReadingDefault: IRoomReadingModel = {
    reading: "",
    observationId: 0,
}

// --- FI
export const filterIntegrityTestDefault: IFilterIntegrityModel = {
    ...baseTestDefaults,
    inference: "",
    aerosolGeneratorType: 0,
    aerosolMedium: 0,
    observations: [],
}

export const filterIntegrityTestObservationDefault: IFilterIntegrityObservationModel = {
    id: 0,
    filterId: "",
    upstreamConcentrationBeforeIntegrityPercentage: 0,
    obtainedResultInDownStreamPercentage: 0,
    upstreamPAOConcentrationAfterIntegrityPercentage: 0,
    upstreamPAOBeforeAndAfterIntegrity: 0,
    filterIntegrityTestId: 0,
}


// --- LI ---
export const lightIntensityObservationDefaults: ILITestObservationModel = {
    id: 0,

    locationNameOrNumber: "",
    acceptanceCriteriaMax: null,
    acceptanceCriteriaMin: null,
    lightIntensityTestId: 0,
    roomReadings: []
}

// --- PC test 
export const pcTestDefault: IPCTestModel = {
    ...baseTestDefaults,
    consideredParticleSizes: "",
    samplingTimeAtEachSamples: "",
    airVolumeSampledPerLocation: "",
    locations: [],
}

export const pcTestLocationDefault: IPCTestLocationModel = {
    id: 0,
    locationNumber: "",
    nonViableParticleCountTestId: 0,
    observations: []
}

export const pcTestObservationDefault: IPCObservation = {
    pointFiveOrMoreMicron: 0,
    oneOrMoreMicron: 0,
    fiveOrMoreMicron: 0,
    locationId: 0,
    id: 0,
    locationNumber: "",
    nonViableParticleCountTestId: 0,
}

export const recoveryTestDefault: IRecoveryTestModel = {
    ...baseTestDefaults,
    consideredParticleSizes: "",
    airVolumeSampledPerLocation: "",
    samplingTimeAtEachLocation: "",
    locationId: "",
    initialReadingTime: null,
    initialReadingAtPointFiveParticleSize: null,
    worstReadingTime: null,
    worstReadingAtPointFiveParticleSize: null,
    recoveryReadings: [],
    finalReadingTime: null,
    finalReadingAtPointFiveParticleSize: null,
    acceptanceCriteriaMin: null,
    acceptanceCriteriaMax: null,
}

export const recoveryReadingDefault: IRecoveryReadings = {
    id: 0,
    recoveryTestId: 0,
    recoveryTime: "",
    recoveryReadingAtPointFiveParticleSize: null,
    recoveryReadingAtFiveParticleSize: null,
    recoveryReadingAtOneParticleSize: null,
}

// --- SI
export const SoundLevelTestDefault: ISoundLevelTestModel = {
    ...baseTestDefaults,
    observations: [],
    observationLocationLabel: ObservationLocationLabel.LocationNameOrNumber,
}

export const SLTestObservationDefault: ISoundLevelObservationModel = {
    id: 0,

    locationNameOrNumber: "",
    roomReadings: [],
    soundLevelTestId: 0,
}

// --- UVLI ---

export const UVLITestDefault: IUVLightTestModel = {
    ...baseTestDefaults,
    observations: [],
    observationLocationLabel: ObservationLocationLabel.LocationNameOrNumber,
}

export const UVLITestObservationDefault: IUVLightTestObservationModel = {
    id: 0,

    locationNameOrNumber: "",
    roomReadings: [],
    uvLightIntensityTestId: 0,
}

// === AFPT ===
export const AFPTTestDefault: IAirflowPatterTestModel = {
    ...baseTestDefaults,
    id: 0,
    cameraDetailId: 0,
    cameraDetail: null,
    doesTheSmokeFlowsSmoothlyDownwardsFromSupplyGrills: null,
    doesTheSmokeFlowsTowardsTheReturnGrills: null,
    doesTheDifferentialPressureIsZeroWhenEquipmentIsInOFFCondition: null,
    doesTheDifferentialPressureIsWithinTheLimitWhenEquipmentIsInONCondition: null,
    doesTheSmokeFlowsAwayFromTheExhaustFilter: null,
}


