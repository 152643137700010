import { BaseComponent } from "./base.component";
import moment from "moment";

export interface IProps {
    customClass ?: string
}

class FooterComponent extends BaseComponent<IProps, any> {
    render() {
        return (<footer className={`pcoded-footer  ${this.props.customClass ?? ""}`}>
         
            Copyright &copy; {moment().year()} IncepBio. All rights reserved.
        </footer>)
        
    }
}

export default FooterComponent;
