import {connect} from "react-redux";
import {BaseComponent} from "../../common/component/base.component";
import {IAppState} from "../../store";
import {Navigate} from "react-router-dom";
import ScreenUrls from "../../common/screen-urls";

export interface IProps {}

class WelcomePage extends BaseComponent<IProps, any> {
    render() {
        return <Navigate to={ScreenUrls.Dashboard.Index()} replace />
    }
}

const mapStateToProps = (state: IAppState) => ({})

export default connect(mapStateToProps, { })(WelcomePage)
