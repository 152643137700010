import {matchPath, Route, Routes, useLocation} from "react-router-dom";
import AccountLayout from "../../common/screen/layout/account.layout";
import ScreenUrls from "../../common/screen-urls";
import AccessRolePage from "../access/screen/access-role.page";
import AccessRoleCreateEditPage from "./screen/form/access-role.create-edit.page";
import AccessDeniedPage from "../../common/screen/anonymous/access-denied.page";
import RouteHelper from "../../common/helper/route-helper";
import {AccessLevel, FeatureNames} from "../../common/enums";

const AccessRoutes = () => {
    const accessRole = RouteHelper.GetFeatureAccessLevel(FeatureNames.AccessRole);
    const location = useLocation();
    return <Routes>
        <Route element={<AccountLayout />}>
            <Route path={ScreenUrls.AccessDenied()} element={<AccessDeniedPage />} />

            <Route path={ScreenUrls.Users.AccessRole.Index()}>
                <Route index element={accessRole.accessLevel >= AccessLevel.View
                    ? <AccessRolePage accessLevel={accessRole.accessLevel} />
                    : <AccessDeniedPage />} />
                <Route path={ScreenUrls.CreateUri}
                       element={accessRole.accessLevel >= AccessLevel.Create
                           ? <AccessRoleCreateEditPage accessLevel={accessRole.accessLevel} />
                           : <AccessDeniedPage />} />
                <Route path={ScreenUrls.EditUriParam}
                       element={accessRole.accessLevel >= AccessLevel.Edit
                           ? <AccessRoleCreateEditPage accessLevel={accessRole.accessLevel}
                                                       id={matchPath({ path: ScreenUrls.Users.AccessRole.Edit(":id") }, location.pathname)?.params["id"]} />
                           : <AccessDeniedPage />} />
            </Route>
        </Route>
    </Routes>
}

export default AccessRoutes;
