import React from "react";
import { IAppState } from "../../../../store";
import { connect } from "react-redux";
import { IStoreSaveModel } from "../../../../common/model/store-save.model";
import { IWorkflowTaskModel } from "../../model/workflow-task.model";
import {
    editWorkflowTask,
    loadWorkflowTaskById,
    updateWorkflowTaskDataState
} from "../../store/workflow-task.slice";
import { FormBaseComponent, IFormBaseState } from "../../../../common/component/form-base.component";
import { ValidationScreens } from "../../../../common/enums";
import ValidationMessageControl from "../../../../common/control/validation-message.control";
import ButtonGroupComponent from "../../../../common/component/button-group.component";
import { openStageAssignmentModal, IStageAssignment } from "../../../../common/stores/stage-assignment.slice";

interface IProps {
    workflowTaskData: IWorkflowTaskModel;
    loadWorkflowTaskById: (id: number) => void;
    editWorkflowTask: (data: IWorkflowTaskModel) => void,
    onChange: () => void;
    stageMoveLoading: boolean;
    updateWorkflowTaskDataState: (payload: IStoreSaveModel) => void;
    workflowDataLoading: boolean;
    workflowTaskCreateEditLoading: boolean;
    openStageAssignmentModal: (payload: IStageAssignment) => void,
}

export interface IState extends IFormBaseState { }

class ReportReviewWorkflowTaskComponent extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            hideForm: false
        }
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.WorkflowTaskReportReviewStage);
    }

    render() {
        if (this.state.hideForm) return;
        return <React.Fragment>
            <article className="card mb-4">
                {(() => { if (this.props.workflowDataLoading) return <div className="loading--bar"><span></span></div> })()}
                <div className="card-header no-bottom-pad">
                    <div className="card-form-header-title"> Please complete following checklist before proceeding to next stage </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <input id="isReportReviewedBasedOnChecklist" type="checkbox"
                                className="checkbox--input"
                                checked={this.props.workflowTaskData.isReportReviewedBasedOnChecklist}
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.ValidateField("isReportReviewedBasedOnChecklist", (e));
                                    setTimeout(() => {
                                        this.props.updateWorkflowTaskDataState({
                                            name: "isReportReviewedBasedOnChecklist",
                                            value: !this.props.workflowTaskData.isReportReviewedBasedOnChecklist
                                        });
                                    }, 1)
                                }} />
                            <label htmlFor="isReportReviewedBasedOnChecklist" className="checkbox--label">
                                Has the report been reviewed as per the checklist?
                            </label>
                            <div className={`${this.vResult("isReportReviewedBasedOnChecklist").className}`}>
                                <ValidationMessageControl message={this.vResult("isReportReviewedBasedOnChecklist").message} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <input id="isReportReviewCompleted" type="checkbox"
                                className="checkbox--input"
                                checked={this.props.workflowTaskData.isReportReviewCompleted}
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.ValidateField("isReportReviewCompleted", (e));
                                    setTimeout(() => {
                                        this.props.updateWorkflowTaskDataState({
                                            name: "isReportReviewCompleted",
                                            value: !this.props.workflowTaskData.isReportReviewCompleted
                                        });
                                    }, 1)
                                }} />
                            <label htmlFor="isReportReviewCompleted" className="checkbox--label">
                                Has report review been completed for this workflow task?
                            </label>
                            <div className={`${this.vResult("isReportReviewCompleted").className}`}>
                                <ValidationMessageControl message={this.vResult("isReportReviewCompleted").message} />
                            </div>
                        </div>
                    </div>
             
                </div>
             
            </article>
            <ButtonGroupComponent>
                <>
                    <button type="button" className="btn btn--secondary btn--right"
                        disabled={this.props.stageMoveLoading
                            || this.props.workflowTaskCreateEditLoading
                        }
                        onClick={e => {
                            e.preventDefault();

                            const error = !this.ValidateForm(this.props.workflowTaskData);
                            this.reloadForm();
                            if (error) return;

                            this.props.openStageAssignmentModal({
                                optionsList: this.props.workflowTaskData.nextStageAssigneeList,
                                openModal: true,
                                proceedBtnClicked: true,
                            });
                        }}>Proceed</button>
                    <button type="button" className="btn btn--red btn--right"
                        disabled={this.props.stageMoveLoading}
                        onClick={e => {
                            e.preventDefault();
                            this.props.openStageAssignmentModal({
                                optionsList: this.props.workflowTaskData.rejectStageAssigneeList,
                                openModal: true,
                                proceedBtnClicked: false,
                            });
                        }}>Reject</button>
                    <button type="button" className="btn btn--primary-d2 btn--right"
                        disabled={this.props.stageMoveLoading}
                        onClick={e => {
                            e.preventDefault();

                            this.props.editWorkflowTask(this.props.workflowTaskData);
                            this.props.onChange();
                        }}>Save</button></>
            </ButtonGroupComponent>

            {(() => { if (this.props.stageMoveLoading || this.props.workflowTaskCreateEditLoading) return <div className="loading--bar fixed--top"><span></span></div> })()}
        </React.Fragment>
    }
}

const mapStateToProps = (state: IAppState) => ({
    workflowTaskData: state.workflowTask.workflowTaskData,
    stageMoveLoading: state.workflowTask.stageMoveLoading,
    workflowDataLoading: state.workflowTask.dataLoading,
    workflowTaskCreateEditLoading: state.workflowTask.createEditLoading,
})
export default connect(mapStateToProps, { openStageAssignmentModal, loadWorkflowTaskById, editWorkflowTask, updateWorkflowTaskDataState })(ReportReviewWorkflowTaskComponent);
