import {
    IFieldValidationModel,
    IFieldValidationResponseModel,
    IValidationRuleModel,
    IValidatorModel
} from "../validator.model";
import {ValidationScreens, ValidationType} from "../../enums";

const defaultValidationRule: IValidationRuleModel = {
    type: ValidationType.None,
    maxLength: null,
    message: ""
}

const defaultFieldValidation: IFieldValidationModel = {
    fieldName: "",
    rules: []
}

export const defaultValidator: IValidatorModel = {
    screen: ValidationScreens.None,
    fields: []
}

export const defaultFieldValidationResponse: IFieldValidationResponseModel = {
    fieldName: "",
    className: "",
    message: "",
    failed: false
}
