import React from "react";
import { IFormBaseState } from "../../../common/component/form-base.component";
import Modal from 'react-modal';
import { Component } from "react";
import { ICleanroomEquipmentModel } from "../model/report.model";
import { Link } from "react-router-dom";
import ScreenUrls from "../../../common/screen-urls";
import { ReportTests } from "../../../common/_static/report.data";

export interface IProps {
    toggle: boolean,
    handleToggleState: any,
    reportData: ICleanroomEquipmentModel,
}

export interface IState extends IFormBaseState {

}

class TestListComponent extends Component<IProps, IState> {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {

    }
    render() {
        return <React.Fragment>
            <Modal
                isOpen={this.props.toggle}
                onRequestClose={this.props.handleToggleState}
            >
                <div className={`d-flex f-w-500 flex-column`}>
                    <div className="f-18">
                        Clean Room Equipment Tests
                    </div>
                    <div className="f-14 mt-2">
                        {
                            ReportTests.cleanroomEquipment?.map((item) => {
                                return (
                                    <Link
                                        style={{ borderRadius: "15px" }}
                                        className="text-capitalize font-12 m-1 pill active-pill"

                                        to={`${ScreenUrls.Report.CleanroomEquipment.Edit(this.props.reportData?.id)}/${item[1]}/${ScreenUrls.CreateUri}`}>
                                        {item[0]}</Link>
                                )
                            })
                        }

                    </div>
                    <button
                        onClick={this.props.handleToggleState}
                        className="text-capitalize f-w-800 border-0 btn-secondary btn-sm mt-2 align-self-end">cancel</button>
                </div>
            </Modal>
        </React.Fragment >
    }
}

export default TestListComponent;
