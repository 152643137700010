import {IFieldValidationModel, IValidatorModel} from "../model/validator.model";
import {ValidationScreens, ValidationType} from "../enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "email",
        rules: [
            {
                type: ValidationType.Required,
                message: "Email is required"
            }
        ]
    },
    {
        fieldName: "password",
        rules: [
            {
                type: ValidationType.Required,
                message: "Password is required"
            }
        ]
    }
]

export const loginValidator: IValidatorModel = {
    screen: ValidationScreens.Login,
    fields: fields
}
