import {BaseComponent} from "../../../common/component/base.component";
import {connect} from "react-redux";
import {IAppState} from "../../../store";
import {IPurchaseOrderServiceItemModel} from "../model/purchase-order-item.model";
import {AgGridReact} from "ag-grid-react";
import {Link} from "react-router-dom";
import ScreenUrls from "../../../common/screen-urls";
import {loadPurchaseOrderServiceItems} from "../store/purchase-order-service-item.slice";
import {AccessLevel} from "../../../common/enums";
import {AgGridDefaultColDef} from "../../../common/app-defaults";
import PurchaseOrderServiceItemService from "../service/purchase-order-service-item.service"
import GridDeleteButtonControl from "../../../common/control/component/grid/grid-delete-buton.control";

export interface IProps {
    accessLevel: number,
    purchaseOrderServiceItems: IPurchaseOrderServiceItemModel[],
    loadPurchaseOrderServiceItems: () => void,
    dataLoading: boolean
}

class PurchaseOrderServiceItemPage extends BaseComponent<IProps, any> {
    componentDidMount() {
        this.props.loadPurchaseOrderServiceItems();
    }

    render() {
        return <div className="pcoded-content">
            <div className="pcoded-inner-content">
                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h5>List of service</h5>
                                        <div className="card-header-right">
                                            <div className="btn-group card-option">
                                                {(() => {
                                                    if(this.props.dataLoading || this.props.accessLevel < AccessLevel.Create) return;
                                                    return <Link className="btn drp-icon btn-rounded btn-primary btn--header" to={ScreenUrls.PurchaseOrder.ServiceItem.Create()}>
                                                        Add New
                                                    </Link>
                                                })()}

                                            </div>
                                        </div>
                                    </div>
                                    {(() => { if(this.props.dataLoading) return <div className="loading--bar"><span></span></div> })()}
                                    <div className="card-block ag-theme-alpine xx-large-grid" style={{ height: "480px" }}>
                                        <AgGridReact
                                            pagination={true}
                                            paginationAutoPageSize={true}
                                            unSortIcon={true}
                                            rowHeight={60}
                                            defaultColDef={AgGridDefaultColDef}
                                            columnDefs={[
                                                {
                                                    suppressMovable: true,
                                                    hide: this.props.accessLevel < AccessLevel.Edit,
                                                    headerName: "Edit", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link  grid-cell-link",
                                                    cellRenderer: (params) => {
                                                        return (
                                                            <Link to={ScreenUrls.PurchaseOrder.ServiceItem.Edit(params.value)}>
                                                                <i className={`feather icon-edit`}></i>
                                                            </Link>
                                                        )
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    hide: this.props.accessLevel < AccessLevel.Delete,
                                                    headerName: "Delete", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link delete",
                                                    cellRenderer: (params) => {
                                                        return (
                                                            <GridDeleteButtonControl onDelete={async () => {                                                                 
                                                                await PurchaseOrderServiceItemService.Delete(params.value);
                                                                this.props.loadPurchaseOrderServiceItems();                                                                                                                                   
                                                             }}></GridDeleteButtonControl>
                                                        )
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Code", field: "code", flex: 1, sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => <span>{params.value}</span>
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "HSN/SAC Code", field: "hsnSacCode", flex: 2, sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => <span>{params.value}</span>
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Title", field: "title", flex: 8, sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => <span>{params.value}</span>
                                                }
                                            ]}
                                            rowData={this.props.purchaseOrderServiceItems}>
                                        </AgGridReact>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state: IAppState) => ({
    purchaseOrderServiceItems: state.purchaseOrderServiceItem.purchaseOrderServiceItems,
    dataLoading: state.purchaseOrderServiceItem.dataLoading
})

export default connect(mapStateToProps, { loadPurchaseOrderServiceItems })(PurchaseOrderServiceItemPage);
