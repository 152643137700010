import { BaseComponent } from "../../../common/component/base.component";
import { connect } from "react-redux";
import { IAppState } from "../../../store";
import { IUserModel } from "../model/user.model";
import { loadUsers } from "../store/user.slice";
import { Link } from "react-router-dom";
import ScreenUrls from "../../../common/screen-urls";
import { AgGridReact } from "ag-grid-react";
import DropdownDisplayHelper from "../../../common/helper/dropdown-display.helper";
import { AccessLevel } from "../../../common/enums";
import UserService from "../service/user.service";
import { AgGridDefaultColDef } from "../../../common/app-defaults";
import GridDeleteButtonControl from "../../../common/control/component/grid/grid-delete-buton.control";
import { setShowModal, IAlert } from "../../../common/stores/alertMsg.slice";
import { NotificationModalType } from "../../../common/enums";

export interface IProps {
    accessLevel: number,
    userList: IUserModel[],
    loadUsers: () => void,
    listLoading: boolean,
    setShowModal: (props: IAlert) => void,
}

class UserPage extends BaseComponent<IProps, any> {
    componentDidMount() {
        this.props.loadUsers();
    }

    render() {
        return <div className="pcoded-content">
            <div className="pcoded-inner-content">
                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    {(() => { if (this.props.listLoading) return <div className="loading--bar"><span></span></div> })()}
                                    <div className="card-header">
                                        <h5>List of users</h5>
                                        <div className="card-header-right">
                                            <div className="btn-group card-option">
                                                {(() => {
                                                    if (this.props.accessLevel >= AccessLevel.Create) {
                                                        return <Link className="btn drp-icon btn-rounded btn-primary" to={ScreenUrls.Users.Create()}>
                                                            Add New
                                                        </Link>
                                                    }
                                                })()}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-block ag-theme-alpine xx-large-grid">
                                        <AgGridReact
                                            pagination={true}
                                            paginationAutoPageSize={true}
                                            unSortIcon={true}
                                            rowHeight={60}
                                            defaultColDef={AgGridDefaultColDef}
                                            columnDefs={[
                                                {
                                                    suppressMovable: true,
                                                    hide: this.props.accessLevel < AccessLevel.Edit,
                                                    headerName: "Edit", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                    cellRenderer: (params) => {
                                                        return (
                                                            <Link to={ScreenUrls.Users.Edit(params.value)}>
                                                                <i className={`feather icon-edit`}></i>
                                                            </Link>
                                                        )
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    hide: this.props.accessLevel < AccessLevel.Delete,
                                                    headerName: "Delete", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link delete",
                                                    cellRenderer: (params) => {
                                                        return (
                                                            <GridDeleteButtonControl onDelete={async () => {
                                                                await UserService.Delete(params.value);
                                                                this.props.loadUsers();
                                                            }}></GridDeleteButtonControl>
                                                        )
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Training Certificate",
                                                    field: "calibrationCertificate",
                                                    width: 90,
                                                    cellClass:
                                                        "grid-cell grid-cell-link download",
                                                    cellRenderer: (params) => {
                                                        if (
                                                            params.value != null &&
                                                            params.value?.publicLink
                                                        ) {
                                                            return (
                                                                <button
                                                                    style={{ borderRadius: "15px" }}
                                                                    className="text-capitalize font-12 m-0 pill active-pill align-self-end"
                                                                    onClick={e => {
                                                                        e.preventDefault();
                                                                        window.open(params.value?.publicLink, '_blank');
                                                                    }}>Open Link</button>

                                                            );
                                                        }
                                                    },
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "#", field: 'id', width: 100, cellClass: "grid-cell",
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value}</span>
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Name", field: "firstName", flex: 1.5, sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => {
                                                        return <span>{DropdownDisplayHelper.User(params.data)}</span>
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Emp Id", field: 'employeeId', flex: 1.5, sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value}</span>
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Email", field: 'email', flex: 2, sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value}</span>
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Phone", field: 'phone', flex: 1.5, sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value}</span>
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Manager", field: 'manager', flex: 1.5, sortable: true, cellClass: "grid-cell",
                                                    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                        if (valueA?.firstName == valueB?.firstName) return 0;
                                                        return (valueA?.firstName > valueB?.firstName) ? 1 : -1;
                                                    },
                                                    cellRenderer: (params) => {
                                                        return <span>{DropdownDisplayHelper.User(params.value)}</span>
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Status", field: 'isActive', width: 120, sortable: true, cellClass: "grid-cell grid-cell-tag",
                                                    cellRenderer: (params) => {
                                                        return <label className={params.value ? 'active' : 'inactive'}
                                                            style={{ borderRadius: "15px" }}>
                                                            {params.value ? 'Active' : 'In-active'}
                                                        </label>
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    hide: this.props.accessLevel < AccessLevel.Edit,
                                                    headerName: "Password", field: 'id', width: 120, cellClass: "grid-cell grid-cell-button",
                                                    cellRenderer: (params) => {
                                                        return (
                                                            <a onClick={async () => {
                                                                await UserService.PasswordReset(params.value);
                                                                this.props.loadUsers();
                                                                const user = params.data as IUserModel;
                                                                this.props.setShowModal({ className: NotificationModalType.success, message: `Password Reset done for ${DropdownDisplayHelper.User(user)}`, title: "success", show: true })
                                                            }}>
                                                                Reset
                                                            </a>
                                                        )

                                                    }
                                                },
                                            ]}
                                            rowData={this.props.userList}>
                                        </AgGridReact>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state: IAppState) => ({
    userList: state.user.userList,
    listLoading: state.user.listLoading
})

export default connect(mapStateToProps, { loadUsers, setShowModal })(UserPage);
