import React, { useEffect, useState } from "react";

import { Document, Page, Text, View, PDFViewer, Image, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import TestService from "../../../service/test.service";
import { CleanroomEquipmentTests, TestObservationResult } from "../../../../../common/enums";
import { ICleanroomEquipmentModel } from "../../../model/report.model";
import { displayDate, localToUtcDate } from "../../../../../common/utils";
import { IUserModel } from "../../../../user/model/user.model";
import moment from "moment";
import IncepOne from "../../../../../assets/images/incepbio.jpg";
import Location from "../../../../../assets/images/location.png";
import WWW from "../../../../../assets/images/www.png";
import Call from "../../../../../assets/images/call.png";
import { IFilterIntegrityModel } from "../../../model/test/filter-integrity-test.model";
import { AerosolGeneratorTypeList, AerosolMediumList } from "../../../../../common/_static/report.data";
import { styles } from "./base-test-report-pdf.component";
import { editFITest } from '../../../store/cleanroom-equipment/test.slice';
import { useDispatch } from "react-redux";
export const FilterIntegrityTest = ({ data }: { data: IFilterIntegrityModel | any }) => {
    return (
        <Document>
            <Page size="A4" style={styles.page} wrap>
                {/* ---- header ------ */}
                <View style={{ ...styles.paddingY }} fixed>
                    <View style={{ ...styles.section, ...styles.flexBox, alignItems: 'center' }}>
                        <view style={{ ...styles.flex_1 }}>
                            <Image src={IncepOne} style={{ ...styles.image }} />
                        </view>
                        <view style={{ ...styles.flex_2 }}>
                            <View style={{ backgroundColor: '#009C8D', width: '100%', height: '8', position: 'relative' }}>
                                <View style={{ backgroundColor: '#004D88', position: 'absolute', width: '30%', height: '100%', right: 0 }}></View>
                                <View style={{ backgroundColor: '#FFF', position: 'absolute', width: '2%', height: '200%', right: '29%', transform: 'rotate(-20deg)', top: '-50%' }}></View>
                            </View>
                        </view>
                    </View>
                    <View style={{ ...styles.section, ...styles.font_12, marginTop: 2 }}>
                        <Text style={styles.bold}>An ISO 9001 : 2015 Certified Company</Text>
                    </View>
                </View>

                {/* ---- body ---- */}
                <View style={{ ...styles.section }}>
                    <View style={{ ...styles.main }}>
                        <View style={{ ...styles.paddingTB }}>
                            <Text style={{ ...styles.reportTitle, ...styles.bold }}>Test Report For Filter Integrity</Text>
                        </View>
                        <View style={{ ...styles.paddingTB }}>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_2, ...styles.col }}>
                                    <Text style={{ ...styles.label, ...styles.bold }}>Customer Details :</Text>
                                </View>
                                <view style={{ ...styles.flexBox, ...styles.flex_1 }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{ ...styles.label }}>Report Number</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{ ...styles.label, ...styles.bold }}>{data?.reportNumber ?? ''}</Text>
                                    </View>
                                </view>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_2, ...styles.col }}>
                                    <Text style={{}}>

                                        {data?.client?.companyName} {"\n"}
                                        {data?.clientAddress?.addressLine1}
                                        {data?.clientAddress?.addressLine2 ? `, ${data?.clientAddress?.addressLine2}` : ""}
                                        {"\n"}
                                        {data.clientAddress?.city}, {data.clientAddress?.state}
                                    </Text>
                                </View>
                                <view style={{ ...styles.flex_1 }}>
                                    <View style={{ ...styles.flexBox, flex: 1, ...styles.flexColumn, }} >
                                        <View style={{ ...styles.flexBox, ...styles.flex_1 }}>
                                            <View style={{ ...styles.label, ...styles.col }}>
                                                <Text style={{ ...styles.label }}>Date of test</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col }}>
                                                <Text style={{ ...styles.label }}>{displayDate(data.dateOfTest ?? "")}</Text>
                                            </View>
                                        </View>
                                        <View style={{ ...styles.flexBox, ...styles.flex_1 }}>
                                            <View style={{ ...styles.label, ...styles.col }}>
                                                <Text style={{ ...styles.label }}>Due Date</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col }}>
                                                <Text style={{ ...styles.label }}>
                                                    {data?.dueDate ? displayDate(data.dueDate) : ""}
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                </view>
                            </View>
                        </View>

                        {/* ----  Equipnet details --- */}
                        <View style={{ ...styles.paddingTB }}>
                            <Text style={{ ...styles.font_12, ...styles.bold }}>Details Of Equipment :</Text>
                        </View>

                        <View style={{}}>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{}}>Equipment Name</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>{data.clientEquipment?.equipmentName ?? ''}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Area Of Test</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data.areaOfTest ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{}}>Equipment Id</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>{data.clientEquipment?.equipmentId ?? ""}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Location</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.location ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{}}>Make</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>{data.clientEquipment?.make ?? ""}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Filter Type</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.filterType ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{}}>Model Number</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>{data?.clientEquipment?.modelNumber ?? ""}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Filter Efficiency</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.filterEfficiency ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{}}>Serial Number</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>{data?.clientEquipment?.serialNumber ?? ""}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Zone Classification</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data.eugmpStandard?.id ? data.eugmpStandard.grade : data.isoStandard?.id ? data.isoStandard?.classNumber : ""}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{}}>Downstream Acceptance Criteria
                                        </Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>{data?.downstreamAcceptanceCriteria ? `NMT ${data?.downstreamAcceptanceCriteria}%` : ""}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Total Number of Filter
                                            </Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.numberOfFilters}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>

                        {/* ----  Equipnet details --- */}
                        <View style={{ ...styles.paddingTB }}>
                            <View style={{}}>
                                <Text style={{ ...styles.font_12, ...styles.bold }}>Details Of Standard :</Text>
                            </View>

                            <View style={{ ...styles.paddingTB }}>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.flexBox, ...styles.col }}>
                                        <Text style={{}}>Name</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox, ...styles.col }}>
                                        <Text style={{}}>{data?.dataLogger?.name ?? ""}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col }}>
                                                <Text style={{}}>Model Number</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col }}>
                                                <Text style={{}}>{data.dataLogger?.modelNumber ?? ""}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col }}>
                                                <Text style={{}}>Make</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col }}>
                                                <Text style={{}}>{data.dataLogger?.make?.name ?? ""}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Instrument Id</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.dataLogger?.instrumentId ?? ""}</Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Serial Number</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.dataLogger?.serialNumber ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Calibration Done Date</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{displayDate(data.dataLogger?.lastCalibratedOn)}</Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col }}>
                                                <Text style={{}}>Calibration Due Date</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col }}>
                                                <Text style={{}}>{displayDate(data.dataLogger?.calibrationDueOn)}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Aerosol Generator type
                                            </Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>
                                                {
                                                    data?.aerosolGeneratorType > -1 ?
                                                        AerosolGeneratorTypeList.find((item) => item.value == data?.aerosolGeneratorType?.toString())?.label
                                                        : null
                                                }
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col }}>
                                                <Text style={{}}>Aerosol Medium
                                                </Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col }}>
                                                <Text style={{}}>
                                                    {
                                                        data?.aerosolMedium > -1 ?
                                                            AerosolMediumList.find((item) => item.value == data?.aerosolMedium?.toString())?.label
                                                            : null
                                                    }
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                        <View style={{ ...styles.paddingTB }}>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.col, textAlign: "center" }}>

                                    <Text style={{ ...styles.bold }}>Protocol Name</Text>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.col, textAlign: "center" }}>

                                    <Text style={{ ...styles.bold }}>Protocol Number</Text>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.col, textAlign: "center" }}>
                                    <Text style={{}}>{data?.refStandardOrProtocolOrDocumentName ?? ""}</Text>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.col, textAlign: "center" }}>

                                    <Text style={{}}>
                                        {data?.procedureOrProtocolOrDocumentNumber ?? ""}

                                    </Text>
                                </View>
                            </View>
                        </View>
                        {/* ---- observations ---- */}
                        <View wrap={false}>
                            <View style={{ ...styles.paddingTB }}>
                                <Text style={{ ...styles.font_12, ...styles.bold }}>Observations :</Text>
                            </View>
                            <View style={{ ...styles.flexBox, ...styles.bold, ...styles.textCenter }}>
                                <View style={{ ...styles.label, width: 30, ...styles.col, }}>
                                    <Text style={{}}>Sr.No</Text>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.col, }}>
                                    <Text style={{}}>
                                        Filter Id
                                    </Text>
                                </View>

                                <View style={{ ...styles.flex_1, }}>
                                    <View style={{ ...styles.flexBox, ...styles.flexColumn, }}>
                                        <View style={{ ...styles.col, textAlign: 'center' }}>
                                            <Text style={{}}>Upstream Concentration Before Integrity
                                            </Text>
                                        </View>
                                        <View style={{ ...styles.flexBox, }}>
                                            <View style={{ ...styles.col, ...styles.flex_1, textAlign: 'center' }}>
                                                <Text style={{}}>
                                                    (ug/l)
                                                </Text>
                                            </View>
                                            <View style={{ ...styles.col, ...styles.flex_1, textAlign: 'center' }}>
                                                <Text style={{}}>
                                                    (%)
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, }}>
                                    <View style={{ ...styles.flexBox, ...styles.flex_1, ...styles.flexColumn }}>
                                        <View style={{ ...styles.col, }}>
                                            <Text style={{}}>Obtained Results in Downstream</Text>
                                        </View>
                                        <View style={{ ...styles.col, textAlign: 'center' }}>
                                            <Text style={{}}>
                                                (%)
                                            </Text>
                                        </View>
                                    </View>

                                </View>
                                <View style={{ ...styles.flex_1, }}>
                                    <View style={{ ...styles.flexBox, ...styles.flexColumn }}>
                                        <View style={{ ...styles.col }}>
                                            <Text style={{}}>Upstream PAO Concentration After Integrity</Text>
                                        </View>
                                        <View style={{ ...styles.col, textAlign: 'center', }}>
                                            <Text style={{}}>
                                                (%)
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_2, }}>
                                    <View style={{ ...styles.flexBox, ...styles.flexColumn, flex: 1 }}>
                                        <View style={{ ...styles.col, ...styles.flex_1 }}>
                                            <Text style={{}}>Upstream PAO Before and After Integrity should not vary ±15%
                                            </Text>
                                        </View>
                                        <View style={{ ...styles.col, textAlign: 'center', }}>
                                            <Text style={{}}>
                                                (%)
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.label, width: 60, ...styles.col }}>
                                    <Text style={{}}>Result
                                    </Text>
                                </View>
                            </View>
                            {
                                data.observations?.map((item, index) => {
                                    return (
                                        <View style={{ ...styles.flexBox, ...styles.textCenter }}>
                                            <View style={{ ...styles.label, width: 30, ...styles.col }}>
                                                <Text style={{}}>{index + 1}</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col }}>
                                                <Text style={{}}>{item.filterId}</Text>
                                            </View>

                                            <View style={{ ...styles.flex_1 }}>
                                                <View style={{ ...styles.flexBox }}>
                                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                                        <Text style={{}}>{item?.upstreamConcentrationBeforeIntegrity}</Text>
                                                    </View>
                                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                                        <Text style={{}}>{item?.upstreamConcentrationBeforeIntegrityPercentage}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                            <View style={{ ...styles.flex_1 }}>
                                                <View style={{ ...styles.flexBox, ...styles.col }}>
                                                    <Text style={{}}>{item.obtainedResultInDownStreamPercentage}</Text>
                                                </View>
                                            </View>
                                            <View style={{ ...styles.flex_1 }}>
                                                <View style={{ ...styles.flexBox, ...styles.col }}>
                                                    <Text style={{}}>
                                                        {item?.upstreamPAOConcentrationAfterIntegrityPercentage ?? 0}
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={{ ...styles.flex_2, }}>
                                                <View style={{ ...styles.flexBox, ...styles.col }}>
                                                    <Text style={{}}>
                                                        {item.upstreamPAOBeforeAndAfterIntegrity}
                                                    </Text>
                                                </View>
                                            </View>

                                            <View style={{ ...styles.label, width: 60, ...styles.col }}>
                                                <Text style={{}}>{
                                                    item.result == TestObservationResult.Pass ? TestObservationResult[TestObservationResult.Pass] :
                                                        item.result == TestObservationResult.Fail ? TestObservationResult[TestObservationResult.Fail] : "NA"}

                                                </Text>
                                            </View>
                                        </View>
                                    )
                                })
                            }
                        </View>
                        <View style={{ ...styles.paddingTB, ...styles.font_12 }} wrap={false}>
                            <View style={{ ...styles.col, border: 0 }}>
                                <Text style={{ ...styles.bold }}>Conclusion :</Text>
                            </View>
                            <View style={{ ...styles.col, border: 0 }}>
                                <Text>{data.remarks ?? ""}</Text>
                            </View>
                        </View>
                        <View style={{ ...styles.paddingTB, ...styles.font_12 }} wrap={false}>
                            <View style={{ ...styles.flexBox, ...styles.bold }}>
                                <View style={{ ...styles.flex_2, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text>Prepared By</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>Checked By</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>Approved By</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.font_12, ...styles.flexBox }}>
                                <View style={{ ...styles.flex_2, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>Name</Text>
                                        </View>
                                        <View style={{ ...styles.flex_2, ...styles.col }}>
                                            <Text style={{}}>
                                                {data.preparedBy?.firstName} {data.preparedBy?.lastName}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>Name</Text>
                                        </View>
                                        <View style={{ ...styles.flex_2, ...styles.col }}>
                                            <Text style={{}}>{data.checkedBy?.firstName} {data.checkedBy?.lastName}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>Name</Text>
                                        </View>
                                        <View style={{ ...styles.flex_2, ...styles.col }}>
                                            <Text style={{}}>{data.verifiedByName}</Text>
                                        </View>
                                    </View>
                                </View>

                            </View>
                            <View style={{ ...styles.font_12, ...styles.flexBox }}>
                                <View style={{ ...styles.flex_2, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>Signature</Text>
                                        </View>
                                        <View style={{ ...styles.flex_2, ...styles.col }}>
                                            <Text style={{}}>

                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>Signature</Text>
                                        </View>
                                        <View style={{ ...styles.flex_2, ...styles.col }}>
                                            <Text style={{}}></Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>Signature</Text>
                                        </View>
                                        <View style={{ ...styles.flex_2, ...styles.col }}>
                                            <Text style={{}}></Text>
                                        </View>
                                    </View>
                                </View>
                            </View>

                            <View style={{ ...styles.font_12, ...styles.flexBox }}>
                                <View style={{ ...styles.flex_2, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>Date</Text>
                                        </View>
                                        <View style={{ ...styles.flex_2, ...styles.col }}>
                                            <Text style={{}}>
                                                {data?.preparedOnDate ? displayDate(data?.preparedOnDate) : ""}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>

                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>Date</Text>
                                        </View>
                                        <View style={{ ...styles.flex_2, ...styles.col }}>
                                            <Text style={{}}>
                                                {data?.checkedOnDate ? displayDate(data?.checkedOnDate) : ""}
                                            </Text>
                                        </View>

                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>Date</Text>
                                    </View>
                                    <View style={{ ...styles.flex_2, ...styles.col }}>
                                        <Text >
                                            {data.verifiedOnDate ? displayDate(data.verifiedOnDate) : ""}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.font_12, ...styles.flexBox, ...styles.bold }}>
                                <View style={{ ...styles.flex_2 }}>
                                    <View style={{ width: "100%", textAlign: 'center', ...styles.col }}>
                                        <Text style={{}}>For IncepBio Private Limited</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, }}>
                                    <View style={{ width: "100%", textAlign: 'center', ...styles.col }}>
                                        <Text style={{}}>For {data?.client?.companyName}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </View >

                {/* --- footer ---- */}
                <View wrap={false} style={{ ...styles.paddingY, fontWeight: 900, ...styles.section, ...styles.font_12 }} fixed>
                    <View>
                        <Text style={{ textAlign: 'center', ...styles.paddingTB }} render={({ pageNumber, totalPages }) => {
                            return (`Page ${pageNumber} of ${totalPages}`);
                        }} fixed />
                    </View>
                    <View style={{ color: "#004D88", }}>
                        <Text style={{ ...styles.font_13, ...styles.bold }}>Incepbio Private Limited</Text>
                    </View>
                    <View style={{ ...styles.paddingTB, ...styles.footer, ...styles.flexBox, width: "80%", alignItems: 'center' }}>
                        <Image src={Location} style={{ ...styles.footerImage }}></Image>
                        <Text style={{}}>
                            #24, 22nd Main, Marenahalli, J.P. Nagar 2nd Phase, Bengaluru - 560078
                        </Text>
                        <Text style={{ width: 20, height: 20, borderRight: '4px solid #FFF', borderBottom: '4px solid #FFF', transform: 'rotate(-45deg)' }}>

                        </Text>
                        <Image src={Call} style={{ marginLeft: 8, ...styles.footerImage }}></Image>
                        <Text>+91-63669 42391</Text>
                    </View>

                    <View style={{ ...styles.flexBox, ...styles.paddingTB, alignItems: 'center' }}>
                        <Image src={WWW} style={{ ...styles.footerImage, width: 18 }}></Image>
                        <Text style={{ ...styles.bold }}>
                            support@incepbio.com
                        </Text>
                    </View>
                </View>
            </Page >
        </Document >
    )
}

export interface IProps {
    data: ICleanroomEquipmentModel,
    id: number,
    currentUser: IUserModel
}
const FilterIntegrityPDFComponent = ({ id, data, currentUser }: IProps) => {
    const [loading, setLoading] = useState(false);
    const dispatch: any = useDispatch();
    const downloadPdf = async () => {
        let res: any;
        setLoading(true);
        try {
            if (id) {
                res = await TestService.GetFITestById({ testName: CleanroomEquipmentTests.filterintegrity, id });
                if (res?.id) {
                    const fileName = 'filter-integrity-test.pdf';
                    const blob = await pdf(<FilterIntegrityTest data={{ ...data, ...res }} />).toBlob();
                    saveAs(blob, fileName);
                }
            }
        } catch (errr) {

        } finally {
            setLoading(false);
            let date = moment(new Date()).toString();
            dispatch(editFITest({
                testName: CleanroomEquipmentTests.filterintegrity, data:
                {
                    ...res,
                    editLocked: true,
                    reportPdfGeneratedById: currentUser?.id,
                    reportPdfGeneratedOn: localToUtcDate(date),
                },
            }));
        }

    };

    useEffect(() => {
        setLoading(false);
    }, [data]);

    return (<React.Fragment>
        {/* <PDFViewer width={'100%'}>
            <FilterIntegrityTest data={data} />
        </PDFViewer> */}
        <button className="btn m-0 btn-primary px-3 py-2" onClick={downloadPdf}>{!loading ? "Download PDF" : "Loading"}</button>
    </React.Fragment>)
}

export default FilterIntegrityPDFComponent;





