import { IFieldValidationModel, IValidatorModel } from "../../../../common/model/validator.model";
import { ValidationScreens, ValidationType } from "../../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "assigneeId",
        rules: [
            {
                type: ValidationType.Required,
                message: "Assignee is required"
            }
        ]
    },
    {
        fieldName: "qaReviewStartDate",
        dependencyFieldName: "reportingEndDate",
        rules: [
            {
                type: ValidationType.Required,
                message: "QA review start date is required"
            },
            {
                type: ValidationType.DateShouldBeGreaterDependentDate,
                message: "QA review start date should be greater than reporting end date"
            }
        ]
    },
    {
        fieldName: "qaReviewEndDate",
        dependencyFieldName: "qaReviewStartDate",
        rules: [
            {
                type: ValidationType.Required,
                message: "QA review end date is required"
            },
            {
                type: ValidationType.DateShouldBeLessThenCurrentDate,
                message: "QA review end date should be less than current date"
            },
            {
                type: ValidationType.DateShouldBeGreaterDependentDate,
                message: "QA review end date should be greater than QA review start date"
            }

        ]
    },
    {
        fieldName: "isQaReviewedBasedOnChecklist",
        rules: [
            {
                type: ValidationType.RequiredIfTrue,
                message: "Please complete Qa reviewedBasedOn checklist"
            }
        ]
    },
    {
        fieldName: "isQaReviewCompleted",
        rules: [
            {
                type: ValidationType.RequiredIfTrue,
                message: "Please complete qaReview"
            }
        ]
    }
]

export const workflowTaskQualityAssuranceStageValidator: IValidatorModel = {
    screen: ValidationScreens.WorkflowTaskQualityAssuranceStage,
    fields: fields
}
