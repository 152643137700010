import ApiUrls from "../../../common/api-urls";
import HttpService from "../../../common/service/http.service";
import { IAirflowVelocityTestObservationModel, IBaseTestPayload } from "../model/test/test.model";
import { ITestObservationPayload } from "../model/test/test.model";
import { IFilterIntegrityObservationModel, IFITestPayload, IFITestObservationPayload } from "../model/test/filter-integrity-test.model";
import { ILIObservationPayload, ILITestObservationModel, ILITestPayload } from "../model/test/light-intensity-test.model";
import { IPCLocationPayload, IPCTestLocationModel } from "../model/test/particle-count-test.model";
import { ISoundLevelObservationModel, ISoundLevelObservationPayload } from "../model/test/sound-level-test.model";
import { IUVLightTestObservationModel, IUVObservationPayload } from "../model/test/uv-light-test.model";

export default class ObservationService {

    static async CreateAVObservation(payload: ITestObservationPayload): Promise<IAirflowVelocityTestObservationModel> {
        return await HttpService.Post<IAirflowVelocityTestObservationModel | null, IAirflowVelocityTestObservationModel>(ApiUrls.Report.Observation.Create(payload.testName), payload.data ?? null);
    }

    static async EditAVObservation(payload: ITestObservationPayload): Promise<IAirflowVelocityTestObservationModel> {
        return await HttpService.Patch<IAirflowVelocityTestObservationModel | null, IAirflowVelocityTestObservationModel>(ApiUrls.Report.Observation.Edit(payload.testName, payload?.data?.id ?? 0), payload.data ?? null);
    }

    static async GetAVObservationById(payload: ITestObservationPayload): Promise<IAirflowVelocityTestObservationModel> {
        return await HttpService.Get<IAirflowVelocityTestObservationModel>(ApiUrls.Report.Observation.GetById(payload.testName, payload.id ?? 0));
    }

    static async DeleteObservation(payload: IBaseTestPayload) {
        await HttpService.Delete(ApiUrls.Report.Observation.Delete(payload.testName, payload.id ?? 0));
    }

    // ==== FI Observation ====

    static async CreateFIObservation(payload: IFITestObservationPayload): Promise<IFilterIntegrityObservationModel> {
        return await HttpService.Post<IFilterIntegrityObservationModel | null, IFilterIntegrityObservationModel>(ApiUrls.Report.Observation.Create(payload.testName), payload.data ?? null);
    }

    static async EditFIObservation(payload: IFITestObservationPayload): Promise<IFilterIntegrityObservationModel> {
        return await HttpService.Patch<IFilterIntegrityObservationModel | null, IFilterIntegrityObservationModel>(ApiUrls.Report.Observation.Edit(payload.testName, payload?.data?.id ?? 0), payload.data ?? null);
    }

    static async GetFIObservationById(payload: IFITestObservationPayload): Promise<IFilterIntegrityObservationModel> {
        return await HttpService.Get<IFilterIntegrityObservationModel>(ApiUrls.Report.Observation.GetById(payload.testName, payload.id ?? 0));
    }

    // ==== LI Observation ====
    static async CreateLIObservation(payload: ILIObservationPayload): Promise<ILITestObservationModel> {
        return await HttpService.Post<ILITestObservationModel | null, ILITestObservationModel>(ApiUrls.Report.Observation.Create(payload.testName), payload.data ?? null);
    }

    static async EditLIObservation(payload: ILIObservationPayload): Promise<ILITestObservationModel> {
        return await HttpService.Patch<ILITestObservationModel | null, ILITestObservationModel>(ApiUrls.Report.Observation.Edit(payload.testName, payload?.data?.id ?? 0), payload.data ?? null);
    }

    static async GetLIObservationById(payload: ILIObservationPayload): Promise<ILITestObservationModel> {
        return await HttpService.Get<ILITestObservationModel>(ApiUrls.Report.Observation.GetById(payload.testName, payload.id ?? 0));
    }

    // ==== PC Location ====

    static async CreatePCLocation(payload: IPCLocationPayload): Promise<IPCTestLocationModel> {
        return await HttpService.Post<IPCTestLocationModel | null, IPCTestLocationModel>(ApiUrls.Report.Location.Create(payload.testName), payload.data ?? null);
    }

    static async EditPCLocation(payload: IPCLocationPayload): Promise<IPCTestLocationModel> {
        return await HttpService.Patch<IPCTestLocationModel | null, IPCTestLocationModel>(ApiUrls.Report.Location.Edit(payload.testName, payload?.data?.id ?? 0), payload.data ?? null);
    }

    static async GetPCLocationById(payload: IPCLocationPayload): Promise<IPCTestLocationModel> {
        return await HttpService.Get<IPCTestLocationModel>(ApiUrls.Report.Location.GetById(payload.testName, payload.id ?? 0));
    }

    static async DeletePCLocation(payload: IBaseTestPayload) {
        await HttpService.Delete(ApiUrls.Report.Location.Delete(payload.testName, payload.id ?? 0));
    }

    // === sound level 
    static async CreateSLObservation(payload: ISoundLevelObservationPayload): Promise<ISoundLevelObservationModel> {
        return await HttpService.Post<ISoundLevelObservationModel | null, ISoundLevelObservationModel>(ApiUrls.Report.Observation.Create(payload.testName), payload.data ?? null);
    }

    static async EditSLObservation(payload: ISoundLevelObservationPayload): Promise<ISoundLevelObservationModel> {
        return await HttpService.Patch<ISoundLevelObservationModel | null, ISoundLevelObservationModel>(ApiUrls.Report.Observation.Edit(payload.testName, payload?.data?.id ?? 0), payload.data ?? null);
    }

    static async GetSLObservationById(payload: ISoundLevelObservationPayload): Promise<ISoundLevelObservationModel> {
        return await HttpService.Get<ISoundLevelObservationModel>(ApiUrls.Report.Observation.GetById(payload.testName, payload.id ?? 0));
    }

    // === uv light intensity test
    static async CreateUVLIObservation(payload:IUVObservationPayload): Promise<IUVLightTestObservationModel> {
        return await HttpService.Post<IUVLightTestObservationModel | null,IUVLightTestObservationModel>(ApiUrls.Report.Observation.Create(payload.testName), payload.data ?? null);
    }

    static async EditUVLIObservation(payload: IUVObservationPayload): Promise<IUVLightTestObservationModel> {
        return await HttpService.Patch<IUVLightTestObservationModel | null, IUVLightTestObservationModel>(ApiUrls.Report.Observation.Edit(payload.testName, payload?.data?.id ?? 0), payload.data ?? null);
    }

    static async GetUVLIObservationById(payload: IUVObservationPayload): Promise<IUVLightTestObservationModel> {
        return await HttpService.Get<IUVLightTestObservationModel>(ApiUrls.Report.Observation.GetById(payload.testName, payload.id ?? 0));
    }
}
