import { BaseComponent } from "../../../common/component/base.component";
import React from "react";
import { IStageFlowItemModel } from "../model/_common.model";

interface IProps {
    stageFlow?: IStageFlowItemModel[],
    isCompleted: boolean,
    customClass?: string
}
class StageFlowStepperComponent extends BaseComponent<IProps, any> {
    render() {
        return <article className="card no-bg mb-4 stage_flow__container">
            <div className={`card-body ${this?.props?.customClass ?? ""}`}>
                {(() => {
                    if (this.props.stageFlow == null || this.props.stageFlow.length == 0) return;
                    return this.props.stageFlow.map((e, i) => {
                        const isActive = e.isActive && !this.props.isCompleted;
                        const isCompleted = e.isCompleted || this.props.isCompleted;

                        return <React.Fragment key={`header-stage-${i}`}>
                            <div className={`stage_flow__step ${isActive ? 'stage_active' : ''} ${isCompleted ? 'stage_completed' : ''}`}>
                                <div className="step_icon">
                                    {(() => {
                                        if (isActive) {
                                            return <i className="feather icon-target"></i>
                                        }
                                        if (isCompleted) {
                                            return <i className="feather icon-check-circle"></i>
                                        }

                                        return <i className="feather icon-circle"></i>
                                    })()}
                                </div>
                                <p className="step_name">{e.displayName}</p>
                            </div>
                            <div className={`stage_flow__step_indicator_line ${isActive ? 'stage_active' : ''} ${isCompleted ? 'stage_completed' : ''}`}></div>
                        </React.Fragment>
                    })
                })()}
            </div>
        </article>;
    }
}

export default StageFlowStepperComponent;
