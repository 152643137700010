import {IClientModel} from "../model/client.model";
import ApiUrls from "../../../common/api-urls";
import {IFormControlDropdownItemModel} from "../../../common/control/model/form.control.field.model";
import {IGetAllRequestModel} from "../../../common/model/get-all-request-model";
import DropdownDisplayHelper from "../../../common/helper/dropdown-display.helper";
import HttpService from "../../../common/service/http.service";

export default class ClientService {

    static async LoadAll(request?: IGetAllRequestModel): Promise<IClientModel[]> {
        return await HttpService.Get<IClientModel[]>(ApiUrls.Client.GetAll(request));
    }

    static async Create(data: IClientModel): Promise<IClientModel> {
        return await HttpService.Post<IClientModel, IClientModel>(ApiUrls.Client.Create(), data);
    }

    static async Edit(data: IClientModel): Promise<IClientModel> {
        return await HttpService.Patch<IClientModel, IClientModel>(ApiUrls.Client.Edit(data.id), data);
    }
    static async Delete(id: number) {
        await HttpService.Delete(ApiUrls.Client.Delete(id));
   }

    static async GetById(id: number): Promise<IClientModel> {
        return await HttpService.Get<IClientModel>(ApiUrls.Client.GetById(id));
    }

    static async ClientDropdown(search: string): Promise<IFormControlDropdownItemModel[]> {
        let req: IGetAllRequestModel = { searchStr: search };
        return (await ClientService.LoadAll(req)).map(d => {
            const obj: IFormControlDropdownItemModel = {
                label: DropdownDisplayHelper.Client(d),
                value: d.id.toString()
            }
            return obj;
        });
    }
}
