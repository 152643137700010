import {BaseComponent} from "../../../common/component/base.component";
import {IAppState} from "../../../store";
import {connect} from "react-redux";
import {loadDataLoggerMakers} from "../store/data-logger-make.slice";
import {AgGridReact} from "ag-grid-react";
import ScreenUrls from "../../../common/screen-urls";
import {Link} from "react-router-dom";
import {IDataLoggerMakeModel} from "../model/data-logger-make.model";
import {AccessLevel} from "../../../common/enums";
import {AgGridDefaultColDef} from "../../../common/app-defaults";
import DataLoggerMakeService from "../service/data-logger-make.service";
import GridDeleteButtonControl from "../../../common/control/component/grid/grid-delete-buton.control";

export interface IProps {
    accessLevel: number,
    dataLoggerMakeList: IDataLoggerMakeModel[],
    listLoading: boolean,
    loadDataLoggerMakers: () => void
}

class DataLoggerMakePage extends BaseComponent<IProps, any> {
    componentDidMount() {
        this.props.loadDataLoggerMakers();
    }

    render() {
        return <div className="pcoded-content">
            <div className="pcoded-inner-content">
                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    {(() => { if(this.props.listLoading) return <div className="loading--bar"><span></span></div> })()}
                                    <div className="card-header">
                                        <h5>List of makers</h5>
                                        <div className="card-header-right">
                                            <div className="btn-group card-option">
                                                {(() => {
                                                    if(this.props.accessLevel >= AccessLevel.Create) {
                                                        return <Link className="btn drp-icon btn-rounded btn-primary" to={ScreenUrls.DataLoggers.Make.Create()}>
                                                            Add New
                                                        </Link>
                                                    }
                                                })()}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-block ag-theme-alpine xx-large-grid">
                                        <AgGridReact
                                            pagination={true}
                                            paginationAutoPageSize={true}
                                            unSortIcon={true}
                                            rowHeight={60}
                                            defaultColDef={AgGridDefaultColDef}
                                            columnDefs={[
                                                {
                                                    suppressMovable: true,
                                                    hide: this.props.accessLevel < AccessLevel.Edit,
                                                    headerName: "Edit", field: 'id', width: 70, cellClass: "grid-cell grid-cell-link",
                                                    cellRenderer: (params) => {
                                                        return (
                                                            <Link to={ScreenUrls.DataLoggers.Make.Edit(params.value)}>
                                                                <i className={`feather icon-edit`}></i>
                                                            </Link>
                                                        )
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    hide: this.props.accessLevel < AccessLevel.Delete,
                                                    headerName: "Delete", field: 'id', width: 70, cellClass: "grid-cell grid-cell-link delete",
                                                    cellRenderer: (params) => {
                                                        return (
                                                            <GridDeleteButtonControl onDelete={async () => {                                                                 
                                                                await DataLoggerMakeService.Delete(params.value);
                                                                this.props.loadDataLoggerMakers();                                                                                                                                  
                                                             }}></GridDeleteButtonControl>
                                                        )
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "#", field: 'id', width: 100, cellClass: "grid-cell",
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value}</span>
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Name", field: "name", flex: 2, sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value}</span>
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Description", flex: 4, field: "description", sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value}</span>
                                                    }
                                                }
                                            ]}
                                            rowData={this.props.dataLoggerMakeList}>
                                        </AgGridReact>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state: IAppState) => ({
    dataLoggerMakeList: state.dataLoggerMake.dataLoggerMakeList,
    listLoading: state.dataLogger.listLoading
})

export default connect(mapStateToProps, { loadDataLoggerMakers })(DataLoggerMakePage);
