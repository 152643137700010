import { IDataLoggerModel } from "../data-logger.model";
import { IDataLoggerMakeModel } from "../data-logger-make.model";
import { IDataLoggerBookingModel } from "../data-logger-booking.model";
import { IDataLoggerCameraDetailModel } from "../data-logger-camera-detail.model";

export const dataLoggerMakeDefault: IDataLoggerMakeModel = {
    id: 0,
    name: "",
    description: "",
}

export const dataLoggerDefault: IDataLoggerModel = {
    id: 0,
    name: "",
    categoryId: null,
    subCategoryId: null,
    makeId: null,
    make: dataLoggerMakeDefault,
    serialNumber: "",
    modelNumber: "",
    instrumentId: "",
    lastCalibratedOn: "",
    calibrationDueOn: "",
    calibrationCertificateId: 0,
    isDamaged: false,
    isAvailable: true,
    isExpired: false,
}

export const dataLoggerBookingDefault: IDataLoggerBookingModel = {
    id: 0,
    dataLoggers: [],
    workflowTaskId: 0,
    description: ""
}

export const dataLoggerCameraDetailDefault: IDataLoggerCameraDetailModel = {
    id: 0,
    serialNumber: "",
    modelNumber: "",
    cameraName: "",
    make: "",
    cameraId: ""
}