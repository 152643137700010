import { IUserModel } from "../model/user.model";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userDefault } from "../model/defaults/user.default";
import { ILoginReqModel } from "../model/login.req.model";
import { loginReqDefault } from "../model/defaults/login.default";
import { IStoreSaveModel } from "../../../common/model/store-save.model";
import AuthService from "../service/auth.service";
import UserService from "../service/user.service";
import { LocalStorageKeys } from "../../../common/enums";
import moment from "moment";
import { utcToLocalDate } from "../../../common/utils";

export interface IUserSessionState {
    currentUser: IUserModel,
    currentUserLoading: boolean,
    loginReqData: ILoginReqModel,
    isNavCollapsed: boolean,
    loginLoading: boolean,
    isLoggedIn: boolean,
    currentUserFetchFailed: boolean,
    returnUrl: string
}

const initialState: IUserSessionState = {
    currentUser: userDefault,
    loginReqData: {
        ...loginReqDefault,
        email: "",
        password: ""
    },
    isNavCollapsed: false,
    loginLoading: false,
    currentUserLoading: false,
    isLoggedIn: false,
    currentUserFetchFailed: false,
    returnUrl: ""
}

export const loginUser = createAsyncThunk("userSession/login", AuthService.Login);
export const loadCurrentUser = createAsyncThunk("userSession/loadCurrentUser", UserService.LoadCurrentUser);

const userSessionSlice = createSlice({
    name: 'user-session',
    initialState,
    reducers: {
        updateLoginReqDataState: (state, action: PayloadAction<IStoreSaveModel>) => {
            state.loginReqData[action.payload.name] = action.payload.value;
        },
        toggleNavCollapsed: (state) => {
            state.isNavCollapsed = !state.isNavCollapsed;
        },
        logoutUser: (state) => {
            localStorage.removeItem(LocalStorageKeys.AccessToken.toString());
            localStorage.removeItem(LocalStorageKeys.TokenExpiry.toString());
            state.currentUser = userDefault;
            state.isLoggedIn = false;
            state.currentUserLoading = false;
            state.loginLoading = false;
            state.currentUserFetchFailed = false;
        },
        clearCurrentUserDataState: (state) => {
            state.currentUser = userDefault;
            state.isLoggedIn = false;
        },
        setReturnUrl: (state, action: PayloadAction<string>) => {
            state.returnUrl = action.payload;
        }
    },
    extraReducers: (builder) => {

        // Login User
        builder.addCase(loginUser.pending, (state, action) => {
            localStorage.removeItem(LocalStorageKeys.AccessToken.toString());
            localStorage.removeItem(LocalStorageKeys.TokenExpiry.toString());
            state.loginLoading = true;
            state.currentUserFetchFailed = false;
        });
        builder.addCase(loginUser.rejected, (state, action) => {
            localStorage.removeItem(LocalStorageKeys.AccessToken.toString());
            localStorage.removeItem(LocalStorageKeys.TokenExpiry.toString());
            state.loginLoading = false;
            state.isLoggedIn = false;
            state.currentUserFetchFailed = true;
        });
        builder.addCase(loginUser.fulfilled, (state, action) => {
            if (!action.payload || action.payload.accessToken == undefined) {
                state.loginLoading = false;
                state.isLoggedIn = false;
                state.currentUserFetchFailed = true;
            }
            else {
                localStorage.setItem(LocalStorageKeys.AccessToken.toString(), action.payload.accessToken);
                state.loginLoading = false;
                state.isLoggedIn = true;
                state.currentUserFetchFailed = false;
                const tokenExpiry = moment(utcToLocalDate(action.payload.createdOnUtc))
                    .add(moment.duration(action.payload.expiresInHrs, 'hours')).toDate();
                localStorage.setItem(LocalStorageKeys.TokenExpiry.toString(), tokenExpiry.toString());
                state.loginReqData = loginReqDefault;
            }
        });

        // Load Current User
        builder.addCase(loadCurrentUser.pending, (state, action) => {
            clearCurrentUserDataState();
            state.currentUserLoading = true;
            state.currentUserFetchFailed = false;
        });
        builder.addCase(loadCurrentUser.rejected, (state, action) => {
            clearCurrentUserDataState();
            state.currentUserFetchFailed = true;
            state.currentUserLoading = false;
        });
        builder.addCase(loadCurrentUser.fulfilled, (state, action) => {
            state.currentUser = action.payload;
            state.currentUserFetchFailed = false;
            state.currentUserLoading = false;
        });

    }
});

const { actions, reducer } = userSessionSlice;

export const { toggleNavCollapsed, updateLoginReqDataState, logoutUser, clearCurrentUserDataState, setReturnUrl } = actions;

export default reducer;
