import {IClientAddressModel, IClientModel} from "./client.model";

export const clientDefault : IClientModel = {
    id: 0,
    companyName: "",
    description: "",
    panNumber: "",
    gstInNumber: "",
    clientAddresses: []
}

export const clientAddressDefault: IClientAddressModel = {
    id: 0,
    buildingName: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    clientId: 0,
    city: "",
    displayName: "",
    email: "",
    state: "",
    postalCode: "",
    country: "",
    landmark: "",
    extraNote: "",
    faxNo: "",
    website: "",
    contactNo: ""
}
