import {BaseComponent} from "../../../common/component/base.component";
import React from "react";
import {IClientAddressModel} from "../model/client.model";
import ScreenUrls from "../../../common/screen-urls";
import {Link} from "react-router-dom";

export interface IProp {
    accessLevel: number
    data: IClientAddressModel
}
class ClientAddressCardComponent extends BaseComponent<IProp, any> {
    render() {
        return <div className="client-address--card col-3">
            <div className="client-address--link">
                <Link className="edit" to={ScreenUrls.Clients.Addresses.Edit(this.props.data.id, this.props.data.clientId)}>
                    <i className={`feather icon-edit`}></i>
                </Link>
                {/*<Link className="delete" to={ScreenUrls.Clients.Addresses.Edit(this.props.data.id, this.props.data.clientId)}>
                    <i className={`feather icon-trash`}></i>
                </Link>*/}
            </div>
            { this.props.data.displayName != null ? <div className="col-12"> <p className="display-name read-only-heading">{this.props.data.displayName}</p> </div> : '' }
            { this.props.data.buildingName != null ? <div className="col-12"><span>{this.props.data.buildingName}</span></div>: '' }
            { this.props.data.addressLine1 != null ? <div className="col-12"><span>{this.props.data.addressLine1}</span></div>: '' }
            { this.props.data.addressLine2 != null ? <div className="col-12"><span>{this.props.data.addressLine2}</span></div>: '' }
            { this.props.data.addressLine3 != null ? <div className="col-12"><span>{this.props.data.addressLine3}</span></div>: '' }
            { this.props.data.landmark != null ? <div className="col-12"><span>{this.props.data.landmark}</span></div>: '' }
            { this.props.data.city != null ? <div className="col-12"><span>{this.props.data.city}</span></div>: '' }
            { this.props.data.state != null ? <div className="col-12"><span>{this.props.data.state}</span></div>: '' }
            { this.props.data.postalCode != null ? <div className="col-12"><span>{this.props.data.postalCode}</span></div>: '' }
            { this.props.data.country != null ? <div className="col-12"><span>{this.props.data.country}</span></div>: '' }
            { this.props.data.email != null ? <div className="col-12"><span>{this.props.data.email}</span></div>: '' }
            { this.props.data.contactNo != null ? <div className="col-12"><span>{this.props.data.contactNo}</span></div>: '' }
            { this.props.data.faxNo != null ? <div className="col-12"><span>{this.props.data.faxNo}</span></div>: '' }
            { this.props.data.extraNote != null ? <div className="col-12"><span>{this.props.data.extraNote}</span></div>: '' }
        </div>
    }
}


export default ClientAddressCardComponent;
