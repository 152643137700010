import { PureComponent } from "react";
import { IAppState } from "../../store";
import { connect } from "react-redux";
import { IAlert, setHideModal } from "../stores/alertMsg.slice";
import Modal from 'react-modal';
import { createPortal } from 'react-dom';

export interface IProps {
    alert: IAlert;
    setHideModal: (data: boolean) => void,
}

const element: any = document.getElementById('portal-root');

class AlertModalComponent extends PureComponent<IProps, any> {
    constructor(props) {
        super(props);
    }

    render() {
        const { title,show,className,message} = this.props?.alert;
        return (show ? createPortal(<Modal
            isOpen={show}
            onRequestClose={() => this.props.setHideModal(false)}
        >
            <div className={`d-flex f-w-500 flex-column notification ${className}-notification`}>
                <div className="f-18">
                    {title}
                </div>
                <div className="f-16">
                    {message}
                </div>
                <button className="text-capitalize f-w-800 border-0 btn-light btn-sm mt-2 align-self-end" onClick={() => this.props.setHideModal(false)}>close</button>
            </div>
        </Modal>, element) : null)
    }
}

const mapStateToProps = (state: IAppState) => ({
    alert: state.alertMsg,
});

export default connect(mapStateToProps, { setHideModal })(AlertModalComponent);
