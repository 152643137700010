import React, { useEffect, useRef, useState } from "react";

import { Document, Page, Text, View, PDFViewer, Image, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import TestService from "../../../service/test.service";
import { CleanroomEquipmentTests, EUGMPZoneClassificationState, TestObservationResult } from "../../../../../common/enums";
import { ISOConsideredParticleSizeList, ReportTestEquipmentState } from "../../../../../common/_static/report.data";
import { IUserModel } from "../../../../user/model/user.model";
import { ICleanroomEquipmentModel } from "../../../model/report.model";
import { localToUtcDate, utcToLocalDate } from '../../../../../common/utils';
import moment from 'moment';
import { AppConstants } from '../../../../../common/app-constants';
import { displayDate } from "../../../../../common/utils";
import IncepOne from "../../../../../assets/images/incepbio.jpg";
import Location from "../../../../../assets/images/location.png";
import WWW from "../../../../../assets/images/www.png";
import Call from "../../../../../assets/images/call.png";
import { styles } from "./base-test-report-pdf.component";
import { useDispatch } from "react-redux";

import Chart from 'chart.js/auto';
import { Line } from "react-chartjs-2";
import { CategoryScale } from 'chart.js';
import { editRecoveryTest } from "../../../store/cleanroom-equipment/test.slice";
import { IRecoveryTestModel } from "../../../model/test/recovery-test.model";

Chart.register(CategoryScale);

export const RecoveryPDFComponent = ({ data, imgUrl }: { id: number, data: IRecoveryTestModel, imgUrl: string }) => {
    return (
        <Document>
            <Page size="A4" style={styles.page} wrap>
                {/* ---- header ------ */}
                <View style={{ ...styles.paddingY }} fixed>
                    <View style={{ ...styles.section, ...styles.flexBox, alignItems: 'center' }}>
                        <view style={{ ...styles.flex_1 }}>
                            <Image src={IncepOne} style={{ ...styles.image }} />
                        </view>
                        <view style={{ ...styles.flex_2 }}>
                            <View style={{ backgroundColor: '#009C8D', width: '100%', height: '8', position: 'relative' }}>
                                <View style={{ backgroundColor: '#004D88', position: 'absolute', width: '30%', height: '100%', right: 0 }}></View>
                                <View style={{ backgroundColor: '#FFF', position: 'absolute', width: '2%', height: '200%', right: '29%', transform: 'rotate(-20deg)', top: '-50%' }}></View>
                            </View>
                        </view>
                    </View>
                    <View style={{ ...styles.section, ...styles.font_12, marginTop: 2 }}>
                        <Text style={styles.bold}>An ISO 9001 : 2015 Certified Company</Text>
                    </View>
                </View>

                {/* ---- body ---- */}
                <View style={{ ...styles.section }}>
                    <View style={{ ...styles.main }}>
                        <View style={{ ...styles.paddingTB }}>
                            <Text style={{ ...styles.reportTitle, ...styles.bold }}>Test Report For Recovery</Text>
                        </View>
                        <View style={{ ...styles.paddingTB }}>
                            <View style={{ ...styles.flexBox, }}>
                                <View style={{ ...styles.flex_2, ...styles.col }}>
                                    <Text style={{ ...styles.label, ...styles.bold }}>Customer Details :</Text>
                                </View>
                                <view style={{ ...styles.flexBox, ...styles.flex_1 }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{ ...styles.label }}>Report Number</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{ ...styles.label, ...styles.bold }}>
                                            {data?.reportNumber ?? ''}
                                        </Text>
                                    </View>
                                </view>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_2, ...styles.col }}>
                                    <Text style={{}}>
                                        {data?.client?.companyName} {"\n"}
                                        {data?.clientAddress?.addressLine1}
                                        {data?.clientAddress?.addressLine2 ? `, ${data?.clientAddress?.addressLine2}` : ""}
                                        {"\n"}
                                        {data.clientAddress?.city}, {data.clientAddress?.state}
                                    </Text>
                                </View>
                                <view style={{ ...styles.flex_1 }}>
                                    <View style={{ ...styles.flexBox, flex: 1, ...styles.flexColumn, }} >
                                        <View style={{ ...styles.flexBox, flex: 1 }}>
                                            <View style={{ ...styles.label, ...styles.col }}>
                                                <Text style={{ ...styles.label }}>Date of test</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col }}>
                                                <Text style={{ ...styles.label }}>{displayDate(data.dateOfTest ?? "")}</Text>
                                            </View>
                                        </View>
                                        <View style={{ ...styles.flexBox, flex: 1 }}>
                                            <View style={{ ...styles.label, ...styles.col }}>
                                                <Text style={{ ...styles.label }}>Due Date</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col }}>
                                                <Text style={{ ...styles.label }}>{data?.dueDate ? displayDate(data.dueDate) : ""}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </view>
                            </View>
                        </View>

                        {/* ----  Equipnet details --- */}
                        <View style={{ ...styles.paddingTB }}>
                            <Text style={{ ...styles.font_12, ...styles.bold }}>Details Of Equipment :</Text>
                        </View>

                        <View style={{}}>
                            {/* --- fixed allignment --- */}
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{}}>Equipment Name
                                        </Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>{data.clientEquipment?.equipmentName ?? ''}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Area Of Test</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data.areaOfTest ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{}}>Equipment Id</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>
                                            {data.clientEquipment?.equipmentId ?? ""}
                                        </Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Location</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.location ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{}}>Make</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>{data.clientEquipment?.make ?? ""}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Filter Type</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.filterType ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{}}>Model Number</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>{data?.clientEquipment?.modelNumber ?? ""}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Filter Efficiency</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.filterEfficiency ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{}}>Serial Number</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>{data?.clientEquipment?.serialNumber ?? ""}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Zone Classification</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data.eugmpStandard?.id ? data.eugmpStandard.grade : data.isoStandard?.id ? data.isoStandard?.classNumber : ""}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>

                        {/* ---- data logger details --- */}
                        <View style={{ ...styles.paddingTB }}>
                            <View style={{}}>
                                <Text style={{ ...styles.font_12, ...styles.bold }}>Details Of Standard :</Text>
                            </View>

                            <View style={{ ...styles.paddingTB }}>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.flexBox, ...styles.col }}>
                                        <Text style={{}}>Name</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox, ...styles.col }}>
                                        <Text style={{}}>{data?.dataLogger?.name ?? ""}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col }}>
                                                <Text style={{}}>Model Number</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col }}>
                                                <Text style={{}}>{data.dataLogger?.modelNumber ?? ""}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col }}>
                                                <Text style={{}}>Make</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col }}>
                                                <Text style={{}}>{data.dataLogger?.make?.name ?? ""}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Instrument Id</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.dataLogger?.instrumentId ?? ""}</Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Serial Number</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.dataLogger?.serialNumber ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Calibration Done Date</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{displayDate(data.dataLogger?.lastCalibratedOn)}</Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col }}>
                                                <Text style={{}}>Calibration Due Date</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col }}>
                                                <Text style={{}}>
                                                    {displayDate(data?.dataLogger?.calibrationDueOn)}
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.col, textAlign: "center" }}>

                                    <Text style={{ ...styles.bold }}>Protocol Name</Text>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.col, textAlign: "center" }}>

                                    <Text style={{ ...styles.bold }}>Protocol Number</Text>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.col, textAlign: "center" }}>
                                    <Text style={{}}>{data?.refStandardOrProtocolOrDocumentName ?? ""}</Text>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.col, textAlign: "center" }}>
                                    <Text style={{}}>{data?.procedureOrProtocolOrDocumentNumber ?? ""}</Text>
                                </View>
                            </View>
                        </View>

                        <View style={{ ...styles.paddingTB }} wrap={false}>
                            <Text style={{ ...styles.font_12, ...styles.bold }}>Sampling Details :</Text>
                        </View>
                        <View style={{}}>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Occupancy State
                                            </Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{ReportTestEquipmentState?.find((item) => item.value == data?.occupancyState?.toString())?.label}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Considered Particle sizes
                                            </Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data.consideredParticleSizes ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{}}>Number of Sampling Location
                                        </Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>{data?.noOfSamplingLocation ?? ""}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{}}>Air Volume Sampled per Location

                                        </Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>{data?.airVolumeSampledPerLocation ?? ""}</Text>
                                    </View>
                                </View>
                            </View>

                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{}}>Location Id
                                        </Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>{data?.locationId ?? ""}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{}}>Sampling Time at each Location
                                        </Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>{data?.samplingTimeAtEachLocation ?? ""}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.col }}>
                                    <Text style={{ ...styles.textCenter, ...styles.bold }}>
                                        Maximum allowable concentrations (particles / m3) for particles equal to and greater than the considered sizes
                                    </Text>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.col }}>
                                    <Text style={{}}> Zone Classification
                                    </Text>
                                </View>

                                {
                                    ISOConsideredParticleSizeList?.filter((item) => data?.consideredParticleSizes?.split(",")?.includes(item.label))?.map((item, index) => {
                                        return (<View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text>{item.label}</Text>
                                        </View>)
                                    })
                                }
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.col }}>
                                    <Text style={{}}>
                                        {
                                            data?.eugmpStandard ?
                                                `Grade ${data?.eugmpStandard?.grade}`
                                                :
                                                data?.isoStandard?.classNumber
                                        }
                                    </Text>
                                </View>
                                {
                                    data?.consideredParticleSizes?.includes("0.5um") ?
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text> {
                                                data?.eugmpStandard ?
                                                    EUGMPZoneClassificationState?.AtRest == data?.occupancyState ?
                                                        data?.eugmpStandard?.pointFiveOrMoreMicronCubicMetre_AtRest ?? "Not specified"
                                                        :
                                                        data?.eugmpStandard?.pointFiveOrMoreMicronCubicMetre_InOperation ?? "Not specified"
                                                    :
                                                    data?.isoStandard?.pointFiveMicron ?? "Not specified"
                                            }
                                            </Text>
                                        </View>
                                        :
                                        ""
                                }
                                {
                                    data?.consideredParticleSizes?.split(",")?.includes("5um") ?
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text>
                                                {
                                                    data?.eugmpStandard ?
                                                        EUGMPZoneClassificationState?.AtRest == data?.occupancyState ?
                                                            data?.eugmpStandard?.fiveOrMoreMicronCubicMetre_AtRest ?? "Not specified"
                                                            :
                                                            data?.eugmpStandard?.fiveOrMoreMicronCubicMetre_InOperation ?? "Not specified"
                                                        :
                                                        data?.isoStandard?.fiveMicron ?? "Not specified"
                                                }
                                            </Text>
                                        </View>
                                        :
                                        ""
                                }
                            </View>
                        </View>

                        <View style={{ ...styles.paddingTB }} wrap={false}>
                            <View style={{ ...styles.flexBox }}>
                                <Text style={{ ...styles.flex_1, ...styles.col, ...styles.bold, textAlign: 'center' }}>OBTAINED RESULTS
                                </Text>
                            </View>
                            <View style={{ ...styles.flexBox, ...styles.bold }}>
                                <View style={{ width: 40, ...styles.col }}>
                                    <Text style={{}}> Sr No
                                    </Text>
                                </View>
                                <View style={{ width: 100, ...styles.col }}>
                                    <Text style={{}}> Descriptions
                                    </Text>
                                </View>
                                <View style={{ width: 120, ...styles.col }}>
                                    <Text style={{}}> Time (HH:MM:SS)
                                    </Text>
                                </View>
                                {
                                    ISOConsideredParticleSizeList?.filter((item) => data?.consideredParticleSizes?.split(",")?.includes(item.label))?.map((item, index) => {
                                        return (<View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text> Number of particles / m3 ( {">="} {item.label})
                                            </Text>
                                        </View>)
                                    })
                                }
                            </View>

                            <View style={{ ...styles.flexBox }}>
                                <View style={{ width: 40, ...styles.col }}>
                                    <Text style={{}}> 1
                                    </Text>
                                </View>
                                <View style={{ width: 100, ...styles.col }}>
                                    <Text style={{}}> Initial Reading
                                    </Text>
                                </View>
                                <View style={{ width: 120, ...styles.col }}>
                                    <Text style={{}}>
                                        {
                                            data?.initialReadingTime ? moment(utcToLocalDate(data?.initialReadingTime))?.format(AppConstants.TimeFormat) : ""
                                        }
                                    </Text>
                                </View>
                                {
                                    ISOConsideredParticleSizeList?.filter((item) => data?.consideredParticleSizes?.split(",")?.includes(item.label))?.map((item, index) => {
                                        return (<View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text>
                                                {
                                                    (() => {
                                                        if (item.label == '0.5um') {
                                                            return data?.initialReadingAtPointFiveParticleSize ?? ""
                                                        }
                                                        if (item.label == '1um') {
                                                            return data?.initialReadingAtOneParticleSize ?? ""
                                                        }
                                                        if (item.label == '5um') {
                                                            return data?.initialReadingAtFiveParticleSize ?? ""
                                                        }
                                                    })()
                                                }
                                            </Text>
                                        </View>)
                                    })
                                }
                            </View>

                            <View style={{ ...styles.flexBox }}>
                                <View style={{ width: 40, ...styles.col }}>
                                    <Text style={{}}> 2
                                    </Text>
                                </View>
                                <View style={{ width: 100, ...styles.col }}>
                                    <Text style={{}}> Worst Reading
                                    </Text>
                                </View>
                                <View style={{ width: 120, ...styles.col }}>
                                    <Text style={{}}>
                                        {
                                            data?.worstReadingTime ? moment(utcToLocalDate(data?.worstReadingTime))?.format(AppConstants.TimeFormat) : ""
                                        }

                                    </Text>
                                </View>
                                {
                                    ISOConsideredParticleSizeList?.filter((item) => data?.consideredParticleSizes?.split(",")?.includes(item.label))?.map((item, index) => {
                                        return (<View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text>
                                                {
                                                    (() => {
                                                        if (item.label == '0.5um') {
                                                            return data?.worstReadingAtPointFiveParticleSize ?? ""
                                                        }
                                                        if (item.label == '1um') {
                                                            return data?.worstReadingAtOneParticleSize ?? ""
                                                        }
                                                        if (item.label == '5um') {
                                                            return data?.worstReadingAtFiveParticleSize ?? ""
                                                        }
                                                    })()
                                                }
                                            </Text>
                                        </View>)
                                    })
                                }
                            </View>

                            <View style={{ ...styles.flexBox }}>
                                <View style={{ width: 40, ...styles.col }}>
                                    <Text style={{}}> 3
                                    </Text>
                                </View>
                                <View style={{ width: 100, ...styles.col }}>
                                    <Text style={{}}> Recovery Reading
                                    </Text>
                                </View>
                                <View style={{ width: 120, }}>
                                    {
                                        data?.recoveryReadings?.map((item) => {
                                            return (<View style={{ ...styles.col }}>
                                                <Text style={{}}>
                                                    {
                                                        item?.recoveryTime ? moment(utcToLocalDate(item?.recoveryTime))?.format(AppConstants.TimeFormat) : ""
                                                    }

                                                </Text>
                                            </View>)
                                        })
                                    }
                                </View>
                                {

                                    ISOConsideredParticleSizeList?.filter((item) => data?.consideredParticleSizes?.split(",")?.includes(item.label))?.map((item, index) => {
                                        return (<View style={{ ...styles.flex_1, ...styles.flexBox, ...styles.flexColumn }}>
                                            {
                                                data?.recoveryReadings?.map((item2) => {
                                                    return (
                                                        <View style={{ ...styles.col, ...styles.flex_1 }}>
                                                            <Text>
                                                                {
                                                                    (() => {
                                                                        if (item.label == '0.5um') {
                                                                            return item2?.recoveryReadingAtPointFiveParticleSize ?? ""
                                                                        }
                                                                        if (item.label == '1um') {
                                                                            return item2?.recoveryReadingAtOneParticleSize ?? ""
                                                                        }
                                                                        if (item.label == '5um') {
                                                                            return item2?.recoveryReadingAtFiveParticleSize ?? ""
                                                                        }
                                                                    })()
                                                                }
                                                            </Text>
                                                        </View>
                                                    )
                                                })
                                            }
                                        </View>)
                                    })
                                }


                            </View>

                            <View style={{ ...styles.flexBox }}>
                                <View style={{ width: 40, ...styles.col }}>
                                    <Text style={{}}> 4
                                    </Text>
                                </View>
                                <View style={{ width: 100, ...styles.col }}>
                                    <Text style={{}}> Final Reading
                                    </Text>
                                </View>
                                <View style={{ width: 120, ...styles.col }}>
                                    <Text style={{}}>
                                        {
                                            data?.finalReadingTime ? moment(utcToLocalDate(data?.finalReadingTime))?.format(AppConstants.TimeFormat) : ""
                                        }
                                    </Text>
                                </View>
                                {
                                    ISOConsideredParticleSizeList?.filter((item) => data?.consideredParticleSizes?.split(",")?.includes(item.label))?.map((item, index) => {
                                        return (<View style={{ ...styles.flex_2, ...styles.col }}>
                                            <Text>
                                                {
                                                    (() => {
                                                        if (item.label == '0.5um') {
                                                            return data?.finalReadingAtPointFiveParticleSize ?? ""
                                                        }
                                                        if (item.label == '1um') {
                                                            return data?.finalReadingAtOneParticleSize ?? ""
                                                        }
                                                        if (item.label == '5um') {
                                                            return data?.finalReadingAtFiveParticleSize ?? ""
                                                        }
                                                    })()
                                                }
                                            </Text>
                                        </View>)
                                    })
                                }
                            </View>

                            <View style={{ ...styles.flexBox }}>
                                <View style={{ width: 140, ...styles.col }}>
                                    <Text style={{}}> Acceptance Criteria
                                    </Text>
                                </View>


                                <View style={{ ...styles.col, ...styles.flex_2 }}>
                                    <Text style={{}}>
                                        {
                                            (() => {
                                                if (data.acceptanceCriteriaMin && data.acceptanceCriteriaMax) {
                                                    return `${data.acceptanceCriteriaMin} to ${data.acceptanceCriteriaMax} Minutes`;
                                                } else if (data.acceptanceCriteriaMin && !data.acceptanceCriteriaMax) {
                                                    return `NLT ${data.acceptanceCriteriaMin} Minutes`;
                                                } else if (data.acceptanceCriteriaMax && !data.acceptanceCriteriaMin) {
                                                    return `NMT ${data.acceptanceCriteriaMax} Minutes`;
                                                }
                                                return "";

                                            })()
                                        }

                                    </Text>
                                </View>
                            </View>

                            <View style={{ ...styles.flexBox }}>
                                <View style={{ width: 140, ...styles.col }}>
                                    <Text style={{}}> Complies
                                    </Text>
                                </View>


                                <View style={{ ...styles.col, ...styles.flex_2 }}>
                                    <Text>
                                        {
                                            data?.result == TestObservationResult.Pass ? TestObservationResult[TestObservationResult.Pass] :
                                                data?.result == TestObservationResult.Fail ? TestObservationResult[TestObservationResult.Fail] : "NA"}

                                    </Text>
                                </View>
                            </View>
                        </View>


                        <View style={{ ...styles.paddingTB }} wrap={false}>
                            <View style={{ ...styles.flexBox, ...styles.flexColumn, alignItems: "center", ...styles.col }}>
                                <Text style={{ ...styles.flex_1, ...styles.bold, textAlign: 'center' }}>RECOVERY PERFORMANCE GRAPH
                                </Text>
                                <View style={{ width: "80%", ...styles.paddingY }}>
                                    <Image src={imgUrl}></Image>
                                </View>
                            </View>
                        </View>

                        {/* --- result --- */}
                        <View style={{ ...styles.paddingTB, ...styles.font_12 }} wrap={false}>
                            <View style={{ ...styles.col, border: 0 }}>
                                <Text style={{ ...styles.bold }}>Conclusion :</Text>
                            </View>
                            <View style={{ ...styles.col, border: 0 }}>
                                <Text>{data.remarks ?? ""}</Text>
                            </View>
                        </View>

                        {/* ---- signature ---- */}
                        <View style={{ ...styles.paddingTB, ...styles.font_12 }} wrap={false}>
                            <View style={{ ...styles.flexBox, ...styles.bold }}>
                                <View style={{ ...styles.flex_2, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text>Prepared By</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>Checked By</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>Approved By</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.font_12, ...styles.flexBox }}>
                                <View style={{ ...styles.flex_2, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>Name</Text>
                                        </View>
                                        <View style={{ ...styles.flex_2, ...styles.col }}>
                                            <Text style={{}}>
                                                {data.preparedBy?.firstName} {data.preparedBy?.lastName}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>Name</Text>
                                        </View>
                                        <View style={{ ...styles.flex_2, ...styles.col }}>
                                            <Text style={{}}>{data.checkedBy?.firstName} {data.checkedBy?.lastName}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>Name</Text>
                                        </View>
                                        <View style={{ ...styles.flex_2, ...styles.col }}>
                                            <Text style={{}}>{data.verifiedByName}</Text>
                                        </View>
                                    </View>
                                </View>

                            </View>
                            <View style={{ ...styles.font_12, ...styles.flexBox }}>
                                <View style={{ ...styles.flex_2, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>Signature</Text>
                                        </View>
                                        <View style={{ ...styles.flex_2, ...styles.col }}>
                                            <Text style={{}}>

                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>Signature</Text>
                                        </View>
                                        <View style={{ ...styles.flex_2, ...styles.col }}>
                                            <Text style={{}}></Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>Signature</Text>
                                        </View>
                                        <View style={{ ...styles.flex_2, ...styles.col }}>
                                            <Text style={{}}></Text>
                                        </View>
                                    </View>
                                </View>
                            </View>

                            <View style={{ ...styles.font_12, ...styles.flexBox }}>
                                <View style={{ ...styles.flex_2, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>Date</Text>
                                        </View>
                                        <View style={{ ...styles.flex_2, ...styles.col }}>
                                            <Text style={{}}>
                                                {data?.preparedOnDate ? displayDate(data?.preparedOnDate) : ""}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>

                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>Date</Text>
                                        </View>
                                        <View style={{ ...styles.flex_2, ...styles.col }}>
                                            <Text style={{}}>
                                                {data?.checkedOnDate ? displayDate(data?.checkedOnDate) : ""}
                                            </Text>
                                        </View>

                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>Date</Text>
                                    </View>
                                    <View style={{ ...styles.flex_2, ...styles.col }}>
                                        <Text >
                                            {data.verifiedOnDate ? displayDate(data.verifiedOnDate) : ""}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.font_12, ...styles.flexBox, ...styles.bold }}>
                                <View style={{ ...styles.flex_2 }}>
                                    <View style={{ width: "100%", textAlign: 'center', ...styles.col }}>
                                        <Text style={{}}>For IncepBio Private Limited</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, }}>
                                    <View style={{ width: "100%", textAlign: 'center', ...styles.col }}>
                                        <Text style={{}}>For {data?.client?.companyName}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>

                {/* --- footer ---- */}
                <View wrap={false} style={{ ...styles.paddingY, fontWeight: 900, ...styles.section, ...styles.font_12 }} fixed>
                    <View>
                        <Text style={{ textAlign: 'center', ...styles.paddingTB }} render={({ pageNumber, totalPages }) => {
                            return (`Page ${pageNumber} of ${totalPages}`);
                        }} fixed />
                    </View>
                    <View style={{ color: "#004D88", }}>
                        <Text style={{ ...styles.font_13, ...styles.bold }}>Incepbio Private Limited</Text>
                    </View>
                    <View style={{ ...styles.paddingTB, ...styles.footer, ...styles.flexBox, width: "80%", alignItems: 'center' }}>
                        <Image src={Location} style={{ ...styles.footerImage }}></Image>
                        <Text style={{}}>
                            #24, 22nd Main, Marenahalli, J.P. Nagar 2nd Phase, Bengaluru - 560078
                        </Text>
                        <Text style={{ width: 20, height: 20, borderRight: '4px solid #FFF', borderBottom: '4px solid #FFF', transform: 'rotate(-45deg)' }}>

                        </Text>
                        <Image src={Call} style={{ marginLeft: 8, ...styles.footerImage }}></Image>
                        <Text>+91-63669 42391</Text>
                    </View>

                    <View style={{ ...styles.flexBox, ...styles.paddingTB, alignItems: 'center' }}>
                        <Image src={WWW} style={{ ...styles.footerImage, width: 18 }}></Image>
                        <Text style={{ ...styles.bold }}>
                            support@incepbio.com
                        </Text>
                    </View>
                </View>
            </Page >
        </Document >
    )
}

export interface IProps {
    data: ICleanroomEquipmentModel,
    id: number,
    currentUser: IUserModel
}

const RecoveryTestPDFComponent = ({ id, data, currentUser }: IProps) => {
    const dispatch: any = useDispatch();
    const graphRef: any = useRef();
    const [state, setState]: any = useState(null);

    const [testData, setTestData]: any = useState(null);
    const downloadPdf = async () => {
        const fileName = 'recovery-test.pdf';
        let imgUrl = graphRef?.current?.canvas?.toDataURL();
        const blob = await pdf(<RecoveryPDFComponent id={testData?.id} data={{ ...data, ...testData }} imgUrl={imgUrl} />).toBlob();
        saveAs(blob, fileName);
        let date = moment(new Date()).toString();
        dispatch(editRecoveryTest({
            testName: CleanroomEquipmentTests.recovery, data:
            {
                ...testData,
                editLocked: true,
                reportPdfGeneratedById: currentUser?.id,
                reportPdfGeneratedOn: localToUtcDate(date),
            },
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            let res: any;
            try {
                if (id) {
                    res = await TestService.GetRecoveryTestById({ testName: CleanroomEquipmentTests.recovery, id });
                    setTestData(res);
                    if (res?.id) {
                        const getLables = () => {
                            let arr: any = [];
                            if (res?.initialReadingTime) {
                                let time = moment(utcToLocalDate(res?.initialReadingTime)).format(AppConstants.TimeFormat)?.toString();
                                if (time) arr.push(time);
                            }
                            if (res?.worstReadingTime) {
                                let time = moment(utcToLocalDate(res?.worstReadingTime)).format(AppConstants.TimeFormat).toString();
                                if (time) arr.push(time);
                            }
                            if (res?.recoveryReadings?.length > 0) {
                                let response = res?.recoveryReadings?.map((item) => moment(utcToLocalDate(item?.recoveryTime)).format(AppConstants.TimeFormat)?.toString())
                                arr = [...arr, ...response];
                            }
                            if (res?.finalReadingTime) {
                                let time = moment(utcToLocalDate(res?.finalReadingTime)).format(AppConstants.TimeFormat);
                                if (time) arr.push(time.toString());
                            }
                            return arr;
                        };

                        let colors = [
                            "rgb(0, 30, 89)",
                            "rgb(255, 99, 132)",
                            "rgb(0, 175, 79)",
                        ];

                        const getDataSets = () => {
                            let arr: any = [];
                            let particleSizes = res?.consideredParticleSizes?.split(",");
                            const getReadings = (particle: string) => {
                                let arr: any = [];
                                if (particle == '0.5um') {
                                    if (res?.initialReadingAtPointFiveParticleSize) {
                                        arr.push(res?.initialReadingAtPointFiveParticleSize)
                                    }
                                    if (res?.worstReadingAtPointFiveParticleSize) {
                                        arr.push(res?.worstReadingAtPointFiveParticleSize)
                                    }
                                    if (res?.recoveryReadings?.length > 0) {
                                        let response = res?.recoveryReadings?.map((item) => item.recoveryReadingAtPointFiveParticleSize)
                                        arr = [...arr, ...response];
                                    }
                                    if (res?.finalReadingAtPointFiveParticleSize) {
                                        arr.push(res?.finalReadingAtPointFiveParticleSize)
                                    }
                                    return arr;
                                }
                                else if (particle == '1um') {
                                    if (res?.initialReadingAtOneParticleSize) {
                                        arr.push(res?.initialReadingAtOneParticleSize)
                                    }
                                    if (res?.worstReadingAtOneParticleSize) {
                                        arr.push(res?.worstReadingAtOneParticleSize)
                                    }
                                    if (res?.recoveryReadings?.length > 0) {
                                        let response = res?.recoveryReadings?.map((item) => item.recoveryReadingAtOneParticleSize)
                                        arr = [...arr, ...response];
                                    }
                                    if (res?.finalReadingAtOneParticleSize) {
                                        arr.push(res?.finalReadingAtOneParticleSize)
                                    }
                                    return arr;
                                }
                                else if (particle == '5um') {
                                    if (res?.initialReadingAtFiveParticleSize) {
                                        arr.push(res?.initialReadingAtFiveParticleSize)
                                    }
                                    if (res?.worstReadingAtFiveParticleSize) {
                                        arr.push(res?.worstReadingAtFiveParticleSize)
                                    }
                                    if (res?.recoveryReadings?.length > 0) {
                                        let response = res?.recoveryReadings?.map((item) => item.recoveryReadingAtFiveParticleSize)
                                        arr = [...arr, ...response];
                                    }
                                    if (res?.finalReadingAtFiveParticleSize) {
                                        arr.push(res?.finalReadingAtFiveParticleSize)
                                    }
                                    return arr;
                                }
                            };

                            if (particleSizes?.length > 0) {
                                arr = particleSizes?.map((item, index) => {
                                    return {
                                        label: item,
                                        backgroundColor: colors[index],
                                        borderColor: colors[index],
                                        data: getReadings(item),
                                    }
                                })
                            }
                            return arr;
                        };
                        let dataSet = {
                            labels: getLables(),
                            datasets: getDataSets(),
                        };
                        setState(dataSet);
                    }
                }
            } catch (errr) {
            }
        }
        fetchData();
    }, [])

    return (<React.Fragment>
        {/* <PDFViewer width={'100%'}>
            <RecoveryPDFComponent data={{ ...data, ...res }} imgUrl={imgUrl} />
        </PDFViewer> */}
        <div style={{ position: "absolute", left: '-9999px', top: '-9999px' }}>
            {
                state ? <Line ref={graphRef} id={"recoveryGraph"} data={state} /> : ""
            }
        </div>
        <button className="btn m-0 btn-primary px-3 py-2" onClick={downloadPdf}>Download PDF</button>
    </React.Fragment>)
}

export default RecoveryTestPDFComponent;




