import { PureComponent, createRef } from "react";
import { IAppState } from "../../store";
import { connect } from "react-redux";
import Modal from 'react-modal';
import { IStageAssignment, setHideModal } from "../stores/stage-assignment.slice";
import AsyncSelect from "react-select/async";
import { DeferredLoadOptions } from "../../common/utils";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import ValidationMessageControl from "../../common/control/validation-message.control";
import { IFormControlDropdownItemModel } from "../control/model/form.control.field.model";

export interface IProps {
    children?: any,
    stageAssignment: IStageAssignment,
    setHideModal: () => void,
    heading: string,
    proceedCallBack?: (obj: any) => void,
    rejectCallBack?: (obj: any) => void,
}

const ModalSchema = Yup.object().shape({
    selectedValue: Yup.string().required("Required"),
});

class StageAssignmentModalComponent extends PureComponent<IProps, any> {
    inputRef: any = createRef();
    constructor(props) {
        super(props);
    }

    // ---- load dropdown options
    loadOption = (inputValue: string) => DeferredLoadOptions(inputValue, async (inputValue: string) => {
        return this.props?.stageAssignment?.optionsList && this.props?.stageAssignment?.optionsList?.length > 0 ? this.props.stageAssignment?.optionsList?.map(item => {
            let obj: IFormControlDropdownItemModel = {
                label: item["firstName"] + " " + item["lastName"],
                value: typeof (item["id"]) == "string" ? item["id"] : item["id"]?.toString(),
            }
            return obj;
        }) : []
    });

    render() {
        const { heading, proceedCallBack, rejectCallBack } = this.props;
        return (<Modal
            isOpen={this.props.stageAssignment.openModal}
            onRequestClose={() => this.props.setHideModal()}
        >
            <div className={`f-w-500 stage-assignment-modal`}>
                <p className="">{heading}</p>
                <Formik
                    initialValues={{
                        selectedValue: ""
                    }}
                    validationSchema={ModalSchema}
                    onSubmit={(
                        values,
                        { setSubmitting }
                    ) => {

                    }}
                >
                    {(formik) => (
                        <Form className="d-flex flex-column">
                            <div>
                                <Field name="selectedValue">
                                    {
                                        ({ field, form }) =>
                                            <div className={`form-group ${formik.errors?.selectedValue ? "error" : ""}`}>
                                                <label htmlFor="selectedValue">Assignee<span className="requried-span">*</span></label>
                                                <AsyncSelect id="selectedValue" cacheOptions defaultOptions ref={this.inputRef}
                                                    className="async-select-control custom-class"
                                                    loadOptions={this.loadOption}
                                                    value={formik.values.selectedValue ? this.inputRef.current?.props?.options?.find((item) => item?.value == formik.values?.selectedValue) : { label: "Select ...", value: "" }}
                                                    onChange={(data) => {
                                                        formik.setFieldValue("selectedValue", data ? data?.value : null);
                                                        formik.validateField("selectedValue");
                                                    }}
                                                    styles={{
                                                        menuList: base => ({
                                                            ...base,
                                                            maxHeight: 240,
                                                        })
                                                    }}
                                                    onBlur={() => {
                                                        if (!formik.touched?.selectedValue) {
                                                            formik.setFieldTouched("selectedvalue", true);
                                                        }
                                                    }}
                                                />
                                                {
                                                    formik.errors?.selectedValue &&
                                                    <ValidationMessageControl message={formik.errors?.selectedValue ?? ""} />
                                                }

                                            </div>

                                    }

                                </Field>

                            </div>
                            <button
                                type="button"
                                className="btn btn-primary align-self-end mr-0 mb-0"
                                onClick={async () => {
                                    const result = await formik.validateForm();
                                    if (Object.keys(result)?.length == 0) {
                                        let obj: any = this.props?.stageAssignment?.optionsList?.find((item: any) => item.id == formik.values?.selectedValue);
                                        let payload = {
                                            id: parseInt(obj.id),
                                            data: obj,
                                        }
                                        if (this.props.stageAssignment.proceedBtnClicked && proceedCallBack) proceedCallBack(payload);
                                        if (!this.props.stageAssignment.proceedBtnClicked && rejectCallBack) rejectCallBack(payload);
                                        this.props.setHideModal();
                                    }
                                }}

                            >
                                Submit
                            </button>
                        </Form>
                    )}

                </Formik>
            </div>
        </Modal>)
    }
}

const mapStateToProps = (state: IAppState) => ({
    stageAssignment: state.stageAssignment,
});

export default connect(mapStateToProps, { setHideModal })(StageAssignmentModalComponent);
