import { connect } from "react-redux";
import { IPurchaseOrderModel } from "../model/purchase-order.model";
import { BaseComponent } from "../../../common/component/base.component";
import React from "react";
import { loadPurchaseOrderById, watchPurchaseOrder } from "../store/purchase-order.slice";
import { IAppState } from "../../../store";
import { Link } from "react-router-dom";
import ScreenUrls from "../../../common/screen-urls";
import { displayDate } from "../../../common/utils";
import DropdownDisplayHelper from "../../../common/helper/dropdown-display.helper";
import { AgGridReact } from "ag-grid-react";
import { PoType } from "../../../common/enums";
import { AgGridDefaultColDef } from "../../../common/app-defaults";
import { IAccessRoleModel } from "../../access/model/access-role.model";

export interface IProps {
    accessLevel: number,
    accessRoles: IAccessRoleModel[],
    id?: string,
    purchaseOrderData: IPurchaseOrderModel,
    loadPurchaseOrderById: (id: number) => void,
    dataLoading: boolean,
    watchPurchaseOrder: (query: { id: number, watching: boolean }) => void,
}

class PurchaseOrderViewPage extends BaseComponent<IProps, any> {

    constructor(props) {
        super(props);
        this.state = {
            hideForm: false,
        };
    }
    componentDidMount() {
        if (this.props.id != null && Number(this.props.id) > 0) {
            this.props.loadPurchaseOrderById(Number(this.props.id));
        }
    }

    getPOLineItemsColDefs() {
        let array = [
            {
                suppressMovable: true,
                headerName: "Code", field: "service",
                flex: 1, sortable: true, cellClass: "grid-cell",
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                    if (valueA?.code == valueB?.code) return 0;
                    return (valueA?.code > valueB?.code) ? 1 : -1;
                },
                cellRenderer: (params) => <span>{params.value?.code}</span>
            },
            {
                suppressMovable: true,
                headerName: "Service", field: "service",
                flex: 3, sortable: true, cellClass: "grid-cell",
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                    if (valueA?.title == valueB?.title) return 0;
                    return (valueA?.title > valueB?.title) ? 1 : -1;
                },
                cellRenderer: (params) => <span>{params.value?.title}</span>
            },
            {
                suppressMovable: true,
                headerName: "HSN/SAC", field: "service",
                flex: 1.5, sortable: true, cellClass: "grid-cell",
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                    if (valueA?.hsnSacCode == valueB?.hsnSacCode) return 0;
                    return (valueA?.hsnSacCode > valueB?.hsnSacCode) ? 1 : -1;
                },
                cellRenderer: (params) => <span>{params.value?.hsnSacCode}</span>
            },
            {
                suppressMovable: true,
                headerName: "Type", field: "type",
                flex: 1.5, sortable: true, cellClass: "grid-cell",
                cellRenderer: (params) => <span>{PoType[Number(params.value)]}</span>
            },
            {
                suppressMovable: true,
                headerName: "Description", field: "description",
                flex: 3, sortable: true, cellClass: "grid-cell",
                cellRenderer: (params) => <span>{params.value}</span>
            },
            {
                suppressMovable: true,
                headerName: "Total Qty", field: "quantity",
                flex: 1, sortable: true, cellClass: "grid-cell",
                cellRenderer: (params) => <span>{params.value}</span>
            },
            {
                suppressMovable: true,
                headerName: "Served Qty", field: "availableQuantity",
                width: 120, sortable: true, cellClass: "grid-cell",
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                    let first = nodeA.data?.quantity - nodeA.data?.availableQuantity;
                    let second = nodeB.data?.quantity - nodeB.data?.availableQuantity;

                    return (first > second) ? 1 : -1;
                },
                cellRenderer: (params) => <span>{params.data?.quantity - params?.data?.availableQuantity}</span>
            },
            {
                suppressMovable: true,
                headerName: "Remaining Qty", field: "availableQuantity",
                width: 120, sortable: true, cellClass: "grid-cell",
                cellRenderer: (params) => <span>{params.value}</span>
            },
        ];
        if (this.props.accessRoles?.find((item) => ['portaladmin', 'sales user','sales lead','thermal validation lead','cleanroom validation lead','thermal validation user','cleanroom validation user']?.includes(item.name?.toLowerCase()))) {
            array.push({
                suppressMovable: true,
                headerName: "Rate", field: "unitRate",
                flex: 1.5, sortable: true, cellClass: "grid-cell",
                cellRenderer: (params) => <span>{params.value}</span>
            });
        }
        array.push({
            suppressMovable: true,
            headerName: "Measurement", field: "unitMeasurement",
            flex: 2, sortable: true, cellClass: "grid-cell",
            cellRenderer: (params) => <span>{params.value}</span>
        });
        if (this.props.accessRoles?.find((item) => ['portaladmin', 'sales user', 'sales lead','thermal validation lead','cleanroom validation lead','thermal validation user','cleanroom validation user']?.includes(item.name?.toLowerCase()))) {
            array.push({
                suppressMovable: true,
                headerName: "Amount", field: "totalAmount",
                flex: 1.5, sortable: true, cellClass: "grid-cell",
                cellRenderer: (params) => <span>{params.value}</span>
            });
        }

        return array;
    }


    render() {
        return <React.Fragment>
            {(() => { if (this.props.dataLoading) return <div className="loading--bar fixed--top"><span></span></div> })()}
            <div className="pcoded-content">
                <div className="pcoded-inner-content">
                    <div className="page-header">
                        <div className="page-block">
                            <div className="row justify-content-between">
                                <div className="col">
                                    <Link to={ScreenUrls.PurchaseOrder.Index()}
                                        className="btn drp-icon btn-rounded btn-primary dropdown-toggle">
                                        <i className="feather icon-arrow-left"></i>
                                    </Link>
                                </div>
                                <div className="col-auto d-flex align-items-center">

                                    <button className="mr-3 border-0 bg-transparent d-flex align-items-center"
                                        onClick={async () => {
                                            await this.props.watchPurchaseOrder({ id: this.props.purchaseOrderData.id, watching: !this.props.purchaseOrderData?.isWatching });
                                            this.setState({ hideForm: true });
                                            setTimeout(() => {
                                                this.setState({ hideForm: false });
                                            }, 1)
                                        }}
                                    >
                                        <i
                                            className={`feather h5 mr-2 mb-0 icon-eye${this.props.purchaseOrderData?.isWatching ? "-off" : ""
                                                }`}
                                        ></i>
                                        {this.props.purchaseOrderData?.isWatching ? "Un-watch" : "Watch"}

                                    </button>

                                    <Link to={ScreenUrls.Workflows.Edit(this.props.purchaseOrderData?.workflowId)}
                                        className="btn drp-icon btn-rounded btn-primary dropdown-toggle">
                                        Go To Workflow
                                    </Link>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-body">
                        <div className="page-wrapper">
                            <div className="row">
                                <div className="col-sm-12">
                                    <article className="card no-bg mb-4">
                                        <div className="card-header card-form-header">
                                            <div className="row">
                                                <div className="col-3">
                                                    <div className="form-group read-only">
                                                        <label>PO Number<span className="requried-span">*</span></label>
                                                        <p>{this.props.purchaseOrderData?.poNumber ?? "-"}</p>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="form-group read-only">
                                                        <label>PO Date<span className="requried-span">*</span></label>
                                                        <p>{displayDate(this.props.purchaseOrderData?.poDate)}</p>
                                                    </div>
                                                </div>


                                                <div className="col-6">
                                                    <div className="form-group read-only">
                                                        <label>Reference Note</label>
                                                        <p>{this.props.purchaseOrderData.referenceNote ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3">
                                                    <div className="form-group read-only">
                                                        <label>Amendment Number</label>
                                                        <p>{this.props.purchaseOrderData.amendmentNumber ?? "-"}</p>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="form-group read-only">
                                                        <label>Amendment Date</label>
                                                        <p>{displayDate(this.props.purchaseOrderData.amendmentDate ?? "")}</p>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="form-group read-only">
                                                        <label>Quotation Number</label>
                                                        <p>{this.props.purchaseOrderData.quotationNumber ?? "-"}</p>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="form-group read-only">
                                                        <label>Quotation Date</label>
                                                        <p>{displayDate(this.props.purchaseOrderData.quotationDate ?? "")}</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group read-only">
                                                        <label>Description</label>
                                                        <p>{this.props.purchaseOrderData.description ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4">
                                                    <div className="form-group read-only">
                                                        <label>Client<span className="requried-span">*</span></label>
                                                        <p>{this.props.purchaseOrderData.client ? DropdownDisplayHelper.Client(this.props.purchaseOrderData.client) : "-"}</p>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-group read-only">
                                                        <label>Billing Address<span className="requried-span">*</span></label>
                                                        <p>{this.props.purchaseOrderData.billingAddress ? DropdownDisplayHelper.ClientAddress(this.props.purchaseOrderData.billingAddress) : "-"}</p>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-group read-only">
                                                        <label>Delivery Address<span className="requried-span">*</span></label>
                                                        <p>{this.props.purchaseOrderData.deliveryAddress ? DropdownDisplayHelper.ClientAddress(this.props.purchaseOrderData.deliveryAddress) : "-"}</p>
                                                    </div>
                                                </div>

                                            </div>



                                            <div className="row">
                                                <div className="col-4">
                                                    <div className="form-group read-only">
                                                        <label>Client Coordinator Name</label>
                                                        <p>{this.props.purchaseOrderData.clientCoordinatorName ? this.props.purchaseOrderData.clientCoordinatorName : "-"}</p>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-group read-only">
                                                        <label>Client Coordinator Email</label>
                                                        <p>{this.props.purchaseOrderData.clientCoordinatorEmail ? this.props.purchaseOrderData.clientCoordinatorEmail : "-"}</p>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-group read-only">
                                                        <label>Client Coordinator Phone Number</label>
                                                        <p>{this.props.purchaseOrderData.clientCoordinatorPhoneNumber ? this.props.purchaseOrderData.clientCoordinatorPhoneNumber : "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                    <article className="card mb-4">
                                        <div className="card-header card-form-header">
                                            <div className="card-form-header-title">Line Items<span className="requried-span">*</span></div>
                                        </div>
                                        <div className="card-block ag-theme-alpine medium-grid">
                                            {(() => {
                                                return <AgGridReact
                                                    unSortIcon={true}
                                                    rowHeight={60}
                                                    defaultColDef={AgGridDefaultColDef}
                                                    columnDefs={this.getPOLineItemsColDefs()}
                                                    pagination={true}
                                                    paginationAutoPageSize={true}
                                                    rowData={this.props.purchaseOrderData.lineItems}>
                                                </AgGridReact>
                                            })()}
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}

const mapStateToProps = (state: IAppState) => ({
    purchaseOrderData: state.purchaseOrder.purchaseOrderData,
    dataLoading: state.purchaseOrder.dataLoading,
    accessRoles: state.userSession?.currentUser?.accessRoles,
})

export default connect(mapStateToProps, { loadPurchaseOrderById, watchPurchaseOrder })(PurchaseOrderViewPage);
