 
import { Text, View, } from '@react-pdf/renderer';
import { CleanroomEquipmentTests, TestObservationResult } from "../../../../../common/enums";
import BasePDFComponent from "./base-test-report-pdf.component";
import { IUserModel } from "../../../../user/model/user.model";
import { ICleanroomEquipmentModel } from "../../../model/report.model";
import { getObservationLocationLabel } from '../../../../../common/_static/report.data';

export const LightIntensityPDFComponent = ({ id, currentUser, data }: { id: number, currentUser: IUserModel, data: ICleanroomEquipmentModel }) => {

    return (
        <BasePDFComponent testName={CleanroomEquipmentTests.lightintensity} title={'light intensity'} id={id} data={data} currentUser={currentUser}
            callBack={(styles: any, data: any, maxReadings: number) => {
                return (
                    <View wrap={false}>
                        <View style={{ ...styles.paddingTB }}>
                            <Text style={{ ...styles.font_12, ...styles.bold }}>Observations :</Text>
                        </View>
                        <View style={{ ...styles.flexBox, ...styles.flexColumn, ...styles.font_12, }}>
                            <View style={{ ...styles.flexBox, ...styles.bold, ...styles.textCenter }}>
                                <View style={{ ...styles.label, width: 30, ...styles.col, }}>
                                    <Text style={{}}>Sr.No</Text>
                                </View>
                                <View style={{ ...styles.label, flex: 1, ...styles.col, }}>
                                    <Text style={{ ...styles.label }}>
                                       {getObservationLocationLabel(data?.observationLocationLabel)}
                                    </Text>
                                </View>

                                <View style={{ ...styles.flex_2, ...styles.flexBox, flexDirection: 'column' }}>
                                    <View style={{ ...styles.col, textAlign: 'center' }}>
                                        <Text style={{}}>Measured Light Intensity (LUX)
                                        </Text>
                                    </View>
                                    <View style={{ ...styles.flexBox }}>
                                        {
                                            Array(maxReadings)?.fill(0).map((item, index) => {
                                                return (
                                                    <View style={{ ...styles.flex_1, padding: 2, borderRight: "0.5px solid black" }}>
                                                        <Text>R{(index + 1)}</Text>
                                                    </View>
                                                )
                                            })
                                        }
                                    </View>
                                </View>
                                <View style={{ ...styles.label, width: 90, ...styles.col }}>
                                    <Text style={{}}>Avg Light Intensity (LUX)</Text>

                                </View>
                                <View style={{ ...styles.label, width: 90, ...styles.col }}>
                                    <Text style={{}}>Acceptance criteria (LUX)</Text>
                                </View>
                                <View style={{ ...styles.label, width: 60, ...styles.col }}>
                                    <Text style={{}}>Result</Text>
                                </View>
                            </View>
                            {
                                data.observations?.map((item, index) => {
                                    return (
                                        <View style={{ ...styles.flexBox, ...styles.textCenter }}>
                                            <View style={{ ...styles.label, width: 30, ...styles.col }}>
                                                <Text style={{}}>{index + 1}</Text>
                                            </View>
                                            <View style={{ ...styles.label, flex: 1, ...styles.col }}>
                                                <Text style={{}}>{item?.locationNameOrNumber}</Text>
                                            </View>

                                            <View style={{ ...styles.flex_2, border: "0.5px solid black", ...styles.flexBox }}>

                                                {
                                                    Array(maxReadings)?.fill(0).map((item2, index2) => {
                                                        return (
                                                            <View style={{ ...styles.flex_1, padding: 2, height: "100%", borderRight: "0.5px solid black" }}>
                                                                <Text style={{}}>{item.roomReadings[index2]?.reading ?? ""}</Text>
                                                            </View>
                                                        )
                                                    })
                                                }
                                            </View>
                                            <View style={{ ...styles.label, width: 90, ...styles.col }}>
                                                <Text style={{}}>{item?.averageLightIntensity}</Text>
                                            </View>
                                            <View style={{ ...styles.label, width: 90, ...styles.col }}>
                                                <Text style={{}}>
                                                    {
                                                        (() => {
                                                            if (item.acceptanceCriteriaMin && item.acceptanceCriteriaMax) {
                                                                return `${item.acceptanceCriteriaMin} to ${item.acceptanceCriteriaMax}`;
                                                            } else if (item.acceptanceCriteriaMin && !item.acceptanceCriteriaMax) {
                                                                return `NLT ${item.acceptanceCriteriaMin}`;
                                                            } else if (item.acceptanceCriteriaMax && !item.acceptanceCriteriaMin) {
                                                                return `NMT ${item.acceptanceCriteriaMax}`;
                                                            }
                                                            return "";

                                                        })()
                                                    }

                                                </Text>
                                            </View>

                                            <View style={{ ...styles.label, width: 60, ...styles.col }}>
                                                <Text style={{}}>{
                                                    item.result == TestObservationResult.Pass ? TestObservationResult[TestObservationResult.Pass] :
                                                        item.result == TestObservationResult.Fail ? TestObservationResult[TestObservationResult.Fail] : "NA"}
                                                </Text>
                                            </View>
                                        </View>
                                    )
                                })
                            }
                        </View>
                    </View>
                )
            }}
        />)
}

export default LightIntensityPDFComponent;





