import {IFieldValidationModel, IValidatorModel} from "../../../common/model/validator.model";
import {ValidationScreens, ValidationType} from "../../../common/enums";


const fields: IFieldValidationModel[] = [
    {
        fieldName: "name",
        rules: [
            {
                type: ValidationType.Required,
                message: "Data logger make name is required"
            }
        ]
    }
]

export const dataLoggerMakeValidator: IValidatorModel = {
    screen: ValidationScreens.DataLoggerMake,
    fields: fields
}
