import { IClientAddressModel, IClientModel } from "../model/client.model";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import ClientService from "../service/client.service";
import { clientDefault } from "../model/client.default";
import { IStoreSaveModel } from "../../../common/model/store-save.model";
import ScreenUrls, { redirectHandler} from "../../../common/screen-urls";

export interface IClientState {
    clientList: IClientModel[],
    clientData: IClientModel,
    dataLoading: boolean,
    listLoading: boolean,
    createEditLoading: boolean
}

const initialState: IClientState = {
    clientList: [],
    clientData: clientDefault,
    dataLoading: false,
    listLoading: false,
    createEditLoading: false
}

export const loadClients = createAsyncThunk("client/loadAll", ClientService.LoadAll);
export const loadClientById = createAsyncThunk("client/loadById", ClientService.GetById);
export const createClient = createAsyncThunk("client/create", ClientService.Create);
export const editClient = createAsyncThunk("client/edit", ClientService.Edit);

const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        clearClientDataState: (state) => { state.clientData = clientDefault },
        clearClientListState: (state) => {
            state.clientList = [];
        },
        updateClientDataState: (state, action: PayloadAction<IStoreSaveModel>) => {
            state.clientData[action.payload.name] = action.payload.value;
        },
        addMergeClientAddressToClientDataState: (state, action: PayloadAction<IClientAddressModel>) => {
            let addresses = state.clientData.clientAddresses;
            if (addresses == null) addresses = [];

            if (action.payload.id > 0) {
                addresses = addresses.filter(a => a.id === action.payload.id);
            }

            addresses.push(action.payload);
        }
    },
    extraReducers: (builder) => {
        // Load All
        builder.addCase(loadClients.pending, (state, action) => {
            clearClientListState();
            state.listLoading = true;
        });
        builder.addCase(loadClients.rejected, (state, action) => {
            clearClientListState();
            state.listLoading = false;
        });
        builder.addCase(loadClients.fulfilled, (state, action) => {
            state.clientList = action.payload;
            state.listLoading = false;
        });

        // Load By ID
        builder.addCase(loadClientById.pending, (state, action) => {
            clearClientDataState();
            state.dataLoading = true;
        });
        builder.addCase(loadClientById.rejected, (state, action) => {
            clearClientDataState();
            state.dataLoading = false;
        });
        builder.addCase(loadClientById.fulfilled, (state, action) => {
            state.clientData = action.payload;
            state.dataLoading = false;
        });

        // Create
        builder.addCase(createClient.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(createClient.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(createClient.fulfilled, (state, action) => {
            state.clientData = action.payload;
            state.createEditLoading = false;
            redirectHandler(ScreenUrls.Clients.Edit(action.payload.id));
        });

        // Edit
        builder.addCase(editClient.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(editClient.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(editClient.fulfilled, (state, action) => {
            state.clientData = action.payload;
            state.createEditLoading = false;
        });
    }
});

const { actions, reducer } = clientSlice;

export const { clearClientDataState, clearClientListState, updateClientDataState, addMergeClientAddressToClientDataState } = actions;

export default reducer;
