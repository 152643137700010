import { IAppState } from "../../../store";
import { connect } from "react-redux";
import React from "react";
import { IStoreSaveModel } from "../../../common/model/store-save.model";
import { FormBaseComponent, IFormBaseState } from "../../../common/component/form-base.component";
import ValidationMessageControl from "../../../common/control/validation-message.control";
import { ValidationScreens } from "../../../common/enums";
import { IDataLoggerCameraDetailModel } from "../model/data-logger-camera-detail.model";
import { createDataLoggerCameraDetail, clearDataLoggerCameraDetailDataState, updateDataLoggerCameraDetailDataState } from "../store/data-logger-camera-detail.slice";
import { dataLoggerCameraDetailDefault } from "../model/defaults/data-logger.default";

export interface IProps {
    dataLoading: boolean,
    createEditLoading: boolean,
    callBackHandler: () => void,
    dataLoggerCameraDetail: IDataLoggerCameraDetailModel,
    quickAddCameraDetail: (data: IDataLoggerCameraDetailModel) => void;
    clearDataLoggerCameraDetailDataState: (payload) => void;
    updateDataLoggerCameraDetailDataState: (payload: IStoreSaveModel) => void,
}

export interface IState extends IFormBaseState {
}

class CameraDetailCreateEditComponent extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            hideForm: false
        }
    }

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.DataLoggerCameraDetail);
        await this.props.clearDataLoggerCameraDetailDataState(dataLoggerCameraDetailDefault);
    }

    async componentWillUnmount() {
        await this.setValidator(ValidationScreens.None);
    }

    render() {
        return <React.Fragment>
            <div className="">
                <div className="pcoded-inner-content">
                    {(() => { if (this.props.dataLoading || this.props.createEditLoading) return <div className="loading--bar fixed--top"><span></span></div> })()}
                    <div className="page-wrapper">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-4">
                                        <div className={`form-group ${this.vResult("cameraName").className}`}>
                                            <label htmlFor="cameraName">Camera Name<span className="requried-span">*</span></label>
                                            <input id="cameraName" className="form-control" type="text"
                                                value={this.props.dataLoggerCameraDetail.cameraName}
                                                onChange={e => {
                                                    e.preventDefault();
                                                    this.ValidateField("cameraName", e.target.value);
                                                    this.props.updateDataLoggerCameraDetailDataState({ name: "cameraName", value: e.target.value });
                                                }} />
                                            <ValidationMessageControl message={this.vResult("cameraName").message} />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className={`form-group ${this.vResult("cameraId").className}`}>
                                            <label htmlFor="cameraId">Camera Id<span className="requried-span">*</span></label>
                                            <input id="cameraId" className="form-control" type="text"
                                                value={this.props.dataLoggerCameraDetail.cameraId}
                                                onChange={e => {
                                                    e.preventDefault();
                                                    this.ValidateField("cameraId", e.target.value);
                                                    this.props.updateDataLoggerCameraDetailDataState({ name: "cameraId", value: e.target.value });
                                                }} />
                                            <ValidationMessageControl message={this.vResult("cameraId").message} />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className={`form-group ${this.vResult("modelNumber").className}`}>
                                            <label htmlFor="modelNumber">Model Number<span className="requried-span">*</span></label>
                                            <input id="roleName" className="form-control" type="text"
                                                value={this.props.dataLoggerCameraDetail.modelNumber}
                                                onChange={e => {
                                                    e.preventDefault();
                                                    this.ValidateField("modelNumber", e.target.value);
                                                    this.props.updateDataLoggerCameraDetailDataState({ name: "modelNumber", value: e.target.value });
                                                }} />
                                            <ValidationMessageControl message={this.vResult("modelNumber").message} />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className={`form-group ${this.vResult("name").className}`}>
                                            <label htmlFor="roleName">Serial Number</label>
                                            <input id="roleName" className="form-control" type="text"
                                                value={this.props.dataLoggerCameraDetail.serialNumber}
                                                onChange={e => {
                                                    e.preventDefault();

                                                    this.props.updateDataLoggerCameraDetailDataState({ name: "serialNumber", value: e.target.value });
                                                }} />

                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className={`form-group ${this.vResult("name").className}`}>
                                            <label htmlFor="roleName">Make</label>
                                            <input id="roleName" className="form-control" type="text"
                                                value={this.props.dataLoggerCameraDetail.make}
                                                onChange={e => {
                                                    e.preventDefault();

                                                    this.props.updateDataLoggerCameraDetailDataState({ name: "make", value: e.target.value });
                                                }} />

                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <button type="button" className="btn btn-primary btn--right"

                                                onClick={async e => {
                                                    e.preventDefault();
                                                    this.props.callBackHandler();
                                                }}>Cancel</button>
                                            <button type="button" className="btn btn-primary btn--right"
                                                onClick={async e => {
                                                    e.preventDefault();
                                                    const error = !this.ValidateForm(this.props.dataLoggerCameraDetail);
                                                    this.reloadForm();
                                                    if (error) return;
                                                    this.props.quickAddCameraDetail(this.props.dataLoggerCameraDetail);
                                                    this.props.callBackHandler();
                                                }}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    }
}

const mapStateToProps = (state: IAppState) => ({
    dataLoggerCameraDetail: state.dataLoggerCameraDetail.dataLoggerCameraDetailData,
    dataLoading: state.dataLoggerCameraDetail.dataLoading,
    createEditLoading: state.dataLoggerCameraDetail.createEditLoading
})
export default connect(mapStateToProps, {
    quickAddCameraDetail: createDataLoggerCameraDetail,
    clearDataLoggerCameraDetailDataState: clearDataLoggerCameraDetailDataState,
    updateDataLoggerCameraDetailDataState: updateDataLoggerCameraDetailDataState
})(CameraDetailCreateEditComponent);
