import { createSlice, PayloadAction} from "@reduxjs/toolkit";
import { IFormControlDropdownItemModel } from "../../common/control/model/form.control.field.model";

export interface IStageAssignment {
    openModal: boolean,
    optionsList: any[] | null,
    proceedBtnClicked:boolean,
}

const initialState: IStageAssignment = {
    openModal: false,
    optionsList: [],
    proceedBtnClicked:false,
}

const stageAssignmentSlice = createSlice({
    name: 'stageAssignment',
    initialState,
    reducers: {
        openStageAssignmentModal:(state,action:PayloadAction<IStageAssignment>)=>{
            state.openModal = true;
            state.optionsList = action.payload?.optionsList ?? [];
            state.proceedBtnClicked = action.payload.proceedBtnClicked;
        },
        setHideModal:(state)=>{
            state.openModal = false;
            state.optionsList = [];
            state.proceedBtnClicked = false;
        }
    },
    extraReducers: (builder) => {

    }
});

const { actions, reducer } = stageAssignmentSlice;


export const {openStageAssignmentModal,setHideModal} = actions;

export default reducer;


