import { IFieldValidationModel, IValidatorModel } from "../../../../../../common/model/validator.model";
import { ValidationScreens, ValidationType } from "../../../../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "dateOfTest",
        rules: [
            {
                type: ValidationType.Required,
                message: "Date of test is required"
            }
        ]
    },
    // {
    //     fieldName: "dueDate",
    //     rules: [
    //         {
    //             type: ValidationType.Required,
    //             message: "Due date is required",
    //         },
    //     ]
    // },
    {
        fieldName: "preparedById",
        rules: [
            {
                type: ValidationType.NumberGreaterThanZero,
                message: "Prepared by user is required",
            },
        ]
    },
    // {
    //     fieldName: "checkedById",
    //     rules: [
    //         {
    //             type: ValidationType.NumberGreaterThanZero,
    //             message: "Checked by user is required",
    //         },
    //     ]
    // },
    {
        fieldName: "dataLoggerId",
        rules: [
            {
                type: ValidationType.NumberGreaterThanZero,
                message: "Datalogger is required",
            },
        ]
    },
    {
        fieldName: "cameraDetailId",
        rules: [
            {
                type: ValidationType.NumberGreaterThanZero,
                message: "Camera detail is required",
            },
        ]
    },
]

export const airflowPatternTestValidator: IValidatorModel = {
    screen: ValidationScreens.airflowPatternTest,
    fields: fields
}
