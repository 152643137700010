
import ApiUrls from "../../../common/api-urls";
import HttpService from "../../../common/service/http.service";
import { IWorkflowModel } from "../../workflow/model/workflow.model";
import {IMyWorkWorkflowFilter,IMyWorkTaskFilter,IWorkflowModelWithPOInfo} from "../model/my-work.model";

export default class MyWorkService {
    static async LoadWorkflow(request?: IMyWorkWorkflowFilter): Promise<IWorkflowModel[]> {
        return await HttpService.Get<IWorkflowModel[]>(ApiUrls.MyWork.GetWorkflow(request));
    }

    static async LoadTask(request?: IMyWorkTaskFilter): Promise<IWorkflowModelWithPOInfo[]> {
        return await HttpService.Get<IWorkflowModelWithPOInfo[]>(ApiUrls.MyWork.GetTask(request));
    }
}
