import {IFieldValidationModel, IValidatorModel} from "../../../common/model/validator.model";
import {ValidationScreens, ValidationType} from "../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "employeeId",
        rules: [
            {
                type: ValidationType.Required,
                message: "Employee Id is required"
            }
        ]
    },
    {
        fieldName: "firstName",
        rules: [
            {
                type: ValidationType.Required,
                message: "First name is required"
            }
        ]
    },
    {
        fieldName: "email",
        rules: [
            {
                type: ValidationType.Required,
                message: "Email is required"
            },
            {
                type: ValidationType.IsEmail,
                message: "Invalid Email"
            },
        ]
    },
    {
        fieldName: "phone",
        rules: [
            {
                type: ValidationType.Required,
                message: "Phone is required"
            }
        ]
    },
    {
        fieldName: "accessRoles",
        rules: [
            {
                type: ValidationType.Required,
                message: "At least one access role is required"
            }
        ]
    }
]

export const userValidator: IValidatorModel = {
    screen: ValidationScreens.Client,
    fields: fields
}
