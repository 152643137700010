import React from "react";
import ScreenUrls from "../../../../common/screen-urls";
import {IAppState} from "../../../../store";
import {connect} from "react-redux";
import {
    clearDataLoggerMakeDataState,
    createDataLoggerMake,
    editDataLoggerMake,
    loadDataLoggerMakeById,
    updateDataLoggerMakeDataState
} from "../../store/data-logger-make.slice";
import {Link} from "react-router-dom";
import {IDataLoggerMakeModel} from "../../model/data-logger-make.model";
import {IStoreSaveModel} from "../../../../common/model/store-save.model";
import {FormBaseComponent, IFormBaseState} from "../../../../common/component/form-base.component";
import {ValidationScreens} from "../../../../common/enums";
import ValidationMessageControl from "../../../../common/control/validation-message.control";

export interface IProps {
    accessLevel: number;
    id?: string;
    dataLoggerMakeData: IDataLoggerMakeModel;
    loadDataLoggerMakeById: (id: number) => void;
    dataLoading: boolean;
    createEditLoading: boolean;
    createDataLoggerMake: (data: IDataLoggerMakeModel) => void;
    editDataLoggerMake: (data: IDataLoggerMakeModel) => void;
    updateDataLoggerMakeDataState: (payload: IStoreSaveModel) => void;
    clearDataLoggerMakeDataState: () => void;
}

export interface IState extends IFormBaseState {
    redirectUrl: string
}

class DataLoggerMakeCreateEditPage extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            redirectUrl: "",
            hideForm: false
        }
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.DataLoggerMake);

        if(this.props.id != null && Number(this.props.id) > 0) {
            await this.props.loadDataLoggerMakeById(Number(this.props.id));
        }
        else {
            await this.props.clearDataLoggerMakeDataState();
        }
    }
    render() {
        return <React.Fragment>
            <div className="pcoded-content">
                <div className="pcoded-inner-content">
                    <div className="page-header">
                        <div className="page-block">
                            <div className="row">
                                <div className="col-md-12">
                                    <Link to={ScreenUrls.DataLoggers.Make.Index()}
                                          className="btn drp-icon btn-rounded btn-primary dropdown-toggle">
                                        <i className="feather icon-arrow-left"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    {(() => { if(this.props.dataLoading || this.props.createEditLoading) return <div className="loading--bar fixed--top"><span></span></div> })()}
                    <div className="main-body">
                        <div className="page-wrapper">
                            <div className="row">
                                <div className="col-sm-12">
                                    <article className="card mb-4">
                                        <div className="card-body">
                                            <div className="row align-items-center">
                                                <div className="col-4">
                                                    <div className={`form-group ${this.vResult("name").className}`}>
                                                        <label htmlFor="roleName">Make Name<span className="requried-span">*</span></label>
                                                        <input id="roleName" className="form-control" type="text"
                                                               value={this.props.dataLoggerMakeData.name?.toString()}
                                                               onChange={e => { e.preventDefault();
                                                                   this.ValidateField("name",  e.target.value);
                                                                   this.props.updateDataLoggerMakeDataState({ name: "name", value: e.target.value });
                                                               }} />
                                                        <ValidationMessageControl message={this.vResult("name").message} />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className={`form-group ${this.vResult("description").className}`}>
                                                        <label htmlFor="roleName">Description</label>
                                                        <input id="roleName" className="form-control" type="text"
                                                               value={this.props.dataLoggerMakeData.description?.toString()}
                                                               onChange={e => { e.preventDefault();
                                                                   this.ValidateField("description",  e.target.value);
                                                                   this.props.updateDataLoggerMakeDataState({ name: "description", value: e.target.value });
                                                               }} />
                                                        <ValidationMessageControl message={this.vResult("description").message} />
                                                    </div>
                                                </div>
                                                <div className="col-2">
                                                    <div className="form-group">
                                                        <button type="button" className="btn btn-primary btn--right"
                                                                disabled={this.props.dataLoggerMakeData == null || this.hasError}
                                                                onClick={e => {
                                                                    e.preventDefault();

                                                                    const error = !this.ValidateForm(this.props.dataLoggerMakeData);
                                                                    this.reloadForm();
                                                                    if(error) return;

                                                                    if (this.props.dataLoggerMakeData.id > 0) {
                                                                        this.props.editDataLoggerMake(this.props.dataLoggerMakeData);
                                                                    } else {
                                                                        this.props.createDataLoggerMake(this.props.dataLoggerMakeData);
                                                                    }
                                                                }}>Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}

const mapStateToProps = (state: IAppState) => ({
    dataLoggerMakeData: state.dataLoggerMake.dataLoggerMakeData,
    dataLoading: state.dataLoggerMake.dataLoading,
    createEditLoading: state.dataLoggerMake.createEditLoading
})

export default connect(mapStateToProps, { loadDataLoggerMakeById, createDataLoggerMake, editDataLoggerMake, updateDataLoggerMakeDataState, clearDataLoggerMakeDataState })(DataLoggerMakeCreateEditPage);
