import { IFieldValidationModel, IValidatorModel } from "../../../common/model/validator.model";
import { ValidationScreens, ValidationType } from "../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "equipmentName",
        rules: [
            {
                type: ValidationType.Required,
                message: "Equipment name is required"
            }
        ]
    },
    {
        fieldName: "equipmentId",
        rules: [
            {
                type: ValidationType.Required,
                message: "Equipment Id is required",
            },
        ]
    },
    {
        fieldName: "modelNumber",
        rules: [
            {
                type: ValidationType.Required,
                message: "Model Number is required",
            },
        ]
    },
    {
        fieldName: "serialNumber",
        rules: [
            {
                type: ValidationType.Required,
                message: "Serial Number is required",
            },
        ]
    },
    {
        fieldName: "clientId",
        rules: [
            {
                type: ValidationType.NumberGreaterThanZero,
                message: "Client is required",
            },
        ]
    },
    {
        fieldName: "make",
        rules: [
            {
                type: ValidationType.Required,
                message: "Make is required",
            },
        ]
    }
]

export const clientEquipmentValidator: IValidatorModel = {
    screen: ValidationScreens.ClientEquipment,
    fields: fields
}
