import {IFieldValidationModel, IValidatorModel} from "../../../common/model/validator.model";
import {ValidationScreens, ValidationType} from "../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "type",
        rules: [
            {
                type: ValidationType.Required,
                message: "Type is required"
            }
        ]
    },
    {
        fieldName: "quantity",
        rules: [
            {
                type: ValidationType.NumberGreaterThanZero,
                message: "Quantity should be a number and greater than zero"
            }
        ]
    },
    {
        fieldName: "unitRate",
        rules: [
            {
                type: ValidationType.NumberGreaterThanZero,
                message: "Unit rate should be a number and greater than zero"
            }
        ]
    },
    {
        fieldName: "unitMeasurement",
        rules: [
            {
                type: ValidationType.Required,
                message: "Unit measurement is required"
            }
        ]
    },
    {
        fieldName: "serviceId",
        rules: [
            {
                type: ValidationType.Required,
                message: "Service is required"
            }
        ]
    }
]

export const purchaseOrderLineItemValidator: IValidatorModel = {
    screen: ValidationScreens.PurchaseOrderLineItem,
    fields: fields
}
