import React from "react";
import ScreenUrls from "../../screen-urls";
import CategoryService from "../../service/category.service";
import { DeferredLoadOptions } from "../../utils";
import AsyncSelect from "react-select/async";
import { IAppState } from "../../../store";
import { connect } from "react-redux";
import {
    clearCategoryDataState,
    createCategory,
    editCategory,
    loadCategoryById,
    updateCategoryDataState
} from "../../stores/category.slice";
import { ICategoryModel } from "../../model/category.model";
import { IStoreSaveModel } from "../../model/store-save.model";
import DropdownDisplayHelper from "../../helper/dropdown-display.helper";
import { Link } from "react-router-dom";
import { FormBaseComponent, IFormBaseState } from "../../component/form-base.component";
import { ValidationScreens } from "../../enums";
import ValidationMessageControl from "../../control/validation-message.control";

export interface IProps {
    accessLevel: number;
    id?: string;
    categoryData: ICategoryModel;
    dataLoading: boolean;
    createEditLoading: boolean;
    loadCategoryById: (id: number) => void;
    createCategory: (data: ICategoryModel) => void;
    editCategory: (data: ICategoryModel) => void;
    clearCategoryDataState: () => void;
    updateCategoryDataState: (data: IStoreSaveModel) => void;
}

export interface IState extends IFormBaseState { }

class CategoryCreateEditPage extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = { hideForm: false }
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.DataLoggerCategory);

        if (this.props.id != null && Number(this.props.id) > 0) {
            await this.props.loadCategoryById(Number(this.props.id));
        } else {
            await this.props.clearCategoryDataState();
        }
    }

    loadParentCategoryOption = async (inputValue: string) => {
        let options = await DeferredLoadOptions(inputValue, (search: string) =>
            CategoryService.LoadCategoriesList({ searchStr: search, onlyParents: true }));
        if (this.props.categoryData.id && !this.props.categoryData.parentId) {
            options = options.filter(item => item.label.toLowerCase() != this.props.categoryData.name.toLowerCase());
        }
        return options;
    }
    render() {
        return <React.Fragment>
            <div className="pcoded-content">
                <div className="pcoded-inner-content">
                    <div className="page-header">
                        <div className="page-block">
                            <div className="row">
                                <div className="col-md-12">
                                    <Link to={ScreenUrls.DataLoggers.Category.Index()}
                                        className="btn drp-icon btn-rounded btn-primary dropdown-toggle">
                                        <i className="feather icon-arrow-left"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    {(() => { if (this.props.dataLoading || this.props.createEditLoading) return <div className="loading--bar fixed--top"><span></span></div> })()}
                    <div className="main-body">
                        <div className="page-wrapper">
                            <div className="row">
                                <div className="col-sm-12">
                                    <article className="card mb-4">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-4">
                                                    <div className={`form-group ${this.vResult("name").className}`}>
                                                        <label htmlFor="roleName">Category Name <span className="requried-span">*</span></label>
                                                        <input id="roleName" className="form-control" type="text"
                                                            value={this.props.categoryData.name?.toString() ?? ""}
                                                            onChange={e => {
                                                                e.preventDefault();
                                                                this.ValidateField("name", e.target.value);
                                                                this.props.updateCategoryDataState({ name: "name", value: e.target.value });
                                                            }} />
                                                        <ValidationMessageControl message={this.vResult("name").message} />
                                                    </div>
                                                </div>
                                                <div className="col-5">
                                                    <div className={`form-group ${this.vResult("description").className}`}>
                                                        <label htmlFor="roleName">Description</label>
                                                        <input id="roleName" className="form-control" type="text"
                                                            value={this.props.categoryData.description?.toString() ?? ""}
                                                            onChange={e => {
                                                                e.preventDefault();
                                                                this.ValidateField("description", e.target.value);
                                                                this.props.updateCategoryDataState({ name: "description", value: e.target.value });
                                                            }} />
                                                        <ValidationMessageControl message={this.vResult("description").message} />
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className={`form-group ${this.vResult("parentId").className}`}>
                                                        <label htmlFor="parent">Parent</label>
                                                        <AsyncSelect id="parent" cacheOptions defaultOptions
                                                            className="async-select-control"
                                                            isClearable={true}
                                                            loadOptions={this.loadParentCategoryOption}
                                                            value={this.props.categoryData.parent != null
                                                                ? { label: DropdownDisplayHelper.Category(this.props.categoryData.parent), value: this.props.categoryData.parent.id.toString() }
                                                                : { label: "Select Category", value: "" }}
                                                            onChange={async (e) => {
                                                                if (e?.value == undefined) {
                                                                    this.props.updateCategoryDataState({ name: "parent", value: null });
                                                                    this.props.updateCategoryDataState({ name: "parentId", value: null });
                                                                    this.ValidateField("parentId", null);
                                                                    return;
                                                                }

                                                                const cId = Number(e?.value);
                                                                if (cId === null || cId === 0) return;

                                                                this.ValidateField("parentId", cId);
                                                                const category = await CategoryService.GetById(cId);
                                                                this.props.updateCategoryDataState({ name: "parent", value: category });
                                                                this.props.updateCategoryDataState({ name: "parentId", value: category.id });
                                                            }} />
                                                        <ValidationMessageControl message={this.vResult("parentId").message} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <button type="button" className="btn btn-primary btn--right"
                                                            disabled={this.props.categoryData == null || this.hasError}
                                                            onClick={e => {
                                                                e.preventDefault();

                                                                const error = !this.ValidateForm(this.props.categoryData);
                                                                this.reloadForm();
                                                                if (error) return;

                                                                if (this.props.categoryData.id > 0) {
                                                                    this.props.editCategory(this.props.categoryData);
                                                                } else {
                                                                    this.props.createCategory(this.props.categoryData);
                                                                }
                                                            }}>Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}

const mapStateToProps = (state: IAppState) => ({
    categoryData: state.category.categoryData,
    dataLoading: state.category.dataLoading,
    createEditLoading: state.category.createEditLoading
})

export default connect(mapStateToProps, { loadCategoryById, createCategory, editCategory, updateCategoryDataState, clearCategoryDataState })(CategoryCreateEditPage);
