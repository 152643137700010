import {IFieldValidationModel, IValidatorModel} from "../../../../common/model/validator.model";
import {ValidationScreens, ValidationType} from "../../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "assigneeId",
        rules: [
            {
                type: ValidationType.Required,
                message: "Assignee is required"
            }
        ]
    },
    {
        fieldName: "reportingStartDate",
        dependencyFieldName:"siteActivityEndDate",
        rules: [
            {
                type: ValidationType.Required,
                message: "Reporting start date is required"
            },
            {
                type: ValidationType.DateShouldBeGreaterDependentDate,
                message: "Reporting start date should be greater than start activity end date"
            }
        ]
    },
    {
        fieldName: "reportingEndDate",
        dependencyFieldName:"reportingStartDate",
        rules: [
            {
                type: ValidationType.Required,
                message: "Reporting end date is required"
            },
            {
                type: ValidationType.DateShouldBeLessThenCurrentDate,
                message: "Reporting end date should be less than current date"
            },
            {
                type: ValidationType.DateShouldBeGreaterDependentDate,
                message: "Reporting end date should be greater than Reporting activity start date"
            }
        ]
    },
    {
        fieldName: "siteActivityEndDate",
        rules: [
            {
                type: ValidationType.Required,
                message: "siteActivity end date is required"
            }
        ]
    },
    {
        fieldName: "isReportCreatedBasedOnChecklist",
        rules: [
            {
                type: ValidationType.RequiredIfTrue,
                message: "Please complete report created basedOn checklist"
            }
        ]
    },
    {
        fieldName: "isReportCreationCompleted",
        rules: [
            {
                type: ValidationType.RequiredIfTrue,
                message: "Please complete report creation"
            }
        ]
    }
]

export const workflowTaskReportingStageValidator: IValidatorModel = {
    screen: ValidationScreens.WorkflowTaskReportingStage,
    fields: fields
}
