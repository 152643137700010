import { BaseComponent } from "../component/base.component";


export interface IProps {
    message: string
}

class ValidationMessageControl extends BaseComponent<IProps, any> {
    render() {
        return <div className={`validation-message`}>
            <span className="validation-message--icon"><i className="feather icon-alert-triangle"></i></span>
            <span className="validation-message--label">{this.props.message}</span>
        </div>
    }
}

export default ValidationMessageControl;
