import {IFieldValidationModel, IValidatorModel} from "../../../common/model/validator.model";
import {ValidationScreens, ValidationType} from "../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "currentPassword",
        rules: [
            {
                type: ValidationType.Required,
                message: "Current password is required"
            }
        ]
    },
    {
        fieldName: "newPassword",
        dependencyFieldName:"currentPassword",
        rules: [
            {
                type: ValidationType.Required,
                message: "New password is required"
            },
            {
                type: ValidationType.ValueShouldNotBeSame,
                message: "Current and new password shuld be different"
            },
        ]
    },
    {
        fieldName: "confirmNewPassword",
        dependencyFieldName:"newPassword",
        rules: [
            {
                type: ValidationType.Required,
                message: "Confirm new password is required"
            },
            {
                type: ValidationType.ValueShouldBeSame,
                message: "New password and confirm password are different"
            },
        ]
    }
]

export const passwordChangeValidator: IValidatorModel = {
    screen: ValidationScreens.Client,
    fields: fields
}
