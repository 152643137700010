import React from "react";
import ScreenUrls from "../../../../common/screen-urls";
import { Link, Navigate } from "react-router-dom";
import { IClientModel } from "../../model/client.model";
import { IAppState } from "../../../../store";
import { connect } from "react-redux";
import {
    clearClientDataState,
    createClient,
    editClient,
    loadClientById,
    updateClientDataState
} from "../../store/client.slice";
import { IStoreSaveModel } from "../../../../common/model/store-save.model";
import ClientAddressCardComponent from "../../component/client-address-card.component";
import { AccessLevel, ValidationScreens } from "../../../../common/enums";
import { FormBaseComponent, IFormBaseState } from "../../../../common/component/form-base.component";
import ValidationMessageControl from "../../../../common/control/validation-message.control";

export interface IProps {
    accessLevel: number,
    accessLevelAddress: number,
    id?: string,
    clientData: IClientModel,
    dataLoading: boolean,
    createEditLoading: boolean
    loadClientById: (id: number) => void;
    createClient: (data: IClientModel) => void;
    editClient: (data: IClientModel) => void;
    updateClientDataState: (payload: IStoreSaveModel) => void;
    clearClientDataState: () => void;
}

export interface IState extends IFormBaseState {
    redirectUrl: string
}

class ClientCreateEditPage extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            redirectUrl: "",
            hideForm: false
        }
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.Client);

        if (this.props.id != null && Number(this.props.id) > 0) {
            await this.props.loadClientById(Number(this.props.id));
        }
        else {
            await this.props.clearClientDataState();
        }
    }

    render() {
        if (this.state.redirectUrl != "") return <Navigate to={this.state.redirectUrl} replace />
        return <React.Fragment>
            <div className="pcoded-content">
                <div className="pcoded-inner-content">
                    <div className="page-header">
                        <div className="page-block">
                            <div className="row">
                                <div className="col-md-12">
                                    <Link to={ScreenUrls.Clients.Index()} className="btn drp-icon btn-rounded btn-primary dropdown-toggle">
                                        <i className="feather icon-arrow-left"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-body">
                        <div className="page-wrapper">
                            <div className="row">
                                <div className="col-sm-12">
                                    <article className="card mb-4">
                                        <div className="card-header">
                                            <h4 className="card-title mb-0">
                                                Client Info
                                            </h4>
                                        </div>
                                        {(() => {
                                            if (this.state.hideForm) return;
                                            return <div className="card-body">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className={`form-group ${this.vResult("companyName").className}`}>
                                                            <label htmlFor="companyName">Company Name<span className="requried-span">*</span></label>
                                                            <input id="companyName" className="form-control" type="text"
                                                                value={this.props.clientData?.companyName ?? ""}
                                                                onChange={e => {
                                                                    e.preventDefault();
                                                                    this.ValidateField("companyName", e.target.value);
                                                                    this.props.updateClientDataState({ name: "companyName", value: e.target.value });
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("companyName").message} />
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("gstInNumber").className}`}>
                                                            <label htmlFor="gstInNumber">GstIn Number</label>
                                                            <input id="gstInNumber" className="form-control" type="text"
                                                                value={this.props.clientData?.gstInNumber ?? ""}
                                                                onChange={e => {
                                                                    e.preventDefault();
                                                                    this.ValidateField("gstInNumber", e.target.value);
                                                                    this.props.updateClientDataState({ name: "gstInNumber", value: e.target.value });
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("gstInNumber").message} />
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("panNumber").className}`}>
                                                            <label htmlFor="panNumber">PAN Number</label>
                                                            <input id="panNumber" className="form-control" type="text"
                                                                value={this.props.clientData?.panNumber ?? ""}
                                                                onChange={e => {
                                                                    e.preventDefault();
                                                                    this.ValidateField("panNumber", e.target.value);
                                                                    this.props.updateClientDataState({ name: "panNumber", value: e.target.value });
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("panNumber").message} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className={`form-group ${this.vResult("description").className}`}>
                                                            <label htmlFor="description">Description</label>
                                                            <textarea id="description" className="form-control" rows={4}
                                                                value={this.props.clientData?.description ?? ""}
                                                                onChange={e => {
                                                                    e.preventDefault();
                                                                    this.ValidateField("description", e.target.value);
                                                                    this.props.updateClientDataState({ name: "description", value: e.target.value });
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("description").message} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <button type="button" className="btn btn-primary btn--right"
                                                                disabled={this.props.clientData == null || this.hasError}
                                                                onClick={e => {
                                                                    e.preventDefault();

                                                                    const error = !this.ValidateForm(this.props.clientData);
                                                                    this.reloadForm();
                                                                    if (error) return;

                                                                    if (this.props.clientData.id > 0) {
                                                                        this.props.editClient(this.props.clientData);
                                                                    } else {
                                                                        this.props.createClient(this.props.clientData);
                                                                    }
                                                                }}>Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })()}
                                        {(() => { if (this.props.createEditLoading) return <div className="loading--bar"><span></span></div> })()}
                                    </article>
                                    {(() => {
                                        if (this.props.clientData.id > 0) {
                                            return <article className="card mb-4">
                                                {(() => { if (this.props.dataLoading) return <div className="loading--bar"><span></span></div> })()}
                                                <div className="card-header card-form-header">
                                                    <div className="card-form-header-title">Addresses<span className="requried-span">*</span></div>
                                                    {(() => {
                                                        if (this.props.accessLevelAddress >= AccessLevel.Create) {
                                                            return <button type="button" className="btn btn-primary btn--right"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.setState({ redirectUrl: ScreenUrls.Clients.Addresses.Create(this.props.id ?? 0) });
                                                                }}>
                                                                Add New
                                                            </button>
                                                        }
                                                    })()}
                                                </div>
                                                <div className="client-address row">
                                                    {(() => {
                                                        if (this.props.clientData.clientAddresses != null && this.props.clientData.clientAddresses.length > 0) {
                                                            return this.props.clientData.clientAddresses.map((e, i) => {
                                                                return <ClientAddressCardComponent key={`client-address-${i}`} data={e} accessLevel={this.props.accessLevelAddress} />
                                                            })
                                                        }
                                                    })()}
                                                </div>
                                            </article>
                                        }
                                    })()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}

const mapStateToProps = (state: IAppState) => ({
    clientData: state.client.clientData,
    dataLoading: state.client.dataLoading,
    createEditLoading: state.client.createEditLoading
})

export default connect(mapStateToProps, { loadClientById, createClient, editClient, updateClientDataState, clearClientDataState })(ClientCreateEditPage);
