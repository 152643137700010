import moment from "moment/moment";
import { utcToLocalDate } from "../utils";

export default class WorkflowTaskHelper {
    static CheckListEditable(endDate?: string): boolean {
        return endDate != null && endDate != ""
            && moment.utc(endDate).toDate() <= new Date();
    }
    static CheckListToDatesEditable(startdate?: string, enddate?: string): boolean {
        return (startdate != null && startdate != ""
            && moment.utc(startdate).toDate()) <= (enddate != null && enddate != ""
                && moment.utc(enddate).toDate());
    }

    static getMinMaxForDatePicker(minDate: string | null = null, maxDate: string | null = null): object {
        let minD: any;
        let maxD = new Date();
        let object: any = {};
        if (minDate) {
            minD = new Date(utcToLocalDate(minDate));
            object.minDate = minD;
        }
        if (maxDate) {
            maxD = new Date(utcToLocalDate(maxDate))
        }
        if (maxDate) object.maxDate = maxD;
        return object;
    }

}
