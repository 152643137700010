import {IFieldValidationModel, IValidatorModel} from "../../../../common/model/validator.model";
import {ValidationScreens, ValidationType} from "../../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "assigneeId",
        rules: [
            {
                type: ValidationType.Required,
                message: "Assignee is required"
            }
        ]
    },
    {
        fieldName: "tasks",
        rules: [
            {
                type: ValidationType.Required,
                message: "At least one task is required"
            },
            {
                type: ValidationType.TasksCompleted,
                message: "All tasks should be completed"
            }
        ]
    }
]

export const workflowTasksStageValidator: IValidatorModel = {
    screen: ValidationScreens.WorkflowTasksStage,
    fields: fields
}
