import { PureComponent, createRef } from "react";
import { connect } from "react-redux";
import Modal from 'react-modal';
import AsyncSelect from "react-select/async";
import { DeferredLoadOptions } from "../../../common/utils";
import UserService from "../../user/service/user.service";
import DropdownService from "../../../common/service/dropdown.service";
import { IPurchaseOrderWorkAllocationModel } from "../../purchase-order/model/purchase-order-work-allocation.model";
import { reAssignAPOLineItem, assignAPOLineItem, loadWorkflowById } from "../store/workflow.slice";
import { IWorkflowModel } from "../model/workflow.model";
import { IPurchaseOrderLineItemModel } from "../../purchase-order/model/purchase-order-item.model";

export interface IProps {
    toggle: boolean,
    setHideModal: () => void,
    workflowData: IWorkflowModel,
    purchaseOrderItem?: null | IPurchaseOrderLineItemModel,
    loadWorkflowById: (id: number) => void,
    assignAPOLineItem: (payload: IPurchaseOrderWorkAllocationModel) => void;
    reAssignAPOLineItem: (payload: IPurchaseOrderWorkAllocationModel) => void;
}

class StageAssignmentModalComponent extends PureComponent<IProps, any> {
    inputRef: any = createRef();
    constructor(props) {
        super(props);
    }

    loadAssigneeOption = (inputValue: string) => DeferredLoadOptions(inputValue, (search: string) => new Promise<any[]>(async (resolve) => {
        let remaining: any = await DropdownService.WorkAllocationTeamMembersDropdown();
        let list: any[] = [];
        for (let i = 0; i < remaining?.length; i++) {
            list.push({
                label: `${remaining[i]?.firstName} ${remaining[i]?.lastName}`,
                value: remaining[i].id?.toString(),
            });
        }
        if (search == null || search === "") resolve(list);
        resolve(list.filter(l => l.label.includes(search)))
    }));

    render() {
        const { toggle, setHideModal, purchaseOrderItem } = this.props;
        return (<Modal
            isOpen={toggle}
            onRequestClose={() => this.props.setHideModal()}
        >
            <div className={`f-w-500 stage-assignment-modal`}>
                <p className="">Work Allocation</p>

                <form className="d-flex flex-column">
                    <div className={`form-group`}>
                        <label htmlFor="selectedValue">Assignee<span className="requried-span">*</span></label>
                        <AsyncSelect id="selectedValue" cacheOptions defaultOptions ref={this.inputRef}
                            className="async-select-control custom-class"
                            loadOptions={this.loadAssigneeOption}
                            value={purchaseOrderItem?.purchaseOrderItemWorkAllocation?.user ? { label: `${purchaseOrderItem?.purchaseOrderItemWorkAllocation?.user?.firstName} ${purchaseOrderItem?.purchaseOrderItemWorkAllocation?.user?.lastName}`, value: purchaseOrderItem?.purchaseOrderItemWorkAllocation?.user?.id } : { label: "Select assignee", value: "" }}
                            onChange={async (e) => {
                                const userItemId = e?.value != "" && !isNaN(Number(e?.value)) ? Number(e?.value) : null;
                                if (userItemId == null) return;
                                const user = await UserService.GetById(userItemId);
                                if (user == null) return;
                                let data: any = {
                                    workflowId: this.props.workflowData?.id,
                                    userId: userItemId,
                                    user: user,
                                    purchaseOrderItemId: purchaseOrderItem?.id,
                                };

                                if (purchaseOrderItem?.purchaseOrderItemWorkAllocationId) {
                                    data.id = purchaseOrderItem?.purchaseOrderItemWorkAllocationId;
                                    await this.props.reAssignAPOLineItem(data);
                                    await this.props.loadWorkflowById(Number(this.props.workflowData?.id));
                                    setHideModal();
                                } else {
                                    await this.props.assignAPOLineItem(data);
                                    await this.props.loadWorkflowById(Number(this.props.workflowData?.id));
                                    setHideModal();
                                }
                            }}
                            styles={{
                                menuList: base => ({
                                    ...base,
                                    maxHeight: 240,
                                })
                            }}
                        />
                    </div>
                    <button
                        type="button"
                        className="btn btn-primary font-12 align-self-end mr-0 mb-0"
                        onClick={setHideModal}

                    >
                        Cancel
                    </button>
                </form>
            </div>
        </Modal>)
    }
}


export default connect(null, { reAssignAPOLineItem, assignAPOLineItem, loadWorkflowById })(StageAssignmentModalComponent);
