import { IPurchaseOrderLineItemModel, IPurchaseOrderServiceItemModel } from "../purchase-order-item.model";
import { IPurchaseOrderModel } from "../purchase-order.model";
import { clientAddressDefault, clientDefault } from "../../../client/model/client.default";

export const purchaseOrderServiceItemDefault: IPurchaseOrderServiceItemModel = {
    id: 0,
    code: '',
    title: '',
    hsnSacCode: ''
}

export const purchaseOrderDefault: IPurchaseOrderModel = {
    id: 0,
    poNumber: "",
    poDate: "",
    amendmentDate: null,
    quotationDate: null,

    lineItems: [],
    clientId: null,
    client: clientDefault,

    billingAddress: clientAddressDefault,
    deliveryAddress: clientAddressDefault,
    workflowAssigneeUserId:null,
    isWatching:false,
    clientCoordinatorName:null,
    clientCoordinatorEmail:null,
    clientCoordinatorPhoneNumber:null,
    description:null,
}

export const purchaseOrderLineItemDefault: IPurchaseOrderLineItemModel = {
    id: 0,
    type: 2,
    serviceId: null,
    description: "",
    quantity: 0,
    unitRate: 0,
    unitMeasurement: "",
    totalAmount: 0,
    purchaseOrderId: 0
}
