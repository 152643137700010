import { IFieldValidationModel, IValidatorModel } from "../../../../common/model/validator.model";
import { ValidationScreens, ValidationType } from "../../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "assigneeId",
        rules: [
            {
                type: ValidationType.Required,
                message: "Assignee is required"
            }
        ]
    },
    {
        fieldName: "reportNumber",
        rules: [
            {
                type: ValidationType.Required,
                message: "ReportNumber is required"
            }
        ]
    },
    {
        fieldName: "booking.dataLoggers",
        rules: [
            {
                type: ValidationType.ListGreaterThanZero,
                message: "At-least one DataLogger selection is required"
            }
        ]
    },
    {
        fieldName: "booking.description",
        rules: [
            {
                type: ValidationType.Required,
                message: "Description is required"
            }
        ]
    },
    {
        fieldName: "isAssetBookingCompleted",
        rules: [
            {
                type: ValidationType.RequiredIfTrue,
                message: "Please complete asset booking"
            }
        ]
    },
    {
        fieldName: "isAssetFunctionalityCheckCompleted",
        rules: [
            {
                type: ValidationType.RequiredIfTrue,
                message: "Please complete asset functionality"
            }
        ]
    },
    {
        fieldName: "isCalibrationCertificateShared",
        rules: [
            {
                type: ValidationType.RequiredIfTrue,
                message: "Please complete Cclibration certificate shared"
            }
        ]
    },
    {
        fieldName: "isPreValidationCompleted",
        rules: [
            {
                type: ValidationType.RequiredIfTrue,
                message: "Please complete preValidation completed"
            }
        ]
    }


]

export const workflowTaskBookingStageValidator: IValidatorModel = {
    screen: ValidationScreens.WorkflowTaskBookingStage,
    fields: fields
}
