import { matchPath, Route, Routes, useLocation } from "react-router-dom";
import AccountLayout from "../../common/screen/layout/account.layout";
import ScreenUrls from "../../common/screen-urls";
import WorkflowViewPage from "./screen/workflow-view.page";
import WorkflowPage from "./screen/workflow.page";
import WorkflowCreateEditPage from "./screen/form/workflow-create-edit.page";
import RouteHelper from "../../common/helper/route-helper";
import { AccessLevel, FeatureNames } from "../../common/enums";
import AccessDeniedPage from "../../common/screen/anonymous/access-denied.page";

const WorkflowRoutes = () => {
  const location = useLocation();
  const workflow = RouteHelper.GetFeatureAccessLevel(FeatureNames.Workflow);
  const workflowTask = RouteHelper.GetFeatureAccessLevel(
    FeatureNames.WorkflowTask
  );
  return (
    <Routes>
      <Route element={<AccountLayout />}>
        <Route path={ScreenUrls.Workflows.Index()}>
          <Route
            index
            element={
              workflow.accessLevel >= AccessLevel.View ? (
                <WorkflowPage accessLevel={workflow.accessLevel} />
              ) : (
                <AccessDeniedPage />
              )
            }
          />
          <Route
            path={ScreenUrls.ViewUriParam}
            element={
              <WorkflowViewPage
                accessLevel={workflow.accessLevel}
                id={
                  matchPath(
                    { path: ScreenUrls.Workflows.View(":id") },
                    location.pathname
                  )?.params["id"]
                }
              />
            }
          />
          <Route
            path={ScreenUrls.EditUriParam}
            element={
              <WorkflowCreateEditPage
                accessLevel={workflow.accessLevel}
                accessLevelTask={workflowTask.accessLevel}
                id={
                  matchPath(
                    { path: ScreenUrls.Workflows.Edit(":id") },
                    location.pathname
                  )?.params["id"]
                }
              />
            }
          />
        </Route>
      </Route>
    </Routes>
  );
};

export default WorkflowRoutes;
