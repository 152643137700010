import { BaseComponent } from "../../../common/component/base.component";
import { connect } from "react-redux";
import { IAppState } from "../../../store";
import { IPurchaseOrderModel, ILoadPurchaseOrderFilters } from "../model/purchase-order.model";
import { loadPurchaseOrders } from "../store/purchase-order.slice";
import { Link } from "react-router-dom";
import ScreenUrls from "../../../common/screen-urls";
import { AgGridReact } from "ag-grid-react";
import { displayDate } from "../../../common/utils";
import { AccessLevel } from "../../../common/enums";
import { AgGridDefaultColDef } from "../../../common/app-defaults";
import SearchBox from "../../../common/component/searchbox.component";

export interface IProps {
    accessLevel: number,
    purchaseOrderList: IPurchaseOrderModel[],
    loadPurchaseOrders: (payload: ILoadPurchaseOrderFilters) => void,
    dataLoading: boolean
}

export interface IState extends ILoadPurchaseOrderFilters {

}

const defaultState: IState = {
    clientName: "",
    poNumber: "",
};

class PurchaseOrderPage extends BaseComponent<IProps, IState> {

    constructor(props) {
        super(props);
        this.state = defaultState;
    }

    componentDidMount() {
        this.props.loadPurchaseOrders(this.state);
    }

    updateHandler(e, field: string) {
        if (e != null) {
            this.setState((prev) => {
                return { ...prev, [field]: e.target.value }
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState, snapshot?: any): void {
        if (prevState?.clientName != this.state.clientName || prevState.poNumber != this.state.poNumber) {
            let filter: ILoadPurchaseOrderFilters = {};
            if (this.state?.clientName) {
                filter.clientName = this.state.clientName;
            }
            if (this.state?.poNumber) {
                filter.poNumber = this.state.poNumber;
            }
            this.props.loadPurchaseOrders(filter);
        }
    }

    render() {
        return <div className="pcoded-content">
            <div className="pcoded-inner-content">
                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header d-flex align-items-center justify-content-between">
                                        <h5>List of purchase order</h5>
                                        <div className="">
                                            {(() => {
                                                if (this.props.dataLoading || this.props.accessLevel < AccessLevel.Create) return;
                                                return <Link className="btn drp-icon btn-rounded btn-primary m-0" to={ScreenUrls.PurchaseOrder.Create()}>
                                                    Add New
                                                </Link>
                                            })()}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="row px-3">
                                            <div className="col-3">
                                                <SearchBox
                                                    label="Client Name"
                                                    htmlFor="clientName"
                                                    value={this.state?.clientName ?? ""}
                                                    onBlurHandler={async (e) => {
                                                        this.updateHandler(e, "clientName")
                                                    }}
                                                />

                                            </div>
                                            <div className="col-3">
                                                <SearchBox
                                                    label="PO Number"
                                                    htmlFor="PONumber"
                                                    value={this.state?.poNumber ?? ""}
                                                    onBlurHandler={async (e) => {
                                                        this.updateHandler(e, "poNumber")
                                                    }}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                    {(() => { if (this.props.dataLoading) return <div className="loading--bar"><span></span></div> })()}
                                    <div className="card-block ag-theme-alpine xx-large-grid">
                                        <AgGridReact
                                            pagination={true}
                                            paginationAutoPageSize={true}
                                            unSortIcon={true}
                                            rowHeight={60}
                                            defaultColDef={AgGridDefaultColDef}
                                            columnDefs={[
                                                /*{
                                                    headerName: "", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link  grid-cell-link",
                                                    cellRenderer: (params) => {
                                                        return (
                                                            <Link to={ScreenUrls.PurchaseOrder.Edit(params.value)}>
                                                                <i className={`feather icon-edit`}></i>
                                                            </Link>
                                                        )
                                                    }
                                                },*/
                                                {
                                                    suppressMovable: true,
                                                    headerName: "PO No.", field: "poNumber", flex: 1, sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => <span>{params.value}</span>
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "PO Date", field: "poDate", width: 120, sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => <span>{displayDate(params.value)}</span>
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Client", field: "client", flex: 3, sortable: true, cellClass: "grid-cell",
                                                    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                        if (valueA?.companyName == valueB?.companyName) return 0;
                                                        return (valueA?.companyName > valueB?.companyName) ? 1 : -1;
                                                    },
                                                    cellRenderer: (params) => <span>{params.value?.companyName}</span>
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Client Coordinator", field: "client", flex: 3, sortable: true, cellClass: "grid-cell",
                                                    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                        if (valueA?.clientCoordinatorName == valueB?.clientCoordinatorName) return 0;
                                                        return (valueA?.clientCoordinatorName > valueB?.clientCoordinatorName) ? 1 : -1;
                                                    },
                                                    cellRenderer: (params) => <span>{params.data.clientCoordinatorName}</span>
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "GST", field: "client", flex: 1, sortable: true, cellClass: "grid-cell",
                                                    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                        if (valueA?.gstInNumber == valueB?.gstInNumber) return 0;
                                                        return (valueA?.gstInNumber > valueB?.gstInNumber) ? 1 : -1;
                                                    },
                                                    cellRenderer: (params) => <span>{params.value?.gstInNumber}</span>
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "PAN", field: "client", flex: 1, sortable: true, cellClass: "grid-cell",
                                                    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                        if (valueA?.panNumber == valueB?.panNumber) return 0;
                                                        return (valueA?.panNumber > valueB?.panNumber) ? 1 : -1;
                                                    },
                                                    cellRenderer: (params) => <span>{params.value?.panNumber}</span>
                                                },
                                                /*{
                                                    headerName: "Amendment No. ", field: "amendmentNumber", flex: 1, sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => <span>{params.value}</span>
                                                },
                                                {
                                                    headerName: "Amendment Date", field: "amendmentDate", width: 150, sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => <span>{params.value}</span>
                                                },
                                                {
                                                    headerName: "Quote No.", field: "quotationNumber", flex: 1, sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => <span>{params.value}</span>
                                                },
                                                {
                                                    headerName: "Quote Date", field: "quotationDate", width: 150, sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => <span>{params.value}</span>
                                                }*/
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Action", field: 'id', width: 120, cellClass: "grid-cell grid-cell-button",
                                                    cellRenderer: (params) => {
                                                        return (<Link to={ScreenUrls.PurchaseOrder.View(params.value)}>
                                                            View
                                                        </Link>)
                                                    }
                                                }
                                            ]}
                                            rowData={this.props.purchaseOrderList}>
                                        </AgGridReact>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state: IAppState) => ({
    purchaseOrderList: state.purchaseOrder.purchaseOrderList,
    dataLoading: state.purchaseOrder.dataLoading
})

export default connect(mapStateToProps, { loadPurchaseOrders })(PurchaseOrderPage);
