import { Route, Routes, matchPath, useLocation } from "react-router-dom";
import AccountLayout from "../../common/screen/layout/account.layout";
import ScreenUrls from "../../common/screen-urls";
import ReportPage from "./screen/report.page";
import CleanroomEquipmentCreateEditPage from "./screen/cleanroom/equipment/form/equipment-create-edit-page";
import AVTestManagerComponent from "./screen/cleanroom/equipment/tests/airflow-velocity-test/av-test-create-edit-page";
import FITestManagerComponent from "./screen/cleanroom/equipment/tests/filter-integrity-test/fi-test-create-edit-page";
import LITestManagerComponent from "./screen/cleanroom/equipment/tests/light-intensity-test/li-test-create-edit-page";
import PCTestManagerComponent from "./screen/cleanroom/equipment/tests/non-viable-particle-count-test/non-viable-particle-count-test-create-edit-page";
import RecoveryTestManagerComponent from "./screen/cleanroom/equipment/tests/recovery-test/recovery-test-create-edit-page";
import AirflowPatternTestManagerComponent from "./screen/cleanroom/equipment/tests/airflow-pattern-test/airflow-pattern-test-create-edit-page";

import SoundLevelTestmanagerComponent from "./screen/cleanroom/equipment/tests/sound-level-test/sound-level-test-create-edit-page";
import UVLightIntensitytestcomponent from "./screen/cleanroom/equipment/tests/uv-light-intensity-test/uv-light-intensity-test-create-edit-page";
import RouteHelper from "../../common/helper/route-helper";
import { AccessLevel, CleanroomEquipmentTests, FeatureNames } from "../../common/enums";
import AccessDeniedPage from "../../common/screen/anonymous/access-denied.page";

const ReportRoutes = () => {
    const location = useLocation();
    const report = RouteHelper.GetFeatureAccessLevel(FeatureNames.Report);

    return <Routes>
        <Route element={<AccountLayout />}>
            <Route path={ScreenUrls.Report.Index()}>
                <Route index
                    element={report.accessLevel >= AccessLevel.View ? <ReportPage accessLevel={report.accessLevel} /> : <AccessDeniedPage />}
                />
            </Route>
            <Route path={ScreenUrls.Report.CleanroomEquipment.Index()}>
                <Route path={ScreenUrls.CreateUri}
                    element={report.accessLevel >= AccessLevel.Create ? <CleanroomEquipmentCreateEditPage accessLevel={report.accessLevel} /> : <AccessDeniedPage />} />
                <Route
                    path={ScreenUrls.EditUriParam}
                    element={
                        report.accessLevel >= AccessLevel.Edit ? <CleanroomEquipmentCreateEditPage accessLevel={report.accessLevel}
                            id={
                                matchPath({ path: ScreenUrls.Report.CleanroomEquipment.Edit(":id") }, location.pathname)?.params["id"]}
                        /> : <AccessDeniedPage />} />

                {/* ----- airflow velocity test ----- */}
                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.airflowvelocity}/${ScreenUrls.CreateUri}`}
                    element={report.accessLevel >= AccessLevel.Create ? <AVTestManagerComponent
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.airflowvelocity}/${ScreenUrls.CreateUri}` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.airflowvelocity}/${ScreenUrls.EditUri}/:testId`}
                    element={report.accessLevel >= AccessLevel.Edit ? <AVTestManagerComponent
                        testId={Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.airflowvelocity}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["testId"])}
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.airflowvelocity}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                {/* ----- filter integrity test ----- */}
                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.filterintegrity}/${ScreenUrls.CreateUri}`}
                    element={report.accessLevel >= AccessLevel.Create ? <FITestManagerComponent
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.filterintegrity}/${ScreenUrls.CreateUri}` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.filterintegrity}/${ScreenUrls.EditUri}/:testId`}
                    element={report.accessLevel >= AccessLevel.Edit ? <FITestManagerComponent
                        testId={Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.filterintegrity}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["testId"])}
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.filterintegrity}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                {/* ----- Light Intensity Test ----- */}
                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.lightintensity}/${ScreenUrls.CreateUri}`}
                    element={report.accessLevel >= AccessLevel.Create ? <LITestManagerComponent
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.lightintensity}/${ScreenUrls.CreateUri}` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.lightintensity}/${ScreenUrls.EditUri}/:testId`}
                    element={report.accessLevel >= AccessLevel.Edit ? <LITestManagerComponent
                        testId={Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.lightintensity}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["testId"])}
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.lightintensity}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                {/* ---- NVPC Test*/}
                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.nonviableparticlecount}/${ScreenUrls.CreateUri}`}
                    element={report.accessLevel >= AccessLevel.Create ? <PCTestManagerComponent
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.nonviableparticlecount}/${ScreenUrls.CreateUri}` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />
                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.nonviableparticlecount}/${ScreenUrls.EditUri}/:testId`}
                    element={report.accessLevel >= AccessLevel.Edit ? <PCTestManagerComponent
                        testId={Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.nonviableparticlecount}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["testId"])}
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.nonviableparticlecount}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                {/* ---- Recovery Test ---- */}
                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.recovery}/${ScreenUrls.CreateUri}`}
                    element={report.accessLevel >= AccessLevel.Create ? <RecoveryTestManagerComponent
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.recovery}/${ScreenUrls.CreateUri}` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />
                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.recovery}/${ScreenUrls.EditUri}/:testId`}
                    element={report.accessLevel >= AccessLevel.Edit ? <RecoveryTestManagerComponent
                        testId={Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.recovery}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["testId"])}
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.recovery}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />
                {/* ---- Sound Level Test ---- */}
                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.soundlevel}/${ScreenUrls.CreateUri}`}
                    element={report.accessLevel >= AccessLevel.Create ? <SoundLevelTestmanagerComponent
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.soundlevel}/${ScreenUrls.CreateUri}` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />
                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.soundlevel}/${ScreenUrls.EditUri}/:testId`}
                    element={report.accessLevel >= AccessLevel.Edit ? <SoundLevelTestmanagerComponent
                        testId={Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.soundlevel}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["testId"])}
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.soundlevel}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                {/* ---- Airflow Pattern Test ---- */}
                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.airflowpattern}/${ScreenUrls.CreateUri}`}
                    element={report.accessLevel >= AccessLevel.Create ? <AirflowPatternTestManagerComponent
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.airflowpattern}/${ScreenUrls.CreateUri}` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />
                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.airflowpattern}/${ScreenUrls.EditUri}/:testId`}
                    element={report.accessLevel >= AccessLevel.Edit ? <AirflowPatternTestManagerComponent
                        testId={Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.airflowpattern}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["testId"])}
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.airflowpattern}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                {/* ---- uv light intensity Test ---- */}
                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.uvlightintensity}/${ScreenUrls.CreateUri}`}
                    element={report.accessLevel >= AccessLevel.Create ? <UVLightIntensitytestcomponent
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.uvlightintensity}/${ScreenUrls.CreateUri}` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />
                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.uvlightintensity}/${ScreenUrls.EditUri}/:testId`}
                    element={report.accessLevel >= AccessLevel.Edit ? <UVLightIntensitytestcomponent
                        testId={Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.uvlightintensity}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["testId"])}
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.uvlightintensity}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />
            </Route>
        </Route>
    </Routes>
}

export default ReportRoutes;
