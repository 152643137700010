import { IFieldValidationModel, IValidatorModel } from "../../../common/model/validator.model";
import { ValidationScreens, ValidationType } from "../../../common/enums";


const fields: IFieldValidationModel[] = [
    {
        fieldName: "cameraName",
        rules: [
            {
                type: ValidationType.Required,
                message: "Camera name is required"
            }
        ]
    },
    {
        fieldName: "cameraId",
        rules: [
            {
                type: ValidationType.Required,
                message: "camera id is required"
            }
        ]
    },
    {
        fieldName: "modelNumber",
        rules: [
            {
                type: ValidationType.Required,
                message: "Model Number is required"
            }
        ]
    }
]

export const dataLoggerCameraDetailValidator: IValidatorModel = {
    screen: ValidationScreens.DataLoggerCameraDetail,
    fields: fields
}
