import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {INavItem} from "../model/navigation.model";
import {navItems} from "../_static/navigation.data";

export interface INavState {
    navItems: INavItem[],
    activeNavIndex: number,
    activeSubNavIndex: number
}

const initialState: INavState = {
    navItems,
    activeNavIndex: 0,
    activeSubNavIndex: 0
}

const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        setActiveNav: (state, action: PayloadAction<number>) => {
            state.activeNavIndex = action.payload;
        },
        setActiveSubNav: (state, action: PayloadAction<number>) => {
            state.activeSubNavIndex = action.payload;
        }
    },
});

const { actions, reducer } = navigationSlice;

export const { setActiveNav, setActiveSubNav } = actions;

export default reducer;
