import {IFieldValidationModel, IValidatorModel} from "../../../../common/model/validator.model";
import {ValidationScreens, ValidationType} from "../../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "assigneeId",
        rules: [
            {
                type: ValidationType.Required,
                message: "Assignee is required"
            }
        ]
    }
]

export const workflowClosureStageValidator: IValidatorModel = {
    screen: ValidationScreens.WorkflowClosureStage,
    fields: fields
}
