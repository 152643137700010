import {IPurchaseOrderWorkAllocationModel} from "../model/purchase-order-work-allocation.model";
import ApiUrls from "../../../common/api-urls";
import HttpService from "../../../common/service/http.service";

export default class PurchaseOrderWorkAllocationService {
    static async AssignAPOLineItem(data: IPurchaseOrderWorkAllocationModel): Promise<any> {
        return await HttpService.Post<IPurchaseOrderWorkAllocationModel,any>(ApiUrls.WorkAllocation.Create(), data);
    }

    static async ReassignAPOLineItem(data: IPurchaseOrderWorkAllocationModel): Promise<any> {
        return await HttpService.Patch<IPurchaseOrderWorkAllocationModel,any>(ApiUrls.WorkAllocation.Edit(),data);
    }
}
