import React from "react";
import {BaseComponent} from "../../component/base.component";
import {IAppState} from "../../../store";
import {connect} from "react-redux";
import {loadCategories} from "../../stores/category.slice";
import {ICategoryModel} from "../../model/category.model";
import {AgGridReact} from "ag-grid-react";
import ScreenUrls from "../../screen-urls";
import {Link} from "react-router-dom";
import {AccessLevel} from "../../enums";
import {AgGridDefaultColDef} from "../../app-defaults";
import CategoryService from "../../service/category.service";
import GridDeleteButtonControl from "../../control/component/grid/grid-delete-buton.control";

export interface IProps {
    accessLevel: number,
    categoryList: ICategoryModel[],
    loadCategories: () => void,
    listLoading: boolean
}

class CategoryPage extends BaseComponent<IProps, any> {
    componentDidMount() {
        this.props.loadCategories();
    }

    render() {
        return <div className="pcoded-content">
            <div className="pcoded-inner-content">
                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    {(() => { if(this.props.listLoading) return <div className="loading--bar"><span></span></div> })()}
                                    <div className="card-header">
                                        <h5>List of categories</h5>
                                        <div className="card-header-right">
                                            <div className="btn-group card-option">
                                                {(() => {
                                                    if(this.props.accessLevel >= AccessLevel.Create) {
                                                        return <Link className="btn drp-icon btn-rounded btn-primary" to={ScreenUrls.DataLoggers.Category.Create()}>
                                                            Add New
                                                        </Link>
                                                    }
                                                })()}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-block ag-theme-alpine xx-large-grid">
                                        <AgGridReact
                                            pagination={true}
                                            paginationAutoPageSize={true}
                                            unSortIcon={true}
                                            rowHeight={60}
                                            defaultColDef={AgGridDefaultColDef}
                                            columnDefs={[
                                                {
                                                    suppressMovable: true,
                                                    hide: this.props.accessLevel < AccessLevel.Edit,
                                                    headerName: "Edit", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                    cellRenderer: (params) => {
                                                        return (
                                                            <Link to={ScreenUrls.DataLoggers.Category.Edit(params.value)}>
                                                                <i className={`feather icon-edit`}></i>
                                                            </Link>
                                                        )
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    hide: this.props.accessLevel < AccessLevel.Delete,
                                                    headerName: "Delete", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link delete",
                                                    cellRenderer: (params) => {
                                                        return (
                                                             <GridDeleteButtonControl onDelete={async () => {                                                                 
                                                                await CategoryService.Delete(params.value);
                                                                   this.props.loadCategories();                                                                                                                                  
                                                             }}></GridDeleteButtonControl>
                                                        )
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "#", field: 'id', width: 100, cellClass: "grid-cell",
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value}</span>
                                                    }
                                                },


                                                {
                                                    suppressMovable: true,
                                                    headerName: "Name", field: "name", flex: 2, sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value}</span>
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Description", field: "description", flex: 3, sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value}</span>
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Parent", field: "parent", flex: 2, sortable: true, cellClass: "grid-cell",
                                                    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                        if (valueA?.name == valueB?.name) return 0;
                                                        return (valueA?.name > valueB?.name) ? 1 : -1;
                                                    },
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value?.name}</span>
                                                    }
                                                }
                                            ]}
                                            rowData={this.props.categoryList}>
                                        </AgGridReact>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state: IAppState) => ({
    categoryList: state.category.categoryList,
    listLoading: state.category.listLoading
})

export default connect(mapStateToProps, { loadCategories })(CategoryPage);
