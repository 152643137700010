import React from "react";
import { IAppState } from "../../../../store";
import { connect } from "react-redux";
import { IStoreSaveModel } from "../../../../common/model/store-save.model";
import { IWorkflowTaskModel } from "../../model/workflow-task.model";
import {
    editWorkflowTask,
    loadWorkflowTaskById,
    updateWorkflowTaskDataState
} from "../../store/workflow-task.slice";
import { dataLoggerBookingDefault } from "../../../data-logger/model/defaults/data-logger.default";
import { loadDataLoaders } from "../../../data-logger/store/data-logger.slice";
import DataLoggerSelectorAccordionComponent from "../data-logger-selector.component";
import MultiFileControl from "../../../../common/control/file/multi-file.control";
import { EntityType, ValidationScreens } from "../../../../common/enums";
import { FormBaseComponent, IFormBaseState } from "../../../../common/component/form-base.component";
import ValidationMessageControl from "../../../../common/control/validation-message.control";
import { IPurchaseOrderModel } from "../../../purchase-order/model/purchase-order.model";
import { IWorkflowModel } from "../../../workflow/model/workflow.model";
import { loadPurchaseOrderById } from "../../../purchase-order/store/purchase-order.slice";
import ButtonGroupComponent from "../../../../common/component/button-group.component";
import { openStageAssignmentModal, IStageAssignment } from "../../../../common/stores/stage-assignment.slice";

interface IProps {
    workflowTaskData: IWorkflowTaskModel;
    loadWorkflowTaskById: (id: number) => void;
    editWorkflowTask: (data: IWorkflowTaskModel) => void,
    onChange: () => void;
    stageMoveLoading: boolean;
    updateWorkflowTaskDataState: (payload: IStoreSaveModel) => void;
    workflowDataLoading: boolean;
    workflowTaskCreateEditLoading: boolean;
    purchaseOrderData: IPurchaseOrderModel;
    workflowData: IWorkflowModel;
    purchaseDataLoading: boolean;
    loadPurchaseOrderById: (id: number) => void;
    isNavCollapsed: boolean,
    openStageAssignmentModal: (payload: IStageAssignment) => void,
}

export interface IState extends IFormBaseState { }

class BookingWorkflowTaskComponent extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            hideForm: false
        }
    }

    async componentDidMount() {
        if (this.props.workflowData.purchaseOrderId > 0) {
            await this.props.loadPurchaseOrderById(this.props.workflowData.purchaseOrderId);
        }

        await this.setValidator(ValidationScreens.WorkflowTaskBookingStage);
    }

    render() {
        if (this.state.hideForm) return;
        return <React.Fragment>
            <article className="card mb-4">
                {(() => {
                    if (this.props.workflowDataLoading || this.props.purchaseDataLoading) {
                        return <div className="loading--bar"><span></span></div>
                    }
                })()}
                <div className="card-header no-bottom-pad">
                    <div className="card-form-header-title">Please select data logger and complete following checklist before proceeding to next stage <span className="requried-span">*</span></div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-3">
                            <div className={`form-group read-only mb-1 ${this.vResult("booking.dataLoggers").className}`}>
                                <p className="mb-0">{this.props.workflowTaskData != null && this.props.workflowTaskData.booking != null
                                    && this.props.workflowTaskData.booking.dataLoggers != null && this.props.workflowTaskData.booking.dataLoggers.length > 0
                                    ? `${this.props.workflowTaskData.booking.dataLoggers.length} DataLogger(s) selected` : "No DataLogger selected"}</p>
                                <ValidationMessageControl message={this.vResult("booking.dataLoggers").message} />
                            </div>
                        </div>
                    </div>
                    {(() => {
                        if (this.props.workflowTaskData != null) {
                            return <DataLoggerSelectorAccordionComponent
                                defaultSelections={this.props.workflowTaskData?.booking?.dataLoggers ?? null}
                                category={this.props.workflowTaskData.category}
                                onSelection={(d) => {
                                    let dataLoggers = [
                                        ...this.props.workflowTaskData.booking?.dataLoggers ?? [],
                                        d
                                    ];

                                    this.ValidateField("booking.dataLoggers", dataLoggers);
                                    let data = {
                                        ...dataLoggerBookingDefault, ...this.props.workflowTaskData.booking,
                                        dataLoggers: dataLoggers, workflowTaskId: this.props.workflowTaskData.id
                                    };
                                    this.props.updateWorkflowTaskDataState({ name: "booking", value: data });
                                }}
                                onUnSelection={(d) => {
                                    let dataLoggers = this.props.workflowTaskData.booking?.dataLoggers ?? [];
                                    dataLoggers = dataLoggers.filter(dl => dl.id !== d.id);

                                    this.ValidateField("booking.dataLoggers", dataLoggers);
                                    const data = {
                                        ...dataLoggerBookingDefault, ...this.props.workflowTaskData.booking,
                                        dataLoggers: dataLoggers, workflowTaskId: this.props.workflowTaskData.id
                                    };
                                    this.props.updateWorkflowTaskDataState({ name: "booking", value: data });
                                }}
                                onUnSelectionAll={() => {
                                    this.ValidateField("booking.dataLoggers", []);
                                    const data = {
                                        ...dataLoggerBookingDefault, ...this.props.workflowTaskData.booking,
                                        dataLoggers: [], workflowTaskId: this.props.workflowTaskData.id
                                    };
                                    this.props.updateWorkflowTaskDataState({ name: "booking", value: data });
                                }}
                            />
                        }
                    })()}
                    <div className="row mt-3">
                        <div className="col-6">
                            <div className={`form-group ${this.vResult("reportNumber").className}`}>
                                <label htmlFor="description">Report Number<span className="requried-span">*</span></label>
                                <input id="description" className="form-control"
                                    value={this.props.workflowTaskData.reportNumber ?? ""}
                                    type="text"
                                    onChange={e => {
                                        e.preventDefault();
                                        this.ValidateField("reportNumber", e.target.value);

                                        this.props.updateWorkflowTaskDataState({ name: "reportNumber", value: e.target.value });
                                    }} />
                                <ValidationMessageControl message={this.vResult("reportNumber").message} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className={`form-group ${this.vResult("booking.description").className}`}>
                                <label htmlFor="description">Description<span className="requried-span">*</span></label>
                                <textarea id="description" className="form-control" rows={4}
                                    value={this.props.workflowTaskData.booking?.description?.toString() ?? ""}
                                    onChange={e => {
                                        e.preventDefault();
                                        this.ValidateField("booking.description", e.target.value);
                                        const data = {
                                            ...dataLoggerBookingDefault, ...this.props.workflowTaskData.booking,
                                            description: e.target.value, workflowTaskId: this.props.workflowTaskData.id
                                        };
                                        this.props.updateWorkflowTaskDataState({ name: "booking", value: data });
                                    }} />
                                <ValidationMessageControl message={this.vResult("booking.description").message} />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <input id="isAssetBookingCompleted" type="checkbox"
                                className="checkbox--input"
                                checked={this.props.workflowTaskData.isAssetBookingCompleted}
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.ValidateField("isAssetBookingCompleted", (e));
                                    setTimeout(() => {
                                        this.props.updateWorkflowTaskDataState({
                                            name: "isAssetBookingCompleted",
                                            value: !this.props.workflowTaskData.isAssetBookingCompleted
                                        });
                                    }, 1)
                                }} />
                            <label htmlFor="isAssetBookingCompleted" className="checkbox--label">
                                Has asset booking been completed?
                            </label>
                            <div className={`${this.vResult("isAssetBookingCompleted").className}`}>
                                <ValidationMessageControl message={this.vResult("isAssetBookingCompleted").message} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <input id="isAssetFunctionalityCheckCompleted" type="checkbox"
                                className="checkbox--input"
                                checked={this.props.workflowTaskData.isAssetFunctionalityCheckCompleted}
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.ValidateField("isAssetFunctionalityCheckCompleted", (e));
                                    setTimeout(() => {
                                        this.props.updateWorkflowTaskDataState({
                                            name: "isAssetFunctionalityCheckCompleted",
                                            value: !this.props.workflowTaskData.isAssetFunctionalityCheckCompleted
                                        });
                                    }, 1)
                                }} />
                            <label htmlFor="isAssetFunctionalityCheckCompleted" className="checkbox--label">
                                Has asset functionality check done?
                            </label>
                            <div className={`${this.vResult("isAssetFunctionalityCheckCompleted").className}`}>
                                <ValidationMessageControl message={this.vResult("isAssetFunctionalityCheckCompleted").message} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <input id="isCalibrationCertificateShared" type="checkbox"
                                className="checkbox--input"
                                checked={this.props.workflowTaskData.isCalibrationCertificateShared}
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.ValidateField("isCalibrationCertificateShared", (e));
                                    setTimeout(() => {
                                        this.props.updateWorkflowTaskDataState({
                                            name: "isCalibrationCertificateShared",
                                            value: !this.props.workflowTaskData.isCalibrationCertificateShared
                                        });
                                    }, 1)
                                }} />
                            <label htmlFor="isCalibrationCertificateShared" className="checkbox--label">
                                Has calibration certificate been shared?
                            </label>
                            <div className={`${this.vResult("isCalibrationCertificateShared").className}`}>
                                <ValidationMessageControl message={this.vResult("isCalibrationCertificateShared").message} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <input id="isPreValidationCompleted" type="checkbox"
                                className="checkbox--input"
                                checked={this.props.workflowTaskData.isPreValidationCompleted}
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.ValidateField("isPreValidationCompleted", (e));
                                    setTimeout(() => {
                                        this.props.updateWorkflowTaskDataState({
                                            name: "isPreValidationCompleted",
                                            value: !this.props.workflowTaskData.isPreValidationCompleted
                                        });
                                    }, 1)
                                }} />
                            <label htmlFor="isPreValidationCompleted" className="checkbox--label">
                                Has pre-verification been done for this workflow task?
                            </label>
                            <div className={`${this.vResult("isPreValidationCompleted").className}`}>
                                <ValidationMessageControl message={this.vResult("isPreValidationCompleted").message} />
                            </div>
                        </div>
                    </div>
                </div>
            </article>
            {/* Files */}
            {(() => {
                if (this.props.workflowTaskData != null && this.props.workflowTaskData.booking != null && this.props.workflowTaskData.booking.id > 0) {
                    return <MultiFileControl title="Booking Files"
                        entityType={EntityType.Booking}
                        entityId={this.props.workflowTaskData.booking.id} editable={true} />
                }
            })()}
            <ButtonGroupComponent>
                <>
                    <button type="button" className="btn btn--secondary btn--right"
                        disabled={this.props.stageMoveLoading
                            || this.props.workflowTaskCreateEditLoading
                        }
                        onClick={e => {
                            e.preventDefault();

                            const error = !this.ValidateForm(this.props.workflowTaskData, this.props.purchaseOrderData);
                            this.reloadForm();
                            if (error) return;
                            // ---- open Stage assignment modal ----
                            this.props.openStageAssignmentModal({
                                optionsList: this.props.workflowTaskData.nextStageAssigneeList,
                                openModal: true,
                                proceedBtnClicked: true,
                            });

                        }}>Proceed</button>

                    <button type="button" className="btn btn--red btn--right"
                        disabled={this.props.stageMoveLoading}
                        onClick={e => {
                            e.preventDefault();
                            // ---- open Stage assignment modal ----
                            this.props.openStageAssignmentModal({
                                optionsList: this.props.workflowTaskData.rejectStageAssigneeList,
                                openModal: true,
                                proceedBtnClicked: false,
                            });
                        }}>Reject</button>

                    <button type="button" className="btn btn--primary-d2 btn--right"
                        disabled={this.props.stageMoveLoading}
                        onClick={e => {
                            e.preventDefault();

                            this.props.editWorkflowTask(this.props.workflowTaskData);
                            this.props.onChange();
                        }}>Save</button>
                </>
            </ButtonGroupComponent>

            {(() => { if (this.props.stageMoveLoading || this.props.workflowTaskCreateEditLoading) return <div className="loading--bar fixed--top"><span></span></div> })()}
        </React.Fragment>
    }
}

const mapStateToProps = (state: IAppState) => ({
    workflowTaskData: state.workflowTask.workflowTaskData,
    stageMoveLoading: state.workflowTask.stageMoveLoading,
    workflowDataLoading: state.workflowTask.dataLoading,
    workflowTaskCreateEditLoading: state.workflowTask.createEditLoading,
    dataLoggerList: state.dataLogger.dataLoggerList,
    dataLoggerListLoading: state.dataLogger.listLoading,
    purchaseOrderData: state.purchaseOrder.purchaseOrderData,
    workflowData: state.workflow.workflowData,
    purchaseDataLoading: state.purchaseOrder.dataLoading,
    isNavCollapsed: state.userSession.isNavCollapsed,
})
export default connect(mapStateToProps, {
    loadWorkflowTaskById,
    editWorkflowTask,
    updateWorkflowTaskDataState,
    loadDataLoaders,
    loadPurchaseOrderById,
    openStageAssignmentModal
})(BookingWorkflowTaskComponent);
