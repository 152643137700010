import {IAccessRoleModel} from "../model/access-role.model";
import ApiUrls from "../../../common/api-urls";
import {IGetAllRequestModel} from "../../../common/model/get-all-request-model";
import {IFormControlDropdownItemModel} from "../../../common/control/model/form.control.field.model";
import DropdownDisplayHelper from "../../../common/helper/dropdown-display.helper";
import HttpService from "../../../common/service/http.service";

export default class AccessRoleService {
    static async LoadAll(request?: IGetAllRequestModel): Promise<IAccessRoleModel[]> {
        return await HttpService.Get<IAccessRoleModel[]>(ApiUrls.AccessRole.GetAll(request));
    }

    static async Create(data: IAccessRoleModel): Promise<IAccessRoleModel> {
        return await HttpService.Post<IAccessRoleModel, IAccessRoleModel>(ApiUrls.AccessRole.Create(), data);
    }

    static async Edit(data: IAccessRoleModel): Promise<IAccessRoleModel> {
        return await HttpService.Patch<IAccessRoleModel, IAccessRoleModel>(ApiUrls.AccessRole.Edit(data.id), data);
    }
    static async Delete(id: number) {         
         await HttpService.Delete(ApiUrls.AccessRole.Delete(id));         
    }

    static async GetById(id: number): Promise<IAccessRoleModel> {
        return await HttpService.Get<IAccessRoleModel>(ApiUrls.AccessRole.GetById(id));
    }

    static async AccessRoleDropdown(search: string): Promise<IFormControlDropdownItemModel[]> {
        let req: IGetAllRequestModel = { searchStr: search };
        return (await AccessRoleService.LoadAll(req)).map(d => {
            const obj: IFormControlDropdownItemModel = {
                label: DropdownDisplayHelper.AccessRole(d),
                value: d.id.toString()
            }
            return obj;
        });
    }
}
