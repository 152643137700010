import React from "react";
import { IAppState } from "../../../../store";
import { connect } from "react-redux";
import { IStoreSaveModel } from "../../../../common/model/store-save.model";
import { IWorkflowTaskModel } from "../../model/workflow-task.model";
import {
    editWorkflowTask,
    loadWorkflowTaskById,
    updateWorkflowTaskDataState
} from "../../store/workflow-task.slice";
import { loadDataLoaders } from "../../../data-logger/store/data-logger.slice";
import { FormBaseComponent, IFormBaseState } from "../../../../common/component/form-base.component";
import { IPurchaseOrderModel } from "../../../purchase-order/model/purchase-order.model";
import { IWorkflowModel } from "../../../workflow/model/workflow.model";
import { loadPurchaseOrderById } from "../../../purchase-order/store/purchase-order.slice";
import ButtonGroupComponent from "../../../../common/component/button-group.component";
import { openStageAssignmentModal, IStageAssignment } from "../../../../common/stores/stage-assignment.slice";

interface IProps {
    workflowTaskData: IWorkflowTaskModel;
    loadWorkflowTaskById: (id: number) => void;
    editWorkflowTask: (data: IWorkflowTaskModel) => void,
    onChange: () => void;
    stageMoveLoading: boolean;
    updateWorkflowTaskDataState: (payload: IStoreSaveModel) => void;
    workflowDataLoading: boolean;
    workflowTaskCreateEditLoading: boolean;
    purchaseOrderData: IPurchaseOrderModel;
    workflowData: IWorkflowModel;
    purchaseDataLoading: boolean;
    loadPurchaseOrderById: (id: number) => void;
    isNavCollapsed: boolean,
    openStageAssignmentModal: (payload: IStageAssignment) => void,
}

export interface IState extends IFormBaseState { }

class ActivityPlaningWorkflowTaskComponent extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            hideForm: false
        }
    }

    async componentDidMount() {
        if (this.props.workflowData.purchaseOrderId) {
            await this.props.loadPurchaseOrderById(this.props.workflowData.purchaseOrderId);
        }

    }

    render() {
        if (this.state.hideForm) return;
        return <React.Fragment>
            <article className="card mb-4">
                {(() => {
                    if (this.props.workflowDataLoading || this.props.purchaseDataLoading) {
                        return <div className="loading--bar"><span></span></div>
                    }
                })()}

            </article>

            <ButtonGroupComponent>
                <>
                    <button type="button" className="btn btn--secondary btn--right"
                        disabled={this.props.stageMoveLoading
                            || this.props.workflowTaskCreateEditLoading
                        }
                        onClick={e => {
                            e.preventDefault();

                            this.props.openStageAssignmentModal({
                                optionsList: this.props.workflowTaskData.nextStageAssigneeList,
                                openModal: true,
                                proceedBtnClicked: true,
                            });

                        }}>Proceed</button>

                    <button type="button" className="btn btn--red btn--right"
                        disabled={this.props.stageMoveLoading}
                        onClick={e => {
                            e.preventDefault();
                            // ---- open Stage assignment modal ----
                            this.props.openStageAssignmentModal({
                                optionsList: this.props.workflowTaskData.rejectStageAssigneeList,
                                openModal: true,
                                proceedBtnClicked: false,
                            });
                        }}>Reject</button>

                    <button type="button" className="btn btn--primary-d2 btn--right"
                        disabled={this.props.stageMoveLoading}
                        onClick={e => {
                            e.preventDefault();

                            this.props.editWorkflowTask(this.props.workflowTaskData);
                            this.props.onChange();
                        }}>Save</button>
                </>
            </ButtonGroupComponent>

            {(() => { if (this.props.stageMoveLoading || this.props.workflowTaskCreateEditLoading) return <div className="loading--bar fixed--top"><span></span></div> })()}
        </React.Fragment>
    }
}

const mapStateToProps = (state: IAppState) => ({
    workflowTaskData: state.workflowTask.workflowTaskData,
    stageMoveLoading: state.workflowTask.stageMoveLoading,
    workflowDataLoading: state.workflowTask.dataLoading,
    workflowTaskCreateEditLoading: state.workflowTask.createEditLoading,
    dataLoggerList: state.dataLogger.dataLoggerList,
    dataLoggerListLoading: state.dataLogger.listLoading,
    purchaseOrderData: state.purchaseOrder.purchaseOrderData,
    workflowData: state.workflow.workflowData,
    purchaseDataLoading: state.purchaseOrder.dataLoading,
    isNavCollapsed: state.userSession.isNavCollapsed,
})
export default connect(mapStateToProps, {
    loadWorkflowTaskById,
    editWorkflowTask,
    updateWorkflowTaskDataState,
    loadDataLoaders,
    loadPurchaseOrderById,
    openStageAssignmentModal
})(ActivityPlaningWorkflowTaskComponent);
