import React from "react";
import ScreenUrls from "../../../../common/screen-urls";
import { IDataLoggerModel } from "../../model/data-logger.model";
import { IAppState } from "../../../../store";
import { connect } from "react-redux";
import {
    clearDataLoaderDataState,
    createDataLogger,
    editDataLogger,
    loadDataLoaderById,
    updateDataLoggerDataState
} from "../../store/data-logger.slice";
import { Link } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { DeferredLoadOptions, localToUtcDate, utcToLocalDate } from "../../../../common/utils";
import DataLoggerMakeService from "../../service/data-logger-make.service";
import MultiFileControl from "../../../../common/control/file/multi-file.control";
import { EntityType, ValidationScreens } from "../../../../common/enums";
import { AppConstants } from "../../../../common/app-constants";
import DatePicker from "react-datepicker";
import { IStoreSaveModel } from "../../../../common/model/store-save.model";
import CategoryService from "../../../../common/service/category.service";
import DropdownDisplayHelper from "../../../../common/helper/dropdown-display.helper";
import { FormBaseComponent, IFormBaseState } from "../../../../common/component/form-base.component";
import ValidationMessageControl from "../../../../common/control/validation-message.control";

export interface IProps {
    accessLevel: number,
    id?: string,
    dataLoading: boolean,
    createEditLoading: boolean
    dataLoggerData: IDataLoggerModel,
    loadDataLoaderById: (id: number) => void;
    createDataLogger: (data: IDataLoggerModel) => void;
    editDataLogger: (data: IDataLoggerModel) => void;
    clearDataLoaderDataState: () => void;
    updateDataLoggerDataState: (payload: IStoreSaveModel) => void;
}

export interface IState extends IFormBaseState {
    hideSubCategory: boolean
}

class DataLoggerCreateEditPage extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            hideSubCategory: false,
            hideForm: false
        }
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.DataLogger);

        if (this.props.id != null && Number(this.props.id) > 0) {
            await this.props.loadDataLoaderById(Number(this.props.id));
        } else {
            await this.props.clearDataLoaderDataState();
        }
    }

    loadParentCategoryOption = (inputValue: string) => DeferredLoadOptions(inputValue, (search: string) =>
        CategoryService.LoadCategoriesList({ searchStr: search, onlyParents: true }));

    loadCategoryOption = (inputValue: string) => DeferredLoadOptions(inputValue, (search: string) =>
        CategoryService.LoadCategoriesList({ searchStr: search, parentId: this.props.dataLoggerData?.categoryId?.toString() ?? "0" }));

    loadMakersOption = (inputValue: string) => DeferredLoadOptions(inputValue, DataLoggerMakeService.MakeDropdown);

    reloadSubCategory = () => {
        this.setState({ hideSubCategory: true });
        setTimeout(() => {
            this.setState({ hideSubCategory: false });
        }, 1);
    }

    render() {
        return <React.Fragment>
            <div className="pcoded-content">
                <div className="pcoded-inner-content">
                    <div className="page-header">
                        <div className="page-block">
                            <div className="row">
                                <div className="col-md-12">
                                    <Link to={ScreenUrls.DataLoggers.Index()}
                                        className="btn drp-icon btn-rounded btn-primary dropdown-toggle">
                                        <i className="feather icon-arrow-left"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    {(() => { if (this.props.dataLoading || this.props.createEditLoading) return <div className="loading--bar fixed--top"><span></span></div> })()}
                    <div className="main-body">
                        <div className="page-wrapper">
                            <div className="row">
                                <div className="col-sm-12">
                                    <article className="card mb-4">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className={`form-group ${this.vResult("name").className}`}>
                                                        <label htmlFor="name">Name<span className="requried-span">*</span></label>
                                                        <input id="name" className="form-control" type="text"
                                                            value={this.props.dataLoggerData.name?.toString()}
                                                            onChange={e => {
                                                                e.preventDefault();
                                                                this.ValidateField("name", e.target.value);
                                                                this.props.updateDataLoggerDataState({ name: "name", value: e.target.value });
                                                            }} />
                                                        <ValidationMessageControl message={this.vResult("name").message} />
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className={`form-group ${this.vResult("categoryId").className}`}>
                                                        <label htmlFor="parentCategory">Category<span className="requried-span">*</span></label>
                                                        <AsyncSelect id="parentCategory" cacheOptions defaultOptions
                                                            className="async-select-control"
                                                            loadOptions={this.loadParentCategoryOption}
                                                            value={this.props.dataLoggerData.category != null
                                                                ? { label: DropdownDisplayHelper.Category(this.props.dataLoggerData.category), value: this.props.dataLoggerData?.categoryId?.toString() ?? "0" }
                                                                : { label: "Select Category", value: "" }}
                                                            onChange={async (e) => {
                                                                const cId = Number(e?.value);
                                                                if (cId === null || cId === 0) return;

                                                                this.ValidateField("categoryId", cId);
                                                                const category = await CategoryService.GetById(cId);
                                                                this.props.updateDataLoggerDataState({ name: "category", value: category });
                                                                this.props.updateDataLoggerDataState({ name: "categoryId", value: category.id });
                                                                this.reloadSubCategory();
                                                            }} />
                                                        <ValidationMessageControl message={this.vResult("categoryId").message} />
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className={`form-group ${this.vResult("subCategoryId").className}`}>
                                                        <label htmlFor="category">Sub Category<span className="requried-span">*</span></label>
                                                        {(() => {
                                                            if (this.state.hideSubCategory) return;
                                                            return <AsyncSelect id="category" defaultOptions
                                                                className="async-select-control"
                                                                loadOptions={this.loadCategoryOption}
                                                                isDisabled={this.props.dataLoggerData.categoryId == null || this.props.dataLoggerData.categoryId == 0}
                                                                value={this.props.dataLoggerData.subCategory != null
                                                                    ? { label: DropdownDisplayHelper.Category(this.props.dataLoggerData.subCategory), value: this.props.dataLoggerData?.subCategoryId?.toString() ?? "0" }
                                                                    : { label: "Select Sub Category", value: "" }}
                                                                onChange={async (e) => {
                                                                    const cId = Number(e?.value);
                                                                    if (cId === null || cId === 0) return;

                                                                    this.ValidateField("subCategoryId", cId);
                                                                    const subCategory = await CategoryService.GetById(cId);
                                                                    this.props.updateDataLoggerDataState({ name: "subCategory", value: subCategory });
                                                                    this.props.updateDataLoggerDataState({ name: "subCategoryId", value: subCategory.id });
                                                                }} />
                                                        })()}
                                                        <ValidationMessageControl message={this.vResult("subCategoryId").message} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3">
                                                    <div className={`form-group ${this.vResult("makeId").className}`}>
                                                        <label htmlFor="maker">Make<span className="requried-span">*</span></label>
                                                        <AsyncSelect id="maker" cacheOptions defaultOptions
                                                            className="async-select-control"
                                                            loadOptions={this.loadMakersOption}
                                                            value={this.props.dataLoggerData.make != null
                                                                ? { label: DropdownDisplayHelper.DataLoggerMake(this.props.dataLoggerData.make), value: this.props.dataLoggerData.make.id.toString() }
                                                                : { label: "Select Make", value: "" }}
                                                            onChange={async (e) => {
                                                                const mId = Number(e?.value);
                                                                if (mId === null || mId === 0 || (this.props.dataLoggerData.make != null && mId === this.props.dataLoggerData.make.id)) return;

                                                                this.ValidateField("makeId", mId);
                                                                const make = await DataLoggerMakeService.GetById(mId);
                                                                this.props.updateDataLoggerDataState({ name: "make", value: make });
                                                                this.props.updateDataLoggerDataState({ name: "makeId", value: make.id });
                                                            }} />
                                                        <ValidationMessageControl message={this.vResult("makeId").message} />
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className={`form-group ${this.vResult("serialNumber").className}`}>
                                                        <label htmlFor="serialNumber">Serial Number<span className="requried-span">*</span></label>
                                                        <input id="serialNumber" className="form-control" type="text"
                                                            value={this.props.dataLoggerData.serialNumber ?? ""}
                                                            onChange={e => {
                                                                e.preventDefault();
                                                                this.ValidateField("serialNumber", e.target.value);
                                                                this.props.updateDataLoggerDataState({ name: "serialNumber", value: e.target.value });
                                                            }} />
                                                        <ValidationMessageControl message={this.vResult("serialNumber").message} />
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className={`form-group ${this.vResult("modelNumber").className}`}>
                                                        <label htmlFor="modelNumber">Model Number</label>
                                                        <input id="modelNumber" className="form-control" type="text"
                                                            value={this.props.dataLoggerData.modelNumber ?? ""}
                                                            onChange={e => {
                                                                e.preventDefault();
                                                                this.ValidateField("modelNumber", e.target.value);
                                                                this.props.updateDataLoggerDataState({ name: "modelNumber", value: e.target.value });
                                                            }} />
                                                        <ValidationMessageControl message={this.vResult("modelNumber").message} />
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className={`form-group ${this.vResult("instrumentId").className}`}>
                                                        <label htmlFor="instrumentId">Instrument Id</label>
                                                        <input id="instrumentId" className="form-control" type="text"
                                                            value={this.props.dataLoggerData.instrumentId ?? ""}
                                                            onChange={e => {
                                                                e.preventDefault();
                                                                this.ValidateField("instrumentId", e.target.value);
                                                                this.props.updateDataLoggerDataState({ name: "instrumentId", value: e.target.value });
                                                            }} />
                                                        <ValidationMessageControl message={this.vResult("instrumentId").message} />
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className={`form-group d-flex flex-column ${this.vResult("lastCalibratedOn").className}`}>
                                                        <label htmlFor="lastCalibrate">Last Calibrate<span className="requried-span">*</span></label>
                                                        <DatePicker key="lastCalibrate" selected={utcToLocalDate(this.props.dataLoggerData.lastCalibratedOn?.toString() ?? "")}
                                                            dateFormat={AppConstants.DatePickerFormat}
                                                            onChange={(d) => {
                                                                this.ValidateField("lastCalibratedOn", localToUtcDate(d));
                                                                this.props.updateDataLoggerDataState({ name: "lastCalibratedOn", value: localToUtcDate(d) });
                                                            }} />
                                                        <ValidationMessageControl message={this.vResult("lastCalibratedOn").message} />
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className={`form-group d-flex flex-column ${this.vResult("calibrationDueOn").className}`}>
                                                        <label htmlFor="calibrationDueOn">Calibration Due<span className="requried-span">*</span></label>
                                                        <DatePicker key="calibrationDueOn" selected={utcToLocalDate(this.props.dataLoggerData.calibrationDueOn?.toString() ?? "")}
                                                            dateFormat={AppConstants.DatePickerFormat}
                                                            onChange={(d) => {
                                                                this.ValidateField("calibrationDueOn", localToUtcDate(d));
                                                                this.props.updateDataLoggerDataState({ name: "calibrationDueOn", value: localToUtcDate(d) });
                                                            }} />
                                                        <ValidationMessageControl message={this.vResult("calibrationDueOn").message} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3">
                                                    <div className={`form-group ${this.vResult("isDamaged").className}`}>
                                                        <input id="isDamaged" type="checkbox"
                                                            className="checkbox--input"
                                                            checked={this.props.dataLoggerData.isDamaged}
                                                            onChange={(e) => {
                                                                e.preventDefault();
                                                                this.ValidateField("isDamaged", !this.props.dataLoggerData.isDamaged);
                                                                setTimeout(() => {
                                                                    this.props.updateDataLoggerDataState({
                                                                        name: "isDamaged",
                                                                        value: !this.props.dataLoggerData.isDamaged
                                                                    });
                                                                }, 1)
                                                            }} />
                                                        <label htmlFor="isDamaged" className="checkbox--label">
                                                            Is DataLogger Damaged?
                                                        </label>
                                                        <ValidationMessageControl message={this.vResult("isDamaged").message} />
                                                    </div>
                                                </div>
                                                {/*<div className="col-3">
                                                    <div className={`form-group ${this.vResult("isAvailable").className}`}>
                                                        <input id="isAvailable" type="checkbox"
                                                               className="checkbox--input"
                                                               checked={this.props.dataLoggerData.isAvailable}
                                                               onChange={(e) => {
                                                                   e.preventDefault();
                                                                   this.ValidateField("isAvailable", !this.props.dataLoggerData.isAvailable);
                                                                   setTimeout(() => {
                                                                       this.props.updateDataLoggerDataState({
                                                                           name: "isAvailable",
                                                                           value: !this.props.dataLoggerData.isAvailable
                                                                       });
                                                                   }, 1)
                                                               }} />
                                                        <label htmlFor="isAvailable" className="checkbox--label">
                                                            Is DataLogger Available?
                                                        </label>
                                                        <ValidationMessageControl message={this.vResult("isDamaged").message} />
                                                    </div>
                                                </div>*/}
                                            </div>
                                        </div>
                                    </article>


                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <button type="button" className="btn btn-primary btn--right"
                                                    disabled={this.props.dataLoggerData == null || this.hasError}
                                                    onClick={async e => {
                                                        e.preventDefault();

                                                        const error = !this.ValidateForm(this.props.dataLoggerData);
                                                        this.reloadForm();
                                                        if (error) return;
                                                        if (this.props.dataLoggerData.id > 0) {
                                                            this.props.editDataLogger(this.props.dataLoggerData);
                                                        } else {
                                                            this.props.createDataLogger(this.props.dataLoggerData);
                                                        }
                                                    }}>Submit</button>
                                            </div>
                                        </div>
                                    </div>



                                    {/* Files */}
                                    {(() => {
                                        const id = Number(this.props.dataLoggerData?.id ?? 0);
                                        if (id > 0) {
                                            return <MultiFileControl title="Files"
                                                entityType={EntityType.DataLogger}
                                                entityId={id} editable={true} />
                                        }
                                    })()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}

const mapStateToProps = (state: IAppState) => ({
    dataLoggerData: state.dataLogger.dataLoggerData,
    dataLoading: state.dataLogger.dataLoading,
    createEditLoading: state.dataLogger.createEditLoading
})
export default connect(mapStateToProps, { createDataLogger, editDataLogger, loadDataLoaderById, clearDataLoaderDataState, updateDataLoggerDataState })(DataLoggerCreateEditPage);
