import ScreenUrls from "../../../../common/screen-urls";
import React from "react";
import { IAppState } from "../../../../store";
import { connect } from "react-redux";
import StageAssignmentModalComponent from "../../../../common/component/stage-assignment-modal.component";
import {
    clearPurchaseOrderDataState,
    createPurchaseOrder,
    editPurchaseOrder,
    loadPurchaseOrderById,
    updatePurchaseOrderDataState
} from "../../store/purchase-order.slice";
import { openStageAssignmentModal, IStageAssignment } from "../../../../common/stores/stage-assignment.slice";
import { IPurchaseOrderModel } from "../../model/purchase-order.model";
import { Link, Navigate } from "react-router-dom";
import { ValidationScreens } from "../../../../common/enums";
import { FormBaseComponent, IFormBaseState } from "../../../../common/component/form-base.component";
import { setReturnUrl } from "../../../user/store/user-session.slice";
import PurchaseOrderCreateEditComponent from "../../component/purchase-order-create-edit.component";
import DropdownService from "../../../../common/service/dropdown.service";

export interface IProps {
    accessLevel: number,
    accessLevelLineItem: number,
    id?: string,
    loadPurchaseOrderById: (id: number) => void,
    purchaseOrderData: IPurchaseOrderModel,
    createPurchaseOrder: (data: IPurchaseOrderModel) => void;
    editPurchaseOrder: (data: IPurchaseOrderModel) => void;
    clearPurchaseOrderDataState: () => void,
    setReturnUrl: (url: string) => void,
    dataLoading: boolean,
    createEditLoading: boolean,
    openStageAssignmentModal: (payload: IStageAssignment) => void,
    stageAssignment: IStageAssignment,
}

export interface IState extends IFormBaseState {
    redirectUrl: string;
}

class PurchaseOrderCreateEditPage extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            redirectUrl: "",
            hideForm: false
        }
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.PurchaseOrderBasic);

        let returnUrl = "";
        if (this.props.id != null && Number(this.props.id) > 0) {
            await this.props.loadPurchaseOrderById(Number(this.props.id));
            returnUrl = ScreenUrls.PurchaseOrder.Edit(this.props.id)
        } else {
            await this.props.clearPurchaseOrderDataState();
            returnUrl = ScreenUrls.PurchaseOrder.Create();
        }

        await this.props.setReturnUrl(returnUrl);
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (this.props.purchaseOrderData.id && this.props.purchaseOrderData.workflowId && prevProps.purchaseOrderData.workflowId != this.props.purchaseOrderData.workflowId) {
            const workflowId = this.props.purchaseOrderData.workflowId;
            this.setState({ redirectUrl: ScreenUrls.Workflows.Edit(workflowId) });
        }
    }

    render() {
        if (this.state.redirectUrl != "") return <Navigate to={this.state.redirectUrl} replace />
        if (this.props.dataLoading || this.props.createEditLoading) return <div className="loading--bar fixed--top"><span></span></div>
        return <React.Fragment>
            <div className="pcoded-content">
                <div className="pcoded-inner-content">
                    <div className="page-header">
                        <div className="page-block">
                            <div className="row">
                                <div className="col-md-12">
                                    <Link to={this.props.id ? ScreenUrls.PurchaseOrder.View(this.props.id) : ScreenUrls.PurchaseOrder.Index()}
                                        className="btn drp-icon btn-rounded btn-primary dropdown-toggle">
                                        <i className="feather icon-arrow-left"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-body">
                        <div className="page-wrapper">
                            <div className="row">
                                <div className="col-sm-12">
                                    {(() => {
                                        if (this.state.hideForm) return;
                                        return <PurchaseOrderCreateEditComponent id={this.props.id}
                                            accessLevel={this.props.accessLevel}
                                            accessLevelLineItem={this.props.accessLevelLineItem}
                                            updateHasError={(val: boolean) => this.setHasError(val)} />
                                    })()}
                                    <article className="card no-bg">
                                        <div className="row">
                                            <div className="col-12 text-right">

                                                <button type="button" className="btn mr-0 mb-0 btn-primary"
                                                    disabled={this.props.purchaseOrderData == null || this.hasError}
                                                    onClick={async (e) => {
                                                        e.preventDefault();
                                                        const error = !this.ValidateForm(this.props.purchaseOrderData, this.props.purchaseOrderData.id);
                                                        this.reloadForm();
                                                        if (error) return;

                                                        if (this.props.purchaseOrderData.id > 0) {
                                                            await this.props.editPurchaseOrder(this.props.purchaseOrderData);
                                                        } else {
                                                            // ---- open Stage assignment modal ----
                                                            let options = await DropdownService.SalesTeamMembersDropdown();
                                                            this.props.openStageAssignmentModal({ openModal: true, optionsList: options, proceedBtnClicked: true });
                                                        }
                                                    }}>Submit</button>

                                            </div>
                                        </div>

                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ---- modal popup for assigning PO ------ */}
                    {
                        this.props.stageAssignment.openModal &&

                        <StageAssignmentModalComponent
                            heading={"Assign workflow to sales person"}
                            proceedCallBack={async (obj: any) => {
                                if (obj) {
                                    await this.props.createPurchaseOrder({ ...this.props.purchaseOrderData, workflowAssigneeUserId: obj.id });
                                }
                            }}
                        />

                    }
                </div>
            </div>
        </React.Fragment>
    }
}

const mapStateToProps = (state: IAppState) => ({
    purchaseOrderData: state.purchaseOrder.purchaseOrderData,
    dataLoading: state.purchaseOrder.dataLoading,
    createEditLoading: state.purchaseOrder.createEditLoading,
    stageAssignment: state.stageAssignment,
})

export default connect(mapStateToProps, {
    loadPurchaseOrderById,
    updatePurchaseOrderDataState,
    createPurchaseOrder,
    editPurchaseOrder,
    clearPurchaseOrderDataState,
    setReturnUrl,
    openStageAssignmentModal,
})(PurchaseOrderCreateEditPage);
