    import React from "react";
    import {BaseComponent} from "../../component/base.component";

    export interface IProps {}

    class AccessDeniedPage extends BaseComponent<IProps, any> {
        render() {
            return <React.Fragment>
                <div className="pcoded-content">
                    <div className="pcoded-inner-content">
                        <div className="main-body">
                            <div className="page-wrapper">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div style={{ marginTop: "100px", minHeight: "100vh", textAlign: "center", verticalAlign: "middle" }}>
                                            <i className="feather icon-lock" style={{ fontSize: "50px", color: "#3f4d67", fontWeight: "bold" }}></i>
                                            <h4 style={{ fontFamily: "'Fira Code', monospace", fontSize: "36px", color: "#3f4d67", fontWeight: "bold",  marginTop: "10px" }}>
                                                Access Denied
                                            </h4>
                                            <h4 style={{ fontFamily: "'Fira Code', monospace", fontSize: "28px", color: "#a9b7d0", fontWeight: "bold",  marginTop: "40px" }}>
                                                Looks like you don't have access to view this page
                                            </h4>
                                            <h4 style={{ fontFamily: "'Fira Code', monospace", fontSize: "20px", color: "#a9b7d0", fontWeight: "bold",  marginTop: "5px" }}>
                                                Please contact admin team to get access
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        }
    }

    export default AccessDeniedPage;
