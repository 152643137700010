import { IClientEquipmentModel, clientEquipmentDefaults } from "../model/client-equipment.model";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IStoreSaveModel } from "../../../common/model/store-save.model";
import ScreenUrls, { redirectHandler } from "../../../common/screen-urls";
import ClientEquipmentService from "../service/client-equipment.service";

export interface IClientEquipmentState {
    equipmentList: IClientEquipmentModel[],
    equipmentData: IClientEquipmentModel,
    dataLoading: boolean,
    listLoading: boolean,
    createEditLoading: boolean,
}


const initialState: IClientEquipmentState = {
    equipmentList: [],
    equipmentData: clientEquipmentDefaults,
    dataLoading: false,
    listLoading: false,
    createEditLoading: false,
}

export const loadEquipments = createAsyncThunk("client/equipment/loadAll", ClientEquipmentService.LoadAll);
export const loadEquipmentById = createAsyncThunk("client/equipment/loadById", ClientEquipmentService.GetById);
export const createEquipment = createAsyncThunk("client/equipment/create", ClientEquipmentService.Create);
export const editEquipment = createAsyncThunk("client/equipment/edit", ClientEquipmentService.Edit);


export const quickAddEquipment = createAsyncThunk("client/quickAdd/equipment/create", ClientEquipmentService.Create);

const clientEquipmentSlice = createSlice({
    name: 'client-equipment',
    initialState,
    reducers: {
        clearEquipmentDataState: (state, action:any) => {
            state.equipmentData = action?.payload ?? clientEquipmentDefaults;
        },
        clearEquipmentListState: (state) => {
            state.equipmentList = [];
        },
        updateClientEquipmentDataState: (state, action: PayloadAction<IStoreSaveModel>) => {
            state.equipmentData[action.payload.name] = action.payload.value;
        },
    },
    extraReducers: (builder) => {
        // Load All
        builder.addCase(loadEquipments.pending, (state, action) => {
            clearEquipmentListState();
            state.listLoading = true;
        });
        builder.addCase(loadEquipments.rejected, (state, action) => {
            clearEquipmentListState();
            state.listLoading = false;
        });
        builder.addCase(loadEquipments.fulfilled, (state, action: any) => {
            state.equipmentList = action.payload ?? [];
            state.listLoading = false;
        });

        // Load By ID
        builder.addCase(loadEquipmentById.pending, (state, action) => { state.dataLoading = true; });
        builder.addCase(loadEquipmentById.rejected, (state, action) => {
            state.dataLoading = false;
        });
        builder.addCase(loadEquipmentById.fulfilled, (state, action) => {
            state.equipmentData = action.payload;
            state.dataLoading = false;
        });

        // Create
        builder.addCase(createEquipment.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(createEquipment.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(createEquipment.fulfilled, (state, action) => {
            state.equipmentData = action.payload;
            state.createEditLoading = false;
            redirectHandler(ScreenUrls.Clients.Equipment.Edit(`${action.payload?.id}`));
        });

        // ----- quickAdd ---
        builder.addCase(quickAddEquipment.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(quickAddEquipment.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(quickAddEquipment.fulfilled, (state, action) => {
            state.equipmentData = action.payload;
            state.createEditLoading = false;
        });


        // Edit
        builder.addCase(editEquipment.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(editEquipment.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(editEquipment.fulfilled, (state, action) => {
            state.equipmentData = action.payload;
            state.createEditLoading = false;
        });
    }
});

const { actions, reducer } = clientEquipmentSlice;

export const { clearEquipmentDataState, clearEquipmentListState, updateClientEquipmentDataState } = actions;

export default reducer;
