import { BaseComponent } from "./base.component";
import ValidationMessageControl from "../control/validation-message.control";

export interface IProps {
    value?: string,
    onChangeHandler: (e) => void,
    handleKeyDown?: (e) => void,
    isRequired?: boolean,
    label?: string,
    htmlFor?: string,
    type?: string,
    error: {
        className: string,
        message: string,
    },
    placeholder?: string,
    hideEyeIcon?:boolean
}

class InputComponent extends BaseComponent<IProps, { showPassword: boolean }> {
    constructor(props) {
        super(props);
        this.state = {
            showPassword: false
        }
    }
    render() {
        return (
            <div className={`form-group ${this.props.error?.className ?? ""}`}>
                {
                    this.props.label &&
                    <label htmlFor={this.props.htmlFor}>{this.props.label}{this.props.isRequired && <span className="requried-span">*</span>}</label>
                }

                <div className={`${this.props.type == "password" ? "common-input-wrapper" : ""}`}>
                    <input className="form-control"
                        id={this.props?.htmlFor}
                        type={this.state.showPassword ? "text" : this.props.type ?? "text"}
                        placeholder={this.props.placeholder ?? ""}
                        value={this.props.value}
                        {...this.props.handleKeyDown && { onKeyDown: this.props.handleKeyDown }}
                        onChange={e => {
                            e.preventDefault();
                            this.props.onChangeHandler(e);
                        }}
                    />

                    {
                        this.props.type == "password" && !this.props.hideEyeIcon &&
                        <div className="eye-div">
                            <span onClick={() => this.setState({ showPassword: !this.state.showPassword })}>
                                <i className={`feather icon-eye${this.state.showPassword ? "" : "-off"}`}></i>
                            </span>
                        </div>
                    }
                </div>
                <ValidationMessageControl message={this.props.error.message ?? ""} />

            </div >
        )
    }
}

export default InputComponent;