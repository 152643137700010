import { IDataLoggerModel } from "../model/data-logger.model";
import { createAsyncThunk, createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import DataLoggerService from "../service/data-logger.service";
import { dataLoggerDefault } from "../model/defaults/data-logger.default";
import { IStoreSaveModel } from "../../../common/model/store-save.model";
import { LocalStorageKeys } from "../../../common/enums";
import ScreenUrls, { redirectHandler } from "../../../common/screen-urls";
import {IPaginationModel} from "../../../common/model/pagination.model";
import {paginationDefaultState} from "../../../common/model/defaults/pagination.default";

export interface IDataLoggerState {
    dataLoggerList: IDataLoggerModel[],
    dataLoggerData: IDataLoggerModel,
    dataLoading: boolean,
    listLoading: boolean,
    createEditLoading: boolean,
    gridView: boolean,
    pagination: IPaginationModel,
}


const initialState: IDataLoggerState = {
    dataLoggerList: [],
    dataLoggerData: dataLoggerDefault,
    dataLoading: false,
    listLoading: false,
    createEditLoading: false,
    gridView: JSON.parse(localStorage.getItem(LocalStorageKeys["grid/tile view"].toString()) ?? "true"),
    pagination: paginationDefaultState,
}

export const loadDataLoaders = createAsyncThunk("data-logger/loadAll", DataLoggerService.LoadAll);
export const loadDataLoaderById = createAsyncThunk("data-logger/loadById", DataLoggerService.GetById);
export const createDataLogger = createAsyncThunk("data-logger/create", DataLoggerService.Create);
export const editDataLogger = createAsyncThunk("data-logger/edit", DataLoggerService.Edit);

const dataLoggerSlice = createSlice({
    name: 'data-logger',
    initialState,
    reducers: {
        clearDataLoaderDataState: (state) => { state.dataLoggerData = dataLoggerDefault },
        clearDataLoaderListState: (state) => {
            state.dataLoggerList = [];
            state.pagination = paginationDefaultState;
        },
        updateDataLoggerDataState: (state, action: PayloadAction<IStoreSaveModel>) => {
            state.dataLoggerData[action.payload.name] = action.payload.value;
        },
        toggleView: (state) => {
            localStorage.setItem(LocalStorageKeys["grid/tile view"].toString(), JSON.stringify(!state.gridView));
            state.gridView = !state.gridView;
        },
        changeCurrentPage: (state, action) => {
            state.pagination.page = action.payload;
        }
    },
    extraReducers: (builder) => {
        // Load All
        builder.addCase(loadDataLoaders.pending, (state, action) => {
            clearDataLoaderListState();
            state.listLoading = true;
        });
        builder.addCase(loadDataLoaders.rejected, (state, action) => {
            clearDataLoaderListState();
            state.listLoading = false;
        });
        builder.addCase(loadDataLoaders.fulfilled, (state, action: any) => {
            state.dataLoggerList = action.payload.data ?? [];
            state.listLoading = false;
            state.pagination = action.payload.pagination;
        });

        // Load By ID
        builder.addCase(loadDataLoaderById.pending, (state, action) => { state.dataLoading = true; });
        builder.addCase(loadDataLoaderById.rejected, (state, action) => {
            state.dataLoading = false;
        });
        builder.addCase(loadDataLoaderById.fulfilled, (state, action) => {
            state.dataLoggerData = action.payload;
            state.dataLoading = false;
        });

        // Create
        builder.addCase(createDataLogger.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(createDataLogger.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(createDataLogger.fulfilled, (state, action) => {
            state.dataLoggerData = action.payload;
            state.createEditLoading = false;
            redirectHandler(ScreenUrls.DataLoggers.Edit(action.payload?.id));
        });

        // Edit
        builder.addCase(editDataLogger.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(editDataLogger.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(editDataLogger.fulfilled, (state, action) => {
            state.dataLoggerData = action.payload;
            state.createEditLoading = false;
        });
    }
});

const { actions, reducer } = dataLoggerSlice;

export const { toggleView, changeCurrentPage, clearDataLoaderDataState, clearDataLoaderListState, updateDataLoggerDataState } = actions;

export default reducer;
