import React from "react";
import { Navigate, Outlet, redirect } from "react-router-dom";
import "../../../assets/styles/base/global.scss";
import "../../../assets/styles/plugins/data-tables/css/datatables.min.css";
import "../../../assets/styles/plugins/select2/css/select2.min.css";
import "../../../assets/styles/plugins/bootstrap-datetimepicker/css/bootstrap-datepicker.min.css";
import NavigationComponent from "../../component/navigation.component";
import HeaderComponent from "../../component/header.component";
import FooterComponet from "../../component/footer.component";
import { BaseComponent } from "../../component/base.component";
import { connect } from "react-redux";
import { setActiveNav, setActiveSubNav } from "../../stores/navigation.slice";
import { INavItem } from "../../model/navigation.model";
import { IAppState } from "../../../store";
import ScreenUrls from "../../screen-urls";
import { IUserModel } from "../../../features/user/model/user.model";
import { LocalStorageKeys } from "../../enums";
import moment from "moment";

const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
];

export interface IProps {
    navItems: INavItem[],
    currentUser: IUserModel,
    currentUserLoading: boolean,
    setActiveNav: (i: number) => void,
    setActiveSubNav: (i: number) => void,
    isLoggedIn: boolean,
}

class AccountLayout extends BaseComponent<IProps, any> {
    timer;
    componentDidMount() {
        Object.values(events).forEach((item) => {
            window.addEventListener(item, () => {
                this.resetTimer();
                this.handleLogoutTimer();
            });
        });
        let screenPath = window.location.pathname?.toLowerCase();
        screenPath = screenPath === ScreenUrls.RootUrl ? ScreenUrls.Dashboard.Index() : screenPath;

        const currentItem = this.props.navItems.find(ni => screenPath.includes(ni?.linkTo?.toLowerCase()));

        if (currentItem != null) {
            this.props.setActiveNav(currentItem.index);
            this.props.setActiveSubNav(0);
        } else {
            let child: any;
            const parentItem = this.props.navItems.find(ni => {
                child = ni?.subMenu?.find(childItem => screenPath.includes(childItem?.linkTo?.toLowerCase()));
                return child != null;
            });

            if (parentItem == null) return;

            this.props.setActiveNav(parentItem.index);
            this.props.setActiveSubNav(child.index);
        }
    }

    handleLogoutTimer = () => {
        this.timer = setTimeout(() => {
            this.resetTimer();
            // Listener clean up. Removes the existing event listener from the window
            Object.values(events).forEach((item) => {
                window.removeEventListener(item, this.resetTimer);
            });
            // logs out user
            window.location.href = ScreenUrls.Logout();
        }, 1000 * 60 * 10); // 10min.
    };

    resetTimer = () => {
        if (this.timer) clearTimeout(this.timer);
    };

    componentWillUnmount() {
        Object.values(events).forEach((item) => {
            window.removeEventListener(item, this.resetTimer);
        });
    }

    render() {
        const accessToken = localStorage.getItem(LocalStorageKeys.AccessToken.toString());
        // Redirect to login page if not logged in
        if (accessToken == null || accessToken == "" || !this.props.isLoggedIn || this.props.currentUser.featureAccessLevels == null || this.props.currentUser.featureAccessLevels.length == 0) {
            return <Navigate to={ScreenUrls.Login()} replace />
        }

        // Redirect to login page if token expired
        const expiryStr = localStorage.getItem(LocalStorageKeys.TokenExpiry.toString());
        const expiry = expiryStr != null && expiryStr != "" ? moment(expiryStr).local(true).toDate() : null;
        if (expiry == null || expiry < new Date()) {
            localStorage.removeItem(LocalStorageKeys.AccessToken.toString());
            localStorage.removeItem(LocalStorageKeys.TokenExpiry.toString());
            return <Navigate to={ScreenUrls.Login()} replace />
        }

        // Redirect to password change page if isPasswordResetRequired is true
        let screenPath = window.location.pathname?.toLowerCase();
        const passwordChangeUrl = ScreenUrls.Users.PasswordChange(this.props.currentUser.id);
        if (this.props.currentUser.isPasswordResetRequired && screenPath != passwordChangeUrl) {
            return <Navigate to={passwordChangeUrl} replace />
        }
        return <React.Fragment>
            <NavigationComponent />
            <HeaderComponent />
            <section className="pcoded-main-container" onClick={e => e.preventDefault()}>
                <div className="pcoded-wrapper">
                    <Outlet />
                    <FooterComponet />
                </div>
            </section>
        </React.Fragment>
    }
}

const mapStateToProps = (state: IAppState) => ({
    navItems: state.navigation.navItems,
    currentUserLoading: state.userSession.currentUserLoading,
    isLoggedIn: state.userSession.isLoggedIn,
    currentUser: state.userSession.currentUser,
})

export default connect(mapStateToProps, { setActiveNav, setActiveSubNav })(AccountLayout);
