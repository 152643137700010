import {BaseComponent} from "../../../common/component/base.component";
import {ISummaryModel} from "../model/dashboard.model";
import SummaryCardComponent from "./summary-card.component";

interface IProps {
    title: string,
    data: ISummaryModel[]
}

class DataWiseSummaryComponent extends BaseComponent<IProps, any> {

    render() {
        if(this.props.data == null || this.props.data.length == 0) return;
        return <div className="dashboard-data-wise--container">
            <p className="dashboard-data-wise--title">{this.props.title}</p>
            <div className="dashboard-data-wise--content">
                {(() => {
                    return this.props.data.map((e, i) => {
                        if(e.displayName == null || e.displayName == "") return;
                        return <SummaryCardComponent key={i} title={e.displayName} count={e.count} cardClass="data-wise" />
                    })
                })()}
            </div>
        </div>
    }
}

export default DataWiseSummaryComponent;
