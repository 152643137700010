import ApiUrls from "../../../common/api-urls";
import {ILoginReqModel} from "../model/login.req.model";
import {ITokenDataModel} from "../model/token.model";
import HttpService from "../../../common/service/http.service";
import {IResetPasswordReqModel} from "../../password-recovery/model/reset-password.request.model";
import {IForgotPasswordReqModel} from "../../password-recovery/model/forgot-password.request.model";

export default class AuthService {
    static async Login(data: ILoginReqModel): Promise<ITokenDataModel> {
        return await HttpService.Post<ILoginReqModel, ITokenDataModel>(ApiUrls.Auth.Login(), data);
    }

    // ===== anonyous requests =====
    static async ForgotPassword(email:string): Promise<ITokenDataModel | string> {
        const data:IForgotPasswordReqModel = {email}
        return await HttpService.Post<IForgotPasswordReqModel,ITokenDataModel | string>(ApiUrls.Auth.ForgotPassword(),data);
    }

    static async ResetPassword(data:IResetPasswordReqModel): Promise<string> {
        return await HttpService.Post<IResetPasswordReqModel,string>(ApiUrls.Auth.ResetPassword(),data);
    }
}
