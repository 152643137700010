import ApiUrls from "../../../common/api-urls";
import HttpService from "../../../common/service/http.service";
import {IRecentView} from "../model/recent-view.model";
import {IGetAllNotifications} from "../model/notification.model";
import { IGetAllNotificationsRequest } from "../model/request/get-all-notifications.request.model";

export default class NotificationService {
    static async QuickView(): Promise<IRecentView> {
        return await HttpService.Get<IRecentView>(ApiUrls.Notification.QuickView());
    }

    static async LoadAll(request:IGetAllNotificationsRequest): Promise<IGetAllNotifications> {
        return await HttpService.Get<IGetAllNotifications>(ApiUrls.Notification.GetAll(request));
    }

    static async MarkAllRead<T>():Promise<T>{
        return await HttpService.Patch<Object,T>(ApiUrls.Notification.MarkAllRead(),{});
    }

    static async MarkRead<T>(id:number):Promise<T>{
        return await HttpService.Patch<Object,T>(ApiUrls.Notification.MarkRead(id),{});
    }
}
