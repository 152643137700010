import {IClientAddressModel} from "../model/client.model";
import ApiUrls from "../../../common/api-urls";
import {IGetAllRequestModel} from "../../../common/model/get-all-request-model";
import HttpService from "../../../common/service/http.service";

export default class ClientAddressService {
    static async LoadAll(request?: IGetAllRequestModel): Promise<IClientAddressModel[]> {
        return await HttpService.Get<IClientAddressModel[]>(ApiUrls.ClientAddress.GetAll(request));
    }

    static async Create(data: IClientAddressModel): Promise<IClientAddressModel> {
        return await HttpService.Post<IClientAddressModel, IClientAddressModel>(ApiUrls.ClientAddress.Create(), data);
    }
    
    static async Edit(data: IClientAddressModel): Promise<IClientAddressModel> {
        return await HttpService.Patch<IClientAddressModel, IClientAddressModel>(ApiUrls.ClientAddress.Edit(data.id), data);
    }
    static async Delete(id: number) {
        await HttpService.Delete(ApiUrls.ClientAddress.Delete(id));
   }
    static async GetById(id: number): Promise<IClientAddressModel> {
        return await HttpService.Get<IClientAddressModel>(ApiUrls.ClientAddress.GetById(id));
    }
}
