import { IReportModel,ReportCommentPayload,ReportCommentModel } from "../model/report.model";
import ApiUrls from "../../../common/api-urls";
import HttpService from "../../../common/service/http.service";
import { IAirflowVelocityModel, IAVTestPayload, IBaseTestPayload } from "../model/test/test.model";
import { IFilterIntegrityModel, IFITestPayload } from "../model/test/filter-integrity-test.model";
import { ILightIntensityTestModel, ILITestPayload } from "../model/test/light-intensity-test.model";
import { IPCTestModel, IPCTestPayload } from "../model/test/particle-count-test.model";
import { IRecoveryTestPayload, IRecoveryTestModel } from "../model/test/recovery-test.model";
import { ISoundLevelTestModel, ISoundLevelTestPayload } from "../model/test/sound-level-test.model";
import { IUVLightTestModel, IUVTestPayload } from "../model/test/uv-light-test.model";
import { IAirflowPatternTestPayload, IAirflowPatterTestModel } from "../model/test/airflow-pattern-test.model";

export default class TestService {
    static async GetAVById(payload: IAVTestPayload): Promise<IAirflowVelocityModel> {
        return await HttpService.Get<IAirflowVelocityModel>(ApiUrls.Report.Test.GetById(payload.testName, payload.id ?? 0));
    }

    static async LoadAVAll(): Promise<IReportModel[]> {
        return await HttpService.Get<IReportModel[]>(ApiUrls.Report.GetAll());
    }

    static async CreateAVTest(payload: IAVTestPayload): Promise<IAirflowVelocityModel> {
        return await HttpService.Post<IAirflowVelocityModel | null, IAirflowVelocityModel>(ApiUrls.Report.Test.Create(payload.testName), payload.data ?? null);
    }

    static async EditAVTest(payload: IAVTestPayload): Promise<IAirflowVelocityModel> {
        return await HttpService.Patch<IAirflowVelocityModel | null, IAirflowVelocityModel>(ApiUrls.Report.Test.Edit(payload.testName, payload.data?.id ?? 0), payload.data ?? null);
    }

    // ==== common route ====
    static async DeleteTest(payload: IBaseTestPayload) {
        await HttpService.Delete(ApiUrls.Report.Test.Delete(payload.testName, payload.id ?? 0));
    }

    static async EnableTestEdit(payload:ReportCommentPayload){
        return await HttpService.Patch<ReportCommentModel,IAirflowPatterTestModel | IAirflowVelocityModel | IFilterIntegrityModel | IRecoveryTestModel | IPCTestModel | ISoundLevelTestModel | ILightIntensityTestModel | IUVLightTestModel>(ApiUrls.Report.Test.EnableEdit(payload.testName),payload.data)
    }

    // ---- FI test ---

    static async GetFITestById(payload: IFITestPayload): Promise<IFilterIntegrityModel> {
        return await HttpService.Get<IFilterIntegrityModel>(ApiUrls.Report.Test.GetById(payload.testName, payload.id ?? 0));
    }
    static async LoadFITestAll(): Promise<IReportModel[]> {
        return await HttpService.Get<IReportModel[]>(ApiUrls.Report.GetAll());
    }

    static async CreateFITest(payload: IFITestPayload): Promise<IFilterIntegrityModel> {
        return await HttpService.Post<IFilterIntegrityModel | null, IFilterIntegrityModel>(ApiUrls.Report.Test.Create(payload.testName), payload?.data ?? null);
    }

    static async EditFITest(payload: IFITestPayload): Promise<IFilterIntegrityModel> {
        return await HttpService.Patch<IFilterIntegrityModel | null, IFilterIntegrityModel>(ApiUrls.Report.Test.Edit(payload.testName, payload.data?.id ?? 0), payload?.data ?? null);
    }

    // === PC ===
    static async GetPCTestById(payload: IBaseTestPayload): Promise<IPCTestModel> {
        return await HttpService.Get<IPCTestModel>(ApiUrls.Report.Test.GetById(payload.testName, payload.id ?? 0));
    }
    static async CreatePCTest(payload: IPCTestPayload): Promise<IPCTestModel> {
        return await HttpService.Post<IPCTestModel | null, IPCTestModel>(ApiUrls.Report.Test.Create(payload.testName), payload?.data ?? null);
    }

    static async EditPCTest(payload: IPCTestPayload): Promise<IPCTestModel> {
        return await HttpService.Patch<IPCTestModel | null, IPCTestModel>(ApiUrls.Report.Test.Edit(payload.testName, payload.data?.id ?? 0), payload?.data ?? null);
    }

    // === LI ===
    static async GetLITestById(payload: IBaseTestPayload): Promise<ILightIntensityTestModel> {
        return await HttpService.Get<ILightIntensityTestModel>(ApiUrls.Report.Test.GetById(payload.testName, payload.id ?? 0));
    }
    static async CreateLITest(payload: ILITestPayload): Promise<ILightIntensityTestModel> {
        return await HttpService.Post<ILightIntensityTestModel | null, ILightIntensityTestModel>(ApiUrls.Report.Test.Create(payload.testName), payload?.data ?? null);
    }

    static async EditLITest(payload: ILITestPayload): Promise<ILightIntensityTestModel> {
        return await HttpService.Patch<ILightIntensityTestModel | null, ILightIntensityTestModel>(ApiUrls.Report.Test.Edit(payload.testName, payload.data?.id ?? 0), payload?.data ?? null);
    }

    // === Recovery ===
    static async GetRecoveryTestById(payload: IBaseTestPayload): Promise<IRecoveryTestModel> {
        return await HttpService.Get<IRecoveryTestModel>(ApiUrls.Report.Test.GetById(payload.testName, payload.id ?? 0));
    }
    static async CreateRecoveryTest(payload: IRecoveryTestPayload): Promise<IRecoveryTestModel> {
        return await HttpService.Post<IRecoveryTestModel | null, IRecoveryTestModel>(ApiUrls.Report.Test.Create(payload.testName), payload?.data ?? null);
    }

    static async EditRecoveryTest(payload: IRecoveryTestPayload): Promise<IRecoveryTestModel> {
        return await HttpService.Patch<IRecoveryTestModel | null, IRecoveryTestModel>(ApiUrls.Report.Test.Edit(payload.testName, payload.data?.id ?? 0), payload?.data ?? null);
    }

    // === Sound level ===
    static async GetSLTestById(payload: IBaseTestPayload): Promise<ISoundLevelTestModel> {
        return await HttpService.Get<ISoundLevelTestModel>(ApiUrls.Report.Test.GetById(payload.testName, payload.id ?? 0));
    }
    static async CreateSLTest(payload: ISoundLevelTestPayload): Promise<ISoundLevelTestModel> {
        return await HttpService.Post<ISoundLevelTestModel | null, ISoundLevelTestModel>(ApiUrls.Report.Test.Create(payload.testName), payload?.data ?? null);
    }
    static async EditSLTest(payload: ISoundLevelTestPayload): Promise<ISoundLevelTestModel> {
        return await HttpService.Patch<ISoundLevelTestModel | null, ISoundLevelTestModel>(ApiUrls.Report.Test.Edit(payload.testName, payload.data?.id ?? 0), payload?.data ?? null);
    }

    // --- UV light test ---
    static async GetUVLITestById(payload: IBaseTestPayload): Promise<IUVLightTestModel> {
        return await HttpService.Get<IUVLightTestModel>(ApiUrls.Report.Test.GetById(payload.testName, payload.id ?? 0));
    }
    static async CreateUVLITest(payload: IUVTestPayload): Promise<IUVLightTestModel> {
        return await HttpService.Post<IUVLightTestModel | null, IUVLightTestModel>(ApiUrls.Report.Test.Create(payload.testName), payload?.data ?? null);
    }
    static async EditUVLITest(payload: IUVTestPayload): Promise<IUVLightTestModel> {
        return await HttpService.Patch<IUVLightTestModel | null, IUVLightTestModel>(ApiUrls.Report.Test.Edit(payload.testName, payload.data?.id ?? 0), payload?.data ?? null);
    }
   
    // --- AFPT ---
    static async GetAFPTTestById(payload: IBaseTestPayload): Promise<IAirflowPatterTestModel> {
        return await HttpService.Get<IAirflowPatterTestModel>(ApiUrls.Report.Test.GetById(payload.testName, payload.id ?? 0));
    }
    static async CreateAFPTTest(payload: IAirflowPatternTestPayload): Promise<IAirflowPatterTestModel> {
        return await HttpService.Post<IAirflowPatterTestModel | null, IAirflowPatterTestModel>(ApiUrls.Report.Test.Create(payload.testName), payload?.data ?? null);
    }
    static async EditAFPTTest(payload: IAirflowPatternTestPayload): Promise<IAirflowPatterTestModel> {
        return await HttpService.Patch<IAirflowPatterTestModel | null, IAirflowPatterTestModel>(ApiUrls.Report.Test.Edit(payload.testName, payload.data?.id ?? 0), payload?.data ?? null);
    }

}
