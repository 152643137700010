import {IFieldValidationModel, IValidatorModel} from "../model/validator.model";
import {ValidationScreens, ValidationType} from "../enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "name",
        rules: [
            {
                type: ValidationType.Required,
                message: "Category name is required"
            }
        ]
    }
]

export const dataLoggerCategoryValidator: IValidatorModel = {
    screen: ValidationScreens.DataLoggerCategory,
    fields: fields
}
