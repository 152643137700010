import {BaseComponent} from "../../../component/base.component";

export interface IProps {       
    onDelete: () => void;
    customClass?:string;
    children?:any;
}
class GridDeleteButtonControl extends BaseComponent<IProps,{}> {   
    render() {      
       
        return (
        <a onClick={async () => {
            const confirmed = window.confirm('Are you sure you want to delete this record?');
            if (confirmed)
            {
              this.props.onDelete(); 
            }                                                                          
            }}>
             <i className={`feather icon-trash delete ${this.props.customClass ?? ""}`}></i>
        </a>       
        );
    }
}

export default GridDeleteButtonControl;
