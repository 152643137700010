import { BaseComponent } from "../../../common/component/base.component";
import { connect } from "react-redux";
import { IAppState } from "../../../store";
import {
    loadPurchaseOrderById
} from "../../purchase-order/store/purchase-order.slice";
import React from "react";
import { IPurchaseOrderModel } from "../../purchase-order/model/purchase-order.model";
import { Link } from "react-router-dom";
import ScreenUrls from "../../../common/screen-urls";
import { displayDate } from "../../../common/utils";
import DropdownDisplayHelper from "../../../common/helper/dropdown-display.helper";

export interface IProps {
    id: number,
    purchaseOrderData: IPurchaseOrderModel,
    loadPurchaseOrderById: (id: number) => void;
    dataLoading: boolean,
}

class PurchaseOrderDetailsAccordionComponent extends BaseComponent<IProps, { collapsed: boolean }> {
    constructor(props) {
        super(props);
        this.state = { collapsed: true };
    }

    componentDidMount() {
        if (this.props.id != null && this.props.id > 0) {
            this.props.loadPurchaseOrderById(this.props.id);
        }
    }

    render() {
        return <React.Fragment>
            {(() => { if (this.props.dataLoading) return <div className="loading--bar fixed--top"><span></span></div> })()}

            <div className="accordion no-bg" id="poDetailsAccordionContainer">
                <div className="card">
                    <div className="card-header">
                        <button className="btn btn-primary accordion--btn" type="button" data-toggle="collapse"
                            data-target="#poDetailsAccordionControl" aria-expanded="false" aria-controls="poDetailsAccordionControl"
                            onClick={() => { this.setState({ collapsed: !this.state.collapsed }) }}>
                            <i className={`button-icon feather ${this.state.collapsed ? 'icon-chevrons-down' : 'icon-chevrons-up'}`}></i>
                            Purchase Order Details
                        </button>
                    </div>
                    <div id="poDetailsAccordionControl" className="collapse" aria-labelledby="headingOne"
                        data-parent="#poDetailsAccordionContainer">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-3">
                                    <div className="form-group read-only">
                                        <label>PO Number</label>
                                        <p>{this.props.purchaseOrderData.poNumber ?? "-"}</p>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="form-group read-only">
                                        <label>PO Date</label>
                                        <p>{displayDate(this.props.purchaseOrderData.poDate) ?? "-"}</p>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="form-group read-only">
                                        <label>Reference Note</label>
                                        <p>{this.props.purchaseOrderData.referenceNote ?? "-"}</p>
                                    </div>
                                </div>
                                <div className="col-3 align-self-center">
                                    <Link to={ScreenUrls.PurchaseOrder.View(this.props.purchaseOrderData.id)}
                                        className="btn btn-primary btn--row-right m-0">
                                        Go To Purchase Order
                                    </Link>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <div className="form-group read-only">
                                        <label>Amendment Number</label>
                                        <p>{this.props.purchaseOrderData.amendmentNumber ?? "-"}</p>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="form-group read-only">
                                        <label>Amendment Date</label>
                                        <p>{displayDate(this.props.purchaseOrderData.amendmentDate ?? "") ?? "-"}</p>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="form-group read-only">
                                        <label>Quotation Number</label>
                                        <p>{this.props.purchaseOrderData.quotationNumber ?? "-"}</p>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="form-group read-only">
                                        <label>Quotation Date</label>
                                        <p>{displayDate(this.props.purchaseOrderData.quotationDate ?? "") ?? "-"}</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group read-only">
                                        <label>Description</label>
                                        <p>{this.props.purchaseOrderData.description ?? "-"}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <div className="form-group read-only">
                                        <label>Client</label>
                                        <p>{this.props.purchaseOrderData.client ? DropdownDisplayHelper.Client(this.props.purchaseOrderData.client) : "-"}</p>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group read-only">
                                        <label>Billing Address</label>
                                        <p>{this.props.purchaseOrderData.billingAddress ? DropdownDisplayHelper.ClientAddress(this.props.purchaseOrderData.billingAddress) : "-"}</p>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group read-only">
                                        <label>Delivery Address</label>
                                        <p>{this.props.purchaseOrderData.deliveryAddress ? DropdownDisplayHelper.ClientAddress(this.props.purchaseOrderData.deliveryAddress) : "-"}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-4">
                                    <div className="form-group read-only">
                                        <label>Client Coordinator Name</label>
                                        <p>{this.props.purchaseOrderData.clientCoordinatorName ? this.props.purchaseOrderData.clientCoordinatorName : "-"}</p>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group read-only">
                                        <label>Client Coordinator Email</label>
                                        <p>{this.props.purchaseOrderData.clientCoordinatorEmail ? this.props.purchaseOrderData.clientCoordinatorEmail : "-"}</p>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group read-only">
                                        <label>Client Coordinator Phone Number</label>
                                        <p>{this.props.purchaseOrderData.clientCoordinatorPhoneNumber ? this.props.purchaseOrderData.clientCoordinatorPhoneNumber : "-"}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}

const mapStateToProps = (state: IAppState) => ({
    purchaseOrderData: state.purchaseOrder.purchaseOrderData,
    dataLoading: state.purchaseOrder.dataLoading
})

export default connect(mapStateToProps, { loadPurchaseOrderById })(PurchaseOrderDetailsAccordionComponent);

