import ApiUrls from "../../../common/api-urls";
import {IGetAllRequestModel} from "../../../common/model/get-all-request-model";
import {IFormControlDropdownItemModel} from "../../../common/control/model/form.control.field.model";
import DropdownDisplayHelper from "../../../common/helper/dropdown-display.helper";
import HttpService from "../../../common/service/http.service";
import {IFeatureModel} from "../model/feature.model";

export default class FeatureService {
    static async LoadAll(request?: IGetAllRequestModel): Promise<IFeatureModel[]> {
        return await HttpService.Get<IFeatureModel[]>(ApiUrls.Feature.GetAll(request));
    }

    static async Create(data: IFeatureModel): Promise<IFeatureModel> {
        return await HttpService.Post<IFeatureModel, IFeatureModel>(ApiUrls.Feature.Create(), data);
    }

    static async Edit(data: IFeatureModel): Promise<IFeatureModel> {
        return await HttpService.Patch<IFeatureModel, IFeatureModel>(ApiUrls.Feature.Edit(data.id), data);
    }

    static async GetById(id: number): Promise<IFeatureModel> {
        return await HttpService.Get<IFeatureModel>(ApiUrls.Feature.GetById(id));
    }

    static async FeatureDropdown(search: string): Promise<IFormControlDropdownItemModel[]> {
        let req: IGetAllRequestModel = { searchStr: search };
        return (await FeatureService.LoadAll(req)).map(d => {
            const obj: IFormControlDropdownItemModel = {
                label: DropdownDisplayHelper.AccessRole(d),
                value: d.id.toString()
            }
            return obj;
        });
    }
}
