import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserModel } from "../model/user.model";
import UserService from "../service/user.service";
import { userDefault } from "../model/defaults/user.default";
import { IStoreSaveModel } from "../../../common/model/store-save.model";
import ScreenUrls, { redirectHandler } from "../../../common/screen-urls";


export interface IUserState {
    userList: IUserModel[],
    userData: IUserModel,
    dataLoading: boolean,
    listLoading: boolean,
    createEditLoading: boolean,
    passwordChangeLoading: boolean,
}

const initialState: IUserState = {
    userList: [],
    userData: userDefault,
    dataLoading: false,
    listLoading: false,
    createEditLoading: false,
    passwordChangeLoading: false
}

export const loadUsers = createAsyncThunk("user/loadAll", UserService.LoadAll);
export const loadUserById = createAsyncThunk("user/loadById", UserService.GetById);
export const createUser = createAsyncThunk("user/create", UserService.Create);
export const editUser = createAsyncThunk("user/edit", UserService.Edit);
export const passwordChange = createAsyncThunk("user/password-change", UserService.PasswordChange);

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        clearUserDataState: (state) => { state.userData = userDefault },
        clearUserListState: (state) => { state.userList = [] },
        updateUserDataState: (state, action: PayloadAction<IStoreSaveModel>) => {
            state.userData[action.payload.name] = action.payload.value;
        }
    },
    extraReducers: (builder) => {

        // Load All
        builder.addCase(loadUsers.pending, (state, action) => {
            clearUserDataState();
            state.listLoading = true;
        });
        builder.addCase(loadUsers.rejected, (state, action) => {
            clearUserDataState();
            state.listLoading = false;
        });
        builder.addCase(loadUsers.fulfilled, (state, action) => {
            state.userList = action.payload;
            state.listLoading = false;
        });

        // Load By ID
        builder.addCase(loadUserById.pending, (state, action) => {
            clearUserListState();
            state.dataLoading = true;
        });
        builder.addCase(loadUserById.rejected, (state, action) => {
            clearUserListState();
            state.dataLoading = false;
        });
        builder.addCase(loadUserById.fulfilled, (state, action) => {
            state.userData = action.payload;
            state.dataLoading = false;
        });

        // Create
        builder.addCase(createUser.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(createUser.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(createUser.fulfilled, (state, action: any) => {
            state.createEditLoading = false;
            if (action?.payload?.id) {
                state.userData = action.payload;
                redirectHandler(ScreenUrls.Users.Edit(action.payload.id));
            }
        });

        // Edit
        builder.addCase(editUser.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(editUser.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(editUser.fulfilled, (state, action) => {
            state.userData = action.payload;
            state.createEditLoading = false;
        });

        // Password Change
        builder.addCase(passwordChange.pending, (state, action) => { state.passwordChangeLoading = true; });
        builder.addCase(passwordChange.rejected, (state, action) => {
            state.passwordChangeLoading = false;
        });
        builder.addCase(passwordChange.fulfilled, (state, action) => {
            if(action.payload?.id){
                state.userData = action.payload;
            }
            state.passwordChangeLoading = false;
        });
    }
});

const { actions, reducer } = userSlice;

export const { clearUserDataState, clearUserListState, updateUserDataState } = actions;

export default reducer;
