import { IPasswordChangeModel, IUserModel,IUserExistResponse } from "../model/user.model";
import ApiUrls from "../../../common/api-urls";
import { IGetAllRequestModel } from "../../../common/model/get-all-request-model";
import { IFormControlDropdownItemModel } from "../../../common/control/model/form.control.field.model";
import DropdownDisplayHelper from "../../../common/helper/dropdown-display.helper";
import HttpService from "../../../common/service/http.service";

export default class UserService {
    static async LoadCurrentUser(): Promise<IUserModel> {
        return await HttpService.Get<IUserModel>(ApiUrls.User.GetCurrentUser());
    }

    static async LoadAll(request?: IGetAllRequestModel): Promise<IUserModel[]> {
        return await HttpService.Get<IUserModel[]>(ApiUrls.User.GetAll(request));
    }

    static async Create(data: IUserModel): Promise<IUserModel | IUserExistResponse> {
        return await HttpService.Post<IUserModel, IUserModel | IUserExistResponse>(ApiUrls.User.Create(), data);
    }

    static async Edit(data: IUserModel): Promise<IUserModel> {
        return await HttpService.Patch<IUserModel, IUserModel>(ApiUrls.User.Edit(data.id), data);
    }

    static async PasswordReset(id: number): Promise<IUserModel> {
        return await HttpService.PlainPost<IUserModel>(ApiUrls.User.PasswordReset(id));
    }

    static async Delete(id: number) {
        await HttpService.Delete(ApiUrls.User.Delete(id));
    }

    static async PasswordChange(data: IPasswordChangeModel): Promise<IUserModel> {
        return await HttpService.Patch<IPasswordChangeModel, IUserModel>(ApiUrls.User.PasswordChange(data.id), data);
    }

    static async GetById(id: number): Promise<IUserModel> {
        return await HttpService.Get<IUserModel>(ApiUrls.User.GetById(id));
    }

    static async UserDropdown(search: string): Promise<IFormControlDropdownItemModel[]> {
        let req: IGetAllRequestModel = { searchStr: search };
        return (await UserService.LoadAll(req)).map(d => {
            const obj: IFormControlDropdownItemModel = {
                label: DropdownDisplayHelper.User(d),
                value: d.id.toString()
            }
            return obj;
        });
    }

}
