import React from "react";
import { BaseComponent } from "../../../common/component/base.component";
import { AgGridDefaultColDef } from "../../../common/app-defaults";
import { AgGridReact } from "ag-grid-react";
import { fullDisplayDateInMilitaryTime } from "../../../common/utils";
import { IAppState } from "../../../store";
import { connect } from "react-redux";
import { loadAll, markAllRead, resetRecentNotifications, changeCurrentPage, markRead, quickView } from "../store/notification.slice";
import { IGetAllNotifications } from "../model/notification.model";
import { Link } from "react-router-dom";
import { IGetAllNotificationsRequest } from "../model/request/get-all-notifications.request.model";
import PaginationComponent from "../../../common/component/pagination.component";

export interface IProps {
  loadAll: (request: IGetAllNotificationsRequest) => void,
  allNotifications: IGetAllNotifications,
  pageLoader: boolean,
  markAllRead: () => void,
  resetRecentNotifications: () => void,
  markRead: (id: number) => void,
  quickView: () => void,
  changeCurrentPage: (page: number) => void
}
class NotificationPage extends BaseComponent<IProps, any> {

  async componentDidMount() {
    await this.props.loadAll({ page: 1, pageSize: 10 });
  }

  async reloadNotifications() {
    setTimeout(async () => {
      await this.props.loadAll({
        page: this.props.allNotifications?.pagination.page,
        pageSize: this.props.allNotifications?.pagination.pageSize,
      });
    }, 200);
  }

  render() {
    return (
      <React.Fragment>
        <div className="pcoded-content dashboard">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card dashboard--section">
                      {(() => { if (this.props.pageLoader) return <div className="loading--bar"><span></span></div> })()}
                      <div className="card-header d-flex align-items-center justify-content-between">
                        <h5>Notifications</h5>
                        {
                          this.props.allNotifications?.data?.length > 0 &&
                          <button
                            disabled={this.props.pageLoader || this.props.pageLoader}
                            className="btn mr-0 mb-0 drp-icon btn-rounded btn-primary dropdown-toggle"
                            onClick={async () => {
                              await this.props.markAllRead();
                              this.props.resetRecentNotifications();
                              await this.props.loadAll({ page: 1, pageSize: 10 });
                            }}
                          >
                            Mark All As Read
                          </button>
                        }

                      </div>

                      <div className="card-block ag-theme-alpine">
                        <AgGridReact
                          domLayout="autoHeight"
                          pagination={false}
                          paginationAutoPageSize={true}
                          unSortIcon={true}
                          rowHeight={60}
                          defaultColDef={AgGridDefaultColDef}
                          columnDefs={[
                            {
                              suppressMovable: true,
                              headerName: "Is Read",
                              field: "isUnread",
                              width: 60,

                              cellClass: "grid-cell",
                              cellRenderer: (params) => (
                                <div className="d-flex align-items-center h-100">
                                  <div className={`emailImgBox email-img ${params.data.isUnread ? "is-unread" : ""}`}>
                                    <i className={`icon feather icon-mail`}></i>
                                  </div>
                                </div>
                              ),
                            },
                            {
                              suppressMovable: true,
                              headerName: "Created On",
                              field: "createdOnUtc",
                              width: 150,
                              sortable: true,
                              cellClass: "grid-cell",
                              cellRenderer: (params) => (
                                <span>{fullDisplayDateInMilitaryTime(params.value)}</span>
                              ),
                            },
                            {
                              suppressMovable: true,
                              headerName: "Heading",
                              field: "notificationLine",
                              flex: 1,
                              cellClass: "grid-cell",
                            },
                            {
                              suppressMovable: true,
                              headerName: "Description",
                              field: "notificationLine2",
                              flex: 3,
                              sortable: true,
                              cellClass: "grid-cell",

                            },
                            {
                              suppressMovable: true,
                              headerName: "Action",
                              field: "",
                              width: 220,
                              cellClass: "grid-cell grid-cell-button",
                              cellRenderer: (params) => {
                                return (
                                  <div className="">
                                    {

                                      params.data?.pageUri?.length &&
                                      <Link

                                        to={params.data?.pageUri}
                                      >Open</Link>
                                    }
                                    {

                                      params.data.isUnread &&
                                      <button
                                        className="small-btn ml-2"
                                        onClick={async () => {
                                          await this.props.markRead(params.data.id);
                                          await this.props.loadAll({ page: 1, pageSize: 10 });
                                          await this.props.quickView();

                                        }}
                                      >Mark read</button>
                                    }



                                  </div>
                                )
                              },
                            }
                          ]}

                          rowData={this.props.allNotifications?.data}
                        ></AgGridReact>

                        <div className="row my-4">
                          <div className="col-12 position-static">
                            <PaginationComponent
                              itemsPerPage={this.props.allNotifications.pagination?.pageSize}
                              currentPage={this.props.allNotifications.pagination?.page}
                              totalRecords={this.props.allNotifications.pagination.total}
                              onPageChangeHandler={(data) => {
                                this.props.changeCurrentPage(
                                  data.selected + 1
                                );
                                this.reloadNotifications();
                              }}
                            />
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  allNotifications: state.notification.allNotifications,
  pageLoader: state.notification.pageLoader,
});

export default connect(mapStateToProps, {
  loadAll,
  markAllRead,
  resetRecentNotifications,
  markRead, quickView,
  changeCurrentPage
})(NotificationPage);