
import {IGetAllRequestModel} from "../../../common/model/get-all-request-model";
import HttpService from "../../../common/service/http.service";
import ApiUrls from "../../../common/api-urls";
import {IDataLoggerBookingModel} from "../model/data-logger-booking.model";

export class DataLoggerBookingService {
    static async LoadAll(request?: IGetAllRequestModel): Promise<IDataLoggerBookingModel[]> {
        return await HttpService.Get<IDataLoggerBookingModel[]>(ApiUrls.Booking.GetAll(request));
    }

    static async Create(data: IDataLoggerBookingModel): Promise<IDataLoggerBookingModel> {
        return await HttpService.Post<IDataLoggerBookingModel, IDataLoggerBookingModel>(ApiUrls.Booking.Create(), data);
    }

    static async Edit(data: IDataLoggerBookingModel): Promise<IDataLoggerBookingModel> {
        return await HttpService.Patch<IDataLoggerBookingModel, IDataLoggerBookingModel>(ApiUrls.Booking.Edit(data.id), data);
    }

    static async GetById(id: number): Promise<IDataLoggerBookingModel> {
        return await HttpService.Get<IDataLoggerBookingModel>(ApiUrls.Booking.GetById(id));
    }
}
