import {IPasswordChangeModel, IUserModel} from "../user.model";

export const passwordChangeDefault: IPasswordChangeModel = {
    id: 0,
    password: "",
    currentPassword:"",
}

export const userDefault: IUserModel = {
    id: 0,
    employeeId: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    isActive: true,
    isPasswordResetRequired: false,
    isSysAdmin: false
}
