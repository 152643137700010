
import ApiUrls from "../../../common/api-urls";
import { IWorkflowTaskModel } from "../model/workflow-task.model";
import { IStageFlowItemModel } from "../../workflow/model/_common.model";
import { IGetAllRequestModel } from "../../../common/model/get-all-request-model";
import HttpService from "../../../common/service/http.service";

export default class WorkflowTaskService {
    static async LoadAll(request?: IGetAllRequestModel): Promise<IWorkflowTaskModel[]> {
        return await HttpService.Get<IWorkflowTaskModel[]>(ApiUrls.WorkflowTask.GetAll(request));
    }
    static async Create(data: IWorkflowTaskModel): Promise<IWorkflowTaskModel> {
        return await HttpService.Post<IWorkflowTaskModel, IWorkflowTaskModel>(ApiUrls.WorkflowTask.Create(), data);
    }

    static async Edit(data: IWorkflowTaskModel): Promise<IWorkflowTaskModel> {
        return await HttpService.Patch<IWorkflowTaskModel, IWorkflowTaskModel>(ApiUrls.WorkflowTask.Edit(data.id), data);
    }

    static async GetById(id: number): Promise<IWorkflowTaskModel> {
        return await HttpService.Get<IWorkflowTaskModel>(ApiUrls.WorkflowTask.GetById(id));
    }

    static async NextStage(payload: IWorkflowTaskModel): Promise<IWorkflowTaskModel> {
        return await HttpService.Patch<IWorkflowTaskModel, IWorkflowTaskModel>(ApiUrls.WorkflowTask.NextStage(payload.id), payload);
    }

    static async RejectStage(payload: IWorkflowTaskModel): Promise<IWorkflowTaskModel> {
        return await HttpService.Patch<IWorkflowTaskModel, IWorkflowTaskModel>(ApiUrls.WorkflowTask.RejectStage(payload.id), payload);
    }

    static async Complete(payload: IWorkflowTaskModel): Promise<IWorkflowTaskModel> {
        return await HttpService.Patch<IWorkflowTaskModel, IWorkflowTaskModel>(ApiUrls.WorkflowTask.Complete(payload.id), payload);
    }

    static async GetDefaultStageFlow(): Promise<IStageFlowItemModel[]> {
        return await HttpService.Get<IStageFlowItemModel[]>(ApiUrls.WorkflowTask.GetDefaultStageFlow());
    }

    static async GetRemainingQuantity(workflowId: number, poItemId: number): Promise<number> {
        return await HttpService.Get<number>(ApiUrls.WorkflowTask.GetRemainingQuantity(workflowId, poItemId));
    }
    static async HandleWatchWorkflowTask(query: { id: number, watching: boolean }): Promise<IWorkflowTaskModel> {
        return await HttpService.Patch<{}, IWorkflowTaskModel>(ApiUrls.WorkflowTask.Watch(query.id, query.watching), {});
    }
}
