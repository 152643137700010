import { IWorkflowTaskModel } from "../workflow-task.model";

export const workflowTaskDefault: IWorkflowTaskModel = {
    id: 0,
    reportNumber: "",
    description: "",
    categoryId: null,
    category: null,
    currentStageId: 0,
    currentStage: null,
    assigneeId: 0,
    purchaseOrderItemId: null,
    priority: 2,
    isComplete: false,
    toBeAssignedTo: null,
    isWatching: false,

    // Activity Planning Stage
    activityPlanningDate: null,

    // Booking Stage
    isAssetBookingCompleted: false,
    isAssetFunctionalityCheckCompleted: false,
    isCalibrationCertificateShared: false,
    isPreValidationCompleted: false,

    // Site Activity Stage
    isPostVerificationCompleted: false,
    isRawDataShared: false,
    isActivitySheetShared: false,
    isActivityAnnexuresSubmitted: false,
    isDocumentsUploaded: false,

    // Document Review Stage
    isRawDataReviewed: false,
    isActivitySheetReviewed: false,
    isActivityAnnexuresReviewed: false,
    isDocumentsReviewed: false,

    // Reporting Stage
    isReportCreatedBasedOnChecklist: false,
    isReportCreationCompleted: false,

    // Report Review Stage
    isReportReviewedBasedOnChecklist: false,
    isReportReviewCompleted: false,

    // Quality Assurance Review Stage
    isQaReviewedBasedOnChecklist: false,
    isQaReviewCompleted: false,

    // Client Review Stage
    isReportSharedToClient: false,
    isClientReviewCompleted: false,
    clientConfirmationDate: null,
    clientReviewRequired: false,

    // Report Delivery Stage
    isReportPrintingCompleted: false,
    isReportSignScanCompleted: false,
    isReportSubmittedToClient: false,
    isClientAcknowledgmentReceived: false,
    nextStageAssigneeList: null,
    rejectStageAssigneeList: null,

    reportDeliveryEndDate: null,

    reportDeliveryStartDate: null,

    // ==== invoicing ======
    invoiceGenerationDate: null,
    hasClientAcknowledgeFinalReport: false,
    hasInvoiceBookedByClient: false,
    hasInvoicePrepared: false,
    hasInvoiceReviewed: false,
    hasServiceReportGenerated: false,

    purchaseOrder:{
        clientCoordinatorName:null,
        clientName:null,
        id:0,
    }
}
