import {IFeatureAccessLevelModel} from "../feature-access-level.model";
import {accessRoleDefault} from "./access-role.default";
import {featureDefault} from "./feature.default";

export const featureAccessLevelDefault: IFeatureAccessLevelModel = {
    id: 0,
    accessLevel: 0,
    accessRoleId: 0,
    accessRole: accessRoleDefault,
    featureId: 0,
    feature: featureDefault
}
