import React from "react";
import ScreenUrls from "../../../../common/screen-urls";
import { IClientEquipmentModel } from "../../model/client-equipment.model";
import { IAppState } from "../../../../store";
import { connect } from "react-redux";
import {
    loadEquipments,
    loadEquipmentById,
    createEquipment,
    editEquipment,
    clearEquipmentDataState, clearEquipmentListState, updateClientEquipmentDataState
} from "../../store/client-equipment.slice";
import { Link} from "react-router-dom";
import AsyncSelect from "react-select/async";
import ClientService from "../../service/client.service";
import { DeferredLoadOptions } from "../../../../common/utils";
import { ValidationScreens } from "../../../../common/enums";
import { IStoreSaveModel } from "../../../../common/model/store-save.model";
import DropdownDisplayHelper from "../../../../common/helper/dropdown-display.helper";
import { FormBaseComponent, IFormBaseState } from "../../../../common/component/form-base.component";
import ValidationMessageControl from "../../../../common/control/validation-message.control";

export interface IProps {
    accessLevel: number,
    id?: string,
    dataLoading: boolean,
    createEditLoading: boolean
    clientEquipmentData: IClientEquipmentModel,
    loadEquipmentById: (id: number) => void;
    createEquipment: (data: IClientEquipmentModel) => void;
    editEquipment: (data: IClientEquipmentModel) => void;
    clearClientEquipmentDataState: (data?: any) => void;
    updateClientEquipmentDataState: (payload: IStoreSaveModel) => void;
}

export interface IState extends IFormBaseState {
}

class ClientEquipmentCreateEditPage extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            hideForm: false
        }
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.ClientEquipment);
        if (this.props.id != null && Number(this.props.id) > 0) {
            await this.props.loadEquipmentById(Number(this.props.id));
        } else {
            await this.props.clearClientEquipmentDataState();
        }
    }

    async componentWillUnmount(){
        await this.setValidator(ValidationScreens.None);
    }

    loadClientOptions = (input: string) => DeferredLoadOptions(input, ClientService.ClientDropdown);

    render() {

        return <React.Fragment>
            <div className="pcoded-content">
                <div className="pcoded-inner-content">
                    <div className="page-header">
                        <div className="page-block">
                            <div className="row">
                                <div className="col-md-12">
                                    <Link to={ScreenUrls.Clients.Equipment.Index()}
                                        className="btn drp-icon btn-rounded btn-primary dropdown-toggle">
                                        <i className="feather icon-arrow-left"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    {(() => { if (this.props.dataLoading || this.props.createEditLoading) return <div className="loading--bar fixed--top"><span></span></div> })()}
                    {
                        (() => {
                            if (this.state.hideForm) return;
                            return (
                                <div className="main-body">
                                    <div className="page-wrapper">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <article className="card mb-4">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-3">
                                                                <div className={`form-group ${this.vResult("equipmentName").className}`}>
                                                                    <label htmlFor="equipmentName">Equipment Name<span className="requried-span">*</span></label>
                                                                    <input id="equipmentName" className="form-control" type="text"
                                                                        value={this.props.clientEquipmentData.equipmentName ?? ""}
                                                                        onChange={e => {
                                                                            e.preventDefault();
                                                                            this.ValidateField("equipmentName", e.target.value);
                                                                            this.props.updateClientEquipmentDataState({ name: "equipmentName", value: e.target.value });
                                                                        }} />
                                                                    <ValidationMessageControl message={this.vResult("equipmentName").message} />
                                                                </div>
                                                            </div>
                                                            <div className="col-3">
                                                                <div className={`form-group ${this.vResult("equipmentId").className}`}>
                                                                    <label htmlFor="equipmentId">Equipment Id<span className="requried-span">*</span></label>
                                                                    <input id="equipmentId" className="form-control" type="text"
                                                                        value={this.props.clientEquipmentData.equipmentId ?? ""}
                                                                        onChange={e => {
                                                                            e.preventDefault();
                                                                            this.ValidateField("equipmentId", e.target.value);
                                                                            this.props.updateClientEquipmentDataState({ name: "equipmentId", value: e.target.value });
                                                                        }} />
                                                                    <ValidationMessageControl message={this.vResult("equipmentId").message} />
                                                                </div>
                                                            </div>
                                                            <div className="col-3">
                                                                <div className={`form-group ${this.vResult("modelNumber").className}`}>
                                                                    <label htmlFor="modelNumber">Model Number<span className="requried-span">*</span></label>
                                                                    <input id="modelNumber" className="form-control" type="text"
                                                                        value={this.props.clientEquipmentData.modelNumber ?? ""}
                                                                        onChange={e => {
                                                                            e.preventDefault();
                                                                            this.ValidateField("modelNumber", e.target.value);
                                                                            this.props.updateClientEquipmentDataState({ name: "modelNumber", value: e.target.value });
                                                                        }} />
                                                                    <ValidationMessageControl message={this.vResult("modelNumber").message} />
                                                                </div>
                                                            </div>
                                                            <div className="col-3">
                                                                <div className={`form-group ${this.vResult("serialNumber").className}`}>
                                                                    <label htmlFor="serialNumber">Serial Number<span className="requried-span">*</span></label>
                                                                    <input id="serialNumber" className="form-control" type="text"
                                                                        value={this.props.clientEquipmentData.serialNumber ?? ""}
                                                                        onChange={e => {
                                                                            e.preventDefault();
                                                                            this.ValidateField("serialNumber", e.target.value);
                                                                            this.props.updateClientEquipmentDataState({ name: "serialNumber", value: e.target.value });
                                                                        }} />
                                                                    <ValidationMessageControl message={this.vResult("serialNumber").message} />
                                                                </div>
                                                            </div>
                                                            <div className="col-3">
                                                                <div className={`form-group ${this.vResult("clientId").className}`}>
                                                                    <label htmlFor="clientId">Client<span className="requried-span">*</span></label>
                                                                    <AsyncSelect id="clientId" cacheOptions defaultOptions
                                                                        className="async-select-control"
                                                                        loadOptions={this.loadClientOptions}
                                                                        value={this.props.clientEquipmentData.client
                                                                            ? { label: DropdownDisplayHelper.Client(this.props.clientEquipmentData.client), value: this.props.clientEquipmentData.client.id.toString() ?? "0" }
                                                                            : { label: "Select Client", value: "" }}
                                                                        onChange={async (e) => {
                                                                            const cId = Number(e?.value);
                                                                            if (cId === null || cId === 0) return;
                                                                            this.ValidateField("clientId", cId);
                                                                            const category = await ClientService.GetById(cId);
                                                                            this.props.updateClientEquipmentDataState({ name: "client", value: category });
                                                                            this.props.updateClientEquipmentDataState({ name: "clientId", value: category.id });
                                                                        }} />
                                                                    <ValidationMessageControl message={this.vResult("clientId").message} />
                                                                </div>
                                                            </div>
                                                            <div className="col-3">
                                                                <div className={`form-group ${this.vResult("make").className}`}>
                                                                    <label htmlFor="make">Make<span className="requried-span">*</span></label>
                                                                    <input id="make" className="form-control" type="text"
                                                                        value={this.props.clientEquipmentData?.make ?? ""}
                                                                        onChange={e => {
                                                                            e.preventDefault();
                                                                            this.ValidateField("make", e.target.value);
                                                                            this.props.updateClientEquipmentDataState({ name: "make", value: e.target.value });
                                                                        }} />
                                                                    <ValidationMessageControl message={this.vResult("make").message} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>

                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <button type="button" className="btn btn-primary btn--right"
                                                                disabled={this.props.clientEquipmentData == null || this.hasError}
                                                                onClick={async e => {
                                                                    e.preventDefault();
                                                                    const error = !this.ValidateForm(this.props.clientEquipmentData);
                                                                    this.reloadForm();
                                                                    if (error) return;
                                                                    if (this.props.clientEquipmentData.id > 0) {
                                                                        this.props.editEquipment(this.props.clientEquipmentData);
                                                                    } else {
                                                                        this.props.createEquipment(this.props.clientEquipmentData);
                                                                    }
                                                                }}>Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })()
                    }

                </div>
            </div >
        </React.Fragment >
    }
}

const mapStateToProps = (state: IAppState) => ({
    clientEquipmentData: state.clientEquipment.equipmentData,
    dataLoading: state.clientEquipment.dataLoading,
    createEditLoading: state.clientEquipment.createEditLoading
})
export default connect(mapStateToProps, {
    loadEquipments,
    loadEquipmentById,
    createEquipment,
    editEquipment,
    clearClientEquipmentDataState: clearEquipmentDataState, clearEquipmentListState, updateClientEquipmentDataState
})(ClientEquipmentCreateEditPage);
