import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import CategoryService from "../service/category.service";
import {ICategoryModel} from "../model/category.model";
import {categoryDefault} from "../model/defaults/common.defaults";
import {IStoreSaveModel} from "../model/store-save.model";
import ScreenUrls,{ redirectHandler} from "../screen-urls";

export interface IDataLoggerCategoryState {
    categoryList: ICategoryModel[];
    categoryData: ICategoryModel;
    dataLoading: boolean;
    listLoading: boolean;
    createEditLoading: boolean;
}

const initialState: IDataLoggerCategoryState = {
    categoryList: [],
    categoryData: categoryDefault,
    dataLoading: false,
    listLoading: false,
    createEditLoading: false
}

export const loadCategories = createAsyncThunk("data-logger/category/loadAll", CategoryService.LoadAll);
export const loadCategoryById = createAsyncThunk("data-logger/category/loadById", CategoryService.GetById);
export const createCategory = createAsyncThunk("data-logger/category/create", CategoryService.Create);
export const editCategory = createAsyncThunk("data-logger/category/edit", CategoryService.Edit);

const categorySlice = createSlice({
    name: 'data-logger-category',
    initialState,
    reducers: {
        clearCategoryDataState: (state) => { state.categoryData = categoryDefault },
        clearCategoryListState: (state) => {
            state.categoryList = [];
        },
        updateCategoryDataState: (state, action: PayloadAction<IStoreSaveModel>) => {
            state.categoryData[action.payload.name] = action.payload.value;
        }
    },
    extraReducers: (builder) => {
        // Load All
        builder.addCase(loadCategories.pending, (state, action) => {
            clearCategoryListState();
            state.listLoading = true;
        });
        builder.addCase(loadCategories.rejected, (state, action) => {
            clearCategoryListState();
            state.listLoading = false;
        });
        builder.addCase(loadCategories.fulfilled, (state, action) => {
            state.categoryList = action.payload;
            state.listLoading = false;
        });

        // Load By ID
        builder.addCase(loadCategoryById.pending, (state, action) => {
            clearCategoryDataState();
            state.dataLoading = true; });
        builder.addCase(loadCategoryById.rejected, (state, action) => {
            clearCategoryDataState();
            state.dataLoading = false;
        });
        builder.addCase(loadCategoryById.fulfilled, (state, action) => {
            state.categoryData = action.payload;
            state.dataLoading = false;
        });

        // Create
        builder.addCase(createCategory.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(createCategory.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(createCategory.fulfilled, (state, action) => {
            state.categoryData = action.payload;
            state.createEditLoading = false;
            redirectHandler(ScreenUrls.DataLoggers.Category.Edit(action.payload.id));
        });

        // Edit
        builder.addCase(editCategory.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(editCategory.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(editCategory.fulfilled, (state, action) => {
            state.categoryData = action.payload;
            state.createEditLoading = false;
        });
    }
});

const { actions, reducer } = categorySlice;

export const { clearCategoryDataState, clearCategoryListState, updateCategoryDataState } = actions;

export default reducer;
