import { IPurchaseOrderModel } from "../model/purchase-order.model";
import { IStoreSaveModel } from "../../../common/model/store-save.model";
import { FormBaseComponent, IFormBaseState } from "../../../common/component/form-base.component";
import { IAppState } from "../../../store";
import { connect } from "react-redux";
import {
    updatePurchaseOrderDataState,
} from "../store/purchase-order.slice";
import React from "react";
import ValidationMessageControl from "../../../common/control/validation-message.control";
import { DeferredLoadOptions, localToUtcDate, utcToLocalDate } from "../../../common/utils";
import { AppConstants } from "../../../common/app-constants";
import AsyncSelect from "react-select/async";
import DropdownDisplayHelper from "../../../common/helper/dropdown-display.helper";
import ClientService from "../../client/service/client.service";
import ClientAddressService from "../../client/service/client-address.service";
import { AccessLevel, PoType } from "../../../common/enums";
import ScreenUrls from "../../../common/screen-urls";
import { AgGridReact } from "ag-grid-react";
import { AgGridDefaultColDef } from "../../../common/app-defaults";
import { Link, Navigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { IFormControlDropdownItemModel } from "../../../common/control/model/form.control.field.model";
import { IAccessRoleModel } from "../../access/model/access-role.model";
export interface IProps {
    accessLevel: number,
    accessLevelLineItem: number,
    id?: string,
    purchaseOrderData: IPurchaseOrderModel,
    updatePurchaseOrderDataState: (data: IStoreSaveModel) => void,
    dataLoading: boolean,
    createEditLoading: boolean;
    updateHasError: (hasError: boolean) => void,
    accessRoles:IAccessRoleModel[],
}

export interface IState extends IFormBaseState {
    hideBillingAddress: boolean;
    hideDeliveryAddress: boolean;
    redirectUrl: string;
}


class PurchaseOrderCreateEditComponent extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            hideBillingAddress: false,
            hideDeliveryAddress: false,
            redirectUrl: "",
            hideForm: false
        }
    }

    // Dropdown
    loadClientOption = (inputValue: string) => DeferredLoadOptions(inputValue, ClientService.ClientDropdown);

    loadClientAddressOption = (inputValue: string) => DeferredLoadOptions(inputValue, (search: string) =>
        new Promise<IFormControlDropdownItemModel[]>(async (resolve) => {
            if (this.props.purchaseOrderData.client == null || this.props.purchaseOrderData.client.id == null || this.props.purchaseOrderData.client.id === 0) {
                resolve([]); return;
            }

            const client = await ClientService.GetById(Number(this.props.purchaseOrderData.client?.id));
            if (client == null || client.clientAddresses == null) {
                resolve([]); return;
            }

            const list = client.clientAddresses.map(d => {
                const obj: IFormControlDropdownItemModel = {
                    label: DropdownDisplayHelper.ClientAddress(d),
                    value: d.id.toString()
                }
                return obj;
            });

            if (search == null || search === "") resolve(list);
            resolve(list.filter(l => l.label.indexOf(search) > 0))
        }));

    // Reload
    reloadDeliverAddressDropdown = () => {
        this.setState({ hideDeliveryAddress: true })

        setTimeout(() => {
            this.setState({ hideDeliveryAddress: false })
        }, 1);
    }
    reloadBillingAddressDropdown = () => {
        this.setState({ hideBillingAddress: true })

        setTimeout(() => {
            this.setState({ hideBillingAddress: false })
        }, 1);
    }

    getPOLineItemsCols() {
        let array = [{
            suppressMovable: true,
            hide: this.props.accessLevel < AccessLevel.Edit,
            headerName: "Edit", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
            cellRenderer: (params) => {
                return (
                    <Link to={ScreenUrls.PurchaseOrder.LineItem.Edit(params.value, this.props.purchaseOrderData.id)}>
                        <i className={`feather icon-edit`}></i>
                    </Link>
                )
            }
        },
        {
            suppressMovable: true,
            headerName: "Code", field: "service",
            flex: 1, sortable: true, cellClass: "grid-cell",
            comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                if (valueA?.code == valueB?.code) return 0;
                return (valueA?.code > valueB?.code) ? 1 : -1;
            },
            cellRenderer: (params) => <span>{params.value?.code}</span>
        },
        {
            suppressMovable: true,
            headerName: "Service", field: "service",
            flex: 3, sortable: true, cellClass: "grid-cell",
            comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                if (valueA?.title == valueB?.title) return 0;
                return (valueA?.title > valueB?.title) ? 1 : -1;
            },
            cellRenderer: (params) => <span>{params.value?.title}</span>
        },
        {
            suppressMovable: true,
            headerName: "HSN/SAC", field: "service",
            flex: 1.5, sortable: true, cellClass: "grid-cell",
            comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                if (valueA?.hsnSacCode == valueB?.hsnSacCode) return 0;
                return (valueA?.hsnSacCode > valueB?.hsnSacCode) ? 1 : -1;
            },
            cellRenderer: (params) => <span>{params.value?.hsnSacCode}</span>
        },
        {
            suppressMovable: true,
            headerName: "Type", field: "type",
            flex: 1.5, sortable: true, cellClass: "grid-cell",
            cellRenderer: (params) => <span>{PoType[Number(params.value)]}</span>
        },
        {
            suppressMovable: true,
            headerName: "Description", field: "description",
            tooltipField: "description",
            flex: 3, sortable: true, cellClass: "grid-cell",
            cellRenderer: (params) => <span>{params.value}</span>
        },
        {
            suppressMovable: true,
            headerName: "Total Qty", field: "quantity",
            flex: 1, sortable: true, cellClass: "grid-cell",
            cellRenderer: (params) => <span>{params.value}</span>
        },
        {
            suppressMovable: true,
            headerName: "Served Qty", field: "availableQuantity",
            width: 120, sortable: true, cellClass: "grid-cell",
            comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                let first = nodeA.data?.quantity - nodeA.data?.availableQuantity;
                let second = nodeB.data?.quantity - nodeB.data?.availableQuantity;

                return (first > second) ? 1 : -1;
            },
            cellRenderer: (params) => <span>{params.data?.quantity - params?.data?.availableQuantity}</span>
        },
        {
            suppressMovable: true,
            headerName: "Remaining Qty", field: "availableQuantity",
            flex: 1, sortable: true, cellClass: "grid-cell",
            cellRenderer: (params) => <span>{params.value}</span>
        },
        ];
        if(this.props.accessRoles?.find((item) => ['portaladmin', 'sales user', 'sales lead','thermal validation lead','cleanroom validation lead','thermal validation user','cleanroom validation user']?.includes(item.name?.toLowerCase()))) {
            array.push({
                suppressMovable: true,
                headerName: "Rate", field: "unitRate",
                flex: 1.5, sortable: true, cellClass: "grid-cell",
                cellRenderer: (params) => <span>{params.value}</span>
            });
        }
        
        array.push({
            suppressMovable: true,
            headerName: "Measurement", field: "unitMeasurement",
            flex: 2, sortable: true, cellClass: "grid-cell",
            cellRenderer: (params) => <span>{params.value}</span>
        });
      
        if(this.props.accessRoles?.find((item) => ['portaladmin', 'sales user', 'sales lead','thermal validation lead','cleanroom validation lead','thermal validation user','cleanroom validation user']?.includes(item.name?.toLowerCase()))) {
            array.push({
                suppressMovable: true,
                headerName: "Amount", field: "totalAmount",
                flex: 1.5, sortable: true, cellClass: "grid-cell",
                cellRenderer: (params) => <span>{params.value}</span>
            });
        }
        return array;
    }

    render() {

        if (this.state.redirectUrl != "") return <Navigate to={this.state.redirectUrl} replace />
        if (this.props.dataLoading || this.props.createEditLoading) return <div className="loading--bar fixed--top"><span></span></div>
        if (this.state.hideForm) return;

        return <React.Fragment>
            <article className="card mb-4">
                <div className="card-body">
                    <div className="row">
                        <div className="col-3">
                            <div className={`form-group ${this.vResult("poNumber").className}`}>
                                <label htmlFor="poNumber">PO Number<span className="requried-span">*</span></label>
                                <input id="poNumber" className="form-control" type="text"
                                    value={this.props.purchaseOrderData?.poNumber?.toString()}
                                    onChange={e => {
                                        e.preventDefault();
                                        this.ValidateField("poNumber", e.target.value);
                                        this.props.updatePurchaseOrderDataState({ name: "poNumber", value: e.target.value })
                                        this.props.updateHasError(this.hasError);
                                    }} />
                                <ValidationMessageControl message={this.vResult("poNumber").message} />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className={`form-group ${this.vResult("poDate").className}`}>
                                <label htmlFor="poDate">PO Date<span className="requried-span">*</span></label>
                                <DatePicker key="poDate" selected={utcToLocalDate(this.props.purchaseOrderData.poDate)}
                                    dateFormat={AppConstants.DatePickerFormat}
                                    onChange={(d) => {
                                        this.ValidateField("poDate", localToUtcDate(d));
                                        this.props.updatePurchaseOrderDataState({ name: "poDate", value: localToUtcDate(d) });
                                        this.props.updateHasError(this.hasError);
                                    }} />
                                <ValidationMessageControl message={this.vResult("poDate").message} />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className={`form-group ${this.vResult("amendmentNumber").className}`}>
                                <label htmlFor="amendmentNumber">Amendment Number</label>
                                <input id="amendmentNumber" className="form-control" type="text"
                                    value={this.props.purchaseOrderData.amendmentNumber?.toString() ?? ""}
                                    onChange={e => {
                                        e.preventDefault();
                                        this.ValidateField("code", e.target.value);
                                        this.props.updatePurchaseOrderDataState({ name: "amendmentNumber", value: e.target.value })
                                        this.props.updateHasError(this.hasError);
                                    }} />
                                <ValidationMessageControl message={this.vResult("amendmentNumber").message} />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className={`form-group ${this.vResult("amendmentDate").className}`}>
                                <label htmlFor="amendmentDate">Amendment Date</label>
                                <DatePicker key="amendmentDate" selected={utcToLocalDate(this.props.purchaseOrderData.amendmentDate?.toString() ?? "")}
                                    dateFormat={AppConstants.DatePickerFormat}
                                    onChange={(d) => {
                                        this.ValidateField("amendmentDate", localToUtcDate(d));
                                        this.props.updatePurchaseOrderDataState({ name: "amendmentDate", value: localToUtcDate(d) })
                                        this.props.updateHasError(this.hasError);
                                    }} />
                                <ValidationMessageControl message={this.vResult("amendmentDate").message} />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className={`form-group ${this.vResult("quotationNumber").className}`}>
                                <label htmlFor="quotationNumber">Quotation Number</label>
                                <input id="quotationNumber" className="form-control" type="text"
                                    value={this.props.purchaseOrderData.quotationNumber?.toString() ?? ""}
                                    onChange={e => {
                                        e.preventDefault();
                                        this.ValidateField("quotationNumber", e.target.value);
                                        this.props.updatePurchaseOrderDataState({ name: "quotationNumber", value: e.target.value })
                                        this.props.updateHasError(this.hasError);
                                    }} />
                                <ValidationMessageControl message={this.vResult("quotationNumber").message} />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className={`form-group ${this.vResult("quotationDate").className}`}>
                                <label htmlFor="quotationDate">Quotation Date</label>
                                <DatePicker key="quotationDate" selected={utcToLocalDate(this.props.purchaseOrderData.quotationDate?.toString() ?? "")}
                                    dateFormat={AppConstants.DatePickerFormat}
                                    onChange={(d) => {
                                        this.ValidateField("quotationDate", localToUtcDate(d));
                                        this.props.updatePurchaseOrderDataState({ name: "quotationDate", value: localToUtcDate(d) });
                                        this.props.updateHasError(this.hasError);
                                    }} />
                                <ValidationMessageControl message={this.vResult("quotationDate").message} />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className={`form-group ${this.vResult("referenceNote").className}`}>
                                <label htmlFor="referenceNote">Reference Note</label>
                                <input id="referenceNote" className="form-control" type="text"
                                    value={this.props.purchaseOrderData.referenceNote?.toString() ?? ""}
                                    onChange={e => {
                                        e.preventDefault();
                                        this.ValidateField("referenceNote", e.target.value);
                                        this.props.updatePurchaseOrderDataState({ name: "referenceNote", value: e.target.value })
                                    }} />
                                <ValidationMessageControl message={this.vResult("referenceNote").message} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <div className={`form-group ${this.vResult("clientId").className}`}>
                                <label htmlFor="clientId">Client<span className="requried-span">*</span></label>
                                <AsyncSelect id="clientId" cacheOptions defaultOptions
                                    className="async-select-control"
                                    loadOptions={this.loadClientOption}
                                    value={this.props.purchaseOrderData.client != null
                                        ? { label: DropdownDisplayHelper.Client(this.props.purchaseOrderData.client), value: this.props.purchaseOrderData.client.id.toString() }
                                        : { label: "Select Client", value: "" }}
                                    onChange={async (e) => {
                                        const cId = Number(e?.value);
                                        this.ValidateField("clientId", cId);
                                        if (cId === null || cId === 0 || (this.props.purchaseOrderData.client != null && cId === this.props.purchaseOrderData.client.id)) return;

                                        const client = await ClientService.GetById(cId);

                                        this.props.updatePurchaseOrderDataState({ name: "client", value: client });
                                        this.props.updatePurchaseOrderDataState({ name: "clientId", value: client.id });

                                        this.props.updatePurchaseOrderDataState({ name: "billingAddress", value: null });
                                        this.props.updatePurchaseOrderDataState({ name: "billingAddressId", value: null });

                                        this.props.updatePurchaseOrderDataState({ name: "deliveryAddress", value: null });
                                        this.props.updatePurchaseOrderDataState({ name: "deliveryAddressId", value: null });

                                        this.reloadDeliverAddressDropdown();
                                        this.reloadBillingAddressDropdown();

                                        this.props.updateHasError(this.hasError);
                                    }} />
                                <ValidationMessageControl message={this.vResult("clientId").message} />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className={`form-group ${this.vResult("billingAddressId").className}`}>
                                <label htmlFor="billingAddressId">Billing Address<span className="requried-span">*</span></label>
                                {(() => {
                                    if (this.state.hideBillingAddress) return;
                                    return <AsyncSelect id="billingAddressId" cacheOptions defaultOptions
                                        className="async-select-control"
                                        value={this.props.purchaseOrderData.billingAddress != null && this.props.purchaseOrderData.billingAddress.id > 0
                                            ? {
                                                label: DropdownDisplayHelper.ClientAddress(this.props.purchaseOrderData.billingAddress),
                                                value: this.props.purchaseOrderData.billingAddress.id.toString()
                                            }
                                            : { label: "Select Billing Address", value: "" }}
                                        loadOptions={this.loadClientAddressOption}
                                        isDisabled={this.props.purchaseOrderData.client == null}
                                        onChange={async (e) => {
                                            const aId = Number(e?.value);
                                            this.ValidateField("billingAddressId", aId);

                                            if (aId == null || aId == 0 || aId == this.props.purchaseOrderData.billingAddress?.id) return;

                                            const address = await ClientAddressService.GetById(aId);
                                            this.props.updatePurchaseOrderDataState({ name: "billingAddress", value: address });
                                            this.props.updatePurchaseOrderDataState({ name: "billingAddressId", value: address.id });

                                            this.reloadBillingAddressDropdown();
                                            this.props.updateHasError(this.hasError);
                                        }} />
                                })()}
                                <ValidationMessageControl message={this.vResult("billingAddressId").message} />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className={`form-group ${this.vResult("deliveryAddressId").className}`}>
                                <label htmlFor="deliveryAddressId">Delivery Address<span className="requried-span">*</span></label>
                                {(() => {
                                    if (this.state.hideDeliveryAddress) return;
                                    return <AsyncSelect id="deliveryAddressId" cacheOptions defaultOptions
                                        className="async-select-control"
                                        value={this.props.purchaseOrderData.deliveryAddress != null && this.props.purchaseOrderData.deliveryAddress.id > 0
                                            ? {
                                                label: DropdownDisplayHelper.ClientAddress(this.props.purchaseOrderData.deliveryAddress),
                                                value: this.props.purchaseOrderData.deliveryAddress.id.toString()
                                            }
                                            : { label: "Select Delivery Address", value: "" }}
                                        loadOptions={this.loadClientAddressOption}
                                        isDisabled={this.props.purchaseOrderData.client == null}
                                        onChange={async (e) => {
                                            const aId = Number(e?.value);
                                            this.ValidateField("billingAddressId", aId);

                                            if (aId == null || aId == 0 || aId == this.props.purchaseOrderData.deliveryAddress?.id) return;

                                            const address = await ClientAddressService.GetById(aId);

                                            this.ValidateField("deliveryAddressId", aId);
                                            this.props.updatePurchaseOrderDataState({ name: "deliveryAddress", value: address });
                                            this.props.updatePurchaseOrderDataState({ name: "deliveryAddressId", value: address.id });

                                            this.reloadDeliverAddressDropdown();
                                            this.props.updateHasError(this.hasError);
                                        }} />
                                })()}
                                <ValidationMessageControl message={this.vResult("deliveryAddressId").message} />
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className={`form-group mb-0 ${this.vResult("description").className}`}>
                                <label htmlFor="amendmentNumber">Description</label>
                                <input id="amendmentNumber" className="form-control" type="text"
                                    value={this.props.purchaseOrderData.description?.toString() ?? ""}
                                    onChange={e => {
                                        e.preventDefault();
                                        //this.ValidateField("description", e.target.value);
                                        this.props.updatePurchaseOrderDataState({ name: "description", value: e.target.value })
                                        //this.props.updateHasError(this.hasError);
                                    }} />
                                {/* <ValidationMessageControl message={this.vResult("amendmentNumber").message} /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </article>

            <article className="card mb-4">
                <div className="card-header">
                    <div className="card-form-header-title">
                        Client Coordinator
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-4">
                            <div className={`form-group`}>
                                <label htmlFor="Name">Name</label>
                                <input id="Name" className="form-control" type="text"
                                    value={this.props.purchaseOrderData?.clientCoordinatorName ?? ""}
                                    onChange={e => {
                                        e.preventDefault();
                                        this.props.updatePurchaseOrderDataState({ name: "clientCoordinatorName", value: e.target.value })
                                    }} />

                            </div>
                        </div>
                        <div className="col-4">
                            <div className={`form-group ${this.vResult("clientCoordinatorEmail").className}`}>
                                <label htmlFor="clientCoordinatorEmail">Email Address</label>
                                <input id="clientCoordinatorEmail" className="form-control" type="text"
                                    value={this.props.purchaseOrderData.clientCoordinatorEmail ?? ""}
                                    onChange={e => {
                                        e.preventDefault();
                                        this.ValidateField("clientCoordinatorEmail", e.target.value);
                                        this.props.updatePurchaseOrderDataState({ name: "clientCoordinatorEmail", value: e.target.value })
                                        this.props.updateHasError(this.hasError);
                                    }} />
                                <ValidationMessageControl message={this.vResult("clientCoordinatorEmail").message} />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className={`form-group ${this.vResult("clientCoordinatorPhoneNumber").className}`}>
                                <label htmlFor="clientCoordinatorPhoneNumber">Phone Number</label>
                                <input id="clientCoordinatorPhoneNumber" className="form-control" type="text"
                                    value={this.props.purchaseOrderData.clientCoordinatorPhoneNumber ?? ""}
                                    onChange={e => {
                                        e.preventDefault();
                                        this.ValidateField("clientCoordinatorPhoneNumber", e.target.value);
                                        this.props.updatePurchaseOrderDataState({ name: "clientCoordinatorPhoneNumber", value: e.target.value })
                                        this.props.updateHasError(this.hasError);
                                    }} />
                                <ValidationMessageControl message={this.vResult("clientCoordinatorPhoneNumber").message} />
                            </div>
                        </div>

                    </div>
                </div>
            </article>
            {(() => {
                if (this.props.purchaseOrderData?.id == null || this.props.purchaseOrderData?.id == 0) return;
                return <article className={`card mb-4 ${this.vResult("lineItems").className}`}>
                    <div className="card-header card-form-header child-grid-title">
                        <div className="card-form-header-title">Line Items</div>
                        {(() => {
                            if (this.props.accessLevelLineItem >= AccessLevel.Create) {
                                return <button type="button" className="btn btn-primary btn--right"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({ redirectUrl: ScreenUrls.PurchaseOrder.LineItem.Create(this.props.id) });
                                    }}>
                                    Add New
                                </button>
                            }
                        })()}
                        <ValidationMessageControl message={this.vResult("lineItems").message} />
                    </div>
                    <div className="card-block ag-theme-alpine medium-grid">
                        {(() => {
                            return <AgGridReact
                                unSortIcon={true}
                                rowHeight={60}
                                defaultColDef={AgGridDefaultColDef}
                                columnDefs={this.getPOLineItemsCols()}
                                pagination={true}
                                paginationAutoPageSize={true}
                                rowData={this.props.purchaseOrderData.lineItems}>
                            </AgGridReact>
                        })()}
                    </div>
                    {(() => { if (this.props.createEditLoading) return <div className="loading--bar"><span></span></div> })()}
                </article>
            })()}
        </React.Fragment>;
    }
}

const mapStateToProps = (state: IAppState) => ({
    purchaseOrderData: state.purchaseOrder.purchaseOrderData,
    dataLoading: state.purchaseOrder.dataLoading,
    createEditLoading: state.purchaseOrder.createEditLoading,
    accessRoles:state.userSession?.currentUser?.accessRoles,
})

export default connect(mapStateToProps, {
    updatePurchaseOrderDataState,
})(PurchaseOrderCreateEditComponent);
