import React from "react";
import ScreenUrls from "../../../../common/screen-urls";
import { IPasswordChangeModel, IUserModel } from "../../model/user.model";
import { IAppState } from "../../../../store";
import { connect } from "react-redux";
import {
    passwordChange,
    updateUserDataState
} from "../../store/user.slice";
import { Link, Navigate } from "react-router-dom";
import { FormBaseComponent, IFormBaseState } from "../../../../common/component/form-base.component";
import { ValidationScreens } from "../../../../common/enums";
import InputBoxComponent from "../../../../common/component/input-box-component";

interface IProps {
    id?: string;
    accessLevel?: number;
    currentUser: IUserModel;
    dataLoading: boolean;
    passwordChangeLoading: boolean;
    passwordChange: (id: IPasswordChangeModel) => void;
}

export interface IState extends IFormBaseState {
    currentPassword: string,
    newPassword: string,
    confirmNewPassword: string,
    redirectUrl: string
}

class UserPasswordChangePage extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);

        const userId = Number(props.id);
        this.state = {
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
            redirectUrl: props.id != null && userId > 0 && userId === props.currentUser.id ? "" : ScreenUrls.AccessDenied(),
            hideForm: false
        };
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.UserPasswordChange);
    }

    updateState(newState: any) {
        this.setState({
            ...this.state,
            ...newState
        });
    }

    render() {
        if (this.state.redirectUrl != "") return <Navigate to={this.state.redirectUrl} replace />
        return <React.Fragment>
            <div className="pcoded-content">
                <div className="pcoded-inner-content">
                    <div className="page-header">
                        <div className="page-block">
                            <div className="row">
                                <div className="col-md-12">
                                    <Link to={ScreenUrls.Users.Index()}
                                        className="btn drp-icon btn-rounded btn-primary dropdown-toggle">
                                        <i className="feather icon-arrow-left"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-body">
                        <div className="page-wrapper">
                            <div className="row">
                                <div className="col-sm-12">
                                    {(() => {
                                        if (this.state.hideForm) return;
                                        return <article className="card">
                                            <div className="card-header">
                                                <h4 className="card-title mb-0">
                                                    Change Password
                                                </h4>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <InputBoxComponent
                                                            label={"Current Password"}
                                                            htmlFor="currentPassword"
                                                            isRequired={true}
                                                            value={this.state.currentPassword}
                                                            type="password"
                                                            error={this.vResult("currentPassword")}
                                                            onChangeHandler={(e) => {
                                                                this.ValidateField("currentPassword", e.target.value);
                                                                this.updateState({ currentPassword: e.target.value });
                                                            }}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <InputBoxComponent
                                                            label={"New Password"}
                                                            htmlFor="newPassword"
                                                            isRequired={true}
                                                            value={this.state.newPassword}

                                                            type="password"
                                                            error={this.vResult("newPassword")}
                                                            onChangeHandler={(e) => {
                                                                this.ValidateField("newPassword", e.target.value, this.state.currentPassword);
                                                                this.updateState({ newPassword: e.target.value });
                                                            }}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <InputBoxComponent
                                                            label={"Confirm New Password"}
                                                            htmlFor="confirmNewPassword"
                                                            isRequired={true}
                                                            value={this.state.confirmNewPassword}
                                                            placeholder=""
                                                            type="password"
                                                            error={this.vResult("confirmNewPassword")}
                                                            onChangeHandler={(e) => {
                                                                this.ValidateField("confirmNewPassword", e.target.value, this.state.newPassword);
                                                                this.updateState({ confirmNewPassword: e.target.value });
                                                            }}
                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    })()}
                                    <article className="card no-bg">

                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <button type="button" className="btn m-0 btn-primary btn--right"
                                                        disabled={this.hasError}
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            const id = Number(this.props.id);
                                                            if (id == null || id == 0) return;

                                                            const error = !this.ValidateForm(this.state,this.state);
                                                            this.reloadForm();
                                                            if (error) return;

                                                            this.props.passwordChange({
                                                                id: id,
                                                                password: this.state.newPassword,
                                                                currentPassword: this.state.currentPassword,
                                                            });

                                                            setTimeout(() => {
                                                                this.updateState({ redirectUrl: ScreenUrls.Logout() })
                                                            }, 1000);
                                                        }}>Submit</button>
                                                </div>
                                            </div>
                                        </div>

                                        {(() => { if (this.props.passwordChangeLoading) return <div className="loading--bar"><span></span></div> })()}
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}

const mapStateToProps = (state: IAppState) => ({
    currentUser: state.userSession.currentUser,
    dataLoading: state.user.dataLoading,
    passwordChangeLoading: state.user.passwordChangeLoading
})

export default connect(mapStateToProps, { passwordChange, updateUserDataState })(UserPasswordChangePage);
