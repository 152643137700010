import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import MyWorkService from "../service/my-work.service";
import { IWorkflowModel } from "../../workflow/model/workflow.model";
import {IWorkflowModelWithPOInfo} from "../model/my-work.model";

export interface IMyWorkState {
    myWorkWorkflow: IWorkflowModel[],
    myWorkWorkflowTask: IWorkflowModelWithPOInfo[],
    workflowDataLoading: boolean,
    taskDataLoading: boolean
}

const initialState: IMyWorkState = {
    myWorkWorkflow:[],
    myWorkWorkflowTask:[],
    workflowDataLoading: false,
    taskDataLoading:false,
}

export const loadMyWorkWorkflow = createAsyncThunk("myWork/load/workflow", MyWorkService.LoadWorkflow);
export const loadMyWorkWorkflowTask = createAsyncThunk("myWork/load/task", MyWorkService.LoadTask);

const myWorkSlice = createSlice({
    name: 'myWork',
    initialState,
    reducers: {
        clearMyTaskListState: (state) => { 
            state.myWorkWorkflowTask= [];
        },
        clearMyWorkflowListState: (state) => { 
            state.myWorkWorkflow = [];
        }
    },
    extraReducers: (builder) => {
        // Load All
        builder.addCase(loadMyWorkWorkflow.pending, (state, action) => {
            clearMyWorkflowListState();
            state.workflowDataLoading = true;
        });
        builder.addCase(loadMyWorkWorkflow.rejected, (state, action) => {
            clearMyWorkflowListState();
            state.workflowDataLoading = false;
        });
        builder.addCase(loadMyWorkWorkflow.fulfilled, (state, action) => {
            state.myWorkWorkflow = action.payload;
            state.workflowDataLoading = false;
        });

        // Load All
        builder.addCase(loadMyWorkWorkflowTask.pending, (state, action) => {
            clearMyTaskListState();
            state.taskDataLoading = true;
        });
        builder.addCase(loadMyWorkWorkflowTask.rejected, (state, action) => {
            clearMyTaskListState();
            state.taskDataLoading = false;
        });
        builder.addCase(loadMyWorkWorkflowTask.fulfilled, (state, action) => {
            state.myWorkWorkflowTask = action.payload;
            state.taskDataLoading = false;
        });
    }
});

const { actions, reducer } = myWorkSlice;

export const { clearMyTaskListState,clearMyWorkflowListState} = actions;

export default reducer;
