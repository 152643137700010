import { BaseComponent } from "../../../../common/component/base.component";
import { connect } from "react-redux";
import { IAppState } from "../../../../store";
import React from "react";
import { AgGridReact } from "ag-grid-react";
import { AgGridDefaultColDef } from '../../../../common/app-defaults';
import { IDataLoggerCameraDetailModel } from "../../../data-logger/model/data-logger-camera-detail.model";
import DataLoggerCameraDetailService from "../../../data-logger/service/data-logger-camera-detail.service";
import QuickAddDataLoggerCameraDetailComponent from "../../../data-logger/component/quick-add-edit-data-logger-camera-detail.component";

export interface IProps {
    defaultSelections?: null | IDataLoggerCameraDetailModel[];
    onSelection: (data: IDataLoggerCameraDetailModel) => void;
    onUnSelection: (data: IDataLoggerCameraDetailModel) => void;
    onUnSelectionAll: () => void;
}

export interface IState {
    collapsed: boolean,
    listLoading: boolean,
    dataLoggerCameraDetailList: IDataLoggerCameraDetailModel[],
    previousSelection?: IDataLoggerCameraDetailModel[] | null,
    addCameraSection: boolean,
}

class DataLoggerCameraSelectorAccordionComponent extends BaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
            listLoading: false,
            dataLoggerCameraDetailList: [],
            previousSelection: this.props.defaultSelections,
            addCameraSection: false,
        }
    }

    reloadEquipments = async () => {
        setTimeout(async () => {
            const list = await DataLoggerCameraDetailService.LoadAll();
            this.updateState({ dataLoggerCameraDetailList: list });
        }, 200)
    }
    async componentDidMount() {
        await this.reloadEquipments();
    }

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (!this.state.addCameraSection && prevState.addCameraSection) {
            await this.reloadEquipments();
        }
    }

    updateState(newState: any) {
        this.setState({
            ...this.state,
            ...newState
        });
    }
    render() {
        return <React.Fragment>
            {(() => { if (this.state.listLoading) return <div className="loading--bar fixed--top"><span></span></div> })()}
            <div className="accordion no-bg" id="dataLoggerCameraDetailSelectorAccordionContainer">
                <div className="card overflow-visible">
                    <div className="card-header">
                        <button className="btn btn-primary accordion--btn" type="button" data-toggle="collapse"
                            data-target="#dataLoggerCameraDetailSelectorAccordionControl" aria-expanded="false" aria-controls="dataLoggerCameraDetailSelectorAccordionControl"
                            onClick={() => { this.setState({ collapsed: !this.state.collapsed }) }}>
                            <i className={`button-icon feather ${this.state.collapsed ? 'icon-chevrons-down' : 'icon-chevrons-up'}`}></i>
                            Data Logger Camera Selection<span className="requried-span text-white">*</span>
                        </button>
                    </div>
                    <div id="dataLoggerCameraDetailSelectorAccordionControl" className="collapse" aria-labelledby="headingOne"
                        data-parent="#dataLoggerCameraDetailSelectorAccordionContainer">
                        <div className="card-body">
                            {/* --------- quick add box --------- */}
                            {
                                this.state.addCameraSection ?
                                    <QuickAddDataLoggerCameraDetailComponent callBackHandler={() => this.setState({ ...this.state, addCameraSection: !this.state.addCameraSection })} />
                                    :
                                    <div className="row">
                                        <div className="col-12 align-self-center text-right">
                                            <button className="btn mr-0 mb-0 btn-primary"
                                                onClick={() => this.setState({ ...this.state, addCameraSection: !this.state.addCameraSection })}
                                            >Add Camera</button>
                                        </div>
                                    </div>
                            }
                            {
                                !this.state.addCameraSection ?
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card-block px-0 ag-theme-alpine">
                                                <AgGridReact
                                                    domLayout='autoHeight'
                                                    pagination={false}
                                                    paginationPageSize={9}
                                                    unSortIcon={true}
                                                    rowHeight={60}
                                                    rowSelection="multiple"
                                                    columnDefs={[
                                                        {
                                                            width: 45,
                                                            suppressMovable: true,
                                                            cellClass: "grid-cell",
                                                            checkboxSelection: (row) => {
                                                                if (row.data == null || row.data == undefined) return false;
                                                                const isRowSelectable = row.node.isSelected() || (row.data != null);
                                                                row.node.selectable = isRowSelectable;
                                                                return isRowSelectable;
                                                            },
                                                            cellRenderer: (params) => {
                                                                if (this.props.defaultSelections != null) {
                                                                    const filtered = this.props.defaultSelections.filter(d => d.id === params.data.id);
                                                                    params.node.selected = filtered != null && filtered.length > 0;
                                                                    return <span>{params.value}</span>
                                                                }

                                                                return <span>{params.value}</span>
                                                            },
                                                        },
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "#", field: 'id', width: 80, cellClass: "grid-cell",
                                                            cellRenderer: (params) => {
                                                                return <span>{params.value}</span>
                                                            }
                                                        },
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "Camera Name", field: "cameraName", flex: 2, sortable: true, cellClass: "grid-cell",
                                                            cellRenderer: (params) => {
                                                                return <span>{params.value}</span>
                                                            }
                                                        },
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "Make", flex: 1, field: "make", sortable: true, cellClass: "grid-cell",
                                                            cellRenderer: (params) => {
                                                                return <span>{params.value}</span>
                                                            }
                                                        },
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "Serial #", flex: 1, field: "serialNumber", sortable: true, cellClass: "grid-cell",
                                                            cellRenderer: (params) => {
                                                                return <span>{params.value}</span>
                                                            }
                                                        }, {
                                                            suppressMovable: true,
                                                            headerName: "Model Number", flex: 1, field: "modelNumber", sortable: true, cellClass: "grid-cell",
                                                            cellRenderer: (params) => {
                                                                return <span>{params.value}</span>
                                                            }
                                                        },
                                                    ]}
                                                    rowData={this.state.dataLoggerCameraDetailList}
                                                    onRowSelected={(r) => {
                                                        if (r.data == null) {
                                                            return;
                                                        }
                                                        if (r.node.isSelected()) {
                                                            this.props.onSelection(r.data);
                                                        } else {
                                                            this.props.onUnSelection(r.data)
                                                        }
                                                    }}
                                                    suppressRowClickSelection={true}
                                                    defaultColDef={AgGridDefaultColDef}>
                                                </AgGridReact>
                                            </div>
                                        </div>

                                    </div>
                                    :
                                    ""
                            }

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}

const mapStateToProps = (state: IAppState) => ({})
export default connect(mapStateToProps, {})(DataLoggerCameraSelectorAccordionComponent);

