import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected, PayloadAction } from "@reduxjs/toolkit";
import { ICleanroomEquipmentModel } from '../../model/report.model';
import { IStoreSaveModel } from "../../../../common/model/store-save.model";
import { IAirflowVelocityModel } from "../../model/test/test.model";
import { airflowVelocityTestDefaults } from "../../model/defaults/test.default";
import TestService from "../../service/test.service";
import { createAVObservation, deleteObservation, editAVObservation, createAVReading, editAVReading, deleteReading, createFIObservation, editFIObservation, createLIObservation, createLIReading, editLIObservation, editLIReading, createPCLocation, editPCLocation, createPCObservation, editPCObservation, deletePCLocation, deletePCObservation, createRecoveryReading, editRecoveryReading, deleteRecoveryreading, createSLObservation, editSLObservation, createSLReading, editSLReading, createUVLIObservation, editUVLIObservation, createUVLIReading } from "./observation.slice";
import ScreenUrls from "../../../../common/screen-urls";
import { CleanroomEquipmentTests } from "../../../../common/enums";
import { IFilterIntegrityModel } from "../../model/test/filter-integrity-test.model";
import { ILightIntensityTestModel } from "../../model/test/light-intensity-test.model";
import { IPCTestModel } from "../../model/test/particle-count-test.model";
import { IRecoveryTestModel } from "../../model/test/recovery-test.model";
import { ISoundLevelTestModel } from "../../model/test/sound-level-test.model";
import { IUVLightTestModel } from "../../model/test/uv-light-test.model";
import { IAirflowPatterTestModel } from "../../model/test/airflow-pattern-test.model";

// ----- AV Test -----
export const loadAVTestById = createAsyncThunk("report/test/AI/loadById", TestService.GetAVById);
export const createAVTest = createAsyncThunk("report/test/AI/create", TestService.CreateAVTest);
export const editAVTest = createAsyncThunk("report/test/AI/edit", TestService.EditAVTest);

//= common =
export const deleteTest = createAsyncThunk("report/test/delete", TestService.DeleteTest);
export const enableTestEdit = createAsyncThunk("report/test/enable-edit", TestService.EnableTestEdit);

// ----- FI Test -----
export const loadFITestById = createAsyncThunk("report/test/FI/loadById", TestService.GetFITestById);
export const createFITest = createAsyncThunk("report/test/FI/create", TestService.CreateFITest);
export const editFITest = createAsyncThunk("report/test/FI/edit", TestService.EditFITest);

// ---- PC Test ----
export const loadPCTestById = createAsyncThunk("report/test/pc/loadById", TestService.GetPCTestById);
export const createPCTest = createAsyncThunk("report/test/pc/create", TestService.CreatePCTest);
export const editPCTest = createAsyncThunk("report/test/pc/edit", TestService.EditPCTest);

// ---- LI Test ----
export const loadLITestById = createAsyncThunk("report/test/li/loadById", TestService.GetLITestById);
export const createLITest = createAsyncThunk("report/test/li/create", TestService.CreateLITest);
export const editLITest = createAsyncThunk("report/test/li/edit", TestService.EditLITest);

// --- Recovery Test ---
export const loadRecoveryTestById = createAsyncThunk("report/test/recovery/loadById", TestService.GetRecoveryTestById);
export const createRecoveryTest = createAsyncThunk("report/test/recovery/create", TestService.CreateRecoveryTest);
export const editRecoveryTest = createAsyncThunk("report/test/recovery/edit", TestService.EditRecoveryTest);

// --- Sound Level Test ---
export const loadSLTestById = createAsyncThunk("report/test/sl/loadById", TestService.GetSLTestById);
export const createSLTest = createAsyncThunk("report/test/sl/create", TestService.CreateSLTest);
export const editSLTest = createAsyncThunk("report/test/sl/edit", TestService.EditSLTest);

// --- UVLI Test ---
export const loadUVLITestById = createAsyncThunk("report/test/uvli/loadById", TestService.GetUVLITestById);
export const createUVLITest = createAsyncThunk("report/test/uvli/create", TestService.CreateUVLITest);
export const editUVLITest = createAsyncThunk("report/test/uvli/edit", TestService.EditUVLITest);

// --- AFPT Test ---
export const loadAFPTTestById = createAsyncThunk("report/test/afpt/loadById", TestService.GetAFPTTestById);
export const createAFPTITest = createAsyncThunk("report/test/afpt/create", TestService.CreateAFPTTest);
export const editAFPTTest = createAsyncThunk("report/test/afpt/edit", TestService.EditAFPTTest);

export interface ICleanroomEquipmentTestState {
    testList: ICleanroomEquipmentModel[],
    testData: IAirflowVelocityModel | IFilterIntegrityModel | ILightIntensityTestModel | IPCTestModel | IRecoveryTestModel | ISoundLevelTestModel | IUVLightTestModel | IAirflowPatterTestModel,
    dataLoading: boolean,
    listLoading: boolean,
    createEditLoading: boolean,
    stageMoveLoading: boolean,
    refetchApi: boolean
}

const initialState: ICleanroomEquipmentTestState = {
    testList: [],
    testData: airflowVelocityTestDefaults,
    dataLoading: false,
    listLoading: false,
    createEditLoading: false,
    stageMoveLoading: false,
    refetchApi: false,
}

const cleanroomEquipmentSlice = createSlice({
    name: 'cleanroomEquipmentTest',
    initialState,
    reducers: {
        clearTestDataState: (state, action) => {
            state.testData = action.payload
        },
        updateTestDataState: (state, action: PayloadAction<IStoreSaveModel>) => {
            state.testData[action.payload.name] = action.payload.value;
        }
    },
    extraReducers: async (builder) => {
        // create
        builder.addCase(createAVTest.fulfilled, (state, action) => {
            state.testData = action.payload;
            state.createEditLoading = false;
            if (action.payload?.cleanroomReportId && action.payload?.id) {
                window.location.href = `${ScreenUrls.Report.CleanroomEquipment.Edit(action.payload?.cleanroomReportId ?? 0)}/${CleanroomEquipmentTests.airflowvelocity}/${ScreenUrls.EditUri}/${action.payload?.id ?? 0}`;
            }

        });
        // ----- FI test -----
        // create
        builder.addCase(createFITest.fulfilled, (state, action) => {
            state.testData = action.payload;
            state.createEditLoading = false;
            if (action.payload?.cleanroomReportId && action.payload?.id) {
                window.location.href = `${ScreenUrls.Report.CleanroomEquipment.Edit(action.payload?.cleanroomReportId ?? 0)}/${CleanroomEquipmentTests.filterintegrity}/${ScreenUrls.EditUri}/${action.payload?.id ?? 0}`;
            }

        });

        // ---- LI test ----
        builder.addCase(createLITest.fulfilled, (state, action) => {
            state.testData = action.payload;
            state.createEditLoading = false;
            if (action.payload?.cleanroomReportId && action.payload?.id) {
                window.location.href = `${ScreenUrls.Report.CleanroomEquipment.Edit(action.payload?.cleanroomReportId ?? 0)}/${CleanroomEquipmentTests.lightintensity}/${ScreenUrls.EditUri}/${action.payload?.id ?? 0}`;
            }
        });

        // ---- PC test ----
        builder.addCase(createPCTest.fulfilled, (state, action) => {
            state.testData = action.payload;
            state.createEditLoading = false;
            if (action.payload?.cleanroomReportId && action.payload?.id) {
                window.location.href = `${ScreenUrls.Report.CleanroomEquipment.Edit(action.payload?.cleanroomReportId ?? 0)}/${CleanroomEquipmentTests.nonviableparticlecount}/${ScreenUrls.EditUri}/${action.payload?.id ?? 0}`;
            }
        });

        // ---- Recovery test ----
        builder.addCase(createRecoveryTest.fulfilled, (state, action) => {
            state.testData = action.payload;
            state.createEditLoading = false;
            if (action.payload?.cleanroomReportId && action.payload?.id) {
                window.location.href = `${ScreenUrls.Report.CleanroomEquipment.Edit(action.payload?.cleanroomReportId ?? 0)}/${CleanroomEquipmentTests.recovery}/${ScreenUrls.EditUri}/${action.payload?.id ?? 0}`;
            }
        });

        // ---- sound level test ---
        builder.addCase(createSLTest.fulfilled, (state, action) => {
            state.testData = action.payload;
            state.createEditLoading = false;
            if (action.payload?.cleanroomReportId && action.payload?.id) {
                window.location.href = `${ScreenUrls.Report.CleanroomEquipment.Edit(action.payload?.cleanroomReportId ?? 0)}/${CleanroomEquipmentTests.soundlevel}/${ScreenUrls.EditUri}/${action.payload?.id ?? 0}`;
            }
        });

        // ---- uvli test ---
        builder.addCase(createUVLITest.fulfilled, (state, action) => {
            state.testData = action.payload;
            state.createEditLoading = false;
            if (action.payload?.cleanroomReportId && action.payload?.id) {
                window.location.href = `${ScreenUrls.Report.CleanroomEquipment.Edit(action.payload?.cleanroomReportId ?? 0)}/${CleanroomEquipmentTests.uvlightintensity}/${ScreenUrls.EditUri}/${action.payload?.id ?? 0}`;
            }
        });

        // ---- AFPT test ---
        builder.addCase(createAFPTITest.fulfilled, (state, action) => {
            state.testData = action.payload;
            state.createEditLoading = false;
            if (action.payload?.cleanroomReportId && action.payload?.id) {
                window.location.href = `${ScreenUrls.Report.CleanroomEquipment.Edit(action.payload?.cleanroomReportId ?? 0)}/${CleanroomEquipmentTests.airflowpattern}/${ScreenUrls.EditUri}/${action.payload?.id ?? 0}`;
            }
        });

        // create,edit loader
        builder.addMatcher(isPending(createAVTest, editAVTest, createFITest, editFITest, createLITest, editLITest, createPCTest, editPCTest, createRecoveryTest, editRecoveryTest, createSLTest, editSLTest, createUVLITest, editUVLITest, createAFPTITest, editAFPTTest), (state, action) => {
            state.createEditLoading = true;
        });

        builder.addMatcher(isRejected(createAVTest, editAVTest, createFITest, editFITest, createLITest, editLITest, createPCTest, editPCTest, createRecoveryTest, editRecoveryTest, createSLTest, editSLTest, createUVLITest, editUVLITest, createAFPTITest, editAFPTTest), (state, action) => {
            state.createEditLoading = false;
        });

        builder.addMatcher(isFulfilled(editAVTest, editLITest, editFITest, editPCTest, editRecoveryTest, editSLTest, editUVLITest, editAFPTTest), (state, action) => {
            state.testData = action.payload;
            state.createEditLoading = false;
        });

        // --- fetch data
        builder.addMatcher(isPending(loadAVTestById, loadFITestById, loadLITestById, loadPCTestById, loadRecoveryTestById, loadSLTestById, loadUVLITestById, loadAFPTTestById), (state, action) => {
            state.dataLoading = true;
        });

        builder.addMatcher(isRejected(loadAVTestById, loadFITestById, loadLITestById, loadPCTestById, loadRecoveryTestById, loadSLTestById, loadUVLITestById, loadAFPTTestById), (state, action) => {
            state.dataLoading = false;
            state.refetchApi = false;
        });

        builder.addMatcher(isFulfilled(loadAVTestById, loadFITestById, loadLITestById, loadPCTestById, loadRecoveryTestById, loadSLTestById, loadUVLITestById, loadAFPTTestById), (state, action) => {
            state.testData = action.payload;
            state.refetchApi = false;
            state.dataLoading = false;
        });

        // --- observations 
        builder.addMatcher(isFulfilled(createFIObservation, editFIObservation, deleteReading, createAVReading, editAVReading, createAVObservation, editAVObservation, deleteObservation, createLIObservation, createLIReading, editLIObservation, editLIReading, createPCLocation, editPCLocation, createPCObservation, editPCObservation, deletePCLocation, deletePCObservation, createRecoveryReading, editRecoveryReading, deleteRecoveryreading, createSLObservation, editSLObservation, createSLReading, editSLReading, createUVLIObservation, editUVLIObservation, createUVLIReading, editUVLIObservation, enableTestEdit), (state, action) => {
            state.refetchApi = true;
        });
    }
});

const { actions, reducer } = cleanroomEquipmentSlice;

export const { clearTestDataState, updateTestDataState } = actions;

export default reducer;