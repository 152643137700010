import React, { useEffect, useState } from "react";
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image, Font, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import TestService from "../../../service/test.service";
import { CleanroomEquipmentTests } from "../../../../../common/enums";
import { ICleanroomEquipmentModel } from "../../../model/report.model";
import { displayDate, localToUtcDate } from "../../../../../common/utils";
import { IAirflowVelocityTestObservationModel, IAirflowVelocityModel } from "../../../model/test/test.model";
import { IUserModel } from "../../../../user/model/user.model";
import moment from "moment";
import IncepOne from "../../../../../assets/images/incepbio.jpg";
import Location from "../../../../../assets/images/location.png";
import WWW from "../../../../../assets/images/www.png";
import TimesNewRoman from "../../../../../assets/fonts/time-new-roman/times.ttf";
import TimesNewRomanBold from "../../../../../assets/fonts/time-new-roman/times-bold.ttf";
import Call from "../../../../../assets/images/call.png";
import { ILITestObservationModel } from "../../../model/test/light-intensity-test.model";
import { ISoundLevelObservationModel } from "../../../model/test/sound-level-test.model";
import { IUVLightTestObservationModel } from "../../../model/test/uv-light-test.model";
import { editAVTest, editPCTest, editLITest, editRecoveryTest, editSLTest, editUVLITest } from '../../../store/cleanroom-equipment/test.slice';
import { useDispatch } from "react-redux";
Font.register({
    family: 'Times', src: TimesNewRoman, fontWeight: 400
})

Font.register({
    family: 'times-bold', src: TimesNewRomanBold
})


export const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFF',
        color: '#333f54',
        fontFamily: 'Times'
    },
    section: {
        width: '100%',
        paddingLeft: 15,
        paddingRight: 15,
    },
    paddingY: {
        paddingTop: "15px",
        paddingBottom: "15px",
    },
    col: {
        padding: 6,
        border: '0.5px solid #000',
    },
    reportTitle: {
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: 12,
        fontWeight: 500,
    },
    flexColumn: {
        flexDirection: 'column'
    },
    flexBox: {
        display: "flex",
        flexDirection: 'row',
        fontSize: 9,
        alignItems: 'stretch',
    },
    label: {
        width: 100,
        fontSize: 9,
    },
    flex_1: {
        flex: 1,
    },
    flex_2: {
        flex: 2,
    },
    font_13: {
        fontSize: '12px',
        marginBottom: 5,
    },
    font_12: {
        fontSize: '10px',
    },
    paddingTB: {
        padding: '6px 0',
    },
    image: {
        width: '90%',
    },
    bold: {
        fontFamily: 'times-bold',
        fontWeight: 'bold',
    },
    main: {
        border: '0.5px double #000',
        padding: 4,
    },
    footer: {
        backgroundColor: '#004D88',
        color: '#FFF',
        margin: '0 -30',
        padding: '6 30'
    },
    pageNumber: {
        textAlign: 'center',
        fontSize: 10,
    }
    , footerImage: {
        borderRadius: '50%',
        backgroundColor: '#FFF',
        padding: 2,
        width: 12,
        marginRight: 5,
    },
    textCenter: {
        textAlign: 'center'
    }
});

export const BaseTest = ({ data, title, maxReadings, testName, callBack }: { title: string, testName: string, data: IAirflowVelocityModel | any, maxReadings?: number, callBack: any }) => {

    return (
        <Document>
            <Page size="A4" style={styles.page} wrap>
                {/* ---- header ------ */}
                <View style={{ ...styles.paddingY }} fixed>
                    <View style={{ ...styles.section, ...styles.flexBox, alignItems: 'center' }}>
                        <view style={{ ...styles.flex_1 }}>
                            <Image src={IncepOne} style={{ ...styles.image }} />
                        </view>
                        <view style={{ ...styles.flex_2 }}>
                            <View style={{ backgroundColor: '#009C8D', width: '100%', height: '8', position: 'relative' }}>
                                <View style={{ backgroundColor: '#004D88', position: 'absolute', width: '30%', height: '100%', right: 0 }}></View>
                                <View style={{ backgroundColor: '#FFF', position: 'absolute', width: '2%', height: '200%', right: '29%', transform: 'rotate(-20deg)', top: '-50%' }}></View>
                            </View>
                        </view>
                    </View>
                    <View style={{ ...styles.section, ...styles.font_12, marginTop: 2 }}>
                        <Text style={styles.bold}>An ISO 9001 : 2015 Certified Company</Text>
                    </View>
                </View>

                {/* ---- body ---- */}
                <View style={{ ...styles.section }}>
                    <View style={{ ...styles.main }}>
                        <View style={{ ...styles.paddingTB }}>
                            <Text style={{ ...styles.reportTitle, ...styles.bold }}>Test Report For {title}</Text>
                        </View>
                        <View style={{ ...styles.paddingTB }}>
                            <View style={{ ...styles.flexBox, }}>
                                <View style={{ ...styles.flex_2, ...styles.col }}>
                                    <Text style={{ ...styles.label, ...styles.bold }}>Customer Details :</Text>
                                </View>
                                <view style={{ ...styles.flexBox, ...styles.flex_1 }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{ ...styles.label }}>Report Number</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{ ...styles.label, ...styles.bold }}>
                                            {data?.reportNumber ?? ''}
                                        </Text>
                                    </View>
                                </view>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_2, ...styles.col }}>
                                    <Text style={{}}>
                                        {data?.client?.companyName} {"\n"}
                                        {data?.clientAddress?.addressLine1}
                                        {data?.clientAddress?.addressLine2 ? `, ${data?.clientAddress?.addressLine2}` : ""}
                                        {"\n"}
                                        {data.clientAddress?.city}, {data.clientAddress?.state}
                                    </Text>
                                </View>
                                <view style={{ ...styles.flex_1 }}>
                                    <View style={{ ...styles.flexBox, flex: 1, ...styles.flexColumn, }} >
                                        <View style={{ ...styles.flexBox, flex: 1 }}>
                                            <View style={{ ...styles.label, ...styles.col }}>
                                                <Text style={{ ...styles.label }}>Date of test</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col }}>
                                                <Text style={{ ...styles.label }}>{displayDate(data.dateOfTest ?? "")}</Text>
                                            </View>
                                        </View>
                                        <View style={{ ...styles.flexBox, flex: 1 }}>
                                            <View style={{ ...styles.label, ...styles.col }}>
                                                <Text style={{ ...styles.label }}>Due Date</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col }}>
                                                <Text style={{ ...styles.label }}>{data?.dueDate ? displayDate(data.dueDate) : ""}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </view>
                            </View>
                        </View>

                        {/* ----  Equipnet details --- */}
                        <View style={{ ...styles.paddingTB }}>
                            <Text style={{ ...styles.font_12, ...styles.bold }}>Details Of Equipment :</Text>
                        </View>

                        <View style={{}}>
                            {/* --- fixed allignment --- */}
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{}}>Equipment Name
                                        </Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>{data.clientEquipment?.equipmentName ?? ''}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Area Of Test</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data.areaOfTest ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{}}>Equipment Id</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>
                                            {data.clientEquipment?.equipmentId ?? ""}
                                        </Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Location</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.location ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{}}>Make</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>{data.clientEquipment?.make ?? ""}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Filter Type</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.filterType ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{}}>Model Number</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>{data?.clientEquipment?.modelNumber ?? ""}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Filter Efficiency</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.filterEfficiency ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{}}>Serial Number</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>{data?.clientEquipment?.serialNumber ?? ""}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Zone Classification</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data.eugmpStandard?.id ? data.eugmpStandard.grade : data.isoStandard?.id ? data.isoStandard?.classNumber : ""}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>

                        {/* ---- data logger details --- */}
                        <View style={{ ...styles.paddingTB }}>
                            <View style={{}}>
                                <Text style={{ ...styles.font_12, ...styles.bold }}>Details Of Standard :</Text>
                            </View>

                            <View style={{ ...styles.paddingTB }}>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.flexBox, ...styles.col }}>
                                        <Text style={{}}>Name</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox, ...styles.col }}>
                                        <Text style={{}}>{data?.dataLogger?.name ?? ""}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col }}>
                                                <Text style={{}}>Model Number</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col }}>
                                                <Text style={{}}>{data.dataLogger?.modelNumber ?? ""}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col }}>
                                                <Text style={{}}>Make</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col }}>
                                                <Text style={{}}>{data.dataLogger?.make?.name ?? ""}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Instrument Id</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.dataLogger?.instrumentId ?? ""}</Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Serial Number</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.dataLogger?.serialNumber ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Calibration Done Date</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{displayDate(data.dataLogger?.lastCalibratedOn)}</Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col }}>
                                                <Text style={{}}>Calibration Due Date</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col }}>
                                                <Text style={{}}>
                                                    {displayDate(data.dataLogger?.calibrationDueOn)}
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.col, textAlign: "center" }}>

                                    <Text style={{ ...styles.bold }}>Protocol Name</Text>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.col, textAlign: "center" }}>

                                    <Text style={{ ...styles.bold }}>Protocol Number</Text>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.col, textAlign: "center" }}>
                                    <Text style={{}}>{data?.refStandardOrProtocolOrDocumentName ?? ""}</Text>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.col, textAlign: "center" }}>
                                    <Text style={{}}>{data?.procedureOrProtocolOrDocumentNumber ?? ""}</Text>
                                </View>
                            </View>
                        </View>

                        {
                            callBack(styles, data, maxReadings)
                        }

                        {/* --- result --- */}
                        <View style={{ ...styles.paddingTB, ...styles.font_12 }} wrap={false}>
                            <View style={{ ...styles.col, border: 0 }}>
                                <Text style={{ ...styles.bold }}>Conclusion :</Text>
                            </View>
                            <View style={{ ...styles.col, border: 0 }}>
                                <Text>{data.remarks ?? ""}</Text>
                            </View>
                        </View>

                        {/* ---- signature ---- */}
                        <View style={{ ...styles.paddingTB, ...styles.font_12 }} wrap={false}>
                            <View style={{ ...styles.flexBox, ...styles.bold }}>
                                <View style={{ ...styles.flex_2, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text>Prepared By</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>Checked By</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>Approved By</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.font_12, ...styles.flexBox }}>
                                <View style={{ ...styles.flex_2, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>Name</Text>
                                        </View>
                                        <View style={{ ...styles.flex_2, ...styles.col }}>
                                            <Text style={{}}>
                                                {data.preparedBy?.firstName} {data.preparedBy?.lastName}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>Name</Text>
                                        </View>
                                        <View style={{ ...styles.flex_2, ...styles.col }}>
                                            <Text style={{}}>{data.checkedBy?.firstName} {data.checkedBy?.lastName}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>Name</Text>
                                        </View>
                                        <View style={{ ...styles.flex_2, ...styles.col }}>
                                            <Text style={{}}>{data.verifiedByName}</Text>
                                        </View>
                                    </View>
                                </View>

                            </View>
                            <View style={{ ...styles.font_12, ...styles.flexBox }}>
                                <View style={{ ...styles.flex_2, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>Signature</Text>
                                        </View>
                                        <View style={{ ...styles.flex_2, ...styles.col }}>
                                            <Text style={{}}>

                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>Signature</Text>
                                        </View>
                                        <View style={{ ...styles.flex_2, ...styles.col }}>
                                            <Text style={{}}></Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>Signature</Text>
                                        </View>
                                        <View style={{ ...styles.flex_2, ...styles.col }}>
                                            <Text style={{}}></Text>
                                        </View>
                                    </View>
                                </View>
                            </View>

                            <View style={{ ...styles.font_12, ...styles.flexBox }}>
                                <View style={{ ...styles.flex_2, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>Date</Text>
                                        </View>
                                        <View style={{ ...styles.flex_2, ...styles.col }}>
                                            <Text style={{}}>
                                                {data?.preparedOnDate ? displayDate(data?.preparedOnDate) : ""}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>

                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>Date</Text>
                                        </View>
                                        <View style={{ ...styles.flex_2, ...styles.col }}>
                                            <Text style={{}}>
                                                {data?.checkedOnDate ? displayDate(data?.checkedOnDate) : ""}
                                            </Text>
                                        </View>

                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>Date</Text>
                                    </View>
                                    <View style={{ ...styles.flex_2, ...styles.col }}>
                                        <Text >
                                            {data.verifiedOnDate ? displayDate(data.verifiedOnDate) : ""}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.font_12, ...styles.flexBox, ...styles.bold }}>
                                <View style={{ ...styles.flex_2 }}>
                                    <View style={{ width: "100%", textAlign: 'center', ...styles.col }}>
                                        <Text style={{}}>For IncepBio Private Limited</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, }}>
                                    <View style={{ width: "100%", textAlign: 'center', ...styles.col }}>
                                        <Text style={{}}>For {data?.client?.companyName}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>

                {/* --- footer ---- */}
                <View wrap={false} style={{ ...styles.paddingY, fontWeight: 900, ...styles.section, ...styles.font_12 }} fixed>
                    <View>
                        <Text style={{ textAlign: 'center', ...styles.paddingTB }} render={({ pageNumber, totalPages }) => {
                            return (`Page ${pageNumber} of ${totalPages}`);
                        }} fixed />
                    </View>
                    <View style={{ color: "#004D88", }}>
                        <Text style={{ ...styles.font_13, ...styles.bold }}>Incepbio Private Limited</Text>
                    </View>
                    <View style={{ ...styles.paddingTB, ...styles.footer, ...styles.flexBox, width: "80%", alignItems: 'center' }}>
                        <Image src={Location} style={{ ...styles.footerImage }}></Image>
                        <Text style={{}}>
                            #24, 22nd Main, Marenahalli, J.P. Nagar 2nd Phase, Bengaluru - 560078
                        </Text>
                        <Text style={{ width: 20, height: 20, borderRight: '4px solid #FFF', borderBottom: '4px solid #FFF', transform: 'rotate(-45deg)' }}>

                        </Text>
                        <Image src={Call} style={{ marginLeft: 8, ...styles.footerImage }}></Image>
                        <Text>+91-63669 42391</Text>
                    </View>

                    <View style={{ ...styles.flexBox, ...styles.paddingTB, alignItems: 'center' }}>
                        <Image src={WWW} style={{ ...styles.footerImage, width: 18 }}></Image>
                        <Text style={{ ...styles.bold }}>
                            support@incepbio.com
                        </Text>
                    </View>
                </View>
            </Page >
        </Document >
    )
}

export interface IProps {
    data: ICleanroomEquipmentModel,
    id: number,
    currentUser: IUserModel,
    testName: string,
    title: string,
    callBack: any,
}
const BasePDFComponent = ({ id, data, currentUser, testName, title, callBack }: IProps) => {
    const [loading, setLoading] = useState(false);

    const dispatch: any = useDispatch();

    const downloadPdf = async () => {
        let res: any;
        let arr: any;
        setLoading(true);
        try {
            if (id && testName == CleanroomEquipmentTests.airflowvelocity) {
                res = await TestService.GetAVById({ testName, id });
                if (res?.id) {
                    arr = res.observations ? [...res.observations] : [];
                    arr?.sort((item1: IAirflowVelocityTestObservationModel, item2: IAirflowVelocityTestObservationModel) => item2.roomReadings?.length - item1.roomReadings.length);
                    const fileName = `airvelocity-test.pdf`;
                    const blob = await pdf(<BaseTest testName={testName} title={title} maxReadings={arr?.[0]?.roomReadings?.length ?? 0} data={{ ...data, ...res }} callBack={callBack} />).toBlob();
                    saveAs(blob, fileName);
                }
            }
            else if (id && testName == CleanroomEquipmentTests.lightintensity) {
                res = await TestService.GetLITestById({ testName, id });
                if (res?.id) {
                    arr = res.observations ? [...res.observations] : [];
                    arr?.sort((item1: ILITestObservationModel, item2: ILITestObservationModel) => item2.roomReadings?.length - item1.roomReadings.length);
                    const fileName = `lightintensity-test.pdf`;
                    const blob = await pdf(<BaseTest testName={testName} title={title} maxReadings={arr?.[0]?.roomReadings?.length ?? 0} data={{ ...data, ...res }} callBack={callBack} />).toBlob();
                    saveAs(blob, fileName);
                }
            } else if (id && testName == CleanroomEquipmentTests.nonviableparticlecount) {
                res = await TestService.GetPCTestById({ testName, id });
                if (res?.id) {
                    arr = res.locations ? [...res.locations] : [];
                    const fileName = `non-viable-particle-count-test.pdf`;
                    const blob = await pdf(<BaseTest testName={testName} title={title} maxReadings={arr?.[0]?.roomReadings?.length ?? 0} data={{ ...data, ...res }} callBack={callBack} />).toBlob();
                    saveAs(blob, fileName);
                }
            }
            else if (id && testName == CleanroomEquipmentTests.soundlevel) {
                res = await TestService.GetSLTestById({ testName, id });
                if (res?.id) {
                    arr = res.observations ? [...res.observations] : [];
                    arr?.sort((item1: ISoundLevelObservationModel, item2: ISoundLevelObservationModel) => item2.roomReadings?.length - item1.roomReadings.length);
                    const fileName = `sound-level-test.pdf`;
                    const blob = await pdf(<BaseTest testName={testName} title={title} maxReadings={arr?.[0]?.roomReadings?.length ?? 0} data={{ ...data, ...res }} callBack={callBack} />).toBlob();
                    saveAs(blob, fileName);
                }
            }
            else if (id && testName == CleanroomEquipmentTests.uvlightintensity) {
                res = await TestService.GetUVLITestById({ testName, id });
                if (res?.id) {
                    arr = res.observations ? [...res.observations] : [];
                    arr?.sort((item1: IUVLightTestObservationModel, item2: IUVLightTestObservationModel) => item2.roomReadings?.length - item1.roomReadings.length);
                    const fileName = `uv-light-intensity-test.pdf`;
                    const blob = await pdf(<BaseTest testName={testName} title={title} maxReadings={arr?.[0]?.roomReadings?.length ?? 0} data={{ ...data, ...res }} callBack={callBack} />).toBlob();
                    saveAs(blob, fileName);
                }
            }
        } catch (errr) {

        } finally {
            setLoading(false);
            let date = moment(new Date()).toString();
            let obj = {
                testName: testName, data:
                {
                    ...res,
                    editLocked: true,
                    reportPdfGeneratedById: currentUser?.id,
                    reportPdfGeneratedOn: localToUtcDate(date),
                },
            };

            if (testName == CleanroomEquipmentTests.airflowvelocity) {
                dispatch(editAVTest(obj));
            }
            else if (testName == CleanroomEquipmentTests.lightintensity) {
                dispatch(editLITest(obj));
            }
            else if (testName == CleanroomEquipmentTests.nonviableparticlecount) {
                dispatch(editPCTest(obj));
            }
            else if (testName == CleanroomEquipmentTests.soundlevel) {
                dispatch(editSLTest(obj));
            }
            else if (testName == CleanroomEquipmentTests.uvlightintensity) {
                dispatch(editUVLITest(obj));
            }
        }
    };

    useEffect(() => {
        setLoading(false);
    }, [data]);

    return (<React.Fragment>
        {/* <PDFViewer width={'100%'}>
            <BaseTest
                data={data}
                maxReadings={10}
                testName={testName}
                title={title}
                callBack={callBack} />
        </PDFViewer> */}
        <button className="btn m-0 btn-primary px-3 py-2" onClick={downloadPdf}>{!loading ? "Download PDF" : "Loading"}</button>
    </React.Fragment>)
}

export default BasePDFComponent;





