import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import NotificationService from "../service/notification.service";
import { INotificationModel, IGetAllNotifications } from "../model/notification.model";
import { IRecentView } from "../model/recent-view.model";
import { paginationDefaultState} from "../../../common/model/defaults/pagination.default";

export interface State {
    recentNotifications: IRecentView,
    pageLoader: boolean,
    allNotifications: IGetAllNotifications,
    NotificationData: INotificationModel | any,
}

const defaultRecentView: IRecentView = {
    totalUnreadCount: 0,
    items: [],
}

const getAllNotificationsDefaults = {
    data:[],
    pagination:paginationDefaultState,
}

const initialState: State = {
    recentNotifications: defaultRecentView,
    pageLoader: false,
    allNotifications:getAllNotificationsDefaults,
    NotificationData: {},
}

export const quickView = createAsyncThunk("notification/quickView", NotificationService.QuickView);
export const loadAll = createAsyncThunk("notification", NotificationService.LoadAll);
export const markRead = createAsyncThunk("notification/markRead", NotificationService.MarkRead<number>);
export const markAllRead = createAsyncThunk("notification/markAllRead", NotificationService.MarkAllRead);

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        resetRecentNotifications: (state) => {
            state.recentNotifications = defaultRecentView;
        },
        changeCurrentPage:(state,action)=>{
           state.allNotifications.pagination.page = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(quickView.pending, (state, action) => {

        });
        builder.addCase(quickView.rejected, (state, action) => {
            state.recentNotifications = defaultRecentView;
        });
        builder.addCase(quickView.fulfilled, (state, action) => {
            let obj = { ...action.payload };
            if (obj.items?.length > 5) {
                obj.items = obj.items?.slice(0, 5);
            }
            state.recentNotifications = obj;
        });

        builder.addCase(loadAll.pending, (state, action) => {
            state.pageLoader = true;
            state.allNotifications = getAllNotificationsDefaults;
        });
        builder.addCase(loadAll.rejected, (state, action) => {
            state.pageLoader = false;
        });
        builder.addCase(loadAll.fulfilled, (state, action) => {
            state.pageLoader = false;
            state.allNotifications = action.payload;
        });

        builder.addCase(markAllRead.pending, (state, action) => {
            state.pageLoader = true;
        });
        builder.addCase(markAllRead.rejected, (state, action) => {
            state.pageLoader = false;
        });
        builder.addCase(markAllRead.fulfilled, (state, action) => {
            state.pageLoader = false;
        });

        builder.addCase(markRead.pending, (state, action) => {
            state.pageLoader = true;
        });
        builder.addCase(markRead.rejected, (state, action) => {
            state.pageLoader = false;
        });
        builder.addCase(markRead.fulfilled, (state, action) => {
            state.pageLoader = false;
        });

    }
});

const { actions, reducer } = notificationSlice;

export const { resetRecentNotifications,changeCurrentPage } = actions;

export default reducer;
