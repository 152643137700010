import ApiUrls from "../../../common/api-urls";
import {IPurchaseOrderLineItemModel} from "../model/purchase-order-item.model";
import {IPurchaseOrderRequestModel} from "../model/purchase-order-request.model";
import {IFormControlDropdownItemModel} from "../../../common/control/model/form.control.field.model";
import HttpService from "../../../common/service/http.service";

export default class PurchaseOrderLineItemService {
    static async LoadAll(request?: IPurchaseOrderRequestModel): Promise<IPurchaseOrderLineItemModel[]> {
        return await HttpService.Get<IPurchaseOrderLineItemModel[]>(ApiUrls.PurchaseOrderLineItem.GetAll(undefined, request));
    }

    static async Create(data: IPurchaseOrderLineItemModel): Promise<IPurchaseOrderLineItemModel> {
        return await HttpService.Post<IPurchaseOrderLineItemModel, IPurchaseOrderLineItemModel>(ApiUrls.PurchaseOrderLineItem.Create(), data);
    }

    static async Edit(data: IPurchaseOrderLineItemModel): Promise<IPurchaseOrderLineItemModel> {
        return await HttpService.Patch<IPurchaseOrderLineItemModel, IPurchaseOrderLineItemModel>(ApiUrls.PurchaseOrderLineItem.Edit(data.id), data);
    }
    static async Delete(id: number) {
        await HttpService.Delete(ApiUrls.PurchaseOrderLineItem.Delete(id));
   }

    static async GetById(id: number): Promise<IPurchaseOrderLineItemModel> {
        return await HttpService.Get<IPurchaseOrderLineItemModel>(ApiUrls.PurchaseOrderLineItem.GetById(id));
    }

    static async PurchaseOrderTypeDropdown(search: string): Promise<IFormControlDropdownItemModel[]> {
        const list: IFormControlDropdownItemModel[] = [
            { label: "Defined", value: "1" },
            { label: "Open", value: "2" }
        ];

        if(search == null || search === "") return list;
        return list.filter(l => l.label.indexOf(search) > 0)
    }
}
