import {createSlice, current, PayloadAction} from "@reduxjs/toolkit";
import {
    IFieldValidationResponseModel,
    IValidatorModel
} from "../model/validator.model";
import {defaultValidator} from "../model/defaults/validator.defaults";
import {ValidationScreens} from "../enums";
import ValidatorHelper from "../helper/validator-helper";

export interface IValidator {
    validatorData: IValidatorModel,
    validationResults: IFieldValidationResponseModel[],
    hasError: boolean
}

const initialState: IValidator = {
    validatorData: defaultValidator,
    validationResults: [],
    hasError: false
}

const validatorSlice = createSlice({
    name: 'validator',
    initialState,
    reducers: {
        setHasError: (state, action: PayloadAction<boolean>) => {
            state.hasError = action.payload;
        },
        setScreenValidator: (state, action: PayloadAction<ValidationScreens>) => {
            state.validationResults = [];
            state.hasError = false;
            state.validatorData =  ValidatorHelper.GetValidator(action.payload);
        },
        setValidationResults: (state, action: PayloadAction<IFieldValidationResponseModel[]>) => {
            state.validationResults = action.payload;
            const failed = current(state).validationResults.filter(v => v.failed);
            state.hasError = failed != null && failed.length > 0;
        },
        updateValidationResult: (state, action: PayloadAction<IFieldValidationResponseModel>) => {
            const index = current(state).validationResults.findIndex(r => r.fieldName === action.payload.fieldName);
            if(index > -1) {
                state.validationResults[index] = action.payload;
            } else {
                state.validationResults.push(action.payload);
            }

            const failed = current(state).validationResults.filter(v => v.failed);
            state.hasError = failed != null && failed.length > 0;
        }
    }
});

const { actions, reducer } = validatorSlice;

export const { setHasError, setScreenValidator, setValidationResults, updateValidationResult } = actions;

export default reducer;
