import React from "react";
import ScreenUrls from "../../../../common/screen-urls";
import { IUserModel } from "../../model/user.model";
import { IAppState } from "../../../../store";
import { connect } from "react-redux";
import { clearUserDataState, createUser, editUser, loadUserById, updateUserDataState } from "../../store/user.slice";
import AsyncSelect from "react-select/async";
import DropdownService from "../../../../common/service/dropdown.service";
import { DeferredLoadOptions } from "../../../../common/utils";
import { Link } from "react-router-dom";
import { IStoreSaveModel } from "../../../../common/model/store-save.model";
import AccessRoleService from "../../../access/service/access-role.service";
import UserService from "../../service/user.service";
import { UserStatus, ValidationScreens,EntityType } from "../../../../common/enums";
import DropdownDisplayHelper from "../../../../common/helper/dropdown-display.helper";
import { IFormControlDropdownItemModel } from "../../../../common/control/model/form.control.field.model";
import { IAccessRoleModel } from "../../../access/model/access-role.model";
import { FormBaseComponent, IFormBaseState } from "../../../../common/component/form-base.component";
import ValidationMessageControl from "../../../../common/control/validation-message.control";
import RouteHelper from "../../../../common/helper/route-helper";
import MultiFileControl from "../../../../common/control/file/multi-file.control";

interface IProps {
    accessLevel: number,
    id?: string,
    userData: IUserModel,
    dataLoading: boolean,
    createEditLoading: boolean,
    createUser: (data: IUserModel) => void,
    editUser: (data: IUserModel) => void,
    loadUserById: (id: number) => void,
    updateUserDataState: (payload: IStoreSaveModel) => void;
    clearUserDataState: () => void;
    navigation?: any,
}

export interface IState extends IFormBaseState {
    isSysAdmin: boolean
}

class UserCreateEditPage extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            hideForm: false,
            isSysAdmin: RouteHelper.IsSystemAdmin()
        }
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.User);
        if (this.props.id != null) {
            await this.props.loadUserById(Number(this.props.id));
        }
        else {
            await this.props.clearUserDataState();
        }
    }

    loadStatus = (inputValue: string) => DeferredLoadOptions(inputValue, DropdownService.UserStatusList);
    loadUserOption = (inputValue: string) => DeferredLoadOptions(inputValue, UserService.UserDropdown);
    loadAccessRoleOption = (inputValue: string) => DeferredLoadOptions(inputValue, AccessRoleService.AccessRoleDropdown);

    render() {

        return <React.Fragment>
            <div className="pcoded-content">
                <div className="pcoded-inner-content">
                    <div className="page-header">
                        <div className="page-block">
                            <div className="row">
                                <div className="col-md-12">
                                    <Link to={ScreenUrls.Users.Index()}
                                        className="btn drp-icon btn-rounded btn-primary dropdown-toggle">
                                        <i className="feather icon-arrow-left"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-body">
                        <div className="page-wrapper">
                            <div className="row">
                                <div className="col-sm-12">
                                    {(() => {
                                        if (this.state.hideForm) return;
                                        return <React.Fragment>
                                            <article className="card">
                                                {(() => { if (this.props.dataLoading) return <div className="loading--bar"><span></span></div> })()}
                                                <div className="card-header">
                                                    <h4 className="card-title mb-0">
                                                        Basic Info
                                                    </h4>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className={`form-group ${this.vResult("firstName").className}`}>
                                                                <label htmlFor="firstName">First Name<span className="requried-span">*</span></label>
                                                                <input id="firstName" className="form-control" type="text"
                                                                    value={this.props.userData?.firstName ?? ""}
                                                                    onChange={e => {
                                                                        e.preventDefault();
                                                                        this.ValidateField("firstName", e.target.value);
                                                                        this.props.updateUserDataState({ name: "firstName", value: e.target.value });
                                                                    }} />
                                                                <ValidationMessageControl message={this.vResult("firstName").message} />
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className={`form-group ${this.vResult("lastName").className}`}>
                                                                <label htmlFor="lastName">Last Name</label>
                                                                <input id="lastName" className="form-control" type="text"
                                                                    value={this.props.userData?.lastName ?? ""}
                                                                    onChange={e => {
                                                                        e.preventDefault();
                                                                        this.ValidateField("lastName", e.target.value);
                                                                        this.props.updateUserDataState({ name: "lastName", value: e.target.value });
                                                                    }} />
                                                                <ValidationMessageControl message={this.vResult("lastName").message} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-5">
                                                            <div className={`form-group ${this.vResult("email").className}`}>
                                                                <label htmlFor="email">Email<span className="requried-span">*</span></label>
                                                                <input id="email" className="form-control" type="text"
                                                                    value={this.props.userData?.email ?? ""}
                                                                    onChange={e => {
                                                                        e.preventDefault();
                                                                        this.ValidateField("email", e.target.value);
                                                                        this.props.updateUserDataState({ name: "email", value: e.target.value });
                                                                    }} />
                                                                <ValidationMessageControl message={this.vResult("email").message} />
                                                            </div>
                                                        </div>
                                                        <div className="col-5">
                                                            <div className={`form-group ${this.vResult("phone").className}`}>
                                                                <label htmlFor="phone">Phone<span className="requried-span">*</span></label>
                                                                <input id="phone" className="form-control" type="text"
                                                                    value={this.props.userData?.phone ?? ""}
                                                                    onChange={e => {
                                                                        e.preventDefault();
                                                                        this.ValidateField("phone", e.target.value);
                                                                        this.props.updateUserDataState({ name: "phone", value: e.target.value });
                                                                    }} />
                                                                <ValidationMessageControl message={this.vResult("phone").message} />
                                                            </div>
                                                        </div>
                                                        <div className="col-2">
                                                            <div className={`form-group ${this.vResult("isActive").className}`}>
                                                                <label htmlFor="isActive">Status</label>
                                                                <AsyncSelect id="isActive" cacheOptions defaultOptions
                                                                    className="async-select-control"
                                                                    loadOptions={this.loadStatus}
                                                                    value={this.props.userData?.isActive != null
                                                                        ? {
                                                                            label: this.props.userData.isActive ? UserStatus[Number(UserStatus.Active)] : UserStatus[Number(UserStatus.InActive)],
                                                                            value: this.props.userData.isActive ? Number(UserStatus.Active) : Number(UserStatus.InActive),
                                                                        } : { label: "Select Status", value: "" }}
                                                                    onChange={e => {
                                                                        if (e == null) return;

                                                                        const isActive = Number(e?.value) === UserStatus.Active;
                                                                        this.ValidateField("isActive", isActive);
                                                                        this.props.updateUserDataState({ name: "isActive", value: isActive });
                                                                    }}
                                                                    isDisabled={!this.state.isSysAdmin}
                                                                />
                                                                <ValidationMessageControl message={this.vResult("isActive").message} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                            <article className="card">
                                                <div className="card-header">
                                                    <h4 className="card-title mb-0">
                                                        Employment Info
                                                    </h4>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <div className={`form-group ${this.vResult("employeeId").className}`}>
                                                                <label htmlFor="employeeId">Employee Id<span className="requried-span">*</span></label>
                                                                <input id="employeeId" className="form-control" type="text"
                                                                    value={this.props.userData?.employeeId ?? ""}
                                                                    onChange={e => {
                                                                        e.preventDefault();
                                                                        this.ValidateField("employeeId", e.target.value);
                                                                        this.props.updateUserDataState({ name: "employeeId", value: e.target.value });
                                                                    }} />
                                                                <ValidationMessageControl message={this.vResult("employeeId").message} />
                                                            </div>
                                                        </div>
                                                        <div className="col-3">
                                                            <div className={`form-group ${this.vResult("managerId").className}`}>
                                                                <label htmlFor="manager">Manager</label>
                                                                <AsyncSelect id="manager" cacheOptions defaultOptions
                                                                    className="async-select-control"
                                                                    loadOptions={this.loadUserOption}
                                                                    value={this.props.userData?.manager != null
                                                                        ? {
                                                                            label: DropdownDisplayHelper.User(this.props.userData.manager),
                                                                            value: this.props.userData.manager.id.toString()
                                                                        }
                                                                        : { label: "Select Manager", value: "" }}
                                                                    onChange={async e => {
                                                                        const uId = e?.value;
                                                                        const user = uId ? await UserService.GetById(Number(uId)) : null;
                                                                        this.ValidateField("managerId", uId);
                                                                        this.props.updateUserDataState({ name: "manager", value: user });
                                                                        this.props.updateUserDataState({ name: "managerId", value: uId });
                                                                    }} />
                                                                <ValidationMessageControl message={this.vResult("managerId").message} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                            <article className="card">
                                                <div className="card-header">
                                                    <h4 className="card-title mb-0">
                                                        Access Details<span className="requried-span">*</span>
                                                    </h4>
                                                </div>
                                                <div className="card-header">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className={`form-group ${this.vResult("accessRoles").className}`}>
                                                                <label htmlFor="roles">Access</label>
                                                                <AsyncSelect id="roles" cacheOptions defaultOptions
                                                                    className="async-select-control"
                                                                    loadOptions={this.loadAccessRoleOption}
                                                                    isMulti={true} hideSelectedOptions={true}
                                                                    value={this.props.userData?.accessRoles?.map(r => {
                                                                        return {
                                                                            label: DropdownDisplayHelper.AccessRole(r),
                                                                            value: r.id.toString()
                                                                        } as IFormControlDropdownItemModel;
                                                                    }) ?? []}
                                                                    onChange={async (e) => {
                                                                        const roleIds = e.map(e => {
                                                                            if (e == null) return;
                                                                            return e.value;
                                                                        });

                                                                        let roles: IAccessRoleModel[] = [];
                                                                        if (roleIds != null && roleIds.length > 0) {
                                                                            for (let i = 0; i < roleIds.length; i++) {
                                                                                const role = await AccessRoleService.GetById(Number(roleIds[i]));
                                                                                roles.push(role);
                                                                            }
                                                                        }

                                                                        this.ValidateField("accessRoles", roleIds);
                                                                        this.props.updateUserDataState({ name: "accessRoles", value: roles });
                                                                    }} />
                                                                <ValidationMessageControl message={this.vResult("accessRoles").message} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                            {/* Files */}
                                            {(() => {
                                                if (
                                                    this.props.userData?.id != null &&
                                                    this.props.userData.id > 0
                                                ) {
                                                    return (
                                                        <MultiFileControl
                                                            title="User Files"
                                                            entityType={EntityType.User}
                                                            entityId={this.props.userData.id}
                                                            editable={true}
                                                        />
                                                    );
                                                }
                                            })()}
                                        </React.Fragment>
                                    })()}
                                    <article className="card no-bg">

                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <button type="button" className="btn btn-primary btn--right"
                                                        disabled={this.props.userData == null || this.hasError}
                                                        onClick={e => {
                                                            e.preventDefault();

                                                            const error = !this.ValidateForm(this.props.userData);
                                                            this.reloadForm();
                                                            if (error) return;

                                                            if (this.props.userData.id > 0) {
                                                                this.props.editUser(this.props.userData);
                                                            } else {
                                                                this.props.createUser(this.props.userData);
                                                            }
                                                        }}>Submit</button>
                                                </div>
                                            </div>
                                        </div>

                                        {(() => { if (this.props.createEditLoading) return <div className="loading--bar"><span></span></div> })()}
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}

const mapStateToProps = (state: IAppState, other) => {

    return {
        userData: state.user.userData,
        dataLoading: state.user.dataLoading,
        createEditLoading: state.user.dataLoading
    }
}

export default connect(mapStateToProps, { loadUserById, createUser, editUser, clearUserDataState, updateUserDataState })(UserCreateEditPage);
