import { IFieldValidationModel, IValidatorModel } from "../../../../common/model/validator.model";
import { ValidationScreens, ValidationType } from "../../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "assigneeId",
        rules: [
            {
                type: ValidationType.Required,
                message: "Assignee is required"
            }
        ]
    },
    {
        fieldName: "clientConfirmationDate",
        dependencyFieldName: "qaReviewEndDate",
        validateIfFieldIsSet:"clientReviewRequired",
        rules: [
            {
                type: ValidationType.Required,
                message: "Client confirmation date is required"
            },
            {
                type: ValidationType.DateShouldBeLessThenCurrentDate,
                message: "Client confirmation date should be less than current date"
            },
            {

                type: ValidationType.DateShouldBeGreaterDependentDate,
                message: "Client confirmation date should be greater than QA review end date"

            }
        ]
    },
    {
        fieldName: "isReportSharedToClient",
        dependencyFieldName:"clientReviewRequired",
        validateIfFieldIsSet:"clientReviewRequired",
        rules: [
            {
                type: ValidationType.RequiredIfTrue,
                message: "Please complete report shared to client"
            }
        ]
    },
    {
        fieldName: "isClientReviewCompleted",
        dependencyFieldName:"clientReviewRequired",
        validateIfFieldIsSet:"clientReviewRequired",
        rules: [
            {
                type: ValidationType.RequiredIfTrue,
                message: "Please complete client review"
            }
        ]
    }
]

export const workflowTaskClientReviewStageValidator: IValidatorModel = {
    screen: ValidationScreens.WorkflowTaskClientReviewStage,
    fields: fields
}
