import {
    IDataLoggerModel,IGetAllDataLoggers
} from "../model/data-logger.model";
import HttpService from "../../../common/service/http.service";
import ApiUrls from "../../../common/api-urls";
import {IFormControlDropdownItemModel} from "../../../common/control/model/form.control.field.model";
import DropdownDisplayHelper from "../../../common/helper/dropdown-display.helper";
import {IDataLoggerFilterRequestModel} from "../model/request/data-logger-filter.rquest.model";

export default class DataLoggerService {
    static async LoadAll(request?: IDataLoggerFilterRequestModel): Promise<IGetAllDataLoggers> {
        return await HttpService.Get<IGetAllDataLoggers>(ApiUrls.DataLogger.GetAll(request));
    }

    static async Create(data: IDataLoggerModel): Promise<IDataLoggerModel> {
        return await HttpService.Post<IDataLoggerModel, IDataLoggerModel>(ApiUrls.DataLogger.Create(), data);
    }

    static async Edit(data: IDataLoggerModel): Promise<IDataLoggerModel> {
        return await HttpService.Patch<IDataLoggerModel, IDataLoggerModel>(ApiUrls.DataLogger.Edit(data.id), data);
    }

    static async Delete(id: number) {
        await HttpService.Delete(ApiUrls.DataLogger.Delete(id));
   }
    static async GetById(id: number): Promise<IDataLoggerModel> {
        return await HttpService.Get<IDataLoggerModel>(ApiUrls.DataLogger.GetById(id));
    }

    static async DataLoggerDropdown(search: string): Promise<IFormControlDropdownItemModel[]> {
        let req: IDataLoggerFilterRequestModel = { searchStr: search };
        return (await DataLoggerService.LoadAll(req))?.data?.map(d => {
            const obj: IFormControlDropdownItemModel = {
                label: DropdownDisplayHelper.DataLoggerMake(d),
                value: d.id.toString()
            }
            return obj;
        });
    }
}
