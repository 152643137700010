import { createAsyncThunk, createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import AuthService from "../../user/service/auth.service";
import { LocalStorageKeys } from "../../../common/enums";

export interface IPasswordRecoveryState {
    step: number,
    loading: boolean
}

export enum steps {
    "forgot-password",
    "reset-password",
    "reset-password-confirm"
}

const initialState: IPasswordRecoveryState = {
    step: steps["forgot-password"],
    loading: false,
}
export const ForgotPassword = createAsyncThunk("userSession/forgot-password", AuthService.ForgotPassword);
export const ResetPasswordAnonymous = createAsyncThunk("userSession/reset-password", AuthService.ResetPassword);

const PasswordRecoverySlice = createSlice({
    name: 'password-recovery',
    initialState,
    reducers: {
        changeStepReducer: (state, action) => {
            state.step = Number(steps[action.payload]);
        },
    },
    extraReducers: (builder) => {

        builder.addCase(ForgotPassword.fulfilled, (state, action:any) => {
            state.loading = false;
            if (action.payload?.accessToken?.length > 0) {
                state.step = steps["reset-password"];
                localStorage.setItem(LocalStorageKeys.AccessToken.toString(), action.payload.accessToken);
            }
        });
        builder.addCase(ResetPasswordAnonymous.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload == "Ok") {
                state.step = steps["reset-password-confirm"];
            }
        });
        builder.addMatcher(isPending(ForgotPassword, ResetPasswordAnonymous), (state, action) => {
            state.loading = true;
        });

        builder.addMatcher(isRejected(ForgotPassword, ResetPasswordAnonymous), (state, action) => {
            state.loading = false;
        });
    }
});

const { actions, reducer } = PasswordRecoverySlice;

export const { changeStepReducer } = actions;

export default reducer;
