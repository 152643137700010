import { createAsyncThunk, createSlice, PayloadAction,isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import { ICleanroomEquipmentModel } from '../../model/report.model';
import { cleanroomEquipmentDefault } from "../../model/defaults/report.default";
import { IStoreSaveModel } from "../../../../common/model/store-save.model";
import ReportService from "../../service/report.service";
import ScreenUrls from "../../../../common/screen-urls";
import { deleteTest} from "./test.slice";

export const loadCleanroomEquipmentById = createAsyncThunk("report/cleanroomEquipment/loadById", ReportService.GetById);
export const createEquipment = createAsyncThunk("report/cleanroomEquipment/create", ReportService.Create);
export const editEquipment = createAsyncThunk("report/cleanroomEquipment/edit", ReportService.Edit);

export interface ICleanroomEquipmentState {
    reportList: ICleanroomEquipmentModel[],
    equipmentData: ICleanroomEquipmentModel,
    dataLoading: boolean,
    listLoading: boolean,
    createEditLoading: boolean
    stageMoveLoading: boolean,
    refetchReportData: boolean,
}

const initialState: ICleanroomEquipmentState = {
    reportList: [],
    equipmentData: cleanroomEquipmentDefault,
    dataLoading: false,
    listLoading: false,
    createEditLoading: false,
    stageMoveLoading: false,
    refetchReportData: false,
}

const cleanroomEquipmentSlice = createSlice({
    name: 'cleanroomequipment',
    initialState,
    reducers: {
        clearCleanroomEquipmentDataState: (state) => { state.equipmentData = cleanroomEquipmentDefault },
        updateCleanroomEquipmentDataState: (state, action: PayloadAction<IStoreSaveModel>) => {
            state.equipmentData[action.payload.name] = action.payload.value;
        }
    },
    extraReducers: (builder) => {
        // create
        builder.addCase(createEquipment.fulfilled, (state, action) => {
            state.equipmentData = action.payload;
            state.createEditLoading = false;
            window.location.href = ScreenUrls.Report.CleanroomEquipment.Edit(action.payload.id)
        });

        // edit
        builder.addCase(editEquipment.fulfilled, (state, action) => {
            state.equipmentData = action.payload;
            state.createEditLoading = false;
        });

        // load by id
        builder.addCase(loadCleanroomEquipmentById.pending, (state, action) => {
            state.dataLoading = true;
        });
        builder.addCase(loadCleanroomEquipmentById.rejected, (state, action) => {
            state.dataLoading = false;
        });
        builder.addCase(loadCleanroomEquipmentById.fulfilled, (state, action) => {
            state.equipmentData = { ...action.payload };
            state.dataLoading = false;
            if(state.refetchReportData){
                state.refetchReportData = false;
            }
        });
        //  -- create edit pending
        builder.addMatcher(isPending(createEquipment,editEquipment), (state, action) => {
            state.createEditLoading = true;
        });
        //  -- create edit rejected
        builder.addMatcher(isRejected(createEquipment,editEquipment), (state, action) => {
            state.createEditLoading = false;
        });

        //---- Report ----
        builder.addMatcher(isFulfilled(deleteTest), (state, action) => {
            state.refetchReportData = true;
        });

    }
});

const { actions, reducer } = cleanroomEquipmentSlice;

export const { clearCleanroomEquipmentDataState, updateCleanroomEquipmentDataState } = actions;

export default reducer;