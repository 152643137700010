import { IFieldValidationModel, IValidatorModel } from "../../../../../../common/model/validator.model";
import { ValidationScreens, ValidationType} from "../../../../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "locationNameOrNumber",
        rules: [
            {
                type: ValidationType.Required,
                message: "LocationNameOrNumber is required"
            }
        ]
    },
   
]

export const uvliTestObservationValidator: IValidatorModel = {
    screen: ValidationScreens.UVLightIntensityTestObservation,
    fields: fields
}
