import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ICommentModel } from "../model/comment.model";
import { commentDefault } from "../model/defaults/comment.default";
import CommentService from "../service/comment.service";
import { IPaginationModel } from "../model/pagination.model";
import { paginationDefaultState } from "../../common/model/defaults/pagination.default";
import { ReportCommentModel } from "../../features/report/model/report.model";

export interface ICommentState {
    commentList: ICommentModel[];
    commentData: ICommentModel | ReportCommentModel;
    dataLoading: boolean;
    listLoading: boolean;
    createEditLoading: boolean;
    pagination: IPaginationModel;
}

const initialState: ICommentState = {
    commentList: [],
    commentData: commentDefault,
    dataLoading: false,
    listLoading: false,
    createEditLoading: false,
    pagination: { ...paginationDefaultState },
}

export const loadComments = createAsyncThunk("comments/loadAll", CommentService.LoadAll);
export const createComment = createAsyncThunk("comments/create", CommentService.Create);
export const editComment = createAsyncThunk("comment/edit", CommentService.Edit);
export const deleteComment = createAsyncThunk("comment/delete", CommentService.Delete);

const commentSlice = createSlice({
    name: 'comments',
    initialState,
    reducers: {
        clearCommentListState: (state) => {
            if (state.pagination.page == 1) {
                state.commentList = [];
            }
        },
        setCommentData: (state, action) => {
            state.commentData = action.payload;
        },
        setCommentDescription: (state, action) => {
            if (state.commentData['commentDescription']) {
                state.commentData['commentDescription'] = action.payload;
            }

        },
    },
    extraReducers: (builder) => {
        // Load All
        builder.addCase(loadComments.pending, (state, action) => {
            clearCommentListState();
            state.listLoading = true;
        });
        builder.addCase(loadComments.rejected, (state, action) => {
            clearCommentListState();
            state.listLoading = false;
        });
        builder.addCase(loadComments.fulfilled, (state, action) => {
            state.commentList = action.payload?.pagination?.page == 1 ? action.payload?.data : [...state.commentList, ...action.payload.data];
            state.pagination = action.payload?.pagination;
            state.listLoading = false;
        });

        // Create
        builder.addCase(createComment.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(createComment.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(createComment.fulfilled, (state, action) => {
            if (action.payload?.id) {
                state.createEditLoading = false;
                state.commentList = [];
                state.pagination.page = 1;
                state.pagination.pageSize = 12;
            }
        });

        // Edit
        builder.addCase(editComment.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(editComment.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(editComment.fulfilled, (state, action) => {
            state.createEditLoading = false;
            state.commentList = [];
            state.pagination.page = 1;
            state.pagination.pageSize = 12;
        });

        // Delete
        builder.addCase(deleteComment.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(deleteComment.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(deleteComment.fulfilled, (state, action) => {
            state.createEditLoading = false;
            state.commentList = [];
            state.pagination.page = 1;
            state.pagination.pageSize = 12;
        });
    }
});

const { actions, reducer } = commentSlice;

export const { clearCommentListState, setCommentData, setCommentDescription } = actions;

export default reducer;
