import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IPurchaseOrderServiceItemModel} from "../model/purchase-order-item.model";
import PurchaseOrderServiceItemService from "../service/purchase-order-service-item.service";
import {purchaseOrderServiceItemDefault} from "../model/defaults/purchase-order.default";
import {IStoreSaveModel} from "../../../common/model/store-save.model";

export interface IPurchaseOrderServiceItemState {
    purchaseOrderServiceItems: IPurchaseOrderServiceItemModel[],
    purchaseOrderServiceItemData: IPurchaseOrderServiceItemModel,
    dataLoading: boolean,
    listLoading: boolean,
    createEditLoading: boolean
}

const initialState: IPurchaseOrderServiceItemState = {
    purchaseOrderServiceItems: [],
    purchaseOrderServiceItemData: purchaseOrderServiceItemDefault,
    dataLoading: false,
    listLoading: false,
    createEditLoading: false
}

export const loadPurchaseOrderServiceItems = createAsyncThunk("purchase-order/service/loadAll", PurchaseOrderServiceItemService.LoadAll);
export const loadPurchaseOrderServiceItemById = createAsyncThunk("purchase-order/service/loadById", PurchaseOrderServiceItemService.GetById);
export const createPurchaseOrderServiceItem = createAsyncThunk("purchase-order/service/create", PurchaseOrderServiceItemService.Create);
export const editPurchaseOrderServiceItem = createAsyncThunk("purchase-order/service/edit", PurchaseOrderServiceItemService.Edit);

const purchaseOrderServiceItemSlice = createSlice({
    name: 'purchase-order-service-item',
    initialState,
    reducers: {
        clearPurchaseOrderServiceItemDataState: (state) => { state.purchaseOrderServiceItemData = purchaseOrderServiceItemDefault },
        clearPurchaseOrderServiceItemListState: (state) => { state.purchaseOrderServiceItems = [] },
        updatePurchaseOrderServiceItemDataState: (state, action: PayloadAction<IStoreSaveModel>) => {
            state.purchaseOrderServiceItemData[action.payload.name] = action.payload.value;
        }
    },
    extraReducers: (builder) => {
        // Load All
        builder.addCase(loadPurchaseOrderServiceItems.pending, (state, action) => {
            clearPurchaseOrderServiceItemListState();
            state.listLoading = true;
        });
        builder.addCase(loadPurchaseOrderServiceItems.rejected, (state, action) => {
            clearPurchaseOrderServiceItemListState();
            state.listLoading = false;
        });
        builder.addCase(loadPurchaseOrderServiceItems.fulfilled, (state, action) => {
            state.purchaseOrderServiceItems = action.payload;
            state.listLoading = false;
        });

        // Load By ID
        builder.addCase(loadPurchaseOrderServiceItemById.pending, (state, action) => {
            clearPurchaseOrderServiceItemDataState();
            state.dataLoading = true;
        });
        builder.addCase(loadPurchaseOrderServiceItemById.rejected, (state, action) => {
            clearPurchaseOrderServiceItemDataState();
            state.dataLoading = false;
        });
        builder.addCase(loadPurchaseOrderServiceItemById.fulfilled, (state, action) => {
            state.purchaseOrderServiceItemData = action.payload;
            state.dataLoading = false;
        });

        // Create
        builder.addCase(createPurchaseOrderServiceItem.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(createPurchaseOrderServiceItem.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(createPurchaseOrderServiceItem.fulfilled, (state, action) => {
            state.createEditLoading = false;
            state.purchaseOrderServiceItemData = action.payload;
        });

        // Edit
        builder.addCase(editPurchaseOrderServiceItem.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(editPurchaseOrderServiceItem.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(editPurchaseOrderServiceItem.fulfilled, (state, action) => {
            state.purchaseOrderServiceItemData = action.payload;
            state.createEditLoading = false;
        });
    }
});

const { actions, reducer } = purchaseOrderServiceItemSlice;

export const { updatePurchaseOrderServiceItemDataState, clearPurchaseOrderServiceItemDataState, clearPurchaseOrderServiceItemListState } = actions;

export default reducer;
