import { IDashboardSummaryModel, IDataLoggerSummaryModel, ISummaryModel, IWorkflowSummaryModel } from "./dashboard.model";

export const summaryDefault: ISummaryModel = {
    displayName: "",
    count: 0
}

export const workflowSummaryDefault: IWorkflowSummaryModel = {
    completedWorkflowCount: 0,
    activeWorkflowCount: 0,
    totalWorkflowCount: 0,
    assigneeWiseSummary: [],
    stageWiseSummary: []
}

export const dashboardSummaryDefault: IDashboardSummaryModel = {
    workflow: workflowSummaryDefault,
    dataLogger: {
        availableDataLoggerCount: 0,
        bookedDataLoggerCount: 0,
        damagedDataLoggerCount: 0,
        totalDataLoggerCount: 0,
        calibrationDueExpired:0,
        makeWiseSummary: [],
        dueForCalibration:0,
    },
    workflowTask: workflowSummaryDefault,
}
