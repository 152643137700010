import HttpService from "../../../common/service/http.service";
import ApiUrls from "../../../common/api-urls";
import {IGetAllRequestModel} from "../../../common/model/get-all-request-model";
import {IFormControlDropdownItemModel} from "../../../common/control/model/form.control.field.model";
import { IDataLoggerCameraDetailModel } from "../model/data-logger-camera-detail.model";

export default class DataLoggerCameraDetailService {
    static async LoadAll(request?: IGetAllRequestModel): Promise<IDataLoggerCameraDetailModel[]> {
        return await HttpService.Get<IDataLoggerCameraDetailModel[]>(ApiUrls.DataLoggerCameraDetail.GetAll());
    }

    static async Create(data:IDataLoggerCameraDetailModel): Promise<IDataLoggerCameraDetailModel> {
        return await HttpService.Post<IDataLoggerCameraDetailModel, IDataLoggerCameraDetailModel>(ApiUrls.DataLoggerCameraDetail.Create(), data);
    }

    static async Edit(data:IDataLoggerCameraDetailModel): Promise<IDataLoggerCameraDetailModel> {
        return await HttpService.Patch<IDataLoggerCameraDetailModel, IDataLoggerCameraDetailModel>(ApiUrls.DataLoggerCameraDetail.Edit(data?.id ?? 0), data);
    }
    static async Delete(id: number) {
        await HttpService.Delete(ApiUrls.DataLoggerCameraDetail.Delete(id));
    }

    static async GetById(id: number): Promise<IDataLoggerCameraDetailModel> {
        return await HttpService.Get<IDataLoggerCameraDetailModel>(ApiUrls.DataLoggerCameraDetail.GetById(id));
    }
}
