import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IPurchaseOrderLineItemModel} from "../model/purchase-order-item.model";
import {purchaseOrderLineItemDefault} from "../model/defaults/purchase-order.default";
import {IStoreSaveModel} from "../../../common/model/store-save.model";
import PurchaseOrderLineItemService from "../service/purchase-order-line-item.service";

export interface IPurchaseOrderLineItemState {
    purchaseOrderLineItemList: IPurchaseOrderLineItemModel[],
    purchaseOrderLineItemData: IPurchaseOrderLineItemModel,
    dataLoading: boolean,
    listLoading: boolean,
    createEditLoading: boolean
}

const initialState: IPurchaseOrderLineItemState = {
    purchaseOrderLineItemList: [],
    purchaseOrderLineItemData: purchaseOrderLineItemDefault,
    dataLoading: false,
    listLoading: false,
    createEditLoading: false
}

export const loadPurchaseOrderLineItems = createAsyncThunk("purchase-order/line-item/loadAll", PurchaseOrderLineItemService.LoadAll);
export const loadPurchaseOrderLineItemById = createAsyncThunk("purchase-order/line-item/loadById", PurchaseOrderLineItemService.GetById);
export const createPurchaseOrderLineItem = createAsyncThunk("purchase-order/line-item/create", PurchaseOrderLineItemService.Create);
export const editPurchaseOrderLineItem = createAsyncThunk("purchase-order/line-item/edit", PurchaseOrderLineItemService.Edit);

const purchaseOrderLineItemSlice = createSlice({
    name: 'purchase-order-line-item',
    initialState,
    reducers: {
        clearPurchaseOrderLineItemDataState: (state) => { state.purchaseOrderLineItemData = purchaseOrderLineItemDefault },
        clearPurchaseOrderLineItemListState: (state) => { state.purchaseOrderLineItemList = [] },
        updatePurchaseOrderLineItemDataState: (state, action: PayloadAction<IStoreSaveModel>) => {
            state.purchaseOrderLineItemData[action.payload.name] = action.payload.value;
        },
        setReturnUrlForWorkflowScreen: (state, action: PayloadAction<IStoreSaveModel>) => {
            state.purchaseOrderLineItemData[action.payload.name] = action.payload.value;
        }
    },
    extraReducers: (builder) => {
        // Load All
        builder.addCase(loadPurchaseOrderLineItems.pending, (state, action) => {
            clearPurchaseOrderLineItemListState();
            state.listLoading = true;
        });
        builder.addCase(loadPurchaseOrderLineItems.rejected, (state, action) => {
            clearPurchaseOrderLineItemListState();
            state.listLoading = false;
        });
        builder.addCase(loadPurchaseOrderLineItems.fulfilled, (state, action) => {
            state.purchaseOrderLineItemList = action.payload;
            state.listLoading = false;
        });

        // Load By ID
        builder.addCase(loadPurchaseOrderLineItemById.pending, (state, action) => {
            clearPurchaseOrderLineItemDataState();
            state.dataLoading = true;
        });
        builder.addCase(loadPurchaseOrderLineItemById.rejected, (state, action) => {
            clearPurchaseOrderLineItemDataState();
            state.dataLoading = false;
        });
        builder.addCase(loadPurchaseOrderLineItemById.fulfilled, (state, action) => {
            state.purchaseOrderLineItemData = action.payload;
            state.dataLoading = false;
        });

        // Create
        builder.addCase(createPurchaseOrderLineItem.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(createPurchaseOrderLineItem.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(createPurchaseOrderLineItem.fulfilled, (state, action) => {
            state.purchaseOrderLineItemData = action.payload;
            state.createEditLoading = false;
        });

        // Edit
        builder.addCase(editPurchaseOrderLineItem.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(editPurchaseOrderLineItem.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(editPurchaseOrderLineItem.fulfilled, (state, action) => {
            state.purchaseOrderLineItemData = action.payload;
            state.createEditLoading = false;
        });
    }
});

const { actions, reducer } = purchaseOrderLineItemSlice;

export const { updatePurchaseOrderLineItemDataState, clearPurchaseOrderLineItemDataState, clearPurchaseOrderLineItemListState } = actions;

export default reducer;
