import { matchPath, Route, Routes, useLocation } from "react-router-dom";
import AccountLayout from "../../common/screen/layout/account.layout";
import ScreenUrls from "../../common/screen-urls";
import WorkflowTaskCreateEditPage from "./screen/form/workflow-task-create-edit.page";
import WorkflowTaskViewPage from "./screen/workflow-task-view.page";
import RouteHelper from "../../common/helper/route-helper";
import { FeatureNames } from "../../common/enums";

const WorkflowTaskRoutes = () => {
    const workflowTask = RouteHelper.GetFeatureAccessLevel(FeatureNames.WorkflowTask);
    const location = useLocation();
    return <Routes>
        <Route element={<AccountLayout />}>

            <Route path={`${ScreenUrls.Workflows.Index()}/${ScreenUrls.Workflows.Task.indexPage(":workflowId")}`}>
                <Route path={ScreenUrls.ViewUriParam}
                    element={<WorkflowTaskViewPage
                        accessLevel={workflowTask.accessLevel}
                        id={matchPath({ path: ScreenUrls.Workflows.Task.View(":id", ":workflowId") }, location.pathname)?.params["id"]}
                        workflowId={matchPath({ path: ScreenUrls.Workflows.Task.View(":id", ":workflowId") }, location.pathname)?.params["workflowId"]} />} />
                <Route path={ScreenUrls.CreateUri}
                    element={<WorkflowTaskCreateEditPage
                        accessLevel={workflowTask.accessLevel}
                        workflowId={matchPath({ path: ScreenUrls.Workflows.Task.Create(":workflowId") }, location.pathname)?.params["workflowId"]} />} />
                <Route path={ScreenUrls.EditUriParam}
                    element={<WorkflowTaskCreateEditPage
                        accessLevel={workflowTask.accessLevel}
                        id={matchPath({ path: ScreenUrls.Workflows.Task.Edit(":id", ":workflowId") }, location.pathname)?.params["id"]}
                        workflowId={matchPath({ path: ScreenUrls.Workflows.Task.Edit(":id", ":workflowId") }, location.pathname)?.params["workflowId"]} />} />
            </Route>

        </Route>
    </Routes>
}

export default WorkflowTaskRoutes;
