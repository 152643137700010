import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {dataLoggerCameraDetailDefault} from "../model/defaults/data-logger.default";
import {IStoreSaveModel} from "../../../common/model/store-save.model";
import ScreenUrls, { redirectHandler} from "../../../common/screen-urls";
import { IDataLoggerCameraDetailModel } from "../model/data-logger-camera-detail.model";
import DataLoggerCameraDetailService from "../service/data-logger-camera-detail.service";

export interface IDataLoggerCameraDetailState {
    dataLoggerCameraDetailList: IDataLoggerCameraDetailModel[],
    dataLoggerCameraDetailData: IDataLoggerCameraDetailModel,
    dataLoading: boolean,
    listLoading: boolean,
    createEditLoading: boolean
}

const initialState: IDataLoggerCameraDetailState = {
    dataLoggerCameraDetailList: [],
    dataLoggerCameraDetailData: dataLoggerCameraDetailDefault,
    dataLoading: false,
    listLoading: false,
    createEditLoading: false
}

export const loadDataLoggerCameraDetails = createAsyncThunk("data-logger/camera/loadAll", DataLoggerCameraDetailService.LoadAll);
export const loadDataLoggerCameraDetailById = createAsyncThunk("data-logger/camera/loadById", DataLoggerCameraDetailService.GetById);
export const createDataLoggerCameraDetail = createAsyncThunk("data-logger/camera/create", DataLoggerCameraDetailService.Create);
export const editDataLoggerCameraDetail = createAsyncThunk("data-logger/camera/edit", DataLoggerCameraDetailService.Edit);

const dataLoggerCameraDetailSlice = createSlice({
    name: 'data-logger-camera-detail',
    initialState,
    reducers: {
        clearDataLoggerCameraDetailDataState: (state) => { state.dataLoggerCameraDetailData = dataLoggerCameraDetailDefault },
        clearDataLoggercameraDetailListState: (state) => {
            state.dataLoggerCameraDetailList = [];
        },
        updateDataLoggerCameraDetailDataState: (state, action: PayloadAction<IStoreSaveModel>) => {
            state.dataLoggerCameraDetailData[action.payload.name] = action.payload.value;
        }
    },
    extraReducers: (builder) => {
        // Load All
        builder.addCase(loadDataLoggerCameraDetails.pending, (state, action) => { state.listLoading = true; });
        builder.addCase(loadDataLoggerCameraDetails.rejected, (state, action) => {
            state.listLoading = false;
        });
        builder.addCase(loadDataLoggerCameraDetails.fulfilled, (state, action) => {
            state.dataLoggerCameraDetailList = action.payload;
            state.listLoading = false;
        });

        // Load By ID
        builder.addCase(loadDataLoggerCameraDetailById.pending, (state, action) => { state.dataLoading = true; });
        builder.addCase(loadDataLoggerCameraDetailById.rejected, (state, action) => {
            state.dataLoading = false;
        });
        builder.addCase(loadDataLoggerCameraDetailById.fulfilled, (state, action) => {
            state.dataLoggerCameraDetailData= action.payload;
            state.dataLoading = false;
        });

        // Create
        builder.addCase(createDataLoggerCameraDetail.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(createDataLoggerCameraDetail.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(createDataLoggerCameraDetail.fulfilled, (state, action) => {
            state.dataLoggerCameraDetailData = action.payload;
            state.createEditLoading = false;
            redirectHandler(ScreenUrls.DataLoggers.CameraDetail.Edit(action.payload.id))
        });

        // Edit
        builder.addCase(editDataLoggerCameraDetail.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(editDataLoggerCameraDetail.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(editDataLoggerCameraDetail.fulfilled, (state, action) => {
            state.dataLoggerCameraDetailData= action.payload;
            state.createEditLoading = false;
        });
    }
});

const { actions, reducer } = dataLoggerCameraDetailSlice;

export const { updateDataLoggerCameraDetailDataState,clearDataLoggerCameraDetailDataState,clearDataLoggercameraDetailListState} = actions;

export default reducer;
