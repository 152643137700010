import {matchPath, Route, Routes, useLocation} from "react-router-dom";
import AccountLayout from "../../common/screen/layout/account.layout";
import ScreenUrls from "../../common/screen-urls";
import PurchaseOrderPage from "./screen/purchase-order.page";
import PurchaseOrderCreateEditPage from "./screen/form/purchase-order.create-edit.page";
import PurchaseOrderServiceItemCreateEditPage from "./screen/form/purchase-order-service-item.create-edit.page";
import PurchaseOrderViewPage from "./screen/purchase-order-view.page";
import RouteHelper from "../../common/helper/route-helper";
import {AccessLevel, FeatureNames} from "../../common/enums";
import AccessDeniedPage from "../../common/screen/anonymous/access-denied.page";
import PurchaseOrderServiceItemPage from "./screen/purchase-order-service-item.page";
import PurchaseOrderLineItemCreateEditPage from "./screen/form/purchase-order-line-item.create-edit.page";

const PurchaseOrderRoutes = () => {
    const po = RouteHelper.GetFeatureAccessLevel(FeatureNames.PurchaseOrder);
    const poLineItem = RouteHelper.GetFeatureAccessLevel(FeatureNames.PurchaseOrderLineItem);
    const serviceItem = RouteHelper.GetFeatureAccessLevel(FeatureNames.PurchaseOrderServiceItem);
    const location = useLocation();

    return <Routes>
        <Route element={<AccountLayout />}>
            <Route path={ScreenUrls.PurchaseOrder.Index()}>
                <Route index element={po.accessLevel >= AccessLevel.View
                    ? <PurchaseOrderPage accessLevel={po.accessLevel} />
                    : <AccessDeniedPage /> } />
                <Route path={ScreenUrls.CreateUri} element={po.accessLevel >= AccessLevel.Create
                    ? <PurchaseOrderCreateEditPage accessLevel={po.accessLevel} accessLevelLineItem={poLineItem.accessLevel} />
                    : <AccessDeniedPage /> } />
                <Route path={ScreenUrls.EditUriParam}
                       element={po.accessLevel >= AccessLevel.Edit
                           ? <PurchaseOrderCreateEditPage
                               accessLevel={po.accessLevel} accessLevelLineItem={poLineItem.accessLevel}
                               id={matchPath({ path: ScreenUrls.PurchaseOrder.Edit(":id") }, location.pathname)?.params["id"]} />
                           : <AccessDeniedPage /> } />
                <Route path={ScreenUrls.ViewUriParam}
                       element={po.accessLevel >= AccessLevel.View
                           ? <PurchaseOrderViewPage
                               accessLevel={po.accessLevel}
                               id={matchPath({ path: ScreenUrls.PurchaseOrder.View(":id") }, location.pathname)?.params["id"]} />
                           : <AccessDeniedPage />} />


                <Route path={ScreenUrls.PurchaseOrder.ServiceItem.indexPage}>
                    <Route index element={serviceItem.accessLevel >= AccessLevel.View
                        ? <PurchaseOrderServiceItemPage accessLevel={po.accessLevel} />
                        : <AccessDeniedPage />} />
                    <Route path={ScreenUrls.CreateUri} element={serviceItem.accessLevel >= AccessLevel.Create
                        ?  <PurchaseOrderServiceItemCreateEditPage accessLevel={po.accessLevel} />
                        : <AccessDeniedPage />} />
                    <Route path={ScreenUrls.EditUriParam}
                           element={serviceItem.accessLevel >= AccessLevel.Edit
                               ?  <PurchaseOrderServiceItemCreateEditPage
                                    accessLevel={po.accessLevel}
                                    id={matchPath({ path: ScreenUrls.PurchaseOrder.ServiceItem.Edit(":id") }, location.pathname)?.params["id"]} />
                               : <AccessDeniedPage />} />
                </Route>

                <Route path={ScreenUrls.PurchaseOrder.LineItem.indexPage(":purchaseOrderId")}>
                    <Route path={ScreenUrls.CreateUri}
                           element={poLineItem.accessLevel >= AccessLevel.Create
                               ? <PurchaseOrderLineItemCreateEditPage
                                   purchaseOrderId={matchPath({ path: ScreenUrls.PurchaseOrder.LineItem.Create(":purchaseOrderId") }, location.pathname)?.params["purchaseOrderId"]}
                                   accessLevel={poLineItem.accessLevel} />
                               : <AccessDeniedPage /> } />
                    <Route path={ScreenUrls.EditUriParam}
                           element={poLineItem.accessLevel >= AccessLevel.Edit
                               ? <PurchaseOrderLineItemCreateEditPage
                                   id={matchPath({ path: ScreenUrls.PurchaseOrder.LineItem.Edit(":id", ":purchaseOrderId") }, location.pathname)?.params["id"]}
                                   purchaseOrderId={matchPath({ path: ScreenUrls.PurchaseOrder.LineItem.Edit(":id",":purchaseOrderId") }, location.pathname)?.params["purchaseOrderId"]}
                                   accessLevel={poLineItem.accessLevel} />
                               : <AccessDeniedPage /> } />
                </Route>
            </Route>

        </Route>
    </Routes>
}

export default PurchaseOrderRoutes;
