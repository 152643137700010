import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import DataLoggerMakeService from "../service/data-logger-make.service";
import {dataLoggerMakeDefault} from "../model/defaults/data-logger.default";
import {IStoreSaveModel} from "../../../common/model/store-save.model";
import {IDataLoggerMakeModel} from "../model/data-logger-make.model";
import ScreenUrls, { redirectHandler} from "../../../common/screen-urls";

export interface IDataLoggerMakeState {
    dataLoggerMakeList: IDataLoggerMakeModel[],
    dataLoggerMakeData: IDataLoggerMakeModel,
    dataLoading: boolean,
    listLoading: boolean,
    createEditLoading: boolean
}

const initialState: IDataLoggerMakeState = {
    dataLoggerMakeList: [],
    dataLoggerMakeData: dataLoggerMakeDefault,
    dataLoading: false,
    listLoading: false,
    createEditLoading: false
}

export const loadDataLoggerMakers = createAsyncThunk("data-logger/make/loadAll", DataLoggerMakeService.LoadAll);
export const loadDataLoggerMakeById = createAsyncThunk("data-logger/make/loadById", DataLoggerMakeService.GetById);
export const createDataLoggerMake = createAsyncThunk("data-logger/make/create", DataLoggerMakeService.Create);
export const editDataLoggerMake = createAsyncThunk("data-logger/make/edit", DataLoggerMakeService.Edit);

const dataLoggerMakeSlice = createSlice({
    name: 'data-logger-make',
    initialState,
    reducers: {
        clearDataLoggerMakeDataState: (state) => { state.dataLoggerMakeData = dataLoggerMakeDefault },
        clearDataLoggerMakeListState: (state) => {
            state.dataLoggerMakeList = [];
        },
        updateDataLoggerMakeDataState: (state, action: PayloadAction<IStoreSaveModel>) => {
            state.dataLoggerMakeData[action.payload.name] = action.payload.value;
        }
    },
    extraReducers: (builder) => {
        // Load All
        builder.addCase(loadDataLoggerMakers.pending, (state, action) => { state.listLoading = true; });
        builder.addCase(loadDataLoggerMakers.rejected, (state, action) => {
            state.listLoading = false;
        });
        builder.addCase(loadDataLoggerMakers.fulfilled, (state, action) => {
            state.dataLoggerMakeList = action.payload;
            state.listLoading = false;
        });

        // Load By ID
        builder.addCase(loadDataLoggerMakeById.pending, (state, action) => { state.dataLoading = true; });
        builder.addCase(loadDataLoggerMakeById.rejected, (state, action) => {
            state.dataLoading = false;
        });
        builder.addCase(loadDataLoggerMakeById.fulfilled, (state, action) => {
            state.dataLoggerMakeData = action.payload;
            state.dataLoading = false;
        });

        // Create
        builder.addCase(createDataLoggerMake.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(createDataLoggerMake.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(createDataLoggerMake.fulfilled, (state, action) => {
            state.dataLoggerMakeData = action.payload;
            state.createEditLoading = false;
            redirectHandler(ScreenUrls.DataLoggers.Make.Edit(action.payload.id))
        });

        // Edit
        builder.addCase(editDataLoggerMake.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(editDataLoggerMake.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(editDataLoggerMake.fulfilled, (state, action) => {
            state.dataLoggerMakeData = action.payload;
            state.createEditLoading = false;
        });
    }
});

const { actions, reducer } = dataLoggerMakeSlice;

export const { clearDataLoggerMakeDataState, clearDataLoggerMakeListState, updateDataLoggerMakeDataState } = actions;

export default reducer;
